import React, { useRef, useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Bar } from '@nivo/bar';

import { i18n } from "../../../../translate/i18n";
import { AuthContext } from "../../../../context/Auth/AuthContext";
import ErrorBoundary from "../../../../errors/Boundary";
import NoDataImg from '../../../../assets/noData.svg';
import useClosedTicketsPerUser from "../../../../hooks/useClosedTicketsPerUser";
import useDashboardChartsListUsers from "../../../../hooks/useDashboardChartsListUsers";
import useDashboardChartsQueuesUsers from "../../../../hooks/useDashboardChartsQueuesUsers";
import useOpenTicketsPerUser from "../../../../hooks/useOpenTicketsPerUser";

const useStyles = makeStyles(theme => ({
	dashboardContainer: {
		width: "100%",
		backgroundColor: theme.palette.background.paper,
		borderRadius: "5px",
		padding: "20px 0px 20px 0px",
		textIndent: "15px",
	},

  emptyBoxContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "50px",
  },

  emptyBoxImage: { width: "250px", height: "250px", },

  emptyBoxSpan: { fontSize: "20px", }
}));

const ChartTicketsPerUser = ({ initialDate, finalDate, ticketStatus }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const { user } = useContext(AuthContext);



  //  ***************
  //  ** Functions **
  //  ***************
  const GetListUsers = () => {
    const queryResult = useDashboardChartsListUsers();
    return queryResult;
  }

  const GetListQueues = () => {
    const queryResult = useDashboardChartsQueuesUsers();
    return queryResult;
  }

  const GetClosedTicketsPerUser = (initialDate, finalDate, considerInternalChats) => {
    const structuredInitialDate = initialDate ? `${initialDate}T00:00:00` : "2020-12-29T00:00:01";
    const structuredFinalDate = finalDate ? `${finalDate}T23:59:59` : "2022-12-29T23:59:59";
    const queryResult = useClosedTicketsPerUser({
      initialDate: structuredInitialDate, 
      finalDate: structuredFinalDate,
      considerInternalChats
    });
    return queryResult;
  }

  const GetOpenTicketsPerUser = (initialDate, finalDate, considerInternalChats) => {
    const structuredInitialDate = initialDate ? `${initialDate}T00:00:01` : "2020-12-29T00:00:01";
    const structuredFinalDate = finalDate ? `${finalDate}T23:59:59` : "2022-12-29T23:59:59";
    const queryResult = useOpenTicketsPerUser({
      initialDate: structuredInitialDate, 
      finalDate: structuredFinalDate,
      considerInternalChats
    });
    return queryResult;
  }



  //  ***********
  //  ** Datas **
  //  ***********
  const containerRef = useRef(null);
  const [width, setWidth] = useState(0);
  const usersList = GetListUsers();
  const usersCount = usersList ? usersList.length : 0;
  const height = 100 + 20 * usersCount;

  const queuesList = GetListQueues();
  const queuesNames = queuesList ? queuesList.map(obj => obj.name) : 0;
  const semFilaQueueName = queuesList ? queuesList.find(item => item.name === "SemFila") : 0;
  
  if (queuesNames !== 0 && (!semFilaQueueName && semFilaQueueName !== 0)) { 
    queuesNames.push("SemFila");
    queuesList.push({ id: 0, name: "SemFila", color: "#818c7e" });
  }

  let ticketsPerQueueColors = [];

  if (queuesList) {
    for (let i = 0; i < queuesList.length; i += 1) {
      ticketsPerQueueColors.push(queuesList[i].color);
    }
  }

  // ***---- Puxando todos os tickets ----***
  let ticketsList = 0;

  if (ticketStatus === "closed") {
    ticketsList = GetClosedTicketsPerUser(initialDate, finalDate, false);
  }
  else if (ticketStatus === "open") {
    ticketsList = GetOpenTicketsPerUser(initialDate, finalDate, false);
  }

  // ***---- Agrupando a contagem dos tickets por usuários e, depois, por fila ----***
  const structuredTicketsList = (ticketsList === 0 || ticketsList === undefined || ticketsList.length === 0 || !usersList) ? 0 : ticketsList.reduce((acc, ticket) => {
    const userIndex = acc.findIndex(user => user.userId === ticket.userId);
    const queueId = ticket.queueId !== "SemFila" ? ticket.queueId : 0;

    if (userIndex === -1) {
      acc.push({ userId: ticket.userId, [`queueId${queueId}`]: queueId, [`count${queueId}`]: ticket.count });
    } 
    else {
      const currentUser = acc[userIndex];
      currentUser[`queueId${queueId}`] = queueId;
      currentUser[`count${queueId}`] = ticket.count;
    }

    return acc;
  }, []);

  // ***---- Descodificando todos os Ids pelos valores ----***
  const decodedStructuredTicketsList = structuredTicketsList === 0 ? 0 : structuredTicketsList.map(obj => {
    try {
      let result = {};
    
      for (const key in obj) {
        if (key.includes('queueId')) {
          const queueId = obj[key];
          result[key] = queuesList.find(queue => queue.id === queueId).name;
        } 
        else if (key.includes('userId')) {
          result.user = usersList.find(user => user.id === obj[key]).name;
        } 
        else {
          result[key] = obj[key];
        }
      }
      return result;
    }
    catch (err) {
      return 0;
    }
  });

  // ***---- Formatando o Array Decodificado ----***
  let transformedStructuredTicketsList = decodedStructuredTicketsList === 0 ? 0 : decodedStructuredTicketsList.map(obj => {
    let result = {};
    result.user = obj.user;

    for (const key in obj) {
      if (key.includes("queueId")) {
        const queue = obj[key];
        const count = obj[key.replace("queueId", "count")];
        result[queue] = count;
      }
    }

    return result;
  });

  // ***---- Filtrando Array Transformado ----***
  let filteredStructuredTicketsList = 0;

  // se dados já foram transformados e usuário é administrador, este verá
  // informações de todos
  if (transformedStructuredTicketsList !== 0 && user.profile === "admin") {
    filteredStructuredTicketsList = transformedStructuredTicketsList;
  }

  // se dados já foram transformados e o usuário não é administrador, este verá
  // apenas as informações dele mesmo
  else if (transformedStructuredTicketsList !== 0 && user.profile !== "admin") {
    filteredStructuredTicketsList = transformedStructuredTicketsList.filter(item => item.user === user.name);
  }

  // ***---- Adicionando contador 'totalTickets' e ordenando dados pela contagem em ordem descendente (▲ para ▼) ----***
  if (filteredStructuredTicketsList !== 0) {
    filteredStructuredTicketsList = filteredStructuredTicketsList.map(item => {
      let totalTickets = Object.keys(item).reduce((sum, key) => {
        if (key !== "user") sum += item[key];
        return sum;
      }, 0);

      return { ...item, totalTickets };
    });

    filteredStructuredTicketsList.sort((itemA, itemB) => itemA.totalTickets - itemB.totalTickets);
  }


  //  **************************************
  //  ** Use Effects and Resize Observers **
  //  **************************************
  useEffect(() => {
    setWidth(containerRef.current.offsetWidth);
  }, []);

  function outputsize() {
    const mainContainer = document.getElementById("mainContainer");
    setWidth(mainContainer.offsetWidth);
  }
  
  useEffect(() => {
    const mainContainer = document.getElementById("mainContainer");
    outputsize();
    new ResizeObserver(outputsize).observe(mainContainer);
  });



  //  ************
  //  ** Return **
  //  ************
  return (
    <div ref={containerRef} className={classes.dashboardContainer} id="mainContainer">
      <ErrorBoundary>
        {(filteredStructuredTicketsList !== 0 && filteredStructuredTicketsList.length > 0) && (
          <>
            <Bar
              data={filteredStructuredTicketsList}
              keys={queuesNames}
              width={width}
              height={height}

              theme={{ 
                textColor: "#999",
                tooltip: { container: { background: "#999", color: "#fff" } },
              }}

              indexBy="user"
              margin={{ top: 50, right: 130, bottom: 50, left: 100 }}
              padding={0.3}
              layout="horizontal"
              valueScale={{ type: 'linear' }}
              indexScale={{ type: 'band', round: true }}
              colors= {ticketsPerQueueColors}
              borderColor={{from: 'color', modifiers: [['darker', 2]]}}
              axisTop={null}
              axisRight={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: 32
              }}
              axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: -40
              }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              labelTextColor={"#000"}
              tooltipLabel={(label) => label.id === 'SemFila' ? 'Sem Fila' : label.id}
              legendLabel={(label) => label.id === 'SemFila' ? 'Sem Fila' : label.id}
              legends={[{
                dataFrom: "keys",
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemTextColor: "#999",
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [{ on: 'hover', style: { itemOpacity: 1 } }]
              }
              ]}
              motionConfig={{
                mass: 1,
                tension: 170,
                friction: 71,
                clamp: false,
                precision: 0.01,
                velocity: 0
              }}
              role="application"
            />
          </>
        )}

        {(filteredStructuredTicketsList === 0 || filteredStructuredTicketsList.length === 0) && (
          <div className={classes.emptyBoxContainer}>
            <img className={classes.emptyBoxImage} src={NoDataImg} alt="No Data" />
            <span className={classes.emptyBoxSpan}>{i18n.t("dashboard.emptyBox")}</span>
          </div>
        )}
      </ErrorBoundary>
    </div>
  );
}

export default ChartTicketsPerUser;