import React, { useState, useEffect } from "react";
import { copyImageToClipboard } from 'copy-image-clipboard';
import { toast } from "react-toastify";
import {
  Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
  IconButton,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Alert from '@material-ui/lab/Alert';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import SendIcon from '@material-ui/icons/Send';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import Webcam from "webcam-easy";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	videoContainer: { width: "95%", height: "80%", },

  displayedElement: { display: "block", },

  notDisplayedElement: { display: "none", },
  
  iconButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  
  floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)', },
  }
}));

const CameraModal = ({ open, onClose, ticketId }) => {
  //  ***************
  //  ** Variables **
  //  ***************
	const classes = useStyles();

  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isCameraSnapped, setIsCameraSnapped] = useState(false);

  const [webcam, setWebcam] = useState(null);
  const [webcamList, setWebcamList] = useState([]);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (!open) return;

    const getVideoInputs = (mediaDevicesArray) => {
      const videoDevicesArray = mediaDevicesArray.filter(mediaDevice => mediaDevice.kind === "videoinput");
      setWebcamList(videoDevicesArray);
    };

    navigator.mediaDevices.enumerateDevices()
      .then(getVideoInputs)
      .catch(exception => {
        toast.info(i18n.t("cameraModal.toasts.exceptionListingDevices"));
        console.log("Exception caught when trying to list video devices:", exception);
      });
  }, [open]);
  


  //  ***************
  //  ** Functions **
  //  ***************
	const handleClose = () => {
    webcam && webcam.stop();
    setIsCameraOn(false);
    setIsCameraSnapped(false);
		onClose();
	};

  const handleTurnOnCamera = () => {
    const webcamElement = document.getElementById("video");
    const canvasElement = document.getElementById("canvas");
    const webcamAPI = new Webcam(webcamElement, "user", canvasElement);

    webcamAPI.stream()
      .then(() => { setWebcam(webcamAPI); })
      .catch(err => { console.log("WebCam Error:", err); });

    webcamAPI.flip();

    setIsCameraSnapped(false);
    setIsCameraOn(true);
  };

  const handleTurnOffCamera = () => { 
    webcam.stop();
    setIsCameraOn(false);
    setIsCameraSnapped(false);
  };

  const handleSnapCamera = () => {
    webcam.snap();
    handleTurnOffCamera();
    setIsCameraSnapped(true);
  }

  const handleCopyPhoto = () => {
    const canvasElement = document.getElementById("canvas");
    const photoUrl = canvasElement.toDataURL();
    copyImageToClipboard(photoUrl);
  };

  function handleDataURIToBlob(dataURI) {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
    const bytesArray = new Uint8Array(byteString.length);

    for (let indexToDecode = 0; indexToDecode < byteString.length; indexToDecode++) {
      bytesArray[indexToDecode] = byteString.charCodeAt(indexToDecode);
    }

    return new Blob([bytesArray], { type: mimeString });
  }

  const handleSendPhoto = async () => {
    try {
      const canvasElement = document.getElementById("canvas");
      const photoUrl = canvasElement.toDataURL();
      const photoFile = handleDataURIToBlob(photoUrl);

      const formData = new FormData();
      formData.append("body", "");
      formData.append("fromMe", true);
      formData.append("medias", photoFile, "image.png");

      handleClose();
      await api.post(`/messages/${ticketId}`, formData);
    } catch (error) {
      console.log("Handle Send Photo Error:", error);
      toastError(error);
    }
  };



  //  ************
  //  ** Return **
  //  ************
	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} fullWidth scroll="paper">
				<DialogTitle id="form-dialog-title">
					{i18n.t("cameraModal.title")}
				</DialogTitle>

        <DialogContent dividers>
          {webcamList.length === 0 && (<Alert severity="info">{i18n.t("cameraModal.messages.noVideoInputs")}</Alert>)}

          {webcamList.length > 0 && (
            <>
              {/*
                ***********
                ** Video **
                ***********
              */}
              <div>
                <video 
                  id="video"
                  className={isCameraSnapped 
                    ? `${classes.videoContainer} ${classes.notDisplayedElement}` 
                    : `${classes.videoContainer} ${classes.displayedElement}`}
                  autoPlay
                  playsInline
                />

                <canvas 
                  id="canvas" 
                  className={isCameraSnapped 
                    ? `${classes.videoContainer} ${classes.displayedElement}` 
                    : `${classes.videoContainer} ${classes.notDisplayedElement}`}
                />
              </div>

              {/* 
                *************
                ** Buttons **
                *************
              */}
              <div className={classes.iconButtonsContainer}>
                <Tooltip title={i18n.t("cameraModal.tooltips.turnOnCamera")} placement="top-start" arrow>
                  <IconButton
                    color="inherit"
                    className={classes.floatingButton}
                    onClick={handleTurnOnCamera}
                    disabled={isCameraOn ? true : false}
                  >
                    <VideocamIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title={i18n.t("cameraModal.tooltips.turnOffCamera")} placement="top-start" arrow>
                  <IconButton
                    color="inherit"
                    className={classes.floatingButton}
                    onClick={handleTurnOffCamera}
                    disabled={isCameraOn ? false : true}
                  >
                    <VideocamOffIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title={i18n.t("cameraModal.tooltips.snapCamera")} placement="top-start" arrow>
                  <IconButton
                    color="inherit"
                    className={classes.floatingButton}
                    onClick={handleSnapCamera}
                    disabled={isCameraOn ? false : true}
                  >
                    <AddAPhotoIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title={i18n.t("cameraModal.tooltips.copyPhoto")} placement="top-start" arrow>
                  <IconButton
                    color="inherit"
                    className={classes.floatingButton}
                    onClick={handleCopyPhoto}
                    disabled={isCameraSnapped ? false : true}
                  >
                    <PhotoLibraryIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title={i18n.t("cameraModal.tooltips.sendPhoto")} placement="top-start" arrow>
                  <IconButton
                    color="inherit"
                    className={classes.floatingButton}
                    onClick={async () => await handleSendPhoto() }
                    disabled={isCameraSnapped ? false : true}
                  >
                    <SendIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="inherit"
            variant="outlined"
            className={classes.floatingButton}
          >
            {i18n.t("cameraModal.buttons.cancel")}
          </Button>
        </DialogActions>
			</Dialog>
		</div>
	);
};

export default CameraModal;
