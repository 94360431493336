import React from "react";
import { Avatar, CardHeader } from "@material-ui/core";

import truncateString from "../../utils/truncateString";
import WhatsappOfficialImg from "../../assets/whatsappOfficial.jpg";

const ChatInfo = ({ contact }) => {
  //  ************
  //  ** Return **
  //  ************
  return (
    <CardHeader
      titleTypographyProps={{ noWrap: true }}
      subheaderTypographyProps={{ noWrap: true }}
      avatar={<Avatar src={contact?.name === "WhatsApp Official ✔️" ? WhatsappOfficialImg : contact?.profilePicUrl} alt="contact_image" />}
      title={contact?.name ? `${truncateString(contact?.name, 27)}` : ""}
    />
  );
};

export default ChatInfo;