import React, { useContext, useEffect, useState } from "react";
import { makeStyles, styled } from "@material-ui/core/styles";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@material-ui/lab";
import {
  Badge,
  InputAdornment,
  Paper,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@material-ui/core";

import Android from '@material-ui/icons/Android';
import AddCommentOutlinedIcon from '@material-ui/icons/AddCommentOutlined';
import EventNoteOutlined from '@material-ui/icons/EventNoteOutlined';
import ModeCommentOutlinedIcon from '@material-ui/icons/ModeCommentOutlined';
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import RecordVoiceOverOutlinedIcon from '@material-ui/icons/RecordVoiceOverOutlined';
import SearchIcon from "@material-ui/icons/Search";
import TrackChangesOutlinedIcon from '@material-ui/icons/TrackChangesOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import NewInternalChatModal from "../NewInternalChatModal";
import NewNoteModal from "../NewNoteModal";
import NewOpenAIChatModal from "../NewOpenAIChatModal";
import NewTicketModal from "../NewTicketModal";
import TabPanel from "../TabPanel";
import TicketsList from "../TicketsList";
import toastError from "../../errors/toastError";
import useAllUsersList from "../../hooks/useAllUsersList";
import UserSelect from "../UserSelect";
import WhatsappSelect from "../WhatsappSelect";

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
    top: theme.spacing(2),
    left: theme.spacing(2),
  },
}));

const StyledBotButton = styled(SpeedDial)(({ theme }) => ({
  position: 'absolute',
  '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
    bottom: theme.spacing(2),
    left: "15px",
  },
}));

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    borderRadius:"20px",
    position: "relative",
    display: "flex",
    minHeight: "350px",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
  },

  ticketManagerButtonsContainer: { height: "15%", },

  tab: { borderRadius:"20px", minWidth: 120, width: 120, },
  
  badge: { borderRadius:"20px", right: "-10px", },
  
  speedDialIconButton: { borderRadius:"20px", color: theme.palette.text.primary, },

  inputField: {
    alignSelf: "center",
    width: "98%",
    height: "50px",

    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.text.secondary,
        borderRadius: "20px",
        height: "50px",
      },
    },
  },

  selectFilters:{
    padding : '0px 1em 1em 1em',

    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    gap: "5px",

    [theme.breakpoints.down("sm")]: { flexDirection: "column", },
  },

  selectContainer: {
    width: "50%",
    [theme.breakpoints.down("sm")]: { width: "100%", }
  },
}));

const TicketsManager = ({ tabOpen, setTabOpen, resetAudioPlayer }) => {
  //  ***************
  //  ** Variables **
  //  ***************

  // ***---- General Variables ----***
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { findAll: findAllUsers } = useAllUsersList();
  const { user } = useContext(AuthContext);

  // ***---- Actions ----***
  const [open, setOpen] = React.useState(false);
  const [newInternalChatModalOpen, setNewInternalChatModalOpen] = useState(false);
  const [newNoteModalOpen, setNewNoteModalOpen] = useState(false);
  const [newOpenAiModalOpen, setNewOpenAiModalOpen] = useState(false);
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const [showAllTickets, setShowAllTickets] = useState(localStorage.getItem("showAllTickets") === "1" ? true : false);

  // ***---- Counts ----***
  const [openCount, setOpenCount] = useState(0);
  const [pendingCount, setPendingCount] = useState(0);
  const [groupCount, setGroupCount] = useState(0);
  const [channelCount, setChannelCount] = useState(0);

  // ***---- Filters: Selects ----***
  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [usersList, setUsersList] = useState([]);

  const [selectedConnectionIds, setSelectedConnectionIds] = useState([]);
  const [connectionsList, setConnectionsList] = useState([]);

  // ***---- Filters: Text ----***
  const [channelFilterValue, setChannelFilterValue] = useState("");
  const [closedFilterValue, setClosedFilterValue] = useState("");
  const [groupFilterValue, setGroupFilterValue] = useState("");
  const [openFilterValue, setOpenFilterValue] = useState("");
  const [pendingFilterValue, setPendingFilterValue] = useState("");

  // ***---- Settings ----***
  const [adminsPendingTicketsControl, setAdminsPendingTicketsControl] = useState("disabled");
  const [allowGroups, setAllowGroups] = useState([]);
  const [allowChannels, setAllowChannels] = useState([]);

  // ***---- URL Query ----***
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const tabRequest = (urlParams.get('tab') ? urlParams.get('tab') : "open");
  const [tab, setTab] = useState("open");



  //  *************
  //  ** Actions **
  //  *************
  const actions = [
    { 
      icon: <AddCommentOutlinedIcon className={classes.speedDialIconButton}/>, 
      name: 'NewChat', 
      caption: i18n.t("ticketsManager.buttons.newTicket"), 
      onClick: () => setNewTicketModalOpen(true)
    },
    { 
      icon: <EventNoteOutlined className={classes.speedDialIconButton}/>, 
      name: 'NewNote', 
      caption: i18n.t("ticketsManager.buttons.newNote"), 
      onClick: () => setNewNoteModalOpen(true)
    },
    {
      icon: <ModeCommentOutlinedIcon className={classes.speedDialIconButton}/>,
      name: 'NewInternalChat',
      caption: i18n.t("ticketsManager.buttons.newInternalChat"),
      onClick: () => setNewInternalChatModalOpen(true)
    },
  ];

  if (user.profile.toUpperCase() === "ADMIN") {
    actions.push({ 
      icon: (showAllTickets ? <VisibilityOffOutlinedIcon className={classes.speedDialIconButton}/> : <VisibilityOutlinedIcon className={classes.speedDialIconButton}/>), 
      name: 'ShowAll', 
      caption: (showAllTickets ? i18n.t("tickets.buttons.showAllOff") : i18n.t("tickets.buttons.showAll")),
      onClick: () => { 
        setShowAllTickets((prevState) => !prevState);
        localStorage.setItem("showAllTickets", localStorage.getItem("showAllTickets") === "1" ? "0" : "1");
      }
    });
  }



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const loadUsers = async () => {
      const list = await findAllUsers();
      setUsersList(list);
    }
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetches = async () => {
      try {
        const apiCalls = [
          api.get("/settings/adminsPendingTicketsControl"),
          api.get("/settings/allowChannels"),
          api.get("/settings/allowGroups"),
          api.get("/AllWhatsapps")
        ];

        const [
          adminsPendingTicketsControlResponse,
          allowChannelsResponse,
          allowGroupsResponse,
          whatsappsResponse
        ] = await Promise.all(apiCalls);

        setAdminsPendingTicketsControl(adminsPendingTicketsControlResponse.data.value); // getting "adminsPendingTicketsControl" setting
        setAllowChannels(allowChannelsResponse.data.value); // getting "allowChannels" setting
        setAllowGroups(allowGroupsResponse.data.value); // getting "allowGroups" setting
        setConnectionsList(whatsappsResponse.data);

        if (adminsPendingTicketsControl === "enabled" && user.profile === "user" && tabOpen === "pending") {
          setTabOpen("open");
          localStorage.setItem("tabOpenTickets", "open");
        }
      } catch (error) {
        console.log("Tickets Manager Use Effect Error:", error);
        toastError(error);
      }
    };

    fetches();
  }, [adminsPendingTicketsControl, setTabOpen, tabOpen, user]);

  useEffect(() => {
    if (user.profile.toUpperCase() === "USER") {
      setShowAllTickets(false);
      localStorage.setItem("showAllTickets", "0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tabRequest === "closed") {
      setChannelFilterValue("");
      setGroupFilterValue("");
      setOpenFilterValue("");
      setPendingFilterValue("");
    }

    if (tabRequest === "open") {
      setClosedFilterValue("");
    }
  }, [tabRequest]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleChangeTabOpen = (event, newValue) => {
    setChannelFilterValue("");
    setClosedFilterValue("");
    setGroupFilterValue("");
    setOpenFilterValue("");
    setPendingFilterValue("");
    setTabOpen(newValue);
    localStorage.setItem("tabOpenTickets", newValue);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleOpenClose = () => { open ? handleClose() : handleOpen(); };

  const applyPanelStyle = (status) => { if (tabOpen !== status) { return { width: 0, height: 0 }; } };

  const handleChannelFilter = (event) => { setChannelFilterValue(event.target.value); };
  const handleClosedFilter = (event) => { setClosedFilterValue(event.target.value); };
  const handleGroupFilter = (event) => { setGroupFilterValue(event.target.value); };
  const handleOpenFilter = (event) => { setOpenFilterValue(event.target.value); };
  const handlePendingFilter = (event) => { setPendingFilterValue(event.target.value); };


  
  //  ************
  //  ** Return **
  //  ************
  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      {/* 
    
        ***---- Modals ----***
    
      */}
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={() => setNewTicketModalOpen(false)}
        setTabOpen={setTabOpen}
      />
    
      <NewNoteModal
        modalOpen={newNoteModalOpen}
        onClose={() => setNewNoteModalOpen(false)}
        setTabOpen={setTabOpen}
      />

      <NewInternalChatModal
        modalOpen={newInternalChatModalOpen}
        onClose={() => setNewInternalChatModalOpen(false)}
        setTabOpen={setTabOpen}
      />

      <NewOpenAIChatModal
        modalOpen={newOpenAiModalOpen}
        onClose={() => setNewOpenAiModalOpen(false)}
        setTabOpen={setTabOpen}
      />

      {/* 
      
        ***---- Tabs ----***

      */}
      {tabRequest === "open" && (
        <TabPanel value={tab} name={"open"} className={classes.ticketsWrapper}>
          {/* 
            *******************
            ** Tabs - Mobile **
            *******************
          */}
          {isSmallScreen && (
            <Tabs
              value={tabOpen}
              onChange={handleChangeTabOpen}
              indicatorColor="primary"
              textColor="primary"
              variant={"fullWidth"}
              scrollButtons="auto"
            >
              {allowChannels === "enabled" && (
                <Tab
                  icon={
                    <Badge className={classes.badge} badgeContent={channelCount} color="primary">
                      <TrackChangesOutlinedIcon />
                    </Badge>
                  }
                  value={"channel"}
                />
              )}

              {allowGroups === "enabled" && (
                <Tab
                  icon={
                    <Badge className={classes.badge} badgeContent={groupCount} color="primary">
                      <PeopleOutlineOutlinedIcon />
                    </Badge>
                  }
                  value={"group"}
                />
              )}

              <Tab
                icon={
                  <Badge className={classes.badge} badgeContent={openCount} color="primary">
                    <PersonOutlinedIcon />
                  </Badge>
                }
                value={"open"}
              />

              {((adminsPendingTicketsControl === "disabled") 
                || (adminsPendingTicketsControl === "enabled" && user.profile === "admin"))
                && (
                  <Tab
                    icon={
                      <Badge className={classes.badge} badgeContent={pendingCount} color="primary">
                        <RecordVoiceOverOutlinedIcon />
                      </Badge>
                    }
                  value={"pending"}
                />
              )}
            </Tabs>
          )}



          {/* 
            ********************
            ** Tabs - Desktop **
            ********************
          */}
          {!isSmallScreen && (
            <Tabs
              value={tabOpen}
              onChange={handleChangeTabOpen}
              indicatorColor="primary"
              textColor="primary"
              variant={allowGroups && allowChannels === "disabled" ? "fullWidth" : "scrollable"}
              scrollButtons={"on"}
              centered
            >
              {allowChannels === "enabled" && (
                <Tab
                  label={
                    <Badge className={classes.badge} badgeContent={channelCount} color="primary">
                      {i18n.t("ticketsList.channelHeader")}
                    </Badge>
                  }
                  value={"channel"}
                />
              )}

              {allowGroups === "enabled" && (
                <Tab
                  label={
                    <Badge className={classes.badge} badgeContent={groupCount} color="primary">
                      {i18n.t("ticketsList.groupHeader")}
                    </Badge>
                  }
                  value={"group"}
                />
              )}

              <Tab
                label={
                  <Badge className={classes.badge} badgeContent={openCount} color="primary">
                    {i18n.t("ticketsList.assignedHeader")}
                  </Badge>
                }
                value={"open"}
              />

              {((adminsPendingTicketsControl === "disabled") 
                || (adminsPendingTicketsControl === "enabled" && user.profile === "admin"))
                && (
                  <Tab
                    label={
                      <Badge className={classes.badge} badgeContent={pendingCount} color="primary">
                        {i18n.t("ticketsList.pendingHeader")}
                      </Badge>
                    }
                    value={"pending"}
                  />
              )}
            </Tabs>
          )}

          

          {/* 
            ***********************
            ** Filters - Channel **
            ***********************
          */}
          {tabOpen === "channel" && (
            <TextField
              value={channelFilterValue}
              onChange={handleChannelFilter}
              variant="outlined"
              margin="normal"
              type="text"
              id="messageKeywords"
              name="messageKeywords"
              className={classes.inputField}
              InputProps={{startAdornment: (<InputAdornment position="center"><SearchIcon /></InputAdornment>)}}
            />
          )}



          {/* 
            *********************
            ** Filters - Group **
            *********************
          */}
          {tabOpen === "group" && (
            <TextField
              value={groupFilterValue}
              onChange={handleGroupFilter}
              variant="outlined"
              margin="normal"
              type="text"
              id="messageKeywords"
              name="messageKeywords"
              className={classes.inputField}
              InputProps={{startAdornment: (<InputAdornment position="center"><SearchIcon /></InputAdornment>)}}
            />
          )}



          {/* 
            **************************
            ** Filters - Working On **
            **************************
          */}
          {tabOpen === "open" && (
            <TextField
              value={openFilterValue}
              onChange={handleOpenFilter}
              variant="outlined"
              margin="normal"
              type="text"
              id="messageKeywords"
              name="messageKeywords"
              className={classes.inputField}
              InputProps={{startAdornment: (<InputAdornment position="center"><SearchIcon /></InputAdornment>)}}
            />
          )}



          {/* 
            ***********************
            ** Filters - Pending **
            ***********************
          */}
          {tabOpen === "pending" 
            && ((adminsPendingTicketsControl === "disabled") 
              || (adminsPendingTicketsControl === "enabled" && user.profile === "admin"))
            && (
            <TextField
              value={pendingFilterValue}
              onChange={handlePendingFilter}
              variant="outlined"
              margin="normal"
              type="text"
              id="messageKeywords"
              name="messageKeywords"
              className={classes.inputField}
              InputProps={{startAdornment: (<InputAdornment position="center"><SearchIcon /></InputAdornment>)}}
            />
          )}

          

          {/* 
              *******************************************
              ** Filters - User and Connections - Open **
              *******************************************
          */}
          {user.profile === "admin" && tabOpen === "open" && (
            <div className={classes.selectFilters}>
              <div className={classes.selectContainer}>
                <UserSelect
                  selectedUserIds={selectedUserIds}
                  onChange={values => setSelectedUserIds(values)}
                  usersOptions={usersList}
                  styleSelectInputField={true}
                />
              </div>

              <div className={classes.selectContainer}>
                <WhatsappSelect
                  selectedWhatsappIds={selectedConnectionIds}
                  onChange={values => setSelectedConnectionIds(values)}
                  whatsappsOptions={connectionsList}
                  styleSelectInputField={true}
                />
              </div>
            </div>
          )}



          {/* 
            ******************
            ** Ticket Lists **
            ******************
          */}
          <Paper className={classes.ticketsWrapper}>
            <TicketsList
              status="open"
              isGroup="false"
              isChannel="false"
              showAll={showAllTickets}
              selectedQueueIds={selectedQueueIds}
              updateCount={(val) => setOpenCount(val)}
              style={applyPanelStyle("open")}
              filter={openFilterValue}
              selectedUserIds={selectedUserIds}
              selectedConnectionIds={selectedConnectionIds}
              setTabOpen={setTabOpen}
              resetAudioPlayer={resetAudioPlayer}
            />
            
            {((adminsPendingTicketsControl === "disabled") 
              || (adminsPendingTicketsControl === "enabled" && user.profile === "admin"))
              && (
                <TicketsList
                  status="pending"
                  isGroup="false"
                  isChannel="false"
                  selectedQueueIds={selectedQueueIds}
                  updateCount={(val) => setPendingCount(val)}
                  style={applyPanelStyle("pending")}
                  filter={pendingFilterValue}
                  setTabOpen={setTabOpen}
                  resetAudioPlayer={resetAudioPlayer}
                />
            )}

            <TicketsList
              status="group"
              isGroup="true"
              isChannel="false"
              direction="up"
              selectedQueueIds={selectedQueueIds}
              updateCount={(val) => setGroupCount(val)}
              style={applyPanelStyle("group")}
              filter={groupFilterValue}
              setTabOpen={setTabOpen}
              resetAudioPlayer={resetAudioPlayer}
            />

            <TicketsList
              status="channel"
              isGroup="false"
              isChannel="true"
              direction="up"
              selectedQueueIds={selectedQueueIds}
              updateCount={(val) => setChannelCount(val)}
              style={applyPanelStyle("channel")}
              filter={channelFilterValue}
              setTabOpen={setTabOpen}
              resetAudioPlayer={resetAudioPlayer}
            />

            <div className={classes.ticketManagerButtonsContainer}>
              {/* 
                ****************
                ** Speed Dial **
                ****************
              */}
              <StyledSpeedDial
                ariaLabel="SpeedDial"
                direction="up"
                icon={<SpeedDialIcon />}
                onClick={handleOpenClose}
                open={open}
              >
                {actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.caption}
                    onClick={action.onClick}
                  />
                ))}
              </StyledSpeedDial>

            

              {/* 
                ****************
                ** Bot Button **
                ****************
              */}
              <Tooltip title={i18n.t("openAi.expander")} placement="top-start" arrow>
                <StyledBotButton
                  ariaLabel="SpeedDial"
                  direction="up"
                  icon={<Android />}
                  onClick= {() => setNewOpenAiModalOpen(true)}
                />
              </Tooltip>
            </div>
          </Paper>
        </TabPanel>
      )}
      
      {tabRequest === "closed" && (
        <TabPanel value={tab} name="open" className={classes.ticketsWrapper}>
          {/* 
            *********************
            ** Filter - Closed **
            *********************
          */}
          <TextField
            value={closedFilterValue}
            onChange={handleClosedFilter}
            variant="outlined"
            margin="normal"
            type="text"
            id="messageKeywords"
            name="messageKeywords"
            className={classes.inputField}
            InputProps={{ startAdornment: (<InputAdornment postion="center"><SearchIcon /></InputAdornment>) }}
          />




          {/* 
            ******************
            ** Tickets List **
            ******************
          */}
          <TicketsList
            status="closed"
            filter={closedFilterValue}
            showAll={showAllTickets}
            selectedQueueIds={selectedQueueIds}
            setTabOpen={setTabOpen}
            resetAudioPlayer={resetAudioPlayer}
          />

          <div className={classes.ticketManagerButtonsContainer}>
            {/* 
              ****************
              ** Speed Dial **
              ****************
            */}
            <StyledSpeedDial
              ariaLabel="SpeedDial"
              direction="up"
              icon={<SpeedDialIcon />}
              onClick={handleOpenClose}
              open={open}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.caption}
                  onClick={action.onClick}
                />
              ))}
            </StyledSpeedDial>

            {/* 
              ****************
              ** Bot Button **
              ****************
            */}
            <Tooltip title={i18n.t("openAi.expander")} placement="top-start" arrow>
              <StyledBotButton
                ariaLabel="SpeedDial"
                direction="up"
                icon={<Android />}
                onClick= {() => setNewOpenAiModalOpen(true)}
              />
            </Tooltip>
          </div>
        </TabPanel>
      )}
    </Paper>
  );
};

export default TicketsManager;
