import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Checkbox,
	Chip,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import botChatIcon from "../../assets/ticketsTypes/bot.svg";
import chatIcon from '../../assets/ticketsTypes/chat.png';
import whatsappIcon from '../../assets/ticketsTypes/whatsapp.png';

const useStyles = makeStyles(theme => ({
	chips: { display: "flex", flexWrap: "wrap", },

	chip: {
		margin: 2,
		border: `2px solid ${theme.palette.primary.main}`,
		color: theme.palette.text.primary,
	},

	chipCheckbox: {
		color: theme.palette.primary.main,
		'&.Mui-checked': { color: theme.palette.primary.main, }
	},

  ticketType: { width: "20px", },

	selectInputField: {
		"& .MuiOutlinedInput-notchedOutline": { borderColor: theme.palette.text.primary, },
    "& .MuiSvgIcon-root": { color: theme.palette.text.primary, },
	},
}));

const TicketTypeSelect = ({ selectedTicketTypes, onChange, ticketTypesOptions }) => {
	// 	***************
	// 	** Variables **
	// 	***************
  const classes = useStyles();
  


	// 	***************
	// 	** Functions **
	// 	***************
	const handleChange = event => { onChange(event.target.value); };



	// 	************
	// 	** Return **
	// 	************
  return (
		<div style={{ marginTop: 6 }}>
			<FormControl 
				fullWidth
				margin="dense"
				variant="outlined"
				classes={{ root: classes.selectInputField }}
			>
				<InputLabel>{i18n.t("ticketTypeSelect.inputLabel")}</InputLabel>
				<Select
					multiple
					label={i18n.t("ticketTypeSelect.inputLabel")}
					value={selectedTicketTypes}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: { vertical: "bottom", horizontal: "left", },
						transformOrigin: { vertical: "top", horizontal: "left", },
						getContentAnchorEl: null,
					}}
					renderValue={selected => (
						<div className={classes.chips}>
							{selected?.length > 0 &&
								selected.map(id => {
									const typeComparison = ticketTypesOptions.find(t => t === id );
									return typeComparison === 0 || typeComparison === 1  || typeComparison === 2 
										? (
											<>
											{typeComparison === 0 && (
												<Chip
													key={id}
													variant="outlined"
													className={classes.chip}
													label={<img className={classes.ticketType} src={whatsappIcon} alt="Ticket Logo" />}
												/>
											)}

											{typeComparison === 1 && (
												<Chip
													key={id}
													variant="outlined"
													className={classes.chip}
													label={<img className={classes.ticketType} src={chatIcon} alt="Ticket Logo" />}
												/>
											)}

											{typeComparison === 2 && (
												<Chip
													key={id}
													variant="outlined"
													className={classes.chip}
													label={<img className={classes.ticketType} src={botChatIcon} alt="Ticket Logo" />}
												/>
											)}
											</>
										)
										: null;
								})}
						</div>
					)}
				>
					{ticketTypesOptions.map(type => (
						<MenuItem key={type} value={type}>
							<Checkbox className={classes.chipCheckbox} checked={selectedTicketTypes.indexOf(type) > -1} />

              {type === 0 && (
                <ListItemText primary={<img className={classes.ticketType} src={whatsappIcon} alt="Ticket Logo" />} />
              )}

              {type === 1 && (
                <ListItemText primary={<img className={classes.ticketType} src={chatIcon} alt="Ticket Logo" />} />
              )}

							{type === 2 && (
								<ListItemText primary={<img className={classes.ticketType} src={botChatIcon} alt="Ticket Logo" />} />
							)}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default TicketTypeSelect;