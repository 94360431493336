import { useEffect } from "react";

import { useSocketAuthenticated } from "../../SocketContext";

const SocketQuickAnswers = ({ dispatch }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { getSocket } = useSocketAuthenticated();



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    const socket = getSocket();

    if (socket) {
      const handleQuickAnswer = (data) => {
        if (data.action === "update" || data.action === "create") {
          dispatch({ type: "UPDATE_QUICK_ANSWERS", payload: data.quickAnswer });
        }
  
        if (data.action === "delete") {
          dispatch({
            type: "DELETE_QUICK_ANSWERS",
            payload: +data.quickAnswerId,
          });
        }
      };
  
      socket.on("quickAnswer", handleQuickAnswer);
  
      return () => {
        socket.off("quickAnswer", handleQuickAnswer);
      };
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);


  
  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketQuickAnswers;