import React, { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import toastError from "../../errors/toastError";
import useWhatsApps from "../../hooks/useWhatsApps";

const useStyles = makeStyles(theme => ({
  // *************
  // ** Content **
  // *************
  dialogContent: {
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  contactCard: {
    borderRadius: "20px",
    padding: "10px",
    marginBottom: "5px",
  },

  contactCardActionArea: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    borderRadius: "20px",
    padding: "5px",
  },

  contactName: {
    fontSize: "16px",
  },

  contactNumber: {
    fontSize: "13px",
    color: "hsl(217, 12%, 63%)",
  },
}));

const MultiVCardModal = ({ open, onClose, contactsList, enableStartChat }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [selectedContactId, setSelectedContactId] = useState(null);
  const [selectedContactName, setSelectedContactName] = useState("-");

  // ***---- Open Private Chat ----***
  const [openPrivateChatConversationModal, setOpenPrivateChatConversationModal] = useState(false);

  // ***---- Selected WhatsApp ----***
  const { whatsApps: whatsAppsList } = useWhatsApps();
  const [selectedWhatsappId, setSelectedWhatsappId] = useState(null);

  

  //  **************
  //  ** Funtions **
  //  **************
  const handleOpenPrivateChatConversationModal = (selectedContactId, selectedContactName) => {
    if (selectedContactId) { 
      setOpenPrivateChatConversationModal(true);
      setSelectedContactId(selectedContactId);
      setSelectedContactName(selectedContactName);
    } else { toast.info(i18n.t("multiVCardModal.toasts.noNumberProvided")); }
  };
  
  const handleClosePrivateChatConversationModal = () => {
    setOpenPrivateChatConversationModal(false);
    setSelectedContactId(null);
    setSelectedContactName("");
    setSelectedWhatsappId(null);
  };

  const handleClose = () => { 
    onClose();
    handleClosePrivateChatConversationModal();
    setSelectedContactId(null);
    setSelectedContactName("-");
  };

  const handleNewChat = async () => {
    if (!selectedWhatsappId || selectedWhatsappId === undefined) {
      toast.info(i18n.t("newTicketModal.toasts.whatsappNotSelected"));
    }
    
    else {
      if (selectedContactId) {
        try {
          const { data: ticket } = await api.post("/tickets", {
            contactId: selectedContactId,
            userId: user.id,
            status: "open",
            isBotInteractionFinished: true,
            whatsappId: selectedWhatsappId
          });
          
          handleClose();
          history.push(`/tickets/${ticket.id}`);
        } catch (error) {
          console.log("Handle New Chat Error:", error);
          toastError(error);
          handleClosePrivateChatConversationModal();
        }
      } else { toast.info(i18n.t("multiVCardModal.toasts.noNumberProvided")); }
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      <ConfirmationModal
        title={`${i18n.t("contacts.confirmationModal.openPrivateChat.title")} ${selectedContactName}?`}
        open={openPrivateChatConversationModal}
        onClose={handleClosePrivateChatConversationModal}
        onConfirm={() => handleNewChat(selectedContactId)}
      >
        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel>{i18n.t("newTicketModal.connection")}</InputLabel>
          <Select
            value={selectedWhatsappId}
            onChange={(e) => setSelectedWhatsappId(e.target.value)}
            label={i18n.t("newTicketModal.connection")}
            fullWidth
          >
            <MenuItem value={""}>&nbsp;</MenuItem>
            {whatsAppsList.map((whatsapp) => (
              <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </ConfirmationModal>

      <div className={classes.root}>
        <Dialog open={open} onClose={handleClose} maxWidth="xs" scroll="paper" fullWidth>
          <DialogTitle id="form-dialog-title">
            <span>{i18n.t("multiVCardModal.title.up")}</span>
          </DialogTitle>

          <DialogContent className={classes.dialogContent} dividers>
            {contactsList.map(contactItem => (
              <Card
                className={classes.contactCard}
                onClick={
                  contactItem.number && contactItem.number !== "-" && enableStartChat 
                    ? () => handleOpenPrivateChatConversationModal(contactItem.id, contactItem.name)
                    : () => {}
                }
              >
                <CardActionArea
                  className={classes.contactCardActionArea}
                  disabled={!contactItem.number || contactItem.number === "-" || !enableStartChat}
                >
                  <Avatar 
                    alt={contactItem.name}
                    src={contactItem.profilePicUrl}
                    className={classes.contactAvatar}
                  />

                  <CardContent>
                    <Typography className={classes.contactName} gutterBottom component="h3">{contactItem.name}</Typography>
                    <Typography className={classes.contactNumber} gutterBottom component="h3">{contactItem.number}</Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ))}
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default MultiVCardModal;