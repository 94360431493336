import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

import useCategories from "../../hooks/useCategories";
import useQueuesAll from "../../hooks/useQueuesAll";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
	floatingButton: {
		transition: 'transform 0.3s',
	
		'&:hover': {
			transform: 'translateY(-5px)',
		},
	}
}));

const SubqueueSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	description: Yup.string()
    .min(2, "Too Short!")
    .max(75, "Too Long!")
});

const SubqueueModal = ({ open, onClose, subqueueId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		description: "",
	};

  const [subqueue, setSubqueue] = useState(initialState);
	const [categoryId, setCategoryId] = useState(false);
	const [queueId, setQueueId] = useState(false);

	const {loadingCategories, categories} = useCategories();
	const {loadingQueues, queues} = useQueuesAll();
	

  useEffect(() => {
		(async () => {
			if (!subqueueId) return;
			try {
				const { data } = await api.get(`/subqueue/${subqueueId}`);
				setSubqueue(prevState => {
					return { ...prevState, ...data };
				});
				setCategoryId(data.categoryId ? data.categoryId : '');
				setQueueId(data.queueId ? data.queueId : '');
			} catch (error) {
				console.log("Subqueue Modal Use Effect Error:", error);
				toastError(error);
			}
		})();

		return () => {
			setSubqueue({
				name: "",
        description: "",
			});
		};
	}, [subqueueId, open]);

  const handleClose = () => {
		onClose();
		setSubqueue(initialState);
	};

  const handleSaveSubqueue = async values => {
		const subqueueData = { ...values, categoryId, queueId };
		try {
			if (subqueueId) await api.put(`/subqueue/${subqueueId}`, subqueueData);
			else await api.post("/subqueue", subqueueData);
			
			handleClose();
		} catch (error) {
			console.log("Handle Save Subqueue Error:", error);
			toastError(error);
		}
	};

  return (
    <div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle>
            {subqueueId
              ? `${i18n.t("subqueueModal.title.edit")}`
              : `${i18n.t("subqueueModal.title.add")}`}
          </DialogTitle>

          <Formik
            initialValues={subqueue}
            enableReinitialize={true}
            validationSchema={SubqueueSchema}
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSaveSubqueue(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ touched, errors, isSubmitting }) => (
              <Form>
                <DialogContent dividers>
									<Field
										as={TextField}
										label={i18n.t("subqueueModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
									/>
									<Field
										as={TextField}
										label={i18n.t("subqueueModal.form.description")}
										name="description"
										error={touched.description && Boolean(errors.description)}
										helperText={touched.description && errors.description}
										variant="outlined"
										margin="dense"
									/>

                  <br />

                  <FormControl variant="outlined" margin="dense" fullWidth>
										<InputLabel>{i18n.t("subqueueModal.form.category")}</InputLabel>
										<Field
											as={Select}
											value={categoryId}
											onChange={(e) => setCategoryId(e.target.value)}
											label={i18n.t("subqueueModal.form.category")}
											required
										>
											<MenuItem value={''}>&nbsp;</MenuItem>
											{categories.map((category) => (
												<MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
											))}
										</Field>
									</FormControl>

                  <FormControl variant="outlined" margin="dense" fullWidth >
										<InputLabel>{i18n.t("subqueueModal.form.queue")}</InputLabel>
										<Field
											as={Select}
											value={queueId}
											onChange={(e) => setQueueId(e.target.value)}
											label={i18n.t("subqueueModal.form.queue")}
											required
										>
											<MenuItem value={''}>&nbsp;</MenuItem>
											{queues.map((queue) => (
												<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
											))}
										</Field>
									</FormControl>  
                </DialogContent>

                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="inherit"
                    disabled={isSubmitting}
                    variant="outlined"
										className={classes.floatingButton}
                  >
                    {i18n.t("subqueueModal.buttons.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    variant="contained"
                    className={`${classes.btnWrapper} ${classes.floatingButton}`}
                  >
                    {subqueueId
                      ? `${i18n.t("subqueueModal.buttons.okEdit")}`
                      : `${i18n.t("subqueueModal.buttons.okAdd")}`}
                    {isSubmitting && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
      </Dialog>
    </div>
  );
};

export default SubqueueModal;