import React, { useState, useEffect } from "react";
import {
	Container,
	Grid,
	makeStyles,
	Paper,
	TextField,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import { toast } from "react-toastify";
import ChartTicketsPerUsers from "./Charts/TicketsPerUser/Chart";
import ChartTicketsPerUsersTitle from "./Charts/TicketsPerUser/Title";
import ChartTotalTimePerUser from "./Charts/TotalTimePerUser/Chart";
import ChartTotalTimePerUsersTitle from "./Charts/TotalTimePerUser/Title";
import MainHeader from "../../components/MainHeader";
import MainContainer from "../../components/MainContainer";
import Title from "../../components/Title";

const useStyles = makeStyles(theme => ({
	mainContainerScroll: { overflowY: "scroll", ...theme.scrollbarStyles, paddingBottom: "20px", },

	container: { paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), },

	flexContainer: {
		display: "flex",
		justifyContent: "right",
		alignItems: "baseline",
		gap: "15px",
		paddingRight: "10px",
	},

	dashboardContainer: {
		width: "100%",
		backgroundColor: theme.palette.background.paper,
		borderRadius: "5px",
		padding: "20px 0px 20px 0px",
		textIndent: "15px",
	},
}))

const AttendantsDashboard = () => {
  // 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();
	const [initialDate, setInitialDate] = useState();
	const [finalDate, setFinalDate] = useState();



	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		const today = new Date();		
		let day = today.getDate();
		let month = today.getMonth() + 1;
		let year = today.getFullYear();

		if (month < 10) { month = '0' + month.toString(); }
		if (day < 10){ day = '0' + day.toString(); }

	 	setInitialDate(`${year}-${month}-${day}`);
		setFinalDate(`${year}-${month}-${day}`);		
  }, []);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleFilterDate = (newDate, flagInitialDate) => {
		const dashboardInitialDate = document.getElementById("dashboardInitialDate");
		const dashboardFinalDate = document.getElementById("dashboardFinalDate");
		
		if (dashboardInitialDate.value === "" || dashboardFinalDate.value === "") { 
			dashboardInitialDate.value = initialDate;
			dashboardFinalDate.value = finalDate;
		}
		else if (dashboardInitialDate.value > dashboardFinalDate.value) { toast.info(i18n.t("backendErrors.DASHBOARD_INITIAL_DATE_AFTER_FINAL_DATE")); }
		else if (flagInitialDate) { setInitialDate(newDate.target.value); }
		else { setFinalDate(newDate.target.value); }
	}

	const handleChangeInitialDate = (newDate) => { handleFilterDate(newDate, true); }
	const handleChangeFinalDate = (newDate) => { handleFilterDate(newDate, false); }



	// ***---- Return ----***
	return (
		<MainContainer>
			{/* 
				***********
				** Title **
				***********
			*/}
			<MainHeader>
				<Title>{i18n.t("attendantsDashboard.title")}</Title>
			</MainHeader>

			<div className={classes.mainContainerScroll}>
				<Container maxWidth="lg" className={classes.container}>
					<Grid container spacing={3}>
						
						{/* 
							*****************
							** Date Inputs **
							***************** 
						*/}
						<Grid item xs={12}>
							<div className={classes.flexContainer}>
								<TextField
									variant="outlined"
									margin="normal"
									type="date"
									id="dashboardInitialDate"
									name="dashboardInitialDate"
									label={i18n.t("dashboard.fields.initialDate")}
									value={initialDate}
									onChange={handleChangeInitialDate}
								/>
								<TextField
									variant="outlined"
									margin="normal"
									type="date"
									id="dashboardFinalDate"
									name="dashboardFinalDate"
									label={i18n.t("dashboard.fields.finalDate")}
									value={finalDate}
									onChange={handleChangeFinalDate}
								/>
							</div>
						</Grid>

						{/* 
							***********
							** Plots **
							***********
						*/}

						{/* Open Tickets per Users */}
						<Grid item xs={12}>
							<Paper className={classes.dashboardContainer}>
								<ChartTicketsPerUsersTitle ticketStatus={"open"} />
								<ChartTicketsPerUsers initialDate={initialDate} finalDate={finalDate} ticketStatus={"open"} />
							</Paper>
						</Grid>

						{/* Closed Tickets per Users */}
						<Grid item xs={12}>
							<Paper className={classes.dashboardContainer}>
								<ChartTicketsPerUsersTitle ticketStatus={"closed"} />
								<ChartTicketsPerUsers initialDate={initialDate} finalDate={finalDate} ticketStatus={"closed"} />
							</Paper>
						</Grid>

						{/* Closed Tickets Total Time per Users */}
						<Grid item xs={12}>
							<Paper className={classes.dashboardContainer}>
								<ChartTotalTimePerUsersTitle />
								<ChartTotalTimePerUser initialDate={initialDate} finalDate={finalDate} />
							</Paper>
						</Grid>
					</Grid>
				</Container>
			</div>
		</MainContainer>
	)
}

export default AttendantsDashboard;