import React, { useEffect, useState } from "react";
import { Paper, makeStyles } from "@material-ui/core";

import api from "../../services/api";
import ChatInfo from "../ChatInfo";
import MessagesListChatView from "../MessagesListChatView";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    backgroundColor: "rgba(255, 255, 255, 0)",
    padding: "3.00px",
    flex: 1.00,
    height: "100.00%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  chatInfo: {
    borderRadius: "10.00px",
    backgroundColor: theme.palette.background.default,
    maxWidth: "100.00%",
    [theme.breakpoints.down("sm")]: { maxWidth: "80.00%", flexBasis: "80.00%", },
  },
}));

const ChatView = ({ open, contactId, whatsappId }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const [contact, setContact] = useState({});



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchContact = async () => {
        try {
          const { data } = await api.get(`/contacts/${contactId}`);
          setContact(data);
        } catch (exception) {
          console.log("Chat View Use Effect Exception:", exception);
          toastError(exception);
        }
      };

      fetchContact();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [contactId]);



  //  ************
  //  ** Return **
  //  ************
  return (
    <Paper variant="outlined" elevation={0} className={classes.mainWrapper}>
      <div className={classes.chatInfo}>
        <ChatInfo contact={contact} />
      </div>

      <MessagesListChatView open={open} contactId={contactId} whatsappId={whatsappId} />
    </Paper>
  );
};

export default ChatView;
