import { useState, useEffect, useReducer } from "react";
import { useSocket } from "../../context/Socket/SocketContext";
import toastError from "../../errors/toastError";

import api from "../../services/api";

const reducer = (state, action) => {
	if (action.type === "LOAD_CATEGORIES") {
		const categories = action.payload;

		return [...categories];
	}

	if (action.type === "UPDATE_CATEGORIES") {
		const category = action.payload;
		const categoryIndex = state.findIndex(c => c.id === category.id);

		if (categoryIndex !== -1) {
			state[categoryIndex] = category;
			return [...state];
		} else {
			return [category, ...state];
		}
	}

	if (action.type === "DELETE_CATEGORY") {
		const categoryId = action.payload;

		const categoryIndex = state.findIndex(c => c.id === categoryId);
		if (categoryIndex !== -1) {
			state.splice(categoryIndex, 1);
		}
		return [...state];
	}

	if (action.type === "RESET") {
		return [];
	}
};

const useCategories = () => {
	const [categories, dispatch] = useReducer(reducer, []);
	const [loading, setLoading] = useState(true);
	const { getSocket } = useSocket();

	useEffect(() => {
		setLoading(true);
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/category/");
				dispatch({ type: "LOAD_CATEGORIES", payload: data });
				setLoading(false);
			} catch (error) {
				setLoading(false);
				console.log("Use Categories Error:", error);
				toastError(error);
			}
		};
		fetchSession();
	}, []);

	useEffect(() => {
		const socket = getSocket();

		if (socket) {
			const handleUpdateCategories = (data) => {
				if (data.action === "update" && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
					dispatch({ type: "UPDATE_CATEGORIES", payload: data.categories });
				}
			};
	
			const handleDeleteCategories = (data) => {
				if (data.action === "delete" && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`) ) {
					dispatch({ type: "DELETE_CATEGORY", payload: data.categoriesId });
				}
			};
	
			socket.on("categories", handleUpdateCategories);
			socket.on("categories", handleDeleteCategories);
	
			return () => {
				socket.off("categories", handleUpdateCategories);
				socket.off("categories", handleDeleteCategories);
			};
		}
		
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { categories, loading };
};

export default useCategories;