import { useEffect } from "react";

import { useSocketAuthenticated } from "../../SocketContext";
import api from "../../../../services/api";

const SocketContacts = ({ dispatch, searchParam, resetContactsListCallback }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { getSocket } = useSocketAuthenticated();



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    const socket = getSocket();

    if (socket) {
      const handleContact = async (data) => {
        if ((data.action === "update" || data.action === "create") && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          dispatch({ type: "UPDATE_CONTACTS", payload: data.contact, searchParam });
        }
  
        if ((data.action === "delete") && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          dispatch({ type: "DELETE_CONTACT", payload: +data.contactId });
        }
  
        if ((data.action === "import") && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          resetContactsListCallback();
        }
      }; 
  
      socket.on("contact", handleContact);
  
      return () => {
        socket.off("contact", handleContact);
      };
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, resetContactsListCallback, searchParam]);


  
  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketContacts;