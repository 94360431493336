import React, { useEffect, useReducer, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormGroup,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";

import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { toast } from "react-toastify";
import SocketBusinessHours from "../../context/Socket/Listeners/SocketBusinessHours";

import compareTimeInMinutes from "../../utils/compareTimeInMinutes";
import compareOpenClosedRadiosTimeInMinutes from "../../utils/compareOpenClosedRadiosTimeInMinutes";
import comparePersonalizedMessagesLength from "../../utils/comparePersonalizedMessagesLength";
import compareIntervalAndBusinessHours from "../../utils/compareIntervalAndBusinessHours";
import checkOutTimeInMinutesComparisons from "../../utils/checkOutTimeInMinutesComparisons";
import checkOutIntervalHoursComparisons from "../../utils/checkOutIntervalHoursComparisons";

const businessHoursReducer = (state, action) => {
  if (action.type === "UPDATE_BUSINESS_HOURS") {
    const hour = action.payload;
    const hourIndex = state.findIndex((h) => h.id === hour.id);

    if (hourIndex !== -1) {
      state[hourIndex] = hour;
      return [...state];
    } else {
      return [hour, ...state];
    }
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  paddingLeft: {
    paddingLeft: "8px",
  },
  personalizedMessage: {
    width: "100%",
  },
  mainPaper: {
    flex: 1,
    width: "99%",
    height: "93vh",
    overflow: "auto",
    padding: theme.spacing(1),
    borderRadius: "20px",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  radioControl: {
    display: "flex",
    alignItems: "center",
  },
  hoursContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    gap: "20px",
  },
  weekdayContainer: {
    paddingTop: "2px",
    boxShadow: "0px 0px 1px black",
    boderRadius: "20px",
  },
  floatingButton: {
    transition: 'transform 0.3s',
  
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  },
  sectionContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		flexWrap: "wrap",
    gap: "20px",

		[theme.breakpoints.down('sm')]: {
      justifyContent: "center",
			gap: "10px",
    },
	},
  businessHoursItem: {
    marginLeft: "5px",
		width: "500px",
	},
}));


const BusinessHours = () => {
  // ---- Classes and Reducers ----
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [businessHours, businessHoursDispatch] = useReducer(businessHoursReducer, []);
  const [backgroundColorTheme, setBackgroundColorTheme] = useState("#f5f5ef");
  const [textColorTheme, setTextColorTheme] = useState("#000000");

  // ---- Effects -----
  useEffect(() => {
    if (user.profile === "user" || !user.configEnabled) { history.push(`/tickets`); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    handlePersonalizedMessageDateSunday();
    handlePersonalizedMessageDateMonday();
    handlePersonalizedMessageDateTuesday();
    handlePersonalizedMessageDateWednesday();
    handlePersonalizedMessageDateThursday();
    handlePersonalizedMessageDateFriday();
    handlePersonalizedMessageDateSaturday();

    // Sunday
    selectInitialFinalHour(0).then(result => {
      setSelectedDateSundayInitial(result[0]);
      setSelectedDateSundayFinal(result[1]);
      setSelectedSundayIsOpen(result[2]);
      setPersonalizedMessageSunday(result[3]);
      setSelectedDateSundayIntervalInital(result[4]);
      setSelectedDateSundayIntervalFinal(result[5]);
    });

    // Monday
    selectInitialFinalHour(1).then(result => {
      setSelectedDateMondayInitial(result[0]);
      setSelectedDateMondayFinal(result[1]);
      setSelectedMondayIsOpen(result[2]);
      setPersonalizedMessageMonday(result[3]);
      setSelectedDateMondayIntervalInital(result[4]);
      setSelectedDateMondayIntervalFinal(result[5]);
    });

    // Tuesday
    selectInitialFinalHour(2).then(result => {
      setSelectedDateTuesdayInitial(result[0]);
      setSelectedDateTuesdayFinal(result[1]);
      setSelectedTuesdayIsOpen(result[2]);
      setPersonalizedMessageTuesday(result[3]);
      setSelectedDateTuesdayIntervalInital(result[4]);
      setSelectedDateTuesdayIntervalFinal(result[5]);
    });

    // Wednesday
    selectInitialFinalHour(3).then(result => {
      setSelectedDateWednesdayInitial(result[0]);
      setSelectedDateWednesdayFinal(result[1]);
      setSelectedWednesdayIsOpen(result[2]);
      setPersonalizedMessageWednesday(result[3]);
      setSelectedDateWednesdayIntervalInital(result[4]);
      setSelectedDateWednesdayIntervalFinal(result[5]);
    });

    // Thursday
    selectInitialFinalHour(4).then(result => {
      setSelectedDateThursdayInitial(result[0]);
      setSelectedDateThursdayFinal(result[1]);
      setSelectedThursdayIsOpen(result[2]);
      setPersonalizedMessageThursday(result[3]);
      setSelectedDateThursdayIntervalInital(result[4]);
      setSelectedDateThursdayIntervalFinal(result[5]);
    });

    // Friday
    selectInitialFinalHour(5).then(result => {
      setSelectedDateFridayInitial(result[0]);
      setSelectedDateFridayFinal(result[1]);
      setSelectedFridayIsOpen(result[2]);
      setPersonalizedMessageFriday(result[3]);
      setSelectedDateFridayIntervalInital(result[4]);
      setSelectedDateFridayIntervalFinal(result[5]);
    });

    // Saturday
    selectInitialFinalHour(6).then(result => {
      setSelectedDateSaturdayInitial(result[0]);
      setSelectedDateSaturdayFinal(result[1]);
      setSelectedSaturdayIsOpen(result[2]);
      setPersonalizedMessageSaturday(result[3]);
      setSelectedDateSaturdayIntervalInital(result[4]);
      setSelectedDateSaturdayIntervalFinal(result[5]);
    });
  }, []);

  // ---- Functions ----
  async function selectInitialFinalHour(weekdayId) {
    const data = await api.get(`/businessHours/${weekdayId}`);

    const hoursData = [
      data.data.initialHour.slice(0, -3)           // initial time
      , data.data.finalHour.slice(0, -3)           // final time
      , data.data.isOpen ? "1" : "0"               // isOpen
      , data.data.personalizedMessage              // personalized message
      , data.data.intervalInitialHour.slice(0, -3) // interval initial hour
      , data.data.intervalFinalHour.slice(0, -3)   // interval final hour
    ];

    return hoursData;
  }

  // ---- Sunday -----
  const [selectedDateSundayInitial, setSelectedDateSundayInitial] = useState();
  const [selectedDateSundayFinal, setSelectedDateSundayFinal] = useState();
  const [selectedDateSundayIntervalInitial, setSelectedDateSundayIntervalInital] = useState();
  const [selectedDateSundayIntervalFinal, setSelectedDateSundayIntervalFinal] = useState();
  const [selectSundayIsOpen, setSelectedSundayIsOpen] = useState();
  const [checkboxPersonalizedMessageSundayFinalHourChecked, setCheckboxPersonalizedMessageSundayFinalHourChecked] = useState(true);
  const [personalizedMessageSunday, setPersonalizedMessageSunday] = useState();

  const handleSundayMessageChange = (event) => { setPersonalizedMessageSunday(event.target.value); }
  const handleDateSundayInitialChange = (event) => { setSelectedDateSundayInitial(event.target.value); };
  const handleDateSundayFinalChange = (event) => { setSelectedDateSundayFinal(event.target.value); };
  const handleDateSundayIntervalInitialChange = (event) => { setSelectedDateSundayIntervalInital(event.target.value); }
  const handleDateSundayIntervalFinalChange = (event) => { setSelectedDateSundayIntervalFinal(event.target.value); }

  const handleOpenDateSunday = () => {
    const sundayInitialHour = document.getElementById("sundayInitialHour");
    const sundayFinalHour = document.getElementById("sundayFinalHour");
    const sundayIntervalInitialHour = document.getElementById("sundayIntervalInitialHour");
    const sundayIntervalFinalHour = document.getElementById("sundayIntervalFinalHour");
    
    sundayInitialHour.style.backgroundColor = backgroundColorTheme;
    sundayFinalHour.style.backgroundColor = backgroundColorTheme;
    sundayIntervalInitialHour.style.backgroundColor = backgroundColorTheme;
    sundayIntervalFinalHour.style.backgroundColor = backgroundColorTheme;

    sundayInitialHour.style.color = textColorTheme;
    sundayFinalHour.style.color = textColorTheme;
    sundayIntervalInitialHour.style.color = textColorTheme;
    sundayIntervalFinalHour.style.color = textColorTheme;

    sundayInitialHour.disabled = false;
    sundayFinalHour.disabled = false;
    sundayIntervalInitialHour.disabled = false;
    sundayIntervalFinalHour.disabled = false;

    sundayInitialHour.value = selectedDateSundayInitial;
    sundayFinalHour.value = selectedDateSundayFinal;
    sundayIntervalInitialHour.value = selectedDateSundayIntervalInitial;
    sundayIntervalFinalHour.value = selectedDateSundayIntervalFinal;
  }

  const handleClosedDateSunday = () => {
    const sundayInitialHour = document.getElementById("sundayInitialHour");
    const sundayFinalHour = document.getElementById("sundayFinalHour");
    const sundayIntervalInitialHour = document.getElementById("sundayIntervalInitialHour");
    const sundayIntervalFinalHour = document.getElementById("sundayIntervalFinalHour");

    sundayInitialHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    sundayFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    sundayIntervalInitialHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    sundayIntervalFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";

    sundayInitialHour.disabled = true;
    sundayFinalHour.disabled = true;
    sundayIntervalInitialHour.disabled = true;
    sundayIntervalFinalHour.disabled = true;

    setSelectedDateSundayInitial("00:00");
    setSelectedDateSundayFinal("00:00");
    setSelectedDateSundayIntervalInital("00:00");
    setSelectedDateSundayIntervalFinal("00:00");
  }

  const handlePersonalizedMessageDateSunday = () => {
    const checkboxPersonalizedMessageSundayFinalHour = document.getElementById("checkboxPersonalizedMessageSundayFinalHour");
    const personalizedMessageSundayFinalHour = document.getElementById("personalizedMessageSundayFinalHour");
    
    if (checkboxPersonalizedMessageSundayFinalHour.checked) {
      personalizedMessageSundayFinalHour.disabled = false;
      personalizedMessageSundayFinalHour.style.backgroundColor = "inherit";
    } else {
      personalizedMessageSundayFinalHour.disabled = true;
      personalizedMessageSundayFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    }
  }

  // ---- Monday -----
  const [selectedDateMondayInitial, setSelectedDateMondayInitial] = useState();
  const [selectedDateMondayFinal, setSelectedDateMondayFinal] = useState();
  const [selectedDateMondayIntervalInitial, setSelectedDateMondayIntervalInital] = useState();
  const [selectedDateMondayIntervalFinal, setSelectedDateMondayIntervalFinal] = useState();
  const [selectMondayIsOpen, setSelectedMondayIsOpen] = useState();
  const [checkboxPersonalizedMessageMondayFinalHourChecked, setCheckboxPersonalizedMessageMondayFinalHourChecked] = useState(false);
  const [personalizedMessageMonday, setPersonalizedMessageMonday] = useState();
  
  const handleMondayMessageChange = (event) => { setPersonalizedMessageMonday(event.target.value); }
  const handleDateMondayInitialChange = (event) => { setSelectedDateMondayInitial(event.target.value); };
  const handleDateMondayFinalChange = (event) => { setSelectedDateMondayFinal(event.target.value); };
  const handleDateMondayIntervalInitialChange = (event) => { setSelectedDateMondayIntervalInital(event.target.value); }
  const handleDateMondayIntervalFinalChange = (event) => { setSelectedDateMondayIntervalFinal(event.target.value); }

  const handleOpenDateMonday = () => {
    const mondayInitialHour = document.getElementById("mondayInitialHour");
    const mondayFinalHour = document.getElementById("mondayFinalHour");
    const mondayIntervalInitialHour = document.getElementById("mondayIntervalInitialHour");
    const mondayIntervalFinalHour = document.getElementById("mondayIntervalFinalHour");

    mondayInitialHour.style.backgroundColor = backgroundColorTheme;
    mondayFinalHour.style.backgroundColor = backgroundColorTheme;
    mondayIntervalInitialHour.style.backgroundColor = backgroundColorTheme;
    mondayIntervalFinalHour.style.backgroundColor = backgroundColorTheme;

    mondayInitialHour.style.color = textColorTheme;
    mondayFinalHour.style.color = textColorTheme;
    mondayIntervalInitialHour.style.color = textColorTheme;
    mondayIntervalFinalHour.style.color = textColorTheme;

    mondayInitialHour.disabled = false;
    mondayFinalHour.disabled = false;
    mondayIntervalInitialHour.disabled = false;
    mondayIntervalFinalHour.disabled = false;

    mondayInitialHour.value = selectedDateMondayInitial;
    mondayFinalHour.value = selectedDateMondayFinal;
    mondayIntervalInitialHour.value = selectedDateMondayIntervalInitial;
    mondayIntervalFinalHour.value = selectedDateMondayIntervalFinal; 
  }

  const handleClosedDateMonday = () => {
    const mondayInitialHour = document.getElementById("mondayInitialHour");
    const mondayFinalHour = document.getElementById("mondayFinalHour");
    const mondayIntervalInitialHour = document.getElementById("mondayIntervalInitialHour");
    const mondayIntervalFinalHour = document.getElementById("mondayIntervalFinalHour");

    mondayInitialHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    mondayFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    mondayIntervalInitialHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    mondayIntervalFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";

    mondayInitialHour.disabled = true;
    mondayFinalHour.disabled = true;
    mondayIntervalInitialHour.disabled = true;
    mondayIntervalFinalHour.disabled = true;

    setSelectedDateMondayInitial("00:00");
    setSelectedDateMondayFinal("00:00");
    setSelectedDateMondayIntervalInital("00:00");
    setSelectedDateMondayIntervalFinal("00:00");
  }

  const handlePersonalizedMessageDateMonday = () => {
    const checkboxPersonalizedMessageMondayFinalHour = document.getElementById("checkboxPersonalizedMessageMondayFinalHour");
    const personalizedMessageMondayFinalHour = document.getElementById("personalizedMessageMondayFinalHour");
    
    
    if (checkboxPersonalizedMessageMondayFinalHour.checked) {
      personalizedMessageMondayFinalHour.disabled = false;
      personalizedMessageMondayFinalHour.style.backgroundColor = "inherit";
    } else {
      personalizedMessageMondayFinalHour.disabled = true;
      personalizedMessageMondayFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    }
  }

  // ---- Tuesday -----
  const [selectedDateTuesdayInitial, setSelectedDateTuesdayInitial] = useState();
  const [selectedDateTuesdayFinal, setSelectedDateTuesdayFinal] = useState();
  const [selectedDateTuesdayIntervalInitial, setSelectedDateTuesdayIntervalInital] = useState();
  const [selectedDateTuesdayIntervalFinal, setSelectedDateTuesdayIntervalFinal] = useState();
  const [selectTuesdayIsOpen, setSelectedTuesdayIsOpen] = useState();
  const [checkboxPersonalizedMessageTuesdayFinalHourChecked, setCheckboxPersonalizedMessageTuesdayFinalHourChecked] = useState(false);
  const [personalizedMessageTuesday, setPersonalizedMessageTuesday] = useState();

  const handleTuesdayMessageChange = (event) => { setPersonalizedMessageTuesday(event.target.value); }
  const handleDateTuesdayInitialChange = (event) => { setSelectedDateTuesdayInitial(event.target.value); };
  const handleDateTuesdayFinalChange = (event) => { setSelectedDateTuesdayFinal(event.target.value); };
  const handleDateTuesdayIntervalInitialChange = (event) => { setSelectedDateTuesdayIntervalInital(event.target.value); }
  const handleDateTuesdayIntervalFinalChange = (event) => { setSelectedDateTuesdayIntervalFinal(event.target.value); }
  
  const handleOpenDateTuesday = () => {
    const tuesdayInitialHour = document.getElementById("tuesdayInitialHour");
    const tuesdayFinalHour = document.getElementById("tuesdayFinalHour");
    const tuesdayIntervalInitialHour = document.getElementById("tuesdayIntervalInitialHour");
    const tuesdayIntervalFinalHour = document.getElementById("tuesdayIntervalFinalHour");

    tuesdayInitialHour.style.backgroundColor = backgroundColorTheme;
    tuesdayFinalHour.style.backgroundColor = backgroundColorTheme;
    tuesdayIntervalInitialHour.style.backgroundColor = backgroundColorTheme;
    tuesdayIntervalFinalHour.style.backgroundColor = backgroundColorTheme;

    tuesdayInitialHour.style.color = textColorTheme;
    tuesdayFinalHour.style.color = textColorTheme;
    tuesdayIntervalInitialHour.style.color = textColorTheme;
    tuesdayIntervalFinalHour.style.color = textColorTheme;

    tuesdayInitialHour.disabled = false;
    tuesdayFinalHour.disabled = false;
    tuesdayIntervalInitialHour.disabled = false;
    tuesdayIntervalFinalHour.disabled = false;

    tuesdayInitialHour.value = selectedDateTuesdayInitial;
    tuesdayFinalHour.value = selectedDateTuesdayFinal;
    tuesdayIntervalInitialHour.value = selectedDateTuesdayIntervalInitial;
    tuesdayIntervalFinalHour.value = selectedDateTuesdayIntervalFinal; 
  }

  const handleClosedDateTuesday = () => {
    const tuesdayInitialHour = document.getElementById("tuesdayInitialHour");
    const tuesdayFinalHour = document.getElementById("tuesdayFinalHour");
    const tuesdayIntervalInitialHour = document.getElementById("tuesdayIntervalInitialHour");
    const tuesdayIntervalFinalHour = document.getElementById("tuesdayIntervalFinalHour");

    tuesdayInitialHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    tuesdayFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    tuesdayIntervalInitialHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    tuesdayIntervalFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";

    tuesdayInitialHour.disabled = true;
    tuesdayFinalHour.disabled = true;
    tuesdayIntervalInitialHour.disabled = true;
    tuesdayIntervalFinalHour.disabled = true;

    setSelectedDateTuesdayInitial("00:00");
    setSelectedDateTuesdayFinal("00:00");
    setSelectedDateTuesdayIntervalInital("00:00");
    setSelectedDateTuesdayIntervalFinal("00:00");
  }

  const handlePersonalizedMessageDateTuesday = () => {
    const checkboxPersonalizedMessageTuesdayFinalHour = document.getElementById("checkboxPersonalizedMessageTuesdayFinalHour");
    const personalizedMessageTuesdayFinalHour = document.getElementById("personalizedMessageTuesdayFinalHour");
    
    
    if (checkboxPersonalizedMessageTuesdayFinalHour.checked) {
      personalizedMessageTuesdayFinalHour.disabled = false;
      personalizedMessageTuesdayFinalHour.style.backgroundColor = "inherit";
    } else {
      personalizedMessageTuesdayFinalHour.disabled = true;
      personalizedMessageTuesdayFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    }
  }

  // ---- Wednesday -----
  const [selectedDateWednesdayInitial, setSelectedDateWednesdayInitial] = useState();
  const [selectedDateWednesdayFinal, setSelectedDateWednesdayFinal] = useState();
  const [selectedDateWednesdayIntervalInitial, setSelectedDateWednesdayIntervalInital] = useState();
  const [selectedDateWednesdayIntervalFinal, setSelectedDateWednesdayIntervalFinal] = useState();
  const [selectWednesdayIsOpen, setSelectedWednesdayIsOpen] = useState();
  const [checkboxPersonalizedMessageWednesdayFinalHourChecked, setCheckboxPersonalizedMessageWednesdayFinalHourChecked] = useState(false);
  const [personalizedMessageWednesday, setPersonalizedMessageWednesday] = useState();

  const handleWednesdayMessageChange = (event) => { setPersonalizedMessageWednesday(event.target.value); }
  const handleDateWednesdayInitialChange = (event) => { setSelectedDateWednesdayInitial(event.target.value); };
  const handleDateWednesdayFinalChange = (event) => { setSelectedDateWednesdayFinal(event.target.value); };
  const handleDateWednesdayIntervalInitialChange = (event) => { setSelectedDateWednesdayIntervalInital(event.target.value); }
  const handleDateWednesdayIntervalFinalChange = (event) => { setSelectedDateWednesdayIntervalFinal(event.target.value); }

  const handleOpenDateWednesday = () => {
    const wednesdayInitialHour = document.getElementById("wednesdayInitialHour");
    const wednesdayFinalHour = document.getElementById("wednesdayFinalHour");
    const wednesdayIntervalInitialHour = document.getElementById("wednesdayIntervalInitialHour");
    const wednesdayIntervalFinalHour = document.getElementById("wednesdayIntervalFinalHour");

    wednesdayInitialHour.style.backgroundColor = backgroundColorTheme;
    wednesdayFinalHour.style.backgroundColor = backgroundColorTheme;
    wednesdayIntervalInitialHour.style.backgroundColor = backgroundColorTheme;
    wednesdayIntervalFinalHour.style.backgroundColor = backgroundColorTheme;

    wednesdayInitialHour.style.color = textColorTheme;
    wednesdayFinalHour.style.color = textColorTheme;
    wednesdayIntervalInitialHour.style.color = textColorTheme;
    wednesdayIntervalFinalHour.style.color = textColorTheme;

    wednesdayInitialHour.disabled = false;
    wednesdayFinalHour.disabled = false;
    wednesdayIntervalInitialHour.disabled = false;
    wednesdayIntervalFinalHour.disabled = false;

    wednesdayInitialHour.value = selectedDateWednesdayInitial;
    wednesdayFinalHour.value = selectedDateWednesdayFinal;
    wednesdayIntervalInitialHour.value = selectedDateWednesdayIntervalInitial;
    wednesdayIntervalFinalHour.value = selectedDateWednesdayIntervalFinal; 
  }

  const handleClosedDateWednesday = () => {
    const wednesdayInitialHour = document.getElementById("wednesdayInitialHour");
    const wednesdayFinalHour = document.getElementById("wednesdayFinalHour");
    const wednesdayIntervalInitialHour = document.getElementById("wednesdayIntervalInitialHour");
    const wednesdayIntervalFinalHour = document.getElementById("wednesdayIntervalFinalHour");

    wednesdayInitialHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    wednesdayFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    wednesdayIntervalInitialHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    wednesdayIntervalFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";

    wednesdayInitialHour.disabled = true;
    wednesdayFinalHour.disabled = true;
    wednesdayIntervalInitialHour.disabled = true;
    wednesdayIntervalFinalHour.disabled = true;

    setSelectedDateWednesdayInitial("00:00");
    setSelectedDateWednesdayFinal("00:00");
    setSelectedDateWednesdayIntervalInital("00:00");
    setSelectedDateWednesdayIntervalFinal("00:00");
  }

  const handlePersonalizedMessageDateWednesday = () => {
    const checkboxPersonalizedMessageWednesdayFinalHour = document.getElementById("checkboxPersonalizedMessageWednesdayFinalHour");
    const personalizedMessageWednesdayFinalHour = document.getElementById("personalizedMessageWednesdayFinalHour");
    
    
    if (checkboxPersonalizedMessageWednesdayFinalHour.checked) {
      personalizedMessageWednesdayFinalHour.disabled = false;
      personalizedMessageWednesdayFinalHour.style.backgroundColor = "inherit";
    } else {
      personalizedMessageWednesdayFinalHour.disabled = true;
      personalizedMessageWednesdayFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    }
  }

  // ---- Thursday ----
  const [selectedDateThursdayInitial, setSelectedDateThursdayInitial] = useState();
  const [selectedDateThursdayFinal, setSelectedDateThursdayFinal] = useState();
  const [selectedDateThursdayIntervalInitial, setSelectedDateThursdayIntervalInital] = useState();
  const [selectedDateThursdayIntervalFinal, setSelectedDateThursdayIntervalFinal] = useState();
  const [selectThursdayIsOpen, setSelectedThursdayIsOpen] = useState();
  const [checkboxPersonalizedMessageThursdayFinalHourChecked, setCheckboxPersonalizedMessageThursdayFinalHourChecked] = useState(false);
  const [personalizedMessageThursday, setPersonalizedMessageThursday] = useState();
  
  const handleThursdayMessageChange = (event) => { setPersonalizedMessageThursday(event.target.value); }
  const handleDateThursdayInitialChange = (event) => { setSelectedDateThursdayInitial(event.target.value); };
  const handleDateThursdayFinalChange = (event) => { setSelectedDateThursdayFinal(event.target.value); };
  const handleDateThursdayIntervalInitialChange = (event) => { setSelectedDateThursdayIntervalInital(event.target.value); }
  const handleDateThursdayIntervalFinalChange = (event) => { setSelectedDateThursdayIntervalFinal(event.target.value); }

  const handleOpenDateThursday = () => {
    const thursdayInitialHour = document.getElementById("thursdayInitialHour");
    const thursdayFinalHour = document.getElementById("thursdayFinalHour");
    const thursdayIntervalInitialHour = document.getElementById("thursdayIntervalInitialHour");
    const thursdayIntervalFinalHour = document.getElementById("thursdayIntervalFinalHour");

    thursdayInitialHour.style.backgroundColor = backgroundColorTheme;
    thursdayFinalHour.style.backgroundColor = backgroundColorTheme;
    thursdayIntervalInitialHour.style.backgroundColor = backgroundColorTheme;
    thursdayIntervalFinalHour.style.backgroundColor = backgroundColorTheme;

    thursdayInitialHour.style.color = textColorTheme;
    thursdayFinalHour.style.color = textColorTheme;
    thursdayIntervalInitialHour.style.color = textColorTheme;
    thursdayIntervalFinalHour.style.color = textColorTheme;

    thursdayInitialHour.disabled = false;
    thursdayFinalHour.disabled = false;
    thursdayIntervalInitialHour.disabled = false;
    thursdayIntervalFinalHour.disabled = false;

    thursdayInitialHour.value = selectedDateThursdayInitial;
    thursdayFinalHour.value = selectedDateThursdayFinal;
    thursdayIntervalInitialHour.value = selectedDateThursdayIntervalInitial;
    thursdayIntervalFinalHour.value = selectedDateThursdayIntervalFinal; 
  }

  const handleClosedDateThursday = () => {
    const thursdayInitialHour = document.getElementById("thursdayInitialHour");
    const thursdayFinalHour = document.getElementById("thursdayFinalHour");
    const thursdayIntervalInitialHour = document.getElementById("thursdayIntervalInitialHour");
    const thursdayIntervalFinalHour = document.getElementById("thursdayIntervalFinalHour");

    thursdayInitialHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    thursdayFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    thursdayIntervalInitialHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    thursdayIntervalFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";

    thursdayInitialHour.disabled = true;
    thursdayFinalHour.disabled = true;
    thursdayIntervalInitialHour.disabled = true;
    thursdayIntervalFinalHour.disabled = true;

    setSelectedDateThursdayInitial("00:00");
    setSelectedDateThursdayFinal("00:00");
    setSelectedDateThursdayIntervalInital("00:00");
    setSelectedDateThursdayIntervalFinal("00:00");
  }

  const handlePersonalizedMessageDateThursday = () => {
    const checkboxPersonalizedMessageThursdayFinalHour = document.getElementById("checkboxPersonalizedMessageThursdayFinalHour");
    const personalizedMessageThursdayFinalHour = document.getElementById("personalizedMessageThursdayFinalHour");
    
    
    if (checkboxPersonalizedMessageThursdayFinalHour.checked) {
      personalizedMessageThursdayFinalHour.disabled = false;
      personalizedMessageThursdayFinalHour.style.backgroundColor = "inherit";
    } else {
      personalizedMessageThursdayFinalHour.disabled = true;
      personalizedMessageThursdayFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    }
  }

  // ---- Friday ----
  const [selectedDateFridayInitial, setSelectedDateFridayInitial] = useState();
  const [selectedDateFridayFinal, setSelectedDateFridayFinal] = useState();
  const [selectFridayIsOpen, setSelectedFridayIsOpen] = useState();
  const [selectedDateFridayIntervalInitial, setSelectedDateFridayIntervalInital] = useState();
  const [selectedDateFridayIntervalFinal, setSelectedDateFridayIntervalFinal] = useState();
  const [checkboxPersonalizedMessageFridayFinalHourChecked, setCheckboxPersonalizedMessageFridayFinalHourChecked] = useState(false);
  const [personalizedMessageFriday, setPersonalizedMessageFriday] = useState();

  const handleFridayMessageChange = (event) => { setPersonalizedMessageFriday(event.target.value); }
  const handleDateFridayInitialChange = (event) => { setSelectedDateFridayInitial(event.target.value); };
  const handleDateFridayFinalChange = (event) => { setSelectedDateFridayFinal(event.target.value); };
  const handleDateFridayIntervalInitialChange = (event) => { setSelectedDateFridayIntervalInital(event.target.value); }
  const handleDateFridayIntervalFinalChange = (event) => { setSelectedDateFridayIntervalFinal(event.target.value); }

  const handleOpenDateFriday = () => {
    const fridayInitialHour = document.getElementById("fridayInitialHour");
    const fridayFinalHour = document.getElementById("fridayFinalHour");
    const fridayIntervalInitialHour = document.getElementById("fridayIntervalInitialHour");
    const fridayIntervalFinalHour = document.getElementById("fridayIntervalFinalHour");

    fridayInitialHour.style.backgroundColor = backgroundColorTheme;
    fridayFinalHour.style.backgroundColor = backgroundColorTheme;
    fridayIntervalInitialHour.style.backgroundColor = backgroundColorTheme;
    fridayIntervalFinalHour.style.backgroundColor = backgroundColorTheme;

    fridayInitialHour.style.color = textColorTheme;
    fridayFinalHour.style.color = textColorTheme;
    fridayIntervalInitialHour.style.color = textColorTheme;
    fridayIntervalFinalHour.style.color = textColorTheme;

    fridayInitialHour.disabled = false;
    fridayFinalHour.disabled = false;
    fridayIntervalInitialHour.disabled = false;
    fridayIntervalFinalHour.disabled = false;

    fridayInitialHour.value = selectedDateFridayInitial;
    fridayFinalHour.value = selectedDateFridayFinal;
    fridayIntervalInitialHour.value = selectedDateFridayIntervalInitial;
    fridayIntervalFinalHour.value = selectedDateFridayIntervalFinal; 
  }

  const handleClosedDateFriday = () => {
    const fridayInitialHour = document.getElementById("fridayInitialHour");
    const fridayFinalHour = document.getElementById("fridayFinalHour");
    const fridayIntervalInitialHour = document.getElementById("fridayIntervalInitialHour");
    const fridayIntervalFinalHour = document.getElementById("fridayIntervalFinalHour");

    fridayInitialHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    fridayFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    fridayIntervalInitialHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    fridayIntervalFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";

    fridayInitialHour.disabled = true;
    fridayFinalHour.disabled = true;
    fridayIntervalInitialHour.disabled = true;
    fridayIntervalFinalHour.disabled = true;

    setSelectedDateFridayInitial("00:00");
    setSelectedDateFridayFinal("00:00");
    setSelectedDateFridayIntervalInital("00:00");
    setSelectedDateFridayIntervalFinal("00:00");
  }

  const handlePersonalizedMessageDateFriday = () => {
    const checkboxPersonalizedMessageFridayFinalHour = document.getElementById("checkboxPersonalizedMessageFridayFinalHour");
    const personalizedMessageFridayFinalHour = document.getElementById("personalizedMessageFridayFinalHour");
    
    
    if (checkboxPersonalizedMessageFridayFinalHour.checked) {
      personalizedMessageFridayFinalHour.disabled = false;
      personalizedMessageFridayFinalHour.style.backgroundColor = "inherit";
    } else {
      personalizedMessageFridayFinalHour.disabled = true;
      personalizedMessageFridayFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    }
  }

  // ---- Saturday ----
  const [selectedDateSaturdayInitial, setSelectedDateSaturdayInitial] = useState();
  const [selectedDateSaturdayFinal, setSelectedDateSaturdayFinal] = useState();
  const [selectedDateSaturdayIntervalInitial, setSelectedDateSaturdayIntervalInital] = useState();
  const [selectedDateSaturdayIntervalFinal, setSelectedDateSaturdayIntervalFinal] = useState();
  const [selectSaturdayIsOpen, setSelectedSaturdayIsOpen] = useState();
  const [checkboxPersonalizedMessageSaturdayFinalHourChecked, setCheckboxPersonalizedMessageSaturdayFinalHourChecked] = useState(false);
  const [personalizedMessageSaturday, setPersonalizedMessageSaturday] = useState();

  const handleSaturdayMessageChange = (event) => { setPersonalizedMessageSaturday(event.target.value); }
  const handleDateSaturdayInitialChange = (event) => { setSelectedDateSaturdayInitial(event.target.value); };
  const handleDateSaturdayFinalChange = (event) => { setSelectedDateSaturdayFinal(event.target.value); };
  const handleDateSaturdayIntervalInitialChange = (event) => { setSelectedDateSaturdayIntervalInital(event.target.value); }
  const handleDateSaturdayIntervalFinalChange = (event) => { setSelectedDateSaturdayIntervalFinal(event.target.value); }

  const handleOpenDateSaturday = () => {
    const saturdayInitialHour = document.getElementById("saturdayInitialHour");
    const saturdayFinalHour = document.getElementById("saturdayFinalHour");
    const saturdayIntervalInitialHour = document.getElementById("saturdayIntervalInitialHour");
    const saturdayIntervalFinalHour = document.getElementById("saturdayIntervalFinalHour");

    saturdayInitialHour.style.backgroundColor = backgroundColorTheme;
    saturdayFinalHour.style.backgroundColor = backgroundColorTheme;
    saturdayIntervalInitialHour.style.backgroundColor = backgroundColorTheme;
    saturdayIntervalFinalHour.style.backgroundColor = backgroundColorTheme;

    saturdayInitialHour.style.color = textColorTheme;
    saturdayFinalHour.style.color = textColorTheme;
    saturdayIntervalInitialHour.style.color = textColorTheme;
    saturdayIntervalFinalHour.style.color = textColorTheme;

    saturdayInitialHour.disabled = false;
    saturdayFinalHour.disabled = false;
    saturdayIntervalInitialHour.disabled = false;
    saturdayIntervalFinalHour.disabled = false;

    saturdayInitialHour.value = selectedDateSaturdayInitial;
    saturdayFinalHour.value = selectedDateSaturdayFinal;
    saturdayIntervalInitialHour.value = selectedDateSaturdayIntervalInitial;
    saturdayIntervalFinalHour.value = selectedDateSaturdayIntervalFinal; 
  }

  const handleClosedDateSaturday = () => {
    const saturdayInitialHour = document.getElementById("saturdayInitialHour");
    const saturdayFinalHour = document.getElementById("saturdayFinalHour");
    const saturdayIntervalInitialHour = document.getElementById("saturdayIntervalInitialHour");
    const saturdayIntervalFinalHour = document.getElementById("saturdayIntervalFinalHour");

    saturdayInitialHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    saturdayFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    saturdayIntervalInitialHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    saturdayIntervalFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";

    saturdayInitialHour.disabled = true;
    saturdayFinalHour.disabled = true;
    saturdayIntervalInitialHour.disabled = true;
    saturdayIntervalFinalHour.disabled = true;

    setSelectedDateSaturdayInitial("00:00");
    setSelectedDateSaturdayFinal("00:00");
    setSelectedDateSaturdayIntervalInital("00:00");
    setSelectedDateSaturdayIntervalFinal("00:00");
  }

  const handlePersonalizedMessageDateSaturday = () => {
    const checkboxPersonalizedMessageSaturdayFinalHour = document.getElementById("checkboxPersonalizedMessageSaturdayFinalHour");
    const personalizedMessageSaturdayFinalHour = document.getElementById("personalizedMessageSaturdayFinalHour");
    
    if (checkboxPersonalizedMessageSaturdayFinalHour.checked) {
      personalizedMessageSaturdayFinalHour.disabled = false;
      personalizedMessageSaturdayFinalHour.style.backgroundColor = "inherit";
    } else {
      personalizedMessageSaturdayFinalHour.disabled = true;
      personalizedMessageSaturdayFinalHour.style.backgroundColor = "rgba(0, 0, 0, 0.1)";
    }
  }

  // ---- Savings ----
  const handleSaveBusinessHours = async () => {
    // Initial and Final Hours
    const sundayInitialHour = document.getElementById("sundayInitialHour");
    const sundayFinalHour = document.getElementById("sundayFinalHour");

    const mondayInitialHour = document.getElementById("mondayInitialHour");
    const mondayFinalHour = document.getElementById("mondayFinalHour");

    const tuesdayInitialHour = document.getElementById("tuesdayInitialHour");
    const tuesdayFinalHour = document.getElementById("tuesdayFinalHour");

    const wednesdayInitialHour = document.getElementById("wednesdayInitialHour");
    const wednesdayFinalHour = document.getElementById("wednesdayFinalHour");

    const thursdayInitialHour = document.getElementById("thursdayInitialHour");
    const thursdayFinalHour = document.getElementById("thursdayFinalHour");

    const fridayInitialHour = document.getElementById("fridayInitialHour");
    const fridayFinalHour = document.getElementById("fridayFinalHour");

    const saturdayInitialHour = document.getElementById("saturdayInitialHour");
    const saturdayFinalHour = document.getElementById("saturdayFinalHour");

    // Interval Initial and Final Hours
    const sundayIntervalInitialHour = document.getElementById("sundayIntervalInitialHour");
    const sundayIntervalFinalHour = document.getElementById("sundayIntervalFinalHour");

    const mondayIntervalInitialHour = document.getElementById("mondayIntervalInitialHour");
    const mondayIntervalFinalHour = document.getElementById("mondayIntervalFinalHour");

    const tuesdayIntervalInitialHour = document.getElementById("tuesdayIntervalInitialHour");
    const tuesdayIntervalFinalHour = document.getElementById("tuesdayIntervalFinalHour");

    const wednesdayIntervalInitialHour = document.getElementById("wednesdayIntervalInitialHour");
    const wednesdayIntervalFinalHour = document.getElementById("wednesdayIntervalFinalHour");

    const thursdayIntervalInitialHour = document.getElementById("thursdayIntervalInitialHour");
    const thursdayIntervalFinalHour = document.getElementById("thursdayIntervalFinalHour");

    const fridayIntervalInitialHour = document.getElementById("fridayIntervalInitialHour");
    const fridayIntervalFinalHour = document.getElementById("fridayIntervalFinalHour");

    const saturdayIntervalInitialHour = document.getElementById("saturdayIntervalInitialHour");
    const saturdayIntervalFinalHour = document.getElementById("saturdayIntervalFinalHour");

    // Open and Closed Radios
    const sundayRadioOpen = document.getElementById("sundayRadioOpen");
    const mondayRadioOpen = document.getElementById("mondayRadioOpen");
    const tuesdayRadioOpen = document.getElementById("tuesdayRadioOpen");
    const wednesdayRadioOpen = document.getElementById("wednesdayRadioOpen");
    const thursdayRadioOpen = document.getElementById("thursdayRadioOpen");
    const fridayRadioOpen = document.getElementById("fridayRadioOpen");
    const saturdayRadioOpen = document.getElementById("saturdayRadioOpen");

    // Personalized Messages Fields
    const personalizedMessageSundayFinalHour = document.getElementById("personalizedMessageSundayFinalHour");
    const personalizedMessageMondayFinalHour = document.getElementById("personalizedMessageMondayFinalHour");
    const personalizedMessageTuesdayFinalHour = document.getElementById("personalizedMessageTuesdayFinalHour");
    const personalizedMessageWednesdayFinalHour = document.getElementById("personalizedMessageWednesdayFinalHour");
    const personalizedMessageThursdayFinalHour = document.getElementById("personalizedMessageThursdayFinalHour");
    const personalizedMessageFridayFinalHour = document.getElementById("personalizedMessageFridayFinalHour");
    const personalizedMessageSaturdayFinalHour = document.getElementById("personalizedMessageSaturdayFinalHour");

    // Personalized Messages Checkboxes
    const checkboxPersonalizedMessageSundayFinalHour = document.getElementById("checkboxPersonalizedMessageSundayFinalHour");
    const checkboxPersonalizedMessageMondayFinalHour = document.getElementById("checkboxPersonalizedMessageMondayFinalHour");
    const checkboxPersonalizedMessageTuesdayFinalHour = document.getElementById("checkboxPersonalizedMessageTuesdayFinalHour");
    const checkboxPersonalizedMessageWednesdayFinalHour = document.getElementById("checkboxPersonalizedMessageWednesdayFinalHour");
    const checkboxPersonalizedMessageThursdayFinalHour = document.getElementById("checkboxPersonalizedMessageThursdayFinalHour");
    const checkboxPersonalizedMessageFridayFinalHour = document.getElementById("checkboxPersonalizedMessageFridayFinalHour");
    const checkboxPersonalizedMessageSaturdayFinalHour = document.getElementById("checkboxPersonalizedMessageSaturdayFinalHour");

    // Comparisons Array
    let initialFinalHoursComparisons = new Array();
    initialFinalHoursComparisons.push(compareTimeInMinutes(sundayInitialHour.value, sundayFinalHour.value));
    initialFinalHoursComparisons.push(compareTimeInMinutes(mondayInitialHour.value, mondayFinalHour.value));
    initialFinalHoursComparisons.push(compareTimeInMinutes(tuesdayInitialHour.value, tuesdayFinalHour.value));
    initialFinalHoursComparisons.push(compareTimeInMinutes(wednesdayInitialHour.value, wednesdayFinalHour.value));
    initialFinalHoursComparisons.push(compareTimeInMinutes(thursdayInitialHour.value, thursdayFinalHour.value));
    initialFinalHoursComparisons.push(compareTimeInMinutes(fridayInitialHour.value, fridayFinalHour.value));
    initialFinalHoursComparisons.push(compareTimeInMinutes(saturdayInitialHour.value, saturdayFinalHour.value));

    let intervalInitialFinalHoursComparisons = new Array();
    intervalInitialFinalHoursComparisons.push(compareIntervalAndBusinessHours(sundayIntervalInitialHour.value, sundayInitialHour.value, sundayIntervalFinalHour.value, sundayFinalHour.value));
    intervalInitialFinalHoursComparisons.push(compareIntervalAndBusinessHours(mondayIntervalInitialHour.value, mondayInitialHour.value, mondayIntervalFinalHour.value, mondayFinalHour.value));
    intervalInitialFinalHoursComparisons.push(compareIntervalAndBusinessHours(tuesdayIntervalInitialHour.value, tuesdayInitialHour.value, tuesdayIntervalFinalHour.value, tuesdayFinalHour.value));
    intervalInitialFinalHoursComparisons.push(compareIntervalAndBusinessHours(wednesdayIntervalInitialHour.value, wednesdayInitialHour.value, wednesdayIntervalFinalHour.value, wednesdayFinalHour.value));
    intervalInitialFinalHoursComparisons.push(compareIntervalAndBusinessHours(thursdayIntervalInitialHour.value, thursdayInitialHour.value, thursdayIntervalFinalHour.value, thursdayFinalHour.value));
    intervalInitialFinalHoursComparisons.push(compareIntervalAndBusinessHours(fridayIntervalInitialHour.value, fridayInitialHour.value, fridayIntervalFinalHour.value, fridayFinalHour.value));
    intervalInitialFinalHoursComparisons.push(compareIntervalAndBusinessHours(saturdayIntervalInitialHour.value, saturdayInitialHour.value, saturdayIntervalFinalHour.value, saturdayFinalHour.value));

    let openClosedRadioComparions = new Array();
    openClosedRadioComparions.push(sundayRadioOpen.checked);
    openClosedRadioComparions.push(mondayRadioOpen.checked);
    openClosedRadioComparions.push(tuesdayRadioOpen.checked);
    openClosedRadioComparions.push(wednesdayRadioOpen.checked);
    openClosedRadioComparions.push(thursdayRadioOpen.checked);
    openClosedRadioComparions.push(fridayRadioOpen.checked);
    openClosedRadioComparions.push(saturdayRadioOpen.checked);

    let checkboxPersonalizedMessageComparisons = new Array();
    checkboxPersonalizedMessageComparisons.push(checkboxPersonalizedMessageSundayFinalHour.checked);
    checkboxPersonalizedMessageComparisons.push(checkboxPersonalizedMessageMondayFinalHour.checked);
    checkboxPersonalizedMessageComparisons.push(checkboxPersonalizedMessageTuesdayFinalHour.checked);
    checkboxPersonalizedMessageComparisons.push(checkboxPersonalizedMessageWednesdayFinalHour.checked);
    checkboxPersonalizedMessageComparisons.push(checkboxPersonalizedMessageThursdayFinalHour.checked);
    checkboxPersonalizedMessageComparisons.push(checkboxPersonalizedMessageFridayFinalHour.checked);
    checkboxPersonalizedMessageComparisons.push(checkboxPersonalizedMessageSaturdayFinalHour.checked);

    let personalizedMessageComparisons = new Array();
    personalizedMessageComparisons.push(personalizedMessageSundayFinalHour.value);
    personalizedMessageComparisons.push(personalizedMessageMondayFinalHour.value);
    personalizedMessageComparisons.push(personalizedMessageTuesdayFinalHour.value);
    personalizedMessageComparisons.push(personalizedMessageWednesdayFinalHour.value);
    personalizedMessageComparisons.push(personalizedMessageThursdayFinalHour.value);
    personalizedMessageComparisons.push(personalizedMessageFridayFinalHour.value);
    personalizedMessageComparisons.push(personalizedMessageSaturdayFinalHour.value);

    // Comparisons Results
    const initialFinalHoursComparisonFlag = checkOutTimeInMinutesComparisons(initialFinalHoursComparisons);
    const openClosedComparisonFlag = compareOpenClosedRadiosTimeInMinutes(initialFinalHoursComparisons, openClosedRadioComparions);
    const personalizedMessagesFlag = comparePersonalizedMessagesLength(checkboxPersonalizedMessageComparisons, personalizedMessageComparisons);
    const intervalHoursComparisonFlag = checkOutIntervalHoursComparisons(intervalInitialFinalHoursComparisons);

    // Checando as comparações:
    //
    // se todas em todas as comparações o horário inicial for menor
    // do que o final E as mensagens personalizadas estão preenchidas corretamente
    // OU 
    // se os horários iguais são dos dias nos quais a empresa
    // está fechada E não há problemas com as mensagens personalizadas
    //
    // os dados são armazenados no banco de dados
    if ((initialFinalHoursComparisonFlag === 0 && !personalizedMessagesFlag && (intervalHoursComparisonFlag === 0 || intervalHoursComparisonFlag === 3))
      || (initialFinalHoursComparisonFlag === 1 && !openClosedComparisonFlag && !personalizedMessagesFlag && ((intervalHoursComparisonFlag === 0 || intervalHoursComparisonFlag === 3)))) {

      const hours = [
        { 
          initialHour: sundayInitialHour.value
          , finalHour: sundayFinalHour.value
          , isOpen: sundayRadioOpen.checked
          , personalizedMessage: personalizedMessageSundayFinalHour.value
          , intervalInitialHour: sundayIntervalInitialHour.value
          , intervalFinalHour: sundayIntervalFinalHour.value
        },

        {
          initialHour: mondayInitialHour.value
          , finalHour: mondayFinalHour.value
          , isOpen: mondayRadioOpen.checked
          , personalizedMessage: personalizedMessageMondayFinalHour.value 
          , intervalInitialHour: mondayIntervalInitialHour.value
          , intervalFinalHour: mondayIntervalFinalHour.value
        },

        {
          initialHour: tuesdayInitialHour.value
          , finalHour: tuesdayFinalHour.value
          , isOpen: tuesdayRadioOpen.checked
          , personalizedMessage: personalizedMessageTuesdayFinalHour.value
          , intervalInitialHour: tuesdayIntervalInitialHour.value
          , intervalFinalHour: tuesdayIntervalFinalHour.value
        },

        {
          initialHour: wednesdayInitialHour.value
          , finalHour: wednesdayFinalHour.value
          , isOpen: wednesdayRadioOpen.checked
          , personalizedMessage: personalizedMessageWednesdayFinalHour.value
          , intervalInitialHour: wednesdayIntervalInitialHour.value
          , intervalFinalHour: wednesdayIntervalFinalHour.value
        },

        {
          initialHour: thursdayInitialHour.value
          , finalHour: thursdayFinalHour.value
          , isOpen: thursdayRadioOpen.checked
          , personalizedMessage: personalizedMessageThursdayFinalHour.value
          , intervalInitialHour: thursdayIntervalInitialHour.value
          , intervalFinalHour: thursdayIntervalFinalHour.value
        },

        {
          initialHour: fridayInitialHour.value
          , finalHour: fridayFinalHour.value
          , isOpen: fridayRadioOpen.checked
          , personalizedMessage: personalizedMessageFridayFinalHour.value
          , intervalInitialHour: fridayIntervalInitialHour.value
          , intervalFinalHour: fridayIntervalFinalHour.value
        },

        {
          initialHour: saturdayInitialHour.value
          , finalHour: saturdayFinalHour.value
          , isOpen: saturdayRadioOpen.checked
          , personalizedMessage: personalizedMessageSaturdayFinalHour.value
          , intervalInitialHour: saturdayIntervalInitialHour.value
          , intervalFinalHour: saturdayIntervalFinalHour.value
        }
      ];

      // Se fuso horário é antes do de Greenwich, offset é positivo,
      // caso contrário, negativo      
      await api.put("/businessHours/", hours);
    }

    // Se pelo menos um horário inicial de intervalo for igual ou maior do que o final
    else if (intervalHoursComparisonFlag === 1) {
      toast.info(i18n.t("backendErrors.INTERVAL_HOURS_EQUALS_AFTER_FINAL_HOUR"));
    }

    // Se pelo menos um horário inicial/final for antes/depois da Business Hours
    else if (intervalHoursComparisonFlag === 2) {
      toast.info(i18n.t("backendErrors.INTERVAL_HOURS_NOT_BETWEEN_BUSINESS_HOURS"));
    }

    // Se os horários foram preenchidos corretamente, mas as mensagens personalizadas
    // não foram, uma mensagem de erro é retornada
    else if ((initialFinalHoursComparisonFlag === 0 && personalizedMessagesFlag)
            || (initialFinalHoursComparisonFlag === 1 && personalizedMessagesFlag)) {
      toast.info(i18n.t("backendErrors.BUSINESS_HOURS_PERSONALIZED_MESSAGES_EMPTY"));
    }

    // Se pelo menos um horário inicial for igual ao horário final e o checkbox
    // 'open' estiver marcado, uma mensagem de erro é retornada
    else if (initialFinalHoursComparisonFlag === 1) {
      toast.info(i18n.t("backendErrors.BUSINESS_HOURS_INITIAL_HOUR_EQUAL_FINAL_HOUR"));
    }

    // Se pelo menos um horário inicial for maior do que o horário final, uma
    // mensagem de erro é retornada
    else if (initialFinalHoursComparisonFlag === 2) {
      toast.info(i18n.t("backendErrors.BUSINESS_HOURS_INITIAL_HOUR_AFTER_FINAL_HOUR"));
    }
  }

  return (
    <>
    <SocketBusinessHours
      dispatch={businessHoursDispatch}
    />

    <MainHeader>
      <Title>{i18n.t("businessHours.title.title1")}</Title>
      <MainHeaderButtonsWrapper>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveBusinessHours}
          className={classes.floatingButton}
        >
          {i18n.t("businessHours.buttons.save")}
        </Button>
      </MainHeaderButtonsWrapper>
    </MainHeader>

    <Paper className={classes.mainPaper} variant="outlined">
      <Grid item xs={12} className={classes.sectionContainer}>

        {/* Sunday */}
        <Grid className={classes.businessHoursItem}>
					<Paper className={classes.weekdayContainer}>
						<Grid item>
              <Typography className={classes.paddingLeft} component="h3" variant="h6" paragraph>
                {i18n.t("businessHours.weekdays.sunday")}
              </Typography>
              <FormControl className={classes.radioControl}>
                {selectSundayIsOpen === "1" && (
                  <>
                  <RadioGroup
                    row
                    aria-labelledby="sunday-radio-buttons-group-label"
                    defaultValue="1"
                    name="sunday-radio-buttons-group"
                  >
                    <FormControlLabel onClick={handleOpenDateSunday} value="1" control={<Radio id="sundayRadioOpen" color="primary" />} label={i18n.t("businessHours.checkbox.open")} />
                    <FormControlLabel onClick={handleClosedDateSunday} value="0" control={<Radio id="sundayRadioClosed" color="primary" />} label={i18n.t("businessHours.checkbox.closed")} />
                  </RadioGroup>
                  <br /><br />

                  <b>{i18n.t("businessHours.assets.businessHour")}</b>         
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="sundayInitialHour"
                      name="sundayInitialHour"
                      value={selectedDateSundayInitial}
                      onChange={handleDateSundayInitialChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme } }}
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="sundayFinalHour"
                      name="sundayFinalHour"
                      value={selectedDateSundayFinal}
                      onChange={handleDateSundayFinalChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme }, }}
                    />
                  </Container>
                  <br /><br />

                  <b>{i18n.t("businessHours.assets.intervalHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="sundayIntervalInitialHour"
                      name="sundayIntervalInitialHour"
                      value={selectedDateSundayIntervalInitial}
                      onChange={handleDateSundayIntervalInitialChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme } }}
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="sundayIntervalFinalHour"
                      name="sundayIntervalFinalHour"
                      value={selectedDateSundayIntervalFinal}
                      onChange={handleDateSundayIntervalFinalChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme }, }}
                    />
                  </Container>
                  </>
                )}

                {selectSundayIsOpen === "0" && (
                  <>
                  <RadioGroup
                    row
                    aria-labelledby="sunday-radio-buttons-group-label"
                    defaultValue="0"
                    name="sunday-radio-buttons-group"
                  >
                    <FormControlLabel onClick={handleOpenDateSunday} value="1" control={<Radio id="sundayRadioOpen" color="primary" />} label={i18n.t("businessHours.checkbox.open")} />
                    <FormControlLabel onClick={handleClosedDateSunday} value="0" control={<Radio id="sundayRadioClosed" color="primary" />} label={i18n.t("businessHours.checkbox.closed")} />
                  </RadioGroup>

                  <br /><br />
                  
                  <b>{i18n.t("businessHours.assets.businessHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="sundayInitialHour"
                      name="sundayInitialHour"
                      value={selectedDateSundayInitial}
                      onChange={handleDateSundayInitialChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme }, }}
                      disabled
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="sundayFinalHour"
                      name="sundayFinalHour"
                      value={selectedDateSundayFinal}
                      onChange={handleDateSundayFinalChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme } }}
                      disabled
                    />
                  </Container>

                  <br /><br />

                  <b>{i18n.t("businessHours.assets.intervalHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="sundayIntervalInitialHour"
                      name="sundayIntervalInitialHour"
                      value={selectedDateSundayIntervalInitial}
                      onChange={handleDateSundayIntervalInitialChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme }, }}
                      disabled
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="sundayIntervalFinalHour"
                      name="sundayIntervalFinalHour"
                      value={selectedDateSundayIntervalFinal}
                      onChange={handleDateSundayIntervalFinalChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme } }}
                      disabled
                    />
                  </Container>
                  </>
                )}

                <br />
                <br />

                <Container>
                  <FormGroup>
                    <FormControlLabel 
                      control={
                        <Checkbox 
                          id="checkboxPersonalizedMessageSundayFinalHour" 
                          onClick={handlePersonalizedMessageDateSunday}
                        />
                      } 
                      label={i18n.t("businessHours.assets.personalizedMessage")} 
                    />

                    {checkboxPersonalizedMessageSundayFinalHourChecked ? (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          type="text"
                          id="personalizedMessageSundayFinalHour"
                          style={{ "backgroundColor": backgroundColorTheme }}
                          InputProps={{ style: { color: textColorTheme }, }}
                          name="personalizedMessageSundayFinalHour"
                          value={personalizedMessageSunday}
                          onChange={handleSundayMessageChange}
                        />
                      ) : (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          type="text"
                          id="personalizedMessageSundayFinalHour"
                          name="personalizedMessageSundayFinalHour"
                          style={{ "backgroundColor": backgroundColorTheme }}
                          InputProps={{ style: { color: textColorTheme }, }}
                          value={personalizedMessageSunday}
                          onChange={handleSundayMessageChange}
                          disabled
                        />
                      )}

                    <br />
                  </FormGroup>
                </Container>
              </FormControl>
            </Grid>
					</Paper>
				</Grid>

        {/* Monday */}
        <Grid className={classes.businessHoursItem}>
          <Paper>
            <Grid item className={classes.weekdayContainer}>
              <Typography className={classes.paddingLeft} component="h3" variant="h6" paragraph>
                {i18n.t("businessHours.weekdays.monday")}
              </Typography>
              <FormControl className={classes.radioControl}>
              {selectMondayIsOpen === "1" && (
                  <>
                  <RadioGroup
                    row
                    aria-labelledby="monday-radio-buttons-group-label"
                    defaultValue="1"
                    name="monday-radio-buttons-group"
                  >
                    <FormControlLabel onClick={handleOpenDateMonday} value="1" control={<Radio id="mondayRadioOpen" color="primary" />} label={i18n.t("businessHours.checkbox.open")} />
                    <FormControlLabel onClick={handleClosedDateMonday} value="0" control={<Radio id="mondayRadioClosed" color="primary" />} label={i18n.t("businessHours.checkbox.closed")} />
                  </RadioGroup>
                  <br /><br />

                  <b>{i18n.t("businessHours.assets.businessHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="mondayInitialHour"
                      name="mondayInitialHour"
                      value={selectedDateMondayInitial}
                      onChange={handleDateMondayInitialChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme } }}
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="mondayFinalHour"
                      name="mondayFinalHour"
                      value={selectedDateMondayFinal}
                      onChange={handleDateMondayFinalChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme }, }}
                    />
                  </Container>
                  <br /><br />

                  <b>{i18n.t("businessHours.assets.intervalHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="mondayIntervalInitialHour"
                      name="mondayIntervalInitialHour"
                      value={selectedDateMondayIntervalInitial}
                      onChange={handleDateMondayIntervalInitialChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme } }}
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="mondayIntervalFinalHour"
                      name="mondayIntervalFinalHour"
                      value={selectedDateMondayIntervalFinal}
                      onChange={handleDateMondayIntervalFinalChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme }, }}
                    />
                  </Container>
                  </>
                )}

                {selectMondayIsOpen === "0" && (
                  <>
                  <RadioGroup
                    row
                    aria-labelledby="monday-radio-buttons-group-label"
                    defaultValue="0"
                    name="monday-radio-buttons-group"
                  >
                    <FormControlLabel onClick={handleOpenDateMonday} value="1" control={<Radio id="mondayRadioOpen" color="primary" />} label={i18n.t("businessHours.checkbox.open")} />
                    <FormControlLabel onClick={handleClosedDateMonday} value="0" control={<Radio id="mondayRadioClosed" color="primary" />} label={i18n.t("businessHours.checkbox.closed")} />
                  </RadioGroup>

                  <br /><br />
                  
                  <b>{i18n.t("businessHours.assets.businessHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="mondayInitialHour"
                      name="mondayInitialHour"
                      value={selectedDateMondayInitial}
                      onChange={handleDateMondayInitialChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme }, }}
                      disabled
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="mondayFinalHour"
                      name="mondayFinalHour"
                      value={selectedDateMondayFinal}
                      onChange={handleDateMondayFinalChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme } }}
                      disabled
                    />
                  </Container>

                  <br /><br />

                  <b>{i18n.t("businessHours.assets.intervalHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="mondayIntervalInitialHour"
                      name="mondayIntervalInitialHour"
                      value={selectedDateMondayIntervalInitial}
                      onChange={handleDateMondayIntervalInitialChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme }, }}
                      disabled
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="mondayIntervalFinalHour"
                      name="mondayIntervalFinalHour"
                      value={selectedDateMondayIntervalFinal}
                      onChange={handleDateMondayIntervalFinalChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme } }}
                      disabled
                    />
                  </Container>
                  </>
                )}

                <br />
                <br />

                <Container>
                  <FormGroup>
                    <FormControlLabel 
                      control={
                        <Checkbox 
                          id="checkboxPersonalizedMessageMondayFinalHour" 
                          onClick={handlePersonalizedMessageDateMonday} 
                        />
                      } 
                      label={i18n.t("businessHours.assets.personalizedMessage")} />
                    
                    {checkboxPersonalizedMessageMondayFinalHourChecked ? (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          type="text"
                          id="personalizedMessageMondayFinalHour"
                          name="personalizedMessageMondayFinalHour"
                          style={{ "backgroundColor": backgroundColorTheme }}
                          InputProps={{ style: { color: textColorTheme }, }}
                          value={personalizedMessageMonday}
                          onChange={handleMondayMessageChange}
                        />
                      ) : (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          type="text"
                          id="personalizedMessageMondayFinalHour"
                          name="personalizedMessageMondayFinalHour"
                          style={{ "backgroundColor": backgroundColorTheme }}
                          InputProps={{ style: { color: textColorTheme }, }}
                          value={personalizedMessageMonday}
                          onChange={handleMondayMessageChange}
                          disabled
                        />
                      )}

                    <br />
                  </FormGroup>
                </Container>
              </FormControl>
            </Grid>
          </Paper>
        </Grid>

        {/* Tuesday */}
        <Grid className={classes.businessHoursItem}>
          <Paper>
            <Grid item className={classes.weekdayContainer}>
              <Typography className={classes.paddingLeft} component="h3" variant="h6" paragraph>
                {i18n.t("businessHours.weekdays.tuesday")}
              </Typography>
              <FormControl className={classes.radioControl}>
              {selectTuesdayIsOpen === "1" && (
                  <>
                  <RadioGroup
                    row
                    aria-labelledby="tuesday-radio-buttons-group-label"
                    defaultValue="1"
                    name="tuesday-radio-buttons-group"
                  >
                    <FormControlLabel onClick={handleOpenDateTuesday} value="1" control={<Radio id="tuesdayRadioOpen" color="primary" />} label={i18n.t("businessHours.checkbox.open")} />
                    <FormControlLabel onClick={handleClosedDateTuesday} value="0" control={<Radio id="tuesdayRadioClosed" color="primary" />} label={i18n.t("businessHours.checkbox.closed")} />
                  </RadioGroup>
                  <br /><br />

                  <b>{i18n.t("businessHours.assets.businessHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="tuesdayInitialHour"
                      name="tuesdayInitialHour"
                      value={selectedDateTuesdayInitial}
                      onChange={handleDateTuesdayInitialChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme } }}
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="tuesdayFinalHour"
                      name="tuesdayFinalHour"
                      value={selectedDateTuesdayFinal}
                      onChange={handleDateTuesdayFinalChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme }, }}
                    />
                  </Container>
                  <br /><br />

                  <b>{i18n.t("businessHours.assets.intervalHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="tuesdayIntervalInitialHour"
                      name="tuesdayIntervalInitialHour"
                      value={selectedDateTuesdayIntervalInitial}
                      onChange={handleDateTuesdayIntervalInitialChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme } }}
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="tuesdayIntervalFinalHour"
                      name="tuesdayIntervalFinalHour"
                      value={selectedDateTuesdayIntervalFinal}
                      onChange={handleDateTuesdayIntervalFinalChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme }, }}
                    />
                  </Container>
                  </>
                )}

                {selectTuesdayIsOpen === "0" && (
                  <>
                  <RadioGroup
                    row
                    aria-labelledby="tuesday-radio-buttons-group-label"
                    defaultValue="0"
                    name="tuesday-radio-buttons-group"
                  >
                    <FormControlLabel onClick={handleOpenDateTuesday} value="1" control={<Radio id="tuesdayRadioOpen" color="primary" />} label={i18n.t("businessHours.checkbox.open")} />
                    <FormControlLabel onClick={handleClosedDateTuesday} value="0" control={<Radio id="tuesdayRadioClosed" color="primary" />} label={i18n.t("businessHours.checkbox.closed")} />
                  </RadioGroup>

                  <br /><br />

                  <b>{i18n.t("businessHours.assets.businessHour")}</b>                  
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="tuesdayInitialHour"
                      name="tuesdayInitialHour"
                      value={selectedDateTuesdayInitial}
                      onChange={handleDateTuesdayInitialChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme }, }}
                      disabled
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="tuesdayFinalHour"
                      name="tuesdayFinalHour"
                      value={selectedDateTuesdayFinal}
                      onChange={handleDateTuesdayFinalChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme } }}
                      disabled
                    />
                  </Container>

                  <br /><br />

                  <b>{i18n.t("businessHours.assets.intervalHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="tuesdayIntervalInitialHour"
                      name="tuesdayIntervalInitialHour"
                      value={selectedDateTuesdayIntervalInitial}
                      onChange={handleDateTuesdayIntervalInitialChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme }, }}
                      disabled
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="tuesdayIntervalFinalHour"
                      name="tuesdayIntervalFinalHour"
                      value={selectedDateTuesdayIntervalFinal}
                      onChange={handleDateTuesdayIntervalFinalChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme } }}
                      disabled
                    />
                  </Container>
                  </>
                )}

                <br />
                <br />

                <Container>
                  <FormGroup>
                    <FormControlLabel 
                      control={
                        <Checkbox 
                          id="checkboxPersonalizedMessageTuesdayFinalHour" 
                          onClick={handlePersonalizedMessageDateTuesday} 
                        />
                      } 
                      label={i18n.t("businessHours.assets.personalizedMessage")} />
                    
                    {checkboxPersonalizedMessageTuesdayFinalHourChecked ? (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          type="text"
                          id="personalizedMessageTuesdayFinalHour"
                          name="personalizedMessageTuesdayFinalHour"
                          style={{ "backgroundColor": backgroundColorTheme }}
                          InputProps={{ style: { color: textColorTheme }, }}
                          value={personalizedMessageTuesday}
                          onChange={handleTuesdayMessageChange}
                        />
                      ) : (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          type="text"
                          id="personalizedMessageTuesdayFinalHour"
                          name="personalizedMessageTuesdayFinalHour"
                          style={{ "backgroundColor": backgroundColorTheme }}
                          InputProps={{ style: { color: textColorTheme }, }}
                          value={personalizedMessageTuesday}
                          onChange={handleTuesdayMessageChange}
                          disabled
                        />
                      )}

                    <br />
                  </FormGroup>
                </Container>
              </FormControl>
            </Grid>
          </Paper>
        </Grid>

        {/* Wednesday */}
        <Grid className={classes.businessHoursItem}>
          <Paper>
            <Grid item className={classes.weekdayContainer}>
              <Typography className={classes.paddingLeft} component="h3" variant="h6" paragraph>
                {i18n.t("businessHours.weekdays.wednesday")}
              </Typography>
              <FormControl className={classes.radioControl}>
              {selectWednesdayIsOpen === "1" && (
                  <>
                  <RadioGroup
                    row
                    aria-labelledby="wednesday-radio-buttons-group-label"
                    defaultValue="1"
                    name="wednesday-radio-buttons-group"
                  >
                    <FormControlLabel onClick={handleOpenDateWednesday} value="1" control={<Radio id="wednesdayRadioOpen" color="primary" />} label={i18n.t("businessHours.checkbox.open")} />
                    <FormControlLabel onClick={handleClosedDateWednesday} value="0" control={<Radio id="wednesdayRadioClosed" color="primary" />} label={i18n.t("businessHours.checkbox.closed")} />
                  </RadioGroup>
                  <br /><br />

                  <b>{i18n.t("businessHours.assets.businessHour")}</b>                  
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="wednesdayInitialHour"
                      name="wednesdayInitialHour"
                      value={selectedDateWednesdayInitial}
                      onChange={handleDateWednesdayInitialChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme } }}
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="wednesdayFinalHour"
                      name="wednesdayFinalHour"
                      value={selectedDateWednesdayFinal}
                      onChange={handleDateWednesdayFinalChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme }, }}
                    />
                  </Container>
                  <br /><br />

                  <b>{i18n.t("businessHours.assets.intervalHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="wednesdayIntervalInitialHour"
                      name="wednesdayIntervalInitialHour"
                      value={selectedDateWednesdayIntervalInitial}
                      onChange={handleDateWednesdayIntervalInitialChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme } }}
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="wednesdayIntervalFinalHour"
                      name="wednesdayIntervalFinalHour"
                      value={selectedDateWednesdayIntervalFinal}
                      onChange={handleDateWednesdayIntervalFinalChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme }, }}
                    />
                  </Container>
                  </>
                )}

                {selectWednesdayIsOpen === "0" && (
                  <>
                  <RadioGroup
                    row
                    aria-labelledby="wednesday-radio-buttons-group-label"
                    defaultValue="0"
                    name="wednesday-radio-buttons-group"
                  >
                    <FormControlLabel onClick={handleOpenDateWednesday} value="1" control={<Radio id="wednesdayRadioOpen" color="primary" />} label={i18n.t("businessHours.checkbox.open")} />
                    <FormControlLabel onClick={handleClosedDateWednesday} value="0" control={<Radio id="wednesdayRadioClosed" color="primary" />} label={i18n.t("businessHours.checkbox.closed")} />
                  </RadioGroup>

                  <br /><br />

                  <b>{i18n.t("businessHours.assets.businessHour")}</b>              
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="wednesdayInitialHour"
                      name="wednesdayInitialHour"
                      value={selectedDateWednesdayInitial}
                      onChange={handleDateWednesdayInitialChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme }, }}
                      disabled
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="wednesdayFinalHour"
                      name="wednesdayFinalHour"
                      value={selectedDateWednesdayFinal}
                      onChange={handleDateWednesdayFinalChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme } }}
                      disabled
                    />
                  </Container>

                  <br /><br />

                  <b>{i18n.t("businessHours.assets.intervalHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="wednesdayIntervalInitialHour"
                      name="wednesdayIntervalInitialHour"
                      value={selectedDateWednesdayIntervalInitial}
                      onChange={handleDateWednesdayIntervalInitialChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme }, }}
                      disabled
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="wednesdayIntervalFinalHour"
                      name="wednesdayIntervalFinalHour"
                      value={selectedDateWednesdayIntervalFinal}
                      onChange={handleDateWednesdayIntervalFinalChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme } }}
                      disabled
                    />
                  </Container>
                  </>
                )}

                <br />
                <br />

                <Container>
                  <FormGroup>
                    <FormControlLabel 
                      control={
                        <Checkbox 
                          id="checkboxPersonalizedMessageWednesdayFinalHour" 
                          onClick={handlePersonalizedMessageDateWednesday} 
                        />
                      } 
                      label={i18n.t("businessHours.assets.personalizedMessage")} />
                    
                    {checkboxPersonalizedMessageWednesdayFinalHourChecked ? (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          type="text"
                          id="personalizedMessageWednesdayFinalHour"
                          name="personalizedMessageWednesdayFinalHour"
                          style={{ "backgroundColor": backgroundColorTheme }}
                          InputProps={{ style: { color: textColorTheme }, }}
                          value={personalizedMessageWednesday}
                          onChange={handleWednesdayMessageChange}
                        />
                      ) : (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          type="text"
                          id="personalizedMessageWednesdayFinalHour"
                          name="personalizedMessageWednesdayFinalHour"
                          style={{ "backgroundColor": backgroundColorTheme }}
                          InputProps={{ style: { color: textColorTheme }, }}
                          value={personalizedMessageWednesday}
                          onChange={handleWednesdayMessageChange}
                          disabled
                        />
                      )}

                    <br />
                  </FormGroup>
                </Container>
              </FormControl>
            </Grid>
          </Paper>
        </Grid>

        {/* Thursday */}
        <Grid className={classes.businessHoursItem}>
          <Paper>
            <Grid item className={classes.weekdayContainer}>
              <Typography className={classes.paddingLeft} component="h3" variant="h6" paragraph>
                {i18n.t("businessHours.weekdays.thursday")}
              </Typography>
              <FormControl className={classes.radioControl}>
              {selectThursdayIsOpen === "1" && (
                  <>
                  <RadioGroup
                    row
                    aria-labelledby="thursday-radio-buttons-group-label"
                    defaultValue="1"
                    name="thursday-radio-buttons-group"
                  >
                    <FormControlLabel onClick={handleOpenDateThursday} value="1" control={<Radio id="thursdayRadioOpen" color="primary" />} label={i18n.t("businessHours.checkbox.open")} />
                    <FormControlLabel onClick={handleClosedDateThursday} value="0" control={<Radio id="thursdayRadioClosed" color="primary" />} label={i18n.t("businessHours.checkbox.closed")} />
                  </RadioGroup>
                  <br /><br />

                  <b>{i18n.t("businessHours.assets.businessHour")}</b>             
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="thursdayInitialHour"
                      name="thursdayInitialHour"
                      value={selectedDateThursdayInitial}
                      onChange={handleDateThursdayInitialChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme } }}
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="thursdayFinalHour"
                      name="thursdayFinalHour"
                      value={selectedDateThursdayFinal}
                      onChange={handleDateThursdayFinalChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme }, }}
                    />
                  </Container>
                  <br /><br />

                  <b>{i18n.t("businessHours.assets.intervalHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="thursdayIntervalInitialHour"
                      name="thursdayIntervalInitialHour"
                      value={selectedDateThursdayIntervalInitial}
                      onChange={handleDateThursdayIntervalInitialChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme } }}
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="thursdayIntervalFinalHour"
                      name="thursdayIntervalFinalHour"
                      value={selectedDateThursdayIntervalFinal}
                      onChange={handleDateThursdayIntervalFinalChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme }, }}
                    />
                  </Container>
                  </>
                )}

                {selectThursdayIsOpen === "0" && (
                  <>
                  <RadioGroup
                    row
                    aria-labelledby="thursday-radio-buttons-group-label"
                    defaultValue="0"
                    name="thursday-radio-buttons-group"
                  >
                    <FormControlLabel onClick={handleOpenDateThursday} value="1" control={<Radio id="thursdayRadioOpen" color="primary" />} label={i18n.t("businessHours.checkbox.open")} />
                    <FormControlLabel onClick={handleClosedDateThursday} value="0" control={<Radio id="thursdayRadioClosed" color="primary" />} label={i18n.t("businessHours.checkbox.closed")} />
                  </RadioGroup>

                  <br /><br />

                  <b>{i18n.t("businessHours.assets.businessHour")}</b>                 
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="thursdayInitialHour"
                      name="thursdayInitialHour"
                      value={selectedDateThursdayInitial}
                      onChange={handleDateThursdayInitialChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme }, }}
                      disabled
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="thursdayFinalHour"
                      name="thursdayFinalHour"
                      value={selectedDateThursdayFinal}
                      onChange={handleDateThursdayFinalChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme } }}
                      disabled
                    />
                  </Container>

                  <br /><br />

                  <b>{i18n.t("businessHours.assets.intervalHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="thursdayIntervalInitialHour"
                      name="thursdayIntervalInitialHour"
                      value={selectedDateThursdayIntervalInitial}
                      onChange={handleDateThursdayIntervalInitialChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme }, }}
                      disabled
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="thursdayIntervalFinalHour"
                      name="thursdayIntervalFinalHour"
                      value={selectedDateThursdayIntervalFinal}
                      onChange={handleDateThursdayIntervalFinalChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme } }}
                      disabled
                    />
                  </Container>
                  </>
                )}

                <br />
                <br />

                <Container>
                  <FormGroup>
                    <FormControlLabel 
                      control={
                        <Checkbox 
                          id="checkboxPersonalizedMessageThursdayFinalHour" 
                          onClick={handlePersonalizedMessageDateThursday} 
                        />
                      } 
                      label={i18n.t("businessHours.assets.personalizedMessage")} />
                    
                    {checkboxPersonalizedMessageThursdayFinalHourChecked ? (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          type="text"
                          id="personalizedMessageThursdayFinalHour"
                          name="personalizedMessageThursdayFinalHour"
                          style={{ "backgroundColor": backgroundColorTheme }}
                          InputProps={{ style: { color: textColorTheme }, }}
                          value={personalizedMessageThursday}
                          onChange={handleThursdayMessageChange}
                        />
                      ) : (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          type="text"
                          id="personalizedMessageThursdayFinalHour"
                          name="personalizedMessageThursdayFinalHour"
                          style={{ "backgroundColor": backgroundColorTheme }}
                          InputProps={{ style: { color: textColorTheme }, }}
                          value={personalizedMessageThursday}
                          onChange={handleThursdayMessageChange}
                          disabled
                        />
                      )}

                    <br />
                  </FormGroup>
                </Container>
              </FormControl>
            </Grid>
          </Paper>
        </Grid>

        {/* Friday */}
        <Grid className={classes.businessHoursItem}>
          <Paper>
            <Grid item className={classes.weekdayContainer}>
              <Typography className={classes.paddingLeft} component="h3" variant="h6" paragraph>
                {i18n.t("businessHours.weekdays.friday")}
              </Typography>
              <FormControl className={classes.radioControl}>
              {selectFridayIsOpen === "1" && (
                  <>
                  <RadioGroup
                    row
                    aria-labelledby="friday-radio-buttons-group-label"
                    defaultValue="1"
                    name="friday-radio-buttons-group"
                  >
                    <FormControlLabel onClick={handleOpenDateFriday} value="1" control={<Radio id="fridayRadioOpen" color="primary" />} label={i18n.t("businessHours.checkbox.open")} />
                    <FormControlLabel onClick={handleClosedDateFriday} value="0" control={<Radio id="fridayRadioClosed" color="primary" />} label={i18n.t("businessHours.checkbox.closed")} />
                  </RadioGroup>
                  <br /><br />

                  <b>{i18n.t("businessHours.assets.businessHour")}</b>             
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="fridayInitialHour"
                      name="fridayInitialHour"
                      value={selectedDateFridayInitial}
                      onChange={handleDateFridayInitialChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme } }}
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="fridayFinalHour"
                      name="fridayFinalHour"
                      value={selectedDateFridayFinal}
                      onChange={handleDateFridayFinalChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme }, }}
                    />
                  </Container>
                  <br /><br />

                  <b>{i18n.t("businessHours.assets.intervalHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="fridayIntervalInitialHour"
                      name="fridayIntervalInitialHour"
                      value={selectedDateFridayIntervalInitial}
                      onChange={handleDateFridayIntervalInitialChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme } }}
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="fridayIntervalFinalHour"
                      name="fridayIntervalFinalHour"
                      value={selectedDateFridayIntervalFinal}
                      onChange={handleDateFridayIntervalFinalChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme }, }}
                    />
                  </Container>
                  </>
                )}

                {selectFridayIsOpen === "0" && (
                  <>
                  <RadioGroup
                    row
                    aria-labelledby="friday-radio-buttons-group-label"
                    defaultValue="0"
                    name="friday-radio-buttons-group"
                  >
                    <FormControlLabel onClick={handleOpenDateFriday} value="1" control={<Radio id="fridayRadioOpen" color="primary" />} label={i18n.t("businessHours.checkbox.open")} />
                    <FormControlLabel onClick={handleClosedDateFriday} value="0" control={<Radio id="fridayRadioClosed" color="primary" />} label={i18n.t("businessHours.checkbox.closed")} />
                  </RadioGroup>

                  <br /><br />

                  <b>{i18n.t("businessHours.assets.businessHour")}</b>                
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="fridayInitialHour"
                      name="fridayInitialHour"
                      value={selectedDateFridayInitial}
                      onChange={handleDateFridayInitialChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme }, }}
                      disabled
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="fridayFinalHour"
                      name="fridayFinalHour"
                      value={selectedDateFridayFinal}
                      onChange={handleDateFridayFinalChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme } }}
                      disabled
                    />
                  </Container>

                  <br /><br />

                  <b>{i18n.t("businessHours.assets.intervalHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="fridayIntervalInitialHour"
                      name="fridayIntervalInitialHour"
                      value={selectedDateFridayIntervalInitial}
                      onChange={handleDateFridayIntervalInitialChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme }, }}
                      disabled
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="fridayIntervalFinalHour"
                      name="fridayIntervalFinalHour"
                      value={selectedDateFridayIntervalFinal}
                      onChange={handleDateFridayIntervalFinalChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme } }}
                      disabled
                    />
                  </Container>
                  </>
                )}

                <br />
                <br />

                <Container>
                  <FormGroup>
                    <FormControlLabel 
                      control={
                        <Checkbox 
                          id="checkboxPersonalizedMessageFridayFinalHour" 
                          onClick={handlePersonalizedMessageDateFriday} 
                        />
                      } 
                      label={i18n.t("businessHours.assets.personalizedMessage")} />
                    
                    {checkboxPersonalizedMessageFridayFinalHourChecked ? (
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        type="text"
                        id="personalizedMessageFridayFinalHour"
                        name="personalizedMessageFridayFinalHour"
                        style={{ "backgroundColor": backgroundColorTheme }}
                        InputProps={{ style: { color: textColorTheme }, }}
                        value={personalizedMessageFriday}
                        onChange={handleFridayMessageChange}
                      />
                    ) : (
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        type="text"
                        id="personalizedMessageFridayFinalHour"
                        name="personalizedMessageFridayFinalHour"
                        style={{ "backgroundColor": backgroundColorTheme }}
                        InputProps={{ style: { color: textColorTheme }, }}
                        value={personalizedMessageFriday}
                        onChange={handleFridayMessageChange}
                        disabled
                      />
                    )}

                    <br />
                  </FormGroup>
                </Container>
              </FormControl>
            </Grid>
          </Paper>
        </Grid>

        {/* Saturday */}
        <Grid className={classes.businessHoursItem}>
          <Paper>
            <Grid item className={classes.weekdayContainer}>
              <Typography className={classes.paddingLeft} component="h3" variant="h6" paragraph>
                {i18n.t("businessHours.weekdays.saturday")}
              </Typography>
              <FormControl className={classes.radioControl}>
              {selectSaturdayIsOpen === "1" && (
                  <>
                  <RadioGroup
                    row
                    aria-labelledby="saturday-radio-buttons-group-label"
                    defaultValue="1"
                    name="saturday-radio-buttons-group"
                  >
                    <FormControlLabel onClick={handleOpenDateSaturday} value="1" control={<Radio id="saturdayRadioOpen" color="primary" />} label={i18n.t("businessHours.checkbox.open")} />
                    <FormControlLabel onClick={handleClosedDateSaturday} value="0" control={<Radio id="saturdayRadioClosed" color="primary" />} label={i18n.t("businessHours.checkbox.closed")} />
                  </RadioGroup>
                  <br /><br />

                  <b>{i18n.t("businessHours.assets.businessHour")}</b>                  
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="saturdayInitialHour"
                      name="saturdayInitialHour"
                      value={selectedDateSaturdayInitial}
                      onChange={handleDateSaturdayInitialChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme } }}
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="saturdayFinalHour"
                      name="saturdayFinalHour"
                      value={selectedDateSaturdayFinal}
                      onChange={handleDateSaturdayFinalChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme }, }}
                    />
                  </Container>
                  <br /><br />

                  <b>{i18n.t("businessHours.assets.intervalHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="saturdayIntervalInitialHour"
                      name="saturdayIntervalInitialHour"
                      value={selectedDateSaturdayIntervalInitial}
                      onChange={handleDateSaturdayIntervalInitialChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme } }}
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="saturdayIntervalFinalHour"
                      name="saturdayIntervalFinalHour"
                      value={selectedDateSaturdayIntervalFinal}
                      onChange={handleDateSaturdayIntervalFinalChange}
                      style={{backgroundColor: backgroundColorTheme}}
                      InputProps={{ style: { color: textColorTheme }, }}
                    />
                  </Container>
                  </>
                )}

                {selectSaturdayIsOpen === "0" && (
                  <>
                  <RadioGroup
                    row
                    aria-labelledby="saturday-radio-buttons-group-label"
                    defaultValue="0"
                    name="saturday-radio-buttons-group"
                  >
                    <FormControlLabel onClick={handleOpenDateSaturday} value="1" control={<Radio id="saturdayRadioOpen" color="primary" />} label={i18n.t("businessHours.checkbox.open")} />
                    <FormControlLabel onClick={handleClosedDateSaturday} value="0" control={<Radio id="saturdayRadioClosed" color="primary" />} label={i18n.t("businessHours.checkbox.closed")} />
                  </RadioGroup>

                  <br /><br />

                  <b>{i18n.t("businessHours.assets.businessHour")}</b>            
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="saturdayInitialHour"
                      name="saturdayInitialHour"
                      value={selectedDateSaturdayInitial}
                      onChange={handleDateSaturdayInitialChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme }, }}
                      disabled
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="saturdayFinalHour"
                      name="saturdayFinalHour"
                      value={selectedDateSaturdayFinal}
                      onChange={handleDateSaturdayFinalChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme } }}
                      disabled
                    />
                  </Container>

                  <br /><br />

                  <b>{i18n.t("businessHours.assets.intervalHour")}</b>
                  <Container className={classes.hoursContainer}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="saturdayIntervalInitialHour"
                      name="saturdayIntervalInitialHour"
                      value={selectedDateSaturdayIntervalInitial}
                      onChange={handleDateSaturdayIntervalInitialChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme }, }}
                      disabled
                    />

                    <b>{i18n.t("businessHours.assets.to")}</b>

                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      type="time"
                      id="saturdayIntervalFinalHour"
                      name="saturdayIntervalFinalHour"
                      value={selectedDateSaturdayIntervalFinal}
                      onChange={handleDateSaturdayIntervalFinalChange}
                      style={{backgroundColor: "#dcdcd7"}}
                      InputProps={{ style: { color: textColorTheme } }}
                      disabled
                    />
                  </Container>
                  </>
                )}

                <br />
                <br />

                <Container>
                  <FormGroup>
                    <FormControlLabel 
                      control={
                        <Checkbox
                          id="checkboxPersonalizedMessageSaturdayFinalHour" 
                          onClick={handlePersonalizedMessageDateSaturday} 
                        />
                      } 
                      label={i18n.t("businessHours.assets.personalizedMessage")} />

                      {checkboxPersonalizedMessageSaturdayFinalHourChecked ? (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          type="text"
                          id="personalizedMessageSaturdayFinalHour"
                          name="personalizedMessageSaturdayFinalHour"
                          style={{ "backgroundColor": backgroundColorTheme }}
                          InputProps={{ style: { color: textColorTheme }, }}
                          value={personalizedMessageSaturday}
                          onChange={handleSaturdayMessageChange}
                        />
                      ) : (
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          type="text"
                          id="personalizedMessageSaturdayFinalHour"
                          name="personalizedMessageSaturdayFinalHour"
                          style={{ "backgroundColor": backgroundColorTheme }}
                          InputProps={{ style: { color: textColorTheme }, }}
                          value={personalizedMessageSaturday}
                          onChange={handleSaturdayMessageChange}
                          disabled
                        />
                      )}
                    <br />
                  </FormGroup>
                </Container>
              </FormControl>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Paper>
    </>
  );
};

export default BusinessHours;
