import React, { useState } from "react";
import { useFormikContext } from "formik";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
  TextField
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexWrap: "wrap" },

  btnWrapper: { position: "relative", },

  floatingButton: {
    transition: "transform 0.30s",
    "&:hover": { transform: "translateY(-5px)", },
  },
}));

const DispatchManagerMessagesModal = ({ open, onClose }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const { setFieldValue, values } = useFormikContext();

  const [isAutoFocus, setIsAutoFocus] = useState(true);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();

    setIsAutoFocus(true);
  };

  const handleAutoFocus = (event) => {
    const textField = event.currentTarget;
  
    if (isAutoFocus) {
      textField.setSelectionRange(textField.value.length, textField.value.length);
      setIsAutoFocus(false);
    }
  
    event.target.focus();
  };

  const validateMessages = (values) => {
    let areMessagesValid = true;

    // ***---- Messages ----***
    const isMessage1Invalid = values.dispatchMessages.message1.length < 3 || values.dispatchMessages.message1.length > 500;
    const isMessage2Invalid = values.dispatchMessages.message2 !== "" && (values.dispatchMessages.message2.length < 3 || values.dispatchMessages.message2.length > 500);
    const isMessage3Invalid = values.dispatchMessages.message3 !== "" && (values.dispatchMessages.message3.length < 3 || values.dispatchMessages.message3.length > 500);

    if (isMessage1Invalid) {
      areMessagesValid = false;
      toast.info(i18n.t("dispatchManagerModal.validations.message1Size"));
    }

    if (isMessage2Invalid) {
      areMessagesValid = false;
      toast.info(i18n.t("dispatchManagerModal.validations.otherMessagesSize"));
    }
    
    if (isMessage3Invalid) {
      areMessagesValid = false;
      toast.info(i18n.t("dispatchManagerModal.validations.otherMessagesSize"));
    }

    return areMessagesValid;
  };

  const handleSaveMessages = () => {
    const areMessagesValid = validateMessages(values);

    if (areMessagesValid) handleClose();
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" scroll="paper" fullWidth>
        <DialogTitle>
          {i18n.t("dispatchManagerMessagesModal.title")}
        </DialogTitle>

        <DialogContent dividers>
          {/* 
            ***************
            ** Message 1 **
            ***************
          */}
          <TextField
            label={i18n.t("dispatchManagerMessagesModal.form.message1")}
            autoFocus
            onFocus={handleAutoFocus}
            autoComplete="off"
            variant="outlined"
            margin="dense"
            fullWidth
            required

            multiline
            minRows={5}
            maxRows={5}

            value={values.dispatchMessages.message1}
            onChange={(event) => setFieldValue("dispatchMessages.message1", event.target.value)}
          />

          {/* 
            ***************
            ** Message 2 **
            ***************
          */}
          <TextField
            label={i18n.t("dispatchManagerMessagesModal.form.message2")}
            autoComplete="off"
            variant="outlined"
            margin="dense"
            fullWidth

            multiline
            minRows={5}
            maxRows={5}

            value={values.dispatchMessages.message2}
            onChange={(event) => setFieldValue("dispatchMessages.message2", event.target.value)}
          />

          {/* 
            ***************
            ** Message 3 **
            ***************
          */}
          <TextField
            as={TextField}
            label={i18n.t("dispatchManagerMessagesModal.form.message3")}
            autoComplete="off"
            variant="outlined"
            margin="dense"
            fullWidth

            multiline
            minRows={5}
            maxRows={5}
            
            value={values.dispatchMessages.message3}
            onChange={(event) => setFieldValue("dispatchMessages.message3", event.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="inherit"
            variant="outlined"
            className={classes.floatingButton}
          >
            {i18n.t("dispatchManagerMessagesModal.buttons.cancel")}
          </Button>

          <Button
            onClick={handleSaveMessages}
            color="primary"
            variant="contained"
            className={`${classes.btnWrapper} ${classes.floatingButton}`}
          >
            {i18n.t("dispatchManagerMessagesModal.buttons.confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DispatchManagerMessagesModal;
