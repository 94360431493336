import { useContext, useEffect } from "react";

import { AuthContext } from "../../../Auth/AuthContext";
import { useSocketAuthenticated } from "../../SocketContext";
import api from "../../../../services/api";

const SocketNotifications = ({
  setNotifications,
  setDesktopNotifications,
  ticketIdRef,
  handleNotifications
}) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { getSocket } = useSocketAuthenticated();
  const { user } = useContext(AuthContext);



  //  ***************
  //  ** Functions **
  //  ***************
  const notBelongsToUserWhatsapps = ticket => {
    let ticketVisible;

    if (!ticket || ticket === undefined) {
      ticketVisible = true;
    } else if (ticket.type === 1 || ticket.type === 2 || ticket.type === 3) {
      ticketVisible = true;
    } else {
      ticketVisible = user.whatsapps !== null && user.whatsapps !== undefined ? user.whatsapps.length <= 0 : true;
  
      user.whatsapps?.forEach(function(whatsAppIndex) {
        if (ticketVisible) return;

        if (whatsAppIndex.id === ticket.whatsappId) {
          ticketVisible = true;
          return;
        }
      });
    }

    return ticketVisible;
  }



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    const socket = getSocket();

    if (socket) {
      const handleNotification = async data => {
        // ***---- Delete Ticket or Unread Message ----***
        if (
          (data.action === "updateUnreadNotification" || data.action === "deleteNotification") &&
          (`${api.defaults.headers.tenantId}` === `${data.tenantId}`) &&
          (notBelongsToUserWhatsapps(data.ticket))
        ) {
          setNotifications(notifications => {
            const index = notifications.findIndex(notification =>
              notification.ticket.id === data.ticketId &&
              (notification.notifiedUser === user.id || notification.notifiedUser === -1)
            );
  
            if (index !== -1) {
              if (
                (data.action === "deleteNotification") ||
                (data.action === "updateUnreadNotification" && notifications[index].ticket.status !== "group")
              ) {
                notifications.splice(index, 1);
                return [...notifications];
              }
              
              if (notifications[index].notifiedUser === data.userId || data.userId === -1) {
                notifications.splice(index, 1);
                return [...notifications];
              }
  
              return notifications;
            }
  
            return notifications;
          });
  
          setDesktopNotifications(notifications => {
            const index = notifications.findIndex(notification => notification.tag === String(data.ticketId));
            if (index !== -1) {
              notifications[index].close();
              notifications.splice(index, 1);
              return [...notifications];
            }
            return notifications;
          });
        }
  
        // ***---- Ticket Transferring ----***
        else if (
          data.action === "transferNotification" &&
          data.ticket.status === "open" &&
          (data.ticket.userId === user?.id || !data.ticket.userId) &&
          (`${api.defaults.headers.tenantId}` === `${data.tenantId}`) &&
          (notBelongsToUserWhatsapps(data.ticket))
        ) {
          // 
          //  ***---- Fetching Settings ----***
          // 
          const settingCalls = [api.get("/settings/sendNotificationsChatsGroups"), api.get("/settings/sendNotifications")];  
          const [sendNotificationsChatsGroupsSetting, sendNotificationsSetting] = await Promise.all(settingCalls);
          const sendNotificationsChatsGroupsValue = sendNotificationsChatsGroupsSetting.data.value;
          const sendNotificationsValue = sendNotificationsSetting.data.value;
  
  
  
          // 
          //  ***---- Updating Application Notifications ----***
          // 
          setNotifications(notifications => {
            const index = notifications.findIndex(notification =>
              notification.ticket.id === data.ticket.id &&
              (notification.notifiedUser === user.id || notification.notifiedUser === -1)
            );
  
            if (index !== -1) {
              notifications[index].ticket = data.ticket;
              return [...notifications];
            }
  
            return [{ notifiedUser: -1, ticket: data.ticket }, ...notifications];
          });
  
  
  
          // 
          //  ***---- Updating Desktop Notifications ----***
          //
          const shouldNotNotificate = [0, 1, 2].includes(data.ticket.type)
            ? (data.message.ticketId === ticketIdRef.current) ||
              (data.ticket.userId && data.ticket.userId !== user?.id)
            
            : (data.message.ticketId === ticketIdRef.current) ||
              (
                data.ticket.userId && data.ticket.userId !== user?.id &&
                data.ticket.userIdInternalChat && data.ticket.userIdInternalChat !== user?.id
              )

          
          if (shouldNotNotificate) return;
          
          handleNotifications(data, sendNotificationsChatsGroupsValue, sendNotificationsValue);
        }
  
        // ***----- New Message ----***
        else if (
          data.action === "createNotification" &&
          (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)
        ) {
          // 
          //  ***---- Fetching Settings ----***
          // 
          const settingCalls = [
            api.get("/settings/adminsPendingTicketsControl"),
            api.get("/settings/sendNotificationsChatsGroups"),
            api.get("/settings/sendNotifications"),
            api.get("/AllUsers")
          ];
    
          const [
            adminsPendingTicketsControlSetting,
            sendNotificationsChatsGroupsSetting,
            sendNotificationsSetting,
            allUsersResponse
          ] = await Promise.all(settingCalls);
    
          const adminsPendingTicketsControlValue = adminsPendingTicketsControlSetting.data.value;
          const sendNotificationsChatsGroupsValue = sendNotificationsChatsGroupsSetting.data.value;
          const sendNotificationsValue = sendNotificationsSetting.data.value;
          const allUsers = allUsersResponse.data.users;
    
          // Notification is Processed when:
          //
          //  - Admins Control over Pending Tickets is 'disabled' OR;
          //  - Admins Control over Pending Tickets is 'enabled' AND user is 'admin' AND ticket is 'pending' OR;
          //  - Admins Control over Pending Tickets is 'enabled' AND ticket is not 'pending', regardless of the user type.
          //
          if (
            (adminsPendingTicketsControlValue === "disabled")
            || (adminsPendingTicketsControlValue === "enabled" && user.profile === "admin" && data.ticket.status === "pending")
            || (adminsPendingTicketsControlValue === "enabled" && data.ticket.status !== "pending")
          ) {
            // Condition is true when:
            //
            //  1 - notification setting is to both (chats and groups) and:
            //    1.1 - ticket status is group OR:
            //    1.2 - chat notification setting is for both open and pending tickets and ticket status is either open or pending;
            //    1.3 - chat notification setting is only for open ticket and ticket status is open;
            //    1.4 - chat notification setting is only for pending tickets and ticket status is pending;
            //  2 - notification setting is only to groups and ticket is a group;
            //  3 - notification setting is only to chats and:
            //    3.1 - chat notification setting is for both open and pending tickets and ticket status is either open or pending;
            //    3.2 - chat notification setting is only for open tickets and ticket status is open;
            //    3.3 - chat notification setting is only for pending tickets and ticket status is pending.
            //
            const chatNotificationCondition = (sendNotificationsValue === "both" && (data.ticket?.status === "open" || data.ticket?.status === "pending")) ||
              (sendNotificationsValue === "open" && data.ticket?.status === "open") ||
              (sendNotificationsValue === "pending" && data.ticket?.status === "pending");
    
            const notificationSettingCondition =
              (sendNotificationsChatsGroupsValue === "both" && (data.ticket?.status === "group" || (chatNotificationCondition))) ||
              (sendNotificationsChatsGroupsValue === "groups" && data.ticket?.status === "group") ||
              (sendNotificationsChatsGroupsValue === "chats" && chatNotificationCondition);
            
            if (
              data.action === "createNotification" &&
              notificationSettingCondition &&
              (
                (!data.message.read &&
                  (data.ticket.userId === user?.id || !data.ticket.userId))
                || (data.ticket.type === 3 && data.message.userIdInternalChat === user?.id)
              ) &&
              (`${api.defaults.headers.tenantId}` === `${data.tenantId}`) &&
              (notBelongsToUserWhatsapps(data.ticket))
            ) {
              setNotifications(notifications => {
                const index = notifications.findIndex(notification =>
                  notification.ticket.id === data.ticket.id &&
                  (notification.notifiedUser === user.id || notification.notifiedUser === -1)
                );
              
                //      ticket notification exists already?
                //             /                      \
                //            yes                      no
                //           /                          \
                //   updates the ticket info          is ticket a group?
                //  of the notification already          /           \
                //  existent and returns all            yes           no
                //        notifications                /               \ 
                //                              returns all            returns all notifications
                //                           notifications and         and a single notification for 
                //                        a group notification for     all users
                //                               each user
                //
                if (index !== -1) {
                  notifications[index].ticket = data.ticket;
                  return [...notifications];
                }
                
                if (data.ticket.status === "group") {
                  const groupNotifications = allUsers.map(user => {
                    return { notifiedUser: user.id, ticket: data.ticket };
                  });
                  return [...groupNotifications, ...notifications];
                }
              
                return [{ notifiedUser: -1, ticket: data.ticket, }, ...notifications];
              });
        
              const shouldNotNotificate = [0, 1, 2].includes(data.message.ticket.type)
                ? (data.message.ticketId === ticketIdRef.current) ||
                  (data.ticket.userId && data.ticket.userId !== user?.id)
                  
                : (data.message.ticketId === ticketIdRef.current) ||
                  (
                    data.ticket.userId && data.ticket.userId !== user?.id &&
                    data.ticket.userIdInternalChat && data.ticket.userIdInternalChat !== user?.id
                  );
                  
              if (shouldNotNotificate) return;
        
              handleNotifications(data, sendNotificationsChatsGroupsValue, sendNotificationsValue);
            }
          }
        }      
      };
  
      socket.on("notification", handleNotification);
  
      return () => {
        socket.off("notification", handleNotification);
      };
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setNotifications, setDesktopNotifications, ticketIdRef, handleNotifications]);



  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketNotifications;