import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import ShoppingImg from "../../assets/shopping.svg";

const useStyles = makeStyles((theme) => ({
  buyDispatchContainer: {
    width: "100%",
    height: "100%",

    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "2em",
  },

  buyDispatchImg: {
    width: "35%",
    minWidth: "250px",
    height: "auto",
  },

  buyDispatchMessage: {
    fontSize: "1.15em",
    textAlign: "center",
  },

  floatingButton: {
    transition: "transform 0.30s",
    "&:hover": { transform: "translateY(-5px)", },
  },
}));

const BuyDispatch = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();



  //  ***************
  //  ** Functions **
  //  ***************
  const handleBuyDispatch = () => {
    window.open("https://bestzap.com.br/", "_blank");
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.buyDispatchContainer}>
      <img className={classes.buyDispatchImg} src={ShoppingImg} alt="Dispatch not Enabled" />

      <span className={classes.buyDispatchMessage}>{i18n.t("buyDispatch.message")}</span>

      <Button
        variant="contained"
        color="primary"
        onClick={handleBuyDispatch}
        className={classes.floatingButton}
      >
        {i18n.t("buyDispatch.buttons.buy")}
      </Button>
    </div>
  );
};

export default BuyDispatch;
