function extractMonthFromStringDateTime(stringDateTime) {
  /* Receives a datatime string "YYYY-MM-DDTHH:mm:SS.000Z" and returns a
  string containing only the month in the format "MM".
  
    *** Params ***
    - stringDateTime: string;

    *** Returns ***
    - extractedStringDateTime: string;
  */
  const dateTime = new Date(stringDateTime);
  const extractedMonth = (dateTime.getMonth() + 1).toString().padStart(2, "0");

  return extractedMonth;
};

export default extractMonthFromStringDateTime;
