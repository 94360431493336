function extractHourMinuteFromStringDateTime(stringDateTime) {
  /* Receives a datatime string "YYYY-MM-DDTHH:mm:SS.000Z" and returns a
  string containing only the hour and minutes in the format "HH:MM".
  
    *** Params ***
    - stringDateTime: string;

    *** Returns ***
    - extractedStringDateTime: string;
  */
 const dateTime = new Date(stringDateTime);
 const extractedHour = dateTime.getHours().toString().padStart(2, "0");
 const extractedMinutes = dateTime.getMinutes().toString().padStart(2, "0");

 return `${extractedHour}:${extractedMinutes}`;
};

export default extractHourMinuteFromStringDateTime;
