import { useEffect } from "react";

import { useSocketAuthenticated } from "../../SocketContext";
import api from "../../../../services/api";

const SocketDispatchManager = ({ dispatch }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { getSocket } = useSocketAuthenticated();



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const socket = getSocket();

    if (socket) {
      const handleDispatches = (data) => {
        if ((data.action === "update" || data.action === "create") && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          dispatch({ type: "UPDATE_DISPATCHES", payload: data.dispatch });
        }

        if ((data.action === "delete") && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          dispatch({ type: "DELETE_DISPATCH", payload: +data.dispatchId });
        }
      };

      socket.on("dispatchManager", handleDispatches);

      return () => {
        socket.off("dispatchManager", handleDispatches);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);



  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketDispatchManager;