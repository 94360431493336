import React, { useRef, useState, useEffect, useContext } from "react"
import { Bar } from '@nivo/bar';
import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../../../translate/i18n";
import { AuthContext } from "../../../../context/Auth/AuthContext";
import ErrorBoundary from "../../../../errors/Boundary";
import NoDataImg from '../../../../assets/noData.svg';
import useDashboardChartsListUsers from "../../../../hooks/useDashboardChartsListUsers";
import useTotalTimePerUsers from "../../../../hooks/useTotalTimePerUsers";

const useStyles = makeStyles(theme => ({
	dashboardContainer: {
		width: "100%",
		backgroundColor: theme.palette.background.paper,
		borderRadius: "5px",
		padding: "20px 0px 20px 0px",
		textIndent: "15px",
	},

  emptyBoxContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "50px",
  },

  emptyBoxImage: { width: "250px", height: "250px", },

  emptyBoxSpan: { fontSize: "20px", }
}))

const ChartTotalTimePerUser = ({ initialDate, finalDate }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const { user } = useContext(AuthContext);



  //  ***************
  //  ** Functions **
  //  ***************
  const GetListUsers = () => {
    const queryResult = useDashboardChartsListUsers();
    return queryResult;
  }

  const GetTimePerUsers = (initialDate, finalDate, considerInternalChats) => {
		const data = useTotalTimePerUsers({ initialDate, finalDate, considerInternalChats });
    return data;
	}



  //  ***********
  //  ** Datas **
  //  ***********
  const containerRef = useRef(null);
  
  const usersList = GetListUsers();
  const usersCount = usersList ? usersList.length : 0;
  
  const [width, setWidth] = useState(0);
  const height = 100 + 25 * usersCount;

  
  // Processando Tempo Médio por Usuários
  let rawTimePerUsers = 0;
  let timePerUsersData = 0;
  let convertedTimePerUsersData = 0;
  let filteredTimePerUsersData = 0;

  rawTimePerUsers = GetTimePerUsers(`${initialDate}T00:00:01`, `${finalDate}T23:59:59`, false);
  timePerUsersData = rawTimePerUsers === 0 || !rawTimePerUsers ? 0 : rawTimePerUsers.data.rows;

  if (usersList !== undefined && timePerUsersData !== 0) {
    convertedTimePerUsersData = timePerUsersData.map(timeData => {
      const matchingUser = usersList.find(user => user.id === timeData.userId);
      const convertedTotalTime = parseFloat(timeData.sumTime) < 2 ? 1 : parseFloat(timeData.sumTime);

      return {
        name: matchingUser.name,
        sumTime: convertedTotalTime
      };
    });
  }

  if (usersList !== undefined && convertedTimePerUsersData !== 0) {
    if (user.profile === "admin") {
      filteredTimePerUsersData = convertedTimePerUsersData;
    }
    else {
      filteredTimePerUsersData = convertedTimePerUsersData.filter(item => item.name === user.name);
    }
  }

  if (filteredTimePerUsersData !== 0 && filteredTimePerUsersData.length > 0) {
    filteredTimePerUsersData.sort((itemA, itemB) => itemA.sumTime - itemB.sumTime);
  }
  


  //  **************************************
  //  ** Use Effects and Resize Observers **
  //  **************************************
  useEffect(() => {
    setWidth(containerRef.current.offsetWidth);
  }, []);

  function outputsize() {
    const mainContainer = document.getElementById("mainContainer");
    setWidth(mainContainer.offsetWidth);
  }
  
  useEffect(() => {
    const mainContainer = document.getElementById("mainContainer");
    outputsize();
    new ResizeObserver(outputsize).observe(mainContainer);
  });



  //  ************
  //  ** Return **
  //  ************
  return (
    <div ref={containerRef} className={classes.dashboardContainer} id="mainContainer">
      <ErrorBoundary>
        {(filteredTimePerUsersData !== 0 && filteredTimePerUsersData.length > 0) && (
          <>
          <Bar
            data={filteredTimePerUsersData}
            keys={['sumTime']}
            width={width}
            height={height}
            theme={{ textColor: "#999" }}
            indexBy="name"
            margin={{ top: 50, right: 130, bottom: 50, left: 100 }}
            padding={0.3}
            layout="horizontal"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={"#F4323C"}
            borderColor={{from: 'color', modifiers: [['darker', 2]]}}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: 'middle',
              legendOffset: 32
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: 'middle',
              legendOffset: -40
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={"#fff"}
            tooltip={(label) => {
              const id = 'Tempo Total:';
              const messageTime = label.data.sumTime > 60 ? "Horas" : "Minutos";
              const data = label.data.sumTime < 60
                ? label.data.sumTime
                : ~~(label.data.sumTime / 60);

              return (
                <div style={{background: "#999", color: "#fff", padding: "5px", borderRadius: "1px", margin: "0px"}}>
                  {id} <b>{data} {messageTime}</b>
                </div>
              )
            }}
            legendLabel={(label) => label.id === 'sumTime' ? 'Tempo Total' : label.id}
            legends={[{
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemTextColor: "#999",
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [{ on: 'hover', style: { itemOpacity: 1 } }]
            }]}
            motionConfig={{
              mass: 1,
              tension: 170,
              friction: 71,
              clamp: false,
              precision: 0.01,
              velocity: 0
            }}
            role="application"
          />
          </>
        )}

        {(filteredTimePerUsersData === 0 || filteredTimePerUsersData.length === 0) && (
          <div className={classes.emptyBoxContainer}>
            <img className={classes.emptyBoxImage} src={NoDataImg} alt="No Data" />
            <span className={classes.emptyBoxSpan}>{i18n.t("dashboard.emptyBox")}</span>
          </div>
        )}
      </ErrorBoundary>
    </div>
  );
}

export default ChartTotalTimePerUser;