import React, { useState } from "react";
import { Button, makeStyles, } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import extractDayMonthYearFromStringDateTime from "../../utils/extractDayMonthYearFromStringDateTime";
import extractHourMinuteFromStringDateTime from "../../utils/extractHourMinuteFromStringDateTime";
import EventDetailsModal from "../EventDetailsModal";

const useStyles = makeStyles((theme) => ({
  eventDetailsPreviewMainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    gap: "1em",
    padding: "3px",
  },

  eventDetailsPreviewSecondaryContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "5px",
    fontSize: "0.90em"
  },

  viewEventDetailsButton: {
    color: theme.palette.secondary.ackCheck,
  },
}));

const EventPreview = ({ message }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();

  const [eventDetailsModalOpen, setEventDetailsModalOpen] = useState(false);

  const startTime = parseInt(message.eventDetails.startTime, 10);
  const eventDetails = {
    name: message.body,
    description: message.eventDetails.description,
    startTimestamp: message.eventDetails.startTime,
    startDateTime: `${extractDayMonthYearFromStringDateTime(startTime)}, ${extractHourMinuteFromStringDateTime(startTime)}`,
    locationName: message.eventDetails.locationName,
    joinLink: message.eventDetails.joinLink,
    isVideoCall: message.eventDetails.isVideoCall,
  };



  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenEventDetailsModal = () => { setEventDetailsModalOpen(true); };
  const handleCloseEventDetailsModal = () => { setEventDetailsModalOpen(false); };




  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      <div className={classes.eventDetailsPreviewMainContainer}>
        <b>📆 {eventDetails.name}</b>

        <div className={classes.eventDetailsPreviewSecondaryContainer}>
          <span>{eventDetails.startDateTime}</span>

          {eventDetails.locationName !== null && eventDetails.locationName !== undefined && (
            <span>{eventDetails.locationName}</span>
          )}

          {eventDetails.joinLink !== null && eventDetails.joinLink !== undefined && (
            eventDetails.isVideoCall
              ? (i18n.t("eventPreview.videoCall"))
              : (i18n.t("eventPreview.voiceCall"))
          )}
        </div>

        <Button
          fullWidth
          color="inherit"
          className={classes.viewEventDetailsButton}
          disabled={message.isDeleted}
          onClick={handleOpenEventDetailsModal}
        >
          {i18n.t("eventPreview.seeDetails")}
        </Button>
      </div>

      <EventDetailsModal
        open={eventDetailsModalOpen}
        onClose={handleCloseEventDetailsModal}
        eventDetails={eventDetails}
      />
    </>
  );
};

export default EventPreview;
