import React from "react";
import { makeStyles } from "@material-ui/core";
import { AnnouncementOutlined } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  deletedFileContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
  },

  deletedFileIcon: {
    backgroundColor: theme.palette.primary.pastel,
    display: "flex",
    flexDirection: "column",
    alignItens: "center",
    justifyContent: "center",
    padding: "5px",
    borderRadius: "20px",
  },

  deletedFileText: { fontSize: "1em", opacity: "0.80", useSelect: "none", },
}));

const MessageNotAvailable = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.deletedFileContainer}>
      <span className={classes.deletedFileIcon}>
        <AnnouncementOutlined />
      </span>

      <span className={classes.deletedFileText}>
        {i18n.t("messageNotAvailable.deletedFile")}
      </span>
    </div>
  );
};

export default MessageNotAvailable;
