import React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
  Divider,
} from '@material-ui/core';

import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import TicketView from "../TicketView";

const useStyles = makeStyles(theme => ({
	root: { display: "flex", flexWrap: "wrap", },

  dialog: { backdropFilter: 'blur(5px)', },

	floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)', },
  }
}));


const ViewTicketModal = ({ open, onClose, ticketId }) => {
  //	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();



	//	***************
  // 	** Functions **
	//	***************
  const handleClose = () => { onClose(); };

	const handleExportToPDF = () => { window.print(); };




	//	************
  // 	** Return **
	//	************
	return (
		<div className={classes.root}>
      <Dialog
				open={open}
				onClose={handleClose}
				maxWidth="md"
				fullWidth
				scroll="paper"
        className={classes.dialog}
			>
        <DialogTitle id="form-dialog-title">
				  {i18n.t("viewTicketModal.title")}
				</DialogTitle>

        <Divider />

        <TicketView viewTicketId={ticketId} />

        <Divider />

        <DialogActions>
          <Button
						onClick={handleClose}
						color="inherit"
						variant="outlined"
						className={classes.floatingButton}
					>
						{i18n.t("viewTicketModal.buttons.return")}
					</Button>

					<Button
						onClick={handleExportToPDF}
						color="primary"
						variant="contained"
						className={classes.floatingButton}
					>
						{i18n.t("viewTicketModal.buttons.exportToPDF")}
					</Button>
				</DialogActions>
      </Dialog>
    </div>
	);
};

export default ViewTicketModal;
