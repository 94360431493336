import React from "react";
import { makeStyles } from "@material-ui/core";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const useStyles = makeStyles((theme) => ({
	specialDataInText: {
		cursor: "pointer",
		color: theme.palette.secondary.ackCheck,
		textDecoration: "none",
		"&:hover": { textDecoration: "underline", },
	},
}));

const MarkdownWrapper = ({
	children,
	handleOpenPhoneNumberMessageOptionsMenu,
	shouldFormat = true,
	isMsgFromMe = true
}) => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();
	const originalChildren = children;
	const phoneNumberRegex = /((?<![a-zA-Z/])\b(?:[-+() ]*\d){10,13}\b)(\n)*/g;



	// 	***************
	// 	** Functions **
	// 	***************
	const formatPhoneNumberInternalFunction = (content) => {
		try {
			return content.split(phoneNumberRegex).map((part, index) =>
				phoneNumberRegex.test(part)
					? <PhoneNumber key={index}>{part}</PhoneNumber>
					: part
			);
		} catch (exception) {
			return content;
		}
	};

	const transformPhoneNumberArrayChildren = (children) => {
		const transformedArrayChildren = [];

		for (let element of children) {
			if (element.constructor === Object) transformedArrayChildren.push(element);
			else transformedArrayChildren.push(formatPhoneNumberInternalFunction(element));
		}

		return transformedArrayChildren;
	};

	const transformPhoneNumberTextChildren = (children) => {
		return formatPhoneNumberInternalFunction(children);
	};

	const transformPhoneNumber = (children) => {
		if (children.constructor === Array) return transformPhoneNumberArrayChildren(children);
		else return transformPhoneNumberTextChildren(children);
	};

	const removeBreakLinesFromChildren = (children) => {
		return children.filter(element => element !== "\n");
	};

	const generateResetStyle = (componentType = "list") => {
		if (componentType === "list") return { margin: "0px" };
		else if (componentType === "pre") return { fontFamily: "monospace" };
		else if (componentType === "code") return { fontFamily: "monospace" };
		return { margin: "0px" };
	};

	const handleBreakLines = (children) => {
		return children.replace(/\n{2,}/g, "\\\n");
	};

	const handleHyphensDashesAndEquals = (children) => {
		const urlRegex = /(https?:\/\/[^\s]+)/g;
		return children.split(urlRegex).map((segment) => {
			if (urlRegex.test(segment)) return segment;

			return segment
				.replace(/-/g, "&#8208;")
				.replace(/=/g, "&#61;");
		}).join("");
	};
	



	// 	*****************************
	// 	** Pre-Processing Children **
	// 	*****************************
	children = shouldFormat
		? handleHyphensDashesAndEquals(handleBreakLines(children))
		: children;



	// 	***********************
	// 	** Custom Components **
	// 	***********************
	const PhoneNumber = ({ children }) => {
		return (
			<span
				className={classes.specialDataInText}
				onClick={(event) => {
					handleOpenPhoneNumberMessageOptionsMenu(
						event,
						event.target.innerText.replace(/\D/g, ""),
						isMsgFromMe
					)
				}}
			>
				{children}
			</span>
		);
	};

	const PersonalizedParagraph = ({ children, rest, shouldFormat }) => {
		return shouldFormat
			? <span children={transformPhoneNumber(children)} {...rest} />
			: <span children={children} {...rest} />
	};

	const PersonalizedPre = ({ children, rest, shouldFormat }) => {
		const resetStyle = generateResetStyle("pre");

		return shouldFormat
			? <span style={resetStyle} children={children} {...rest} />
			: <span children={children} {...rest} />
	};

	const PersonalizedCode = ({ children, rest, shouldFormat }) => {
		const resetStyle = generateResetStyle("code");

		return shouldFormat
			? <span style={resetStyle} children={children} {...rest} />
			: <span children={children} {...rest} />
	};

	const PersonalizedHr = ({ offset, rest, shouldFormat }) => {
		return shouldFormat
			? <span children={'-'.repeat(offset)} {...rest} />
			: <span children={'-'.repeat(offset)} {...rest} />
	};

	const PersonalizedAnchor = ({ children, href, rest, shouldFormat }) => {
		return shouldFormat
			? <a href={href} children={children} target="_blank" rel="noreferrer" className={classes.specialDataInText} {...rest} />
			: <span children={children} {...rest} />
	};

	const PersonalizedBold = ({ children, rest, shouldFormat }) => {
		return shouldFormat
			? <b children={transformPhoneNumber(children)} {...rest} />
			: <b children={children} {...rest} />;
	};

	const PersonalizedOrderedList = ({ children, rest, shouldFormat }) => {
		const childrenWithNoBreakLines = removeBreakLinesFromChildren(children);
		const resetStyle = generateResetStyle("list");
		
		return shouldFormat
			?	<ol style={resetStyle} children={childrenWithNoBreakLines} {...rest} />
			: <span children={originalChildren} {...rest} />;
	};

	const PersonalizedUnorderedList = ({ children, rest, shouldFormat }) => {
		const childrenWithNoBreakLines = removeBreakLinesFromChildren(children);
		const resetStyle = generateResetStyle("list");

		return shouldFormat
			?	<ul style={resetStyle} children={childrenWithNoBreakLines} {...rest} />
			: <span children={originalChildren} {...rest} />;
	};

	const PersonalizedHeader = ({ children, rest, shouldFormat, headerHierarchy }) => {
		const isChildrenEmpty = [null, undefined].includes(children);

		if (isChildrenEmpty) return <span children={children} {...rest}>{"#".repeat(headerHierarchy)}</span>;
		
		return shouldFormat
			? <span {...rest}><b>{transformPhoneNumber(children)}</b></span>
			: <span children={children} {...rest} />;
	};
	


	// 	********************
	// 	** Components Map **
	// 	********************
	const componentsMap = {
		p(props) {
			const { node, children, ...rest } = props;
			return <PersonalizedParagraph children={children} rest={rest} shouldFormat={shouldFormat} />;
		},

		pre(props) {
			const { node, children, ...rest } = props;
			return <PersonalizedPre children={children} rest={rest} shouldFormat={shouldFormat} />;
		},

		code(props) {
			const { node, children, ...rest } = props;
			return <PersonalizedCode children={children} rest={rest} shouldFormat={shouldFormat} />;
		},

		hr(props) {
			const { node, position, ...rest } = props;
			return <PersonalizedHr offset={node.position.end.offset} rest={rest} shouldFormat={shouldFormat} />;
		},

		a(props) {
			const { node, children, href, ...rest } = props;
			return <PersonalizedAnchor children={children} href={href} rest={rest} shouldFormat={shouldFormat} />;
		},

		i(props) {
			const { node, children, ...rest } = props;
			return <PersonalizedBold children={children} rest={rest} shouldFormat={shouldFormat} />;
		},

		em(props) {
			const { node, children, ...rest } = props;
			return <PersonalizedBold children={children} rest={rest} shouldFormat={shouldFormat} />;
		},

		b(props) {
			const { node, children, ...rest } = props;
			return <PersonalizedBold children={children} rest={rest} shouldFormat={shouldFormat} />;
		},

		strong(props) {
			const { node, children, ...rest } = props;
			return <PersonalizedBold children={children} rest={rest} shouldFormat={shouldFormat} />;
		},

		ol(props) {
			const { node, children, ...rest } = props;
			return <PersonalizedOrderedList children={children} rest={rest} shouldFormat={shouldFormat} />;
		},

		ul(props) {
			const { node, children, ...rest } = props;
			return <PersonalizedUnorderedList children={children} rest={rest} shouldFormat={shouldFormat} />;
		},

		h1(props) {
			const { node, children, ...rest } = props;
			return <PersonalizedHeader children={children} rest={rest} shouldFormat={shouldFormat} headerHierarchy={1} />;
		},

		h2(props) {
			const { node, children, ...rest } = props;
			return <PersonalizedHeader children={children} rest={rest} shouldFormat={shouldFormat} headerHierarchy={2} />;
		},

		h3(props) {
			const { node, children, ...rest } = props;
			return <PersonalizedHeader children={children} rest={rest} shouldFormat={shouldFormat} headerHierarchy={3} />;
		},

		h4(props) {
			const { node, children, ...rest } = props;
			return <PersonalizedHeader children={children} rest={rest} shouldFormat={shouldFormat} headerHierarchy={4} />;
		},

		h5(props) {
			const { node, children, ...rest } = props;
			return <PersonalizedHeader children={children} rest={rest} shouldFormat={shouldFormat} headerHierarchy={5} />;
		},

		h6(props) {
			const { node, children, ...rest } = props;
			return <PersonalizedHeader children={children} rest={rest} shouldFormat={shouldFormat} headerHierarchy={6} />;
		},
	};



	// 	************
	// 	** Return **
	// 	************
	return (
		<Markdown components={componentsMap} remarkPlugins={[remarkGfm]}>
			{children}
		</Markdown>
	);
};

export default MarkdownWrapper;