import React, { useEffect, useReducer, useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import { AuthContext } from "../../context/Auth/AuthContext";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import SocketSubqueues from "../../context/Socket/Listeners/SocketSubqueues";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit } from "@material-ui/icons";
import SubqueueModal from "../../components/SubqueueModal";
import ConfirmationModal from "../../components/ConfirmationModal";

const reducer = (state, action) => {
  if (action.type === "LOAD_SUBQUEUES") {
    const subqueues = action.payload;
    const newSubqueues = [];

    subqueues.forEach((subqueue) => {
      const subqueueIndex = state.findIndex((s) => s.id === subqueue.id);
      if (subqueueIndex !== -1) {
        state[subqueueIndex] = subqueue;
      } else {
        newSubqueues.push(subqueue);
      }
    });

    return [...state, ...newSubqueues];
  }

  if (action.type === "UPDATE_SUBQUEUES") {
    const subqueue = action.payload;
    const subqueueIndex = state.findIndex((s) => s.id === subqueue.id);

    if (subqueueIndex !== -1) {
      state[subqueueIndex] = subqueue;
      return [...state];
    } else {
      return [subqueue, ...state];
    }
  }

  if (action.type === "DELETE_SUBQUEUE") {
    const subqueueId = action.payload;

    const subqueueIndex = state.findIndex((s) => s.id === subqueueId);
    if (subqueueIndex !== -1) {
      state.splice(subqueueIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: {
		color: theme.palette.text.primary,
	},
  floatingButton: {
    transition: 'transform 0.3s',
  
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  },
  actionButton: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));


const Subqueues = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [subqueues, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);

  const [subqueueModalOpen, setSubqueueModalOpen] = useState(false);
  const [selectedSubqueue, setSelectedSubqueue] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    if (user.profile === "user" || !user.configEnabled) { history.push(`/tickets`); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({ type: "RESET" });
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/subqueue/");
        dispatch({ type: "LOAD_SUBQUEUES", payload: data });

        setLoading(false);
      } catch (error) {
        console.log("Subqueues Use Effect 1 Error:", error);
        toastError(error);
        setLoading(false);
      }
    })();
  }, []);

  const handleOpenSubqueueModal = () => {
    setSelectedSubqueue(null);
    setSubqueueModalOpen(true);
  };

  const handleCloseSubqueueModal = () => {
    setSelectedSubqueue(null);
    setSubqueueModalOpen(false);
  };

  const handleEditSubqueue = (subqueue) => {
    setSelectedSubqueue(subqueue);
    setSubqueueModalOpen(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedSubqueue(null);
  };

  const handleDeleteSubqueue = async (subqueueId) => {
    try {
      await api.delete(`/subqueue/${subqueueId}`);
    } catch (error) {
      console.log("Handle Delete Subqueue Error:", error);
      toastError(error);
    }
    setSelectedSubqueue(null);
  };

  return (
    <MainContainer>
      <SocketSubqueues
        dispatch={dispatch}
      />

      <ConfirmationModal
        title={`${i18n.t("subqueues.confirmationModal.deleteTitle")}?`}
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={() => handleDeleteSubqueue(selectedSubqueue.id)}
      >
        {i18n.t("subqueues.confirmationModal.deleteMessage")}
      </ConfirmationModal>

      <SubqueueModal
        open={subqueueModalOpen}
        onClose={handleCloseSubqueueModal}
        subqueueId={selectedSubqueue?.id}
      />
      <MainHeader>
        <Title>{i18n.t("subqueues.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenSubqueueModal}
            className={classes.floatingButton}
          >
            {i18n.t("subqueues.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("subqueues.table.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("subqueues.table.description")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("subqueues.table.category")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("subqueues.table.queue")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("subqueues.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
            {subqueues.map((subqueue) => (
              <TableRow key={subqueue.id}>
                <TableCell align="center">{subqueue.name}</TableCell>
                <TableCell align="center">{subqueue.description}</TableCell>
                <TableCell align="center">{subqueue.category?.name}</TableCell>
                <TableCell align="center">{subqueue.queue?.name}</TableCell>
                <TableCell align="center">
                  <IconButton className={classes.icon}
                    size="small"
                    onClick={() => handleEditSubqueue(subqueue)}
                  >
                    <Edit className={classes.actionButton} />
                  </IconButton>

                  <IconButton className={classes.icon}
                    size="small"
                    onClick={() => {
                      setSelectedSubqueue(subqueue);
                      setConfirmModalOpen(true);
                    }}
                  >
                    <DeleteOutline className={classes.actionButton} />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {loading && <TableRowSkeleton columns={5} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Subqueues;