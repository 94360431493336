import React, { useCallback, useEffect, useReducer, useState } from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import CancelScheduleSendOutlinedIcon from "@material-ui/icons/CancelScheduleSendOutlined";
import KeyboardReturnOutlinedIcon from "@material-ui/icons/KeyboardReturnOutlined";
import SyncOutlinedIcon from "@material-ui/icons/SyncOutlined";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import DispatchSendingMessageModal from "../DispatchSendingMessageModal";
import DispatchSendingErrorDescriptionModal from "../DispatchSendingErrorDescriptionModal";
import DispatchStatusSelect from "../DispatchStatusSelect";
import extractDayMonthYearFromStringDateTime from "../../utils/extractDayMonthYearFromStringDateTime";
import extractHourMinuteFromStringDateTime from "../../utils/extractHourMinuteFromStringDateTime";
import LabelSelect from "../LabelSelect";
import TableRowSkeleton from "../TableRowSkeleton";
import toastError from "../../errors/toastError";
import truncateString from "../../utils/truncateString";

import SocketDispatchSendingsManager from "../../context/Socket/Listeners/SocketDispatchSendingsManager";

const reducer = (state, action) => {
  if (action.type === "LOAD_DISPATCH_SENDINGS") {
    const dispatchSendings = action.payload;
    const newDispatchSendings = [];

    dispatchSendings.forEach((dispatchSending) => {
      const dispatchSendingIndex = state.findIndex((ds) => ds.id === dispatchSending.id);
      if (dispatchSendingIndex !== -1) { state[dispatchSendingIndex] = dispatchSending; }
      else { newDispatchSendings.push(dispatchSending); }
    });

    return [...state, ...newDispatchSendings];
  }

  if (action.type === "UPDATE_DISPATCH_SENDINGS") {
    const dispatchSending = action.payload;
    const dispatchSendingIndex = state.findIndex((ds) => ds.id === dispatchSending.id);

    if (dispatchSendingIndex !== -1) {
      state[dispatchSendingIndex] = dispatchSending;
      return [...state];
    }
    else { return [dispatchSending, ...state]; }
  }

  if (action.type === "DELETE_DISPATCH_SENDING") {
    const dispatchSendingId = action.payload;
    const dispatchSendingIndex = state.findIndex((ds) => ds.id === dispatchSendingId);
    if (dispatchSendingIndex !== -1) { state.splice(dispatchSendingIndex, 1); }
    return [...state];
  }

  if (action.type === "RESET") { return []; }
};

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexWrap: "wrap" },

  dialogContent: { overflowY: "hidden" },

  tableContainer: { height: "70.00%", overflowY: "auto" },

  tableCaption: { fontSize: "1.50em", },

  filtersContainer: {
    width: "100.00%",
    display: "flex",
    flexDiretion: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
  },

  textInputField: {
    width: "250px",
    "& .MuiOutlinedInput-notchedOutline": { borderColor: theme.palette.text.primary },
  },

  interactiveTableCell: { cursor: "pointer", },

  btnWrapper: { position: "relative", },

  floatingButton: {
    transition: "transform 0.30s",
    "&:hover": { transform: "translateY(-5px)", },
  },

  icon: { color: theme.palette.text.primary, },

  actionButton: { "&:hover": { color: theme.palette.primary.main, }, },
}));

const DispatchSendingsModal = ({ open, onClose, dispatch }) => {
  //  ***************
  //  ** Variables **
  //  ***************

  // ***---- Controls ----***
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  
  // ***---- Elements Lists ----***
  const DISPATCH_SENDINGS_BATCH_SIZE = 50;
  const [dispatchSendingsFullList, dispatchSendingsFullListDispatch] = useReducer(reducer, []);
  const [dispatchSendingsFilteredList, setDispatchSendingsFilteredList] = useState([]);

  // ***---- Modals ----***
  const [dispatchSendingMessageModalOpen, setDispatchSendingMessageModalOpen] = useState(false);
  const [selectedDispatchSendingMessage, setSelectedDispatchSendingMessage] = useState(null);

  const [dispatchSendingErrorDescriptionModalOpen, setDispatchSendingErrorDescriptionModalOpen] = useState(false);
  const [selectedDispatchSendingErrorDescription, setSelectedDispatchSendingErrorDescription] = useState(null);

  // ***---- Confirmation Modals ----***
  const [selectedDispatchId, setSelectedDispatchId] = useState(null);
  const [cancelDispatchSendingConfirmationModalOpen, setCancelDispatchSendingConfirmationModalOpen] = useState(false);
  const [uncancelDispatchSendingConfirmationModalOpen, setUncancelDispatchSendingConfirmationModalOpen] = useState(false);

  // ***---- Filters ----***
  const [labelsList, setLabelsList] = useState([]);
  const [searchParam, setSearchParam] = useState("");
  const [searchLabel, setSearchLabel] = useState([]);
  const [searchStatus, setSearchStatus] = useState([]);



  //  ***************
  //  ** Callbacks **
  //  ***************
  const filterListCallback = useCallback((item) => {
    return (
      (
        item.contactName.toLowerCase().includes(searchParam)
        || item.contactNumber.toLowerCase().includes(searchParam)
      )
      && (
        searchLabel.length === 0
        || item.contactLabels.some(label => searchLabel.includes(label.id))
      )
      && (
        searchStatus.length === 0
        || searchStatus.includes(item.status)
      )
    );
  }, [searchParam, searchLabel, searchStatus]);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const fetchDispatchSendings = async () => {
      if (!open) return;
      if (!dispatch?.id) return;

      try {
        setLoading(true);

        // ***---- Fetching DispatchSendings ----***
        const apiCalls = [api.get(`/dispatchSendings/${dispatch?.id}`), api.get("/label")];

        const [DispatchSendingsResponse, LabelsResponse] = await Promise.all(apiCalls);

        // ***---- Transforming DispatchSendings ----***
        const transformedDispatchSendings = [];
        
        DispatchSendingsResponse.data.forEach(element => {
          transformedDispatchSendings.push({
            id: element.id,
            contactName: element.dispatchContact.contact
              ? element.dispatchContact.contact.name
              : element.dispatchContact.additionalContact,
            contactNumber: element.dispatchContact.contact
              ? element.dispatchContact.contact.number
              : element.dispatchContact.additionalContact,
            contactLabels: element.dispatchContact.contact?.labels
              ? element.dispatchContact.contact.labels.map(label => { return { id: label.id, name: label.name }; })
              : [],
            message: element.dispatchMessage.body,
            status: element.status,
            errorDescription: element.errorDescription,
            lastUpdate: element.updatedAt,
          });
        });

        transformedDispatchSendings.sort(
          (elementA, elementB) => elementA.contactName.localeCompare(elementB.contactName)
        );

        dispatchSendingsFullListDispatch({ type: "LOAD_DISPATCH_SENDINGS", payload: transformedDispatchSendings });
        setDispatchSendingsFilteredList(
          transformedDispatchSendings.slice(0, DISPATCH_SENDINGS_BATCH_SIZE)
        );

        // ***---- Formatting Labels ----***
        const formattedLabels = LabelsResponse.data.map(label => ({ id: label.id, name: label.name }));

        setLabelsList(formattedLabels);

        // ***---- Resetting Loading ----***
        setLoading(false);
      } catch (exception) {
        setLoading(false);
        console.log("DispatchSendingsModal Use Effect 1 Exception:", exception);
        toastError(exception);
      }
    };

    fetchDispatchSendings();
  }, [open, dispatch]);

  useEffect(() => {
    setDispatchSendingsFilteredList(
      dispatchSendingsFullList
        .filter(item => filterListCallback(item))
        .slice(0, DISPATCH_SENDINGS_BATCH_SIZE)
    );
  }, [dispatchSendingsFullList, searchParam, searchLabel, searchStatus, filterListCallback]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();

    setTimeout(() => {
      dispatchSendingsFullListDispatch({ type: "RESET" });

      setDispatchSendingsFilteredList([]);
      setSelectedDispatchSendingMessage(null);
      setSelectedDispatchSendingErrorDescription(null);

      setLabelsList([]);
      setSearchParam("");
      setSearchLabel([]);
      setSearchStatus([]);

      setSelectedDispatchId(null);
      setCancelDispatchSendingConfirmationModalOpen(false);
      setUncancelDispatchSendingConfirmationModalOpen(false);
    }, 100);
  };

  const filterList = (item) => {
    return (
      (
        item.contactName.toLowerCase().includes(searchParam)
        || item.contactNumber.toLowerCase().includes(searchParam)
      )
      && (
        searchLabel.length === 0
        || item.contactLabels.some(label => searchLabel.includes(label.id))
      )
      && (
        searchStatus.length === 0
        || searchStatus.includes(item.status)
      )
    );
  };

  const handleSearchParam = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleSearchLabel = (values) => {
    setSearchLabel(values);
  };

  const handleSearchStatus = (values) => {
    setSearchStatus(values);
  };

  const handleOpenDispatchSendingMessageModal = () => {
    setDispatchSendingMessageModalOpen(true);
  };

  const handleCloseDispatchSendingMessageModal = () => {
    setDispatchSendingMessageModalOpen(false);
    setSelectedDispatchSendingMessage(null);
  };

  const handleViewDispatchSendingMessage = (message) => {
    setSelectedDispatchSendingMessage(message);
    handleOpenDispatchSendingMessageModal();
  };

  const handleOpenDispatchSendingErrorDescriptionModal = () => {
    setDispatchSendingErrorDescriptionModalOpen(true);
  };

  const handleCloseDispatchSendingErrorDescriptionModal = () => {
    setDispatchSendingErrorDescriptionModalOpen(false);
    setSelectedDispatchSendingErrorDescription(null);
  };

  const handleViewDispatchSendingErrorDescription = (errorDescription) => {
    if (!errorDescription) return;
    setSelectedDispatchSendingErrorDescription(errorDescription);
    handleOpenDispatchSendingErrorDescriptionModal();
  }; 

  const handleScroll = (event) => {
    if (dispatchSendingsFilteredList.length === dispatchSendingsFullList.length) return;

    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      setDispatchSendingsFilteredList(previousValue => {
        return [
          ...previousValue,
          ...dispatchSendingsFullList
            .filter(item => filterList(item))
            .slice(previousValue.length, previousValue.length + DISPATCH_SENDINGS_BATCH_SIZE)
        ];
      });
    }
  };

  const handleOpenCancelConfirmationModal = (selectedDispatchId) => {
    setSelectedDispatchId(selectedDispatchId);
    setCancelDispatchSendingConfirmationModalOpen(true);
  };

  const handleCloseCancelConfirmationModal = () => {
    setSelectedDispatchId(null);
    setCancelDispatchSendingConfirmationModalOpen(false);
  };

  const handleOpenUncancelConfirmationModal = (selectedDispatchId) => {
    setSelectedDispatchId(selectedDispatchId);
    setUncancelDispatchSendingConfirmationModalOpen(true);
  };

  const handleCloseUncancelConfirmationModal = () => {
    setSelectedDispatchId(null);
    setUncancelDispatchSendingConfirmationModalOpen(false);
  };

  const handleCancelDispatchSending = async () => {
    try {
      const { data: scheduleDispatchSending } = await api.get(`/dispatchSendings-validation/${selectedDispatchId}`);
      const scheduleDispatchSendingStatus = scheduleDispatchSending[0].status;

      if (scheduleDispatchSendingStatus === 0) {
        await api.patch(`/dispatchSendings-cancel/${selectedDispatchId}`);
        handleCloseCancelConfirmationModal();
      }
      else {
        toast.info(i18n.t("dispatchSendingsModal.validation.dispatchAlreadySent"));
        await api.patch(`/dispatchSendings-updateStatus/${selectedDispatchId}`);
      }
    } catch (exception) {
      console.log(`Exception caught while handling cancel dispatch sending: ${JSON.stringify(exception)}`);
    }
  };

  const handleUncancelDispatchSending = async () => {
    try {
      await api.patch(`/dispatchSendings-uncancel/${selectedDispatchId}`);
      handleCloseUncancelConfirmationModal();
    } catch (exception) {
      console.log(`Exception caught while handling uncancel dispatch sending: ${JSON.stringify(exception)}`);
    }
  };

  const handleSyncDispatchSendingStatus = async (dispatchSendingId) => {
    try {
      await api.patch(`dispatchSendings-syncStatus/${dispatchSendingId}`);
    } catch (exception) {
      console.log(`Exception caught while handling sync dispatch sending status: ${JSON.stringify(exception)}`);
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <SocketDispatchSendingsManager dispatch={dispatchSendingsFullListDispatch} />

      <DispatchSendingMessageModal
        open={dispatchSendingMessageModalOpen}
        onClose={handleCloseDispatchSendingMessageModal}
        selectedMessage={selectedDispatchSendingMessage}
      />

      <DispatchSendingErrorDescriptionModal
        open={dispatchSendingErrorDescriptionModalOpen}
        onClose={handleCloseDispatchSendingErrorDescriptionModal}
        selectedErrorDescription={selectedDispatchSendingErrorDescription}
      />

      <ConfirmationModal
        title={i18n.t("dispatchSendingsModal.confirmationModals.cancelTitle")}
        open={cancelDispatchSendingConfirmationModalOpen}
        onClose={handleCloseCancelConfirmationModal}
        onConfirm={handleCancelDispatchSending}
      />

      <ConfirmationModal
        title={i18n.t("dispatchSendingsModal.confirmationModals.uncancelTitle")}
        open={uncancelDispatchSendingConfirmationModalOpen}
        onClose={handleCloseUncancelConfirmationModal}
        onConfirm={handleUncancelDispatchSending}
      />

      <Dialog open={open} onClose={handleClose} scroll="paper" fullWidth fullScreen>
        <DialogTitle>
          {i18n.t("dispatchSendingsModal.title")}
        </DialogTitle>

        <DialogContent className={classes.dialogContent} dividers>
          {/* 
            *************
            ** Caption **
            *************
          */}
          <Table>
            <span className={classes.tableCaption}>{i18n.t("dispatchSendingsModal.dispatch")} {dispatch?.name}</span>
          </Table>

          <br />
          <Divider />
          <br />

          {/* 
            *************
            ** Filters **
            *************
          */}
          <div className={classes.filtersContainer}>
            <TextField
              className={classes.textInputField}
              fullWidth={isSmallScreen ? true : false}
              label={i18n.t("dispatchSendingsModal.filters.nameNumber")}
              margin="dense"
              variant="outlined"
              value={searchParam}
              onChange={handleSearchParam}
              autoFocus
            />

            <LabelSelect
              selectedLabelIds={searchLabel}
              onChange={values => handleSearchLabel(values)}
              labelsOptions={labelsList}
              styleSelecInputField={true}
            />

            <DispatchStatusSelect
              selectedOptions={searchStatus}
              onChange={values => handleSearchStatus(values)}
              styleSelectInputField={true}
            />
          </div>

          <br />
          <Divider />
          <br />

          {/* 
            ***********
            ** Table **
            ***********
          */}
          <TableContainer className={classes.tableContainer} onScroll={handleScroll}>
            <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  {isSmallScreen && !["pending", "concluded"].includes(dispatch?.status) && (
                    <TableCell align="center">{i18n.t("dispatchSendingsModal.table.actions")}</TableCell>
                  )}

                  <TableCell align="center">{i18n.t("dispatchSendingsModal.table.contactName")}</TableCell>
                  <TableCell align="center">{i18n.t("dispatchSendingsModal.table.contactNumber")}</TableCell>
                  <TableCell align="center">{i18n.t("dispatchSendingsModal.table.message")}</TableCell>
                  <TableCell align="center">{i18n.t("dispatchSendingsModal.table.status")}</TableCell>
                  <TableCell align="center">{i18n.t("dispatchSendingsModal.table.errorDescription")}</TableCell>
                  <TableCell align="center">{i18n.t("dispatchSendingsModal.table.lastUpdateAt")}</TableCell>

                  {!isSmallScreen && !["pending", "concluded"].includes(dispatch?.status) && (
                    <TableCell align="center">{i18n.t("dispatchSendingsModal.table.actions")}</TableCell>
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {dispatchSendingsFilteredList.map((dispatchSending) => (
                  <TableRow key={dispatchSending.id}>
                    {isSmallScreen && !["pending", "concluded"].includes(dispatch?.status) && (
                      <TableCell align="center">
                        {["pending"].includes(dispatchSending.status) && (
                          <IconButton className={classes.icon} size="small" onClick={() => handleOpenCancelConfirmationModal(dispatchSending.id)}>
                            <CancelScheduleSendOutlinedIcon className={classes.actionButton} />
                          </IconButton>
                        )}

                        {["cancelled"].includes(dispatchSending.status) && (
                          <IconButton className={classes.icon} size="small" onClick={() => handleOpenUncancelConfirmationModal(dispatchSending.id)}>
                            <KeyboardReturnOutlinedIcon className={classes.actionButton} />
                          </IconButton>
                        )}

                        {["cancelled", "inProgress", "pending"].includes(dispatchSending.status) && (
                          <IconButton className={classes.icon} size="small" onClick={() => handleSyncDispatchSendingStatus(dispatchSending.id)}>
                            <SyncOutlinedIcon className={classes.actionButton} />
                          </IconButton>
                        )}
                      </TableCell>
                    )}

                    <TableCell align="center">{dispatchSending.contactName}</TableCell>

                    <TableCell align="center">{dispatchSending.contactNumber}</TableCell>

                    <TableCell
                      align="center"
                      className={classes.interactiveTableCell}
                      onClick={() => handleViewDispatchSendingMessage(dispatchSending.message)}
                    >
                      {truncateString(dispatchSending.message, 27)}
                    </TableCell>

                    <TableCell align="center">
                      {i18n.t(`dispatchSendingsModal.table.statusValues.${dispatchSending.status}`)}
                    </TableCell>

                    <TableCell
                      align="center"
                      className={dispatchSending.errorDescription ? classes.interactiveTableCell : ``}
                      onClick={() => handleViewDispatchSendingErrorDescription(dispatchSending.errorDescription)}
                    >
                      {dispatchSending.errorDescription && truncateString(dispatchSending.errorDescription, 27)}
                    </TableCell>

                    <TableCell align="center">
                      {extractDayMonthYearFromStringDateTime(dispatchSending.lastUpdate)} {extractHourMinuteFromStringDateTime(dispatchSending.lastUpdate)}
                    </TableCell>

                    {!isSmallScreen && !["pending", "concluded"].includes(dispatch?.status) && (
                      <TableCell align="center">
                        {["pending"].includes(dispatchSending.status) && (
                          <IconButton className={classes.icon} size="small" onClick={() => handleOpenCancelConfirmationModal(dispatchSending.id)}>
                            <CancelScheduleSendOutlinedIcon className={classes.actionButton} />
                          </IconButton>
                        )}

                        {["cancelled"].includes(dispatchSending.status) && (
                          <IconButton className={classes.icon} size="small" onClick={() => handleOpenUncancelConfirmationModal(dispatchSending.id)}>
                            <KeyboardReturnOutlinedIcon className={classes.actionButton} />
                          </IconButton>
                        )}

                        {["cancelled", "inProgress", "pending"].includes(dispatchSending.status) && (
                          <IconButton className={classes.icon} size="small" onClick={() => handleSyncDispatchSendingStatus(dispatchSending.id)}>
                            <SyncOutlinedIcon className={classes.actionButton} />
                          </IconButton>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}

                {loading && <TableRowSkeleton columns={6} />}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>

        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            disabled={loading}
            className={`${classes.btnWrapper} ${classes.floatingButton}`}
            onClick={handleClose}
          >
            {i18n.t("dispatchSendingsModal.buttons.close")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DispatchSendingsModal;
