function getConfig(name, defaultValue=null) {
	// If inside a docker container, use window.ENV
	if (window.ENV !== undefined) return window.ENV[name] || defaultValue;
	return process.env[name] || defaultValue;
}

export function getBackendUrl() {
  return getConfig('REACT_APP_BACKEND_URL');
}

export function getTenorApiKey() {
	return getConfig('REACT_APP_TENOR_API_KEY');
};

export function getTenorClientKey() {
	return getConfig('REACT_APP_TENOR_CLIENT_KEY');
}