import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field } from "formik";
import {
  Button,
  Container,
  FormControlLabel,
  IconButton,
  makeStyles,
  Paper,
  Switch,
  TextField,
  Tooltip
} from "@material-ui/core";
import { DeleteOutline, LocationOnOutlined, LocationOffOutlined } from "@material-ui/icons";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../../components/ConfirmationModal";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(8, 8, 3),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		marginBottom: 12,
	},
  paperLogoImage: {
    padding: theme.spacing(2),
		alignItems: "center",
		marginBottom: 12,
  },
	margin: {
		margin: theme.spacing(1),
	},
  hiddenField: {
    display: "none",
  },
  logoImage: {
    width: "300px",
    height: "300px",
  },

  locationContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
  },

  locationFieldsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "5px",
  },

  locationButtonsContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "5px",
  },

  floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)', },
  },
  actionButton: {
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
}));

const Profile = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const initialState = { name: "", logo: "", sendLogoAsSticker: false, latitude: "", longitude: "", accuracy: "" };
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [myProfile, setMyProfile] = useState(initialState);

  

  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    if (user.profile === "user" || !user.configEnabled) { history.push(`/tickets`); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/myProfile/1");
				setMyProfile(data);
			} catch (error) {
        console.log("My Profile Use Effect 1 Error:", error);
        toastError(error);
      }
		};
		fetchSession();
	}, []);



  //  ***************
  //  ** CallBacks **
  //  ***************
  const successLocationCallback = (position) => {
    setMyProfile({ 
      ...myProfile
      , latitude: position.coords.latitude
      , longitude: position.coords.longitude
      , accuracy: position.coords.accuracy
    });
  };

  const errorLocationCallback = (error) => {
    //  Error Codes:
    //
    //   - 1: (PERMISSION_DENIED) - The acquisition of the geolocation information failed because the page didn't
    // have the permission to do it.
    //   - 2: (POSITION_UNAVAILABLE) - The acquisition of the geolocation failed because one or several internal
    // sources of position returned an internal error.
    //   - 3: (TIMEOUT) - Geolocation information was not obtained in the allowed time.
    //
    if (error.code === 1) {
      toast.info(i18n.t("location.errors.permissionNotGranted"));
    } else if (error.code === 2) {
      toast.info(i18n.t("location.errors.positionUnavaible"));
    } else {
      toast.error(i18n.t("location.errors.timeout"));
    }
  };
  


  //  **************
  //  ** Function **
  //  **************
  const handleCompanyName = (event) => {
    setMyProfile({
      ...myProfile,
      name: event.target.value
    });
  }

  const handleChooseProfileLogo = () => {
    const profileLogoInput = document.getElementById("profileLogoInput");
    profileLogoInput.click();
  }

  const handlerDrop = (e) => {
    const profileLogoInput = document.getElementById("profileLogoInput");
    const profileLogo = document.getElementById("profileLogo");
    const paperLogoImage = document.getElementById("paperLogoImage");
    const inputLogoImage = document.getElementById("inputLogoImage");
   
    const dT = new DataTransfer();

    profileLogoInput.files = e.dataTransfer.files;

    if (profileLogoInput.files[0].type !== "image/png"
      && profileLogoInput.files[0].type !== "image/gif"
      && profileLogoInput.files[0].type !== "image/jpeg") {
        profileLogo.value = "";
        profileLogoInput.value = "";
        inputLogoImage.src = "";
        paperLogoImage.style.display = "none";
        return;
    }

    dT.items.add(e.dataTransfer.files[0]);
    profileLogoInput.files = dT.files;
    profileLogo.value = profileLogoInput.files[0].name;
    inputLogoImage.src = URL.createObjectURL(profileLogoInput.files[0]);
    paperLogoImage.style.display = "block";

    e.preventDefault();
  }

  const handleRemoveLogoProfile = async () => {
    try {
      await api.put("/myProfile/removeLogo/1");
    } catch (error) {
      console.log("Handle Remove Logo Profile Error:", error);
      toastError(error);
    }
  }

  const handleRemoveLogoClick = () => {
    const profileLogoInput = document.getElementById("profileLogoInput");
    const profileLogo = document.getElementById("profileLogo");
    const inputLogoImage = document.getElementById("inputLogoImage");

    inputLogoImage.removeAttribute('src');
    profileLogo.value = "";
    profileLogoInput.value = "";
    inputLogoImage.src = "";

    handleRemoveLogoProfile();
  } 

  const handleChangeLogoClick = () => { document.getElementById("profileLogoInput").click(); } 

  const handleChangeLogo = () => {
    const profileLogoInput = document.getElementById("profileLogoInput");
    const profileLogo = document.getElementById("profileLogo");
    const paperLogoImage = document.getElementById("paperLogoImage");
    const inputLogoImage = document.getElementById("inputLogoImage");

    if (profileLogoInput.files[0].type !== "image/png"
      && profileLogoInput.files[0].type !== "image/gif"
      && profileLogoInput.files[0].type !== "image/jpeg") {
        profileLogo.value = "";
        profileLogoInput.value = "";
        inputLogoImage.src = "";
        paperLogoImage.style.display = "none";
        return;
    }
    
    profileLogo.value = profileLogoInput.files[0].name;
    inputLogoImage.src = URL.createObjectURL(profileLogoInput.files[0]);
    paperLogoImage.style.display = "block";
  }

  const handleUpdateProfile = async () => {
    const profileLogoInput = document.getElementById("profileLogoInput");

    const formData = new FormData();
    formData.append("name", myProfile.name);
    formData.append("logo", profileLogoInput.files[0]);
    formData.append("sendLogoAsSticker", myProfile.sendLogoAsSticker);
    formData.append("latitude", myProfile.latitude);
    formData.append("longitude", myProfile.longitude);
    formData.append("accuracy", myProfile.accuracy);

    try {
      await api.put("/myProfile/1", formData);
    } catch (error) {
      console.log("Handle Update Profile Error:", error);
      toastError(error);
    }
  }

  const handleCloseConfirmationModal = () => { setConfirmModalOpen(false); };

  const handleTurnOnLocation = () => {
    navigator.geolocation.getCurrentPosition(successLocationCallback, errorLocationCallback);
  };

  const handleTurnOffLocation = () => {
    setMyProfile({ 
      ...myProfile
      , latitude: "-"
      , longitude: "-"
      , accuracy: "-"
    });
  };

  const handleChangeSendLogoAsSticker = () => {
    setMyProfile({
      ...myProfile,
      sendLogoAsSticker: !myProfile.sendLogoAsSticker
    });
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div id="profileMainContainer" onDrop={handlerDrop}>
      <MainHeader>
        <Title>{i18n.t("profile.title")}</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdateProfile}
            className={classes.floatingButton}
          >
            {i18n.t("profile.buttons.save")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>

      <Container>
        <Formik initialValues={myProfile} enableReinitialize={true}>
          <Form>


            {/* 
              ********************
              ** Company's Name **
              ********************
            */}
            <Paper className={classes.paper}>
              <Field
                as={TextField}
                label={i18n.t("profile.labels.name")}
                autoFocus
                autoComplete="off"
                name="name"
                variant="outlined"
                margin="dense"
                fullWidth
                onChange={(e) => handleCompanyName(e)}
              />
            </Paper>
            


            {/* 
              ***********************
              ** Hidden Logo Input **
              ***********************
            */}
            <div hidden="hidden">
              <Paper className={classes.paper}>
                <Field
                  as={TextField}
                  id="profileLogo"
                  helperText={i18n.t("profile.labels.logo")}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                  placeholder={i18n.t("profile.labels.logoField")}
                  name="logoField"
                  InputProps={{ readOnly: true, }}
                  onClick={handleChooseProfileLogo}
                />

                <input
                  name="logo"
                  className={classes.hiddenField}
                  margin="dense"
                  variant="outlined"
                  id="profileLogoInput"
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  onChange={handleChangeLogo}
                />
              </Paper>
            </div>


            {/*
              ******************
              ** Logo Preview **
              ******************
            */}
            <Paper id="paperLogoImage" className={classes.paperLogoImage}>
              <center>
                <div 
                  variant="contained"
                  color="primary"
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  onClick={handleChangeLogoClick}
                  display={myProfile.logoBase64 ? "none" : "block"}
                >
                  {i18n.t("profile.labels.logoField")}  
                </div>

                <img 
                  id="inputLogoImage"
                  alt=""
                  className={classes.logoImage}
                  src={myProfile.logoBase64 ? myProfile.logoBase64 : null} 
                  onClick={handleChangeLogoClick}
                />

                <br></br>

                {/*
                  ************************** 
                  ** Send Logo as Sticker **
                  **************************
                */}
                <div>
                  <FormControlLabel
                    control={<Field as={Switch} onClick={handleChangeSendLogoAsSticker} color="primary" name="isBot" checked={myProfile.sendLogoAsSticker} />}
                    label={i18n.t("profile.labels.sendLogoAsSticker")}
                  />
                </div>

                <br></br>

                <ConfirmationModal
                  title={`${i18n.t("myProfile.confirmationModal.deleteTitle")}?`}
                  open={confirmModalOpen}
                  onClose={handleCloseConfirmationModal}
                  onConfirm={() => handleRemoveLogoClick()}
                >
                  {i18n.t("myProfile.confirmationModal.deleteMessage")}
                </ConfirmationModal>

                <IconButton
                  size="small"
                  variant="contained"
                  color="inherit"
                  onClick={() => { setConfirmModalOpen(true); }}
                >
                  <DeleteOutline className={`${classes.actionButton} ${classes.floatingButton}`} />
                </IconButton>
              </center>
            </Paper>

            
            {/* 
              ************************
              ** Company's Location **
              ************************
            */}
            <Paper className={classes.paper}>
              <div className={classes.locationContainer}>
                <div className={classes.locationFieldsContainer}>
                  <Field
                    as={TextField}
                    label={i18n.t("profile.labels.latitude")}
                    name="latitude"
                    variant="outlined"
                    margin="dense"
                    onChange={() => {}}
                  />
                  <Field
                    as={TextField}
                    label={i18n.t("profile.labels.longitude")}
                    name="longitude"
                    variant="outlined"
                    margin="dense"
                    onChange={() => {}}
                  />
                  <Field
                    as={TextField}
                    label={i18n.t("profile.labels.accuracy")}
                    name="accuracy"
                    variant="outlined"
                    margin="dense"
                    onChange={() => {}}
                  />
                </div>

                <div className={classes.locationButtonsContainer}>
                  <Tooltip title={i18n.t("profile.tooltips.turnOnGeolocation")} placement="top-start" arrow>
                    <IconButton
                      size="small"
                      variant="contained"
                      color="inherit"
                      onClick={handleTurnOnLocation}
                    >
                      <LocationOnOutlined className={`${classes.actionButton} ${classes.floatingButton}`} />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={i18n.t("profile.tooltips.turnOffGeolocation")} placement="top-start" arrow>
                    <IconButton
                      size="small"
                      variant="contained"
                      color="inherit"
                      onClick={handleTurnOffLocation}
                    >
                      <LocationOffOutlined className={`${classes.actionButton} ${classes.floatingButton}`} />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            </Paper>
          </Form>
        </Formik>
      </Container>
    </div>
  );
};

export default Profile;