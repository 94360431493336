import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";
import ChatView from "../ChatView";
import useWhatsApps from "../../hooks/useWhatsApps";

const useStyles = makeStyles(theme => ({
  root: { display: "flex", flexWrap: "wrap", },

  dialog: { backdropFilter: "blur(5px)", },

  floatingButton: {
    transition: "transform 0.30s",
    "&:hover": { transform: "translateY(-5px)", },
  },
}));

const ViewChatModal = ({ open, onClose, contact, handleOpenPrivateChatConversationModal }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const { whatsApps } = useWhatsApps();

  const [whatsappId, setWhatsappId] = useState("");



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (open && whatsApps.length > 0) {
      const defaultWhatsappId = whatsApps.filter(whatsapp => whatsapp.isDefault)[0].id;
      setWhatsappId(defaultWhatsappId);
    }
  }, [open, whatsApps]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    setWhatsappId("");
    onClose();
  };

  const handleExportToPDF = () => { window.print(); };

  const handleOpenPrivateChat = () => { handleOpenPrivateChatConversationModal(contact); };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        scroll="paper"
        className={classes.dialog}
      >
        <DialogTitle>
          {i18n.t("viewTicketModal.title")}
        </DialogTitle>

        <FormControl variant="outlined" margin="dense" fullWidth>
          <InputLabel>{i18n.t("newTicketModal.connection")}</InputLabel>
          
          <Select
            value={whatsappId}
            onChange={(event) => setWhatsappId(event.target.value)}
            label={i18n.t("newTicketModal.connection")}
            fullWidth
          >
            {whatsApps.map((whatsapp) => (
              <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <ChatView open={open} contactId={contact.id} whatsappId={whatsappId} />

        <DialogActions>
          <Button
            onClick={handleClose}
            color="inherit"
            variant="outlined"
            className={classes.floatingButton}
          >
            {i18n.t("viewTicketModal.buttons.return")}
          </Button>

          <Button
            onClick={handleExportToPDF}
            color="primary"
            variant="contained"
            className={classes.floatingButton}
          >
            {i18n.t("viewTicketModal.buttons.exportToPDF")}
          </Button>

          <Button
            onClick={handleOpenPrivateChat}
            color="primary"
            variant="contained"
            className={classes.floatingButton}
          >
            {i18n.t("viewTicketModal.buttons.openPrivateChat")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ViewChatModal;
