import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexWrap: "wrap", },
}));

const DispatchSendingErrorDescriptionModal = ({ open, onClose, selectedErrorDescription }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} scroll="paper" maxWidth="sm" fullWidth>
        <DialogTitle>
          {i18n.t("dispatchSendingErrorDescriptionModal.title")}
        </DialogTitle>

        <DialogContent dividers>
          <span>{selectedErrorDescription}</span>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DispatchSendingErrorDescriptionModal;
