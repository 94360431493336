import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Checkbox,
	Chip,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	chips: { display: "flex", flexWrap: "wrap", },

	chip: { margin: 2, color: theme.palette.text.primary, },

	chipCheckbox: {
		color: theme.palette.primary.main,
		'&.Mui-checked': { color: theme.palette.primary.main, }
	},

	selectInputField: {
    "& .MuiOutlinedInput-notchedOutline": { borderColor: theme.palette.text.primary, },
    "& .MuiSvgIcon-root": { color: theme.palette.text.primary, },
	},
}));

const QueueSelect = ({ selectedQueueIds, onChange, queuesOptions }) => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();
	const [queues, setQueues] = useState([]);



	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get("/queue");
				setQueues(data);
			} catch (error) {
				console.log("Queue Select Use Effect Error:", error);
				toastError(error);
			}
		})();
	}, []);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleChange = event => { onChange(event.target.value); };



	// 	************
	// 	** Return **
	// 	************
	return (
		<div style={{ marginTop: 6 }}>
			<FormControl
				fullWidth
				margin="dense"
				variant="outlined"
				classes={{ root: classes.selectInputField }}
			>
				<InputLabel>{i18n.t("queueSelect.inputLabel")}</InputLabel>
				<Select
					multiple
					label={i18n.t("queueSelect.inputLabel")}
					value={selectedQueueIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: { vertical: "bottom", horizontal: "left", },
						transformOrigin: { vertical: "top", horizontal: "left", },
						getContentAnchorEl: null,
					}}
					renderValue={selected => (
						<div className={classes.chips}>
							{selected?.length > 0 &&
								selected.map(id => {
									const queue = queues.find(q => q.id === id);
									return queue
										? (<Chip key={id} style={{ border: `2px solid ${queue.color}` }} variant="outlined" label={queue.name} className={classes.chip} /> )
										: null;
								})}
						</div>
					)}
				>
					{queuesOptions.map(queue => (
						<MenuItem key={queue.id} value={queue.id}>					
							<Checkbox className={classes.chipCheckbox} checked={selectedQueueIds.indexOf(queue.id) > -1} />
              <ListItemText primary={queue.name} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default QueueSelect;
