const messages = {
  pt: {
    translations: {  
      languagesMenu: {
        language1: 'Português',
        language2: 'English',
        language3: 'Español',
      },
      modalImage: {
        openNewTab: "Abrir imagem em uma nova aba.",
      },
      page404: {
        message: "Parece que você se perdeu, clique em uma das opções do menu no lado esquerdo para voltar à página!",
      },
      messagesListLabels: {
        ticketEnd: "Término do chamado",
      },
      errorBoundary: {
        message: "Oops! Não conseguimos carregar as informações.",
        button: "Tente Novamente",
      },
      reactions: {
        fromMe: "Enviada por mim",
        fromContact: "Enviada pelo contato",
      },
      adsComponent: {
        identification: "Mensagem de Anúncio",
      },
      releasesModal: {
        title: "Novidades",
        upToDateSpan: "Você está atualizado! 🎉",
        buttons: {
          close: "Fechar",
        },
        patch: "Nota",
        labels: {
          implementations: "🔨 Implementações",
          adjustments: "🔃 Melhorias",
          bugFixes: "✅ Correções",
        },
        messages: {
          patch5: {
            implementations: {
              i1: "- encaminhamento de mensagens (conexão padrão)",
              i2: "- encaminhamento de contatos",
              i3: "- nome da empresa incluído na mensagem de saudação em texto",
            },
            adjustments: {
              a1: "- filtro de conversas pendentes e em atendimentos para administradores",
              a2: "- tratamento da tradução automática do navegador Google Chrome",
              a3: "- menu de conversas em atendimento flexível para versão mobile",
            },
            bugFixes: {
              b1: "- envio de anexos de usuários normais",
              b2: "- interações em grupos de usuários normais",
              b3: "- cadastro de contatos",
            },
          },
          patch4: {
            implementations: {
              i1: "- importação de conversas do WhatsApp",
              i2: "- identificação do final de cada chamado",
              i3: "- prioridade de usuários para contatos",
              i4: "- mensagem de boas-vindas sem departamento vinculado ao chip",
            },
            adjustments: {
              a1: "- novo estilo de filtros por departamentos, setores e usuários nas conversas ativas",
            },
            bugFixes: {
              b1: "- transferência de contatos para usuários sem acesso ao departamento",
            },
          },
          patch3: {
            implementations: {
              i1: "- visualização gráfica da triagem",
              i2: "- opção de copiar mensagens de texto",
              i3: "- identificação de chamadas de voz e vídeo recebidas",
              i4: "- edição de departamento de chats pendentes",
              i5: "- filtro por departamento nos chats abertos e pendentes",
            },
            adjustments: {
              a1: "- passo-a-passo de como conectar o chip na aplicação na página de conexões",
              a2: "- identificação de chamado retornado para fila",
              a3: "- dashboard sendo carregado com melhor performance",
            },
            bugFixes: {
              b1: "- listas de transmissões enviadas não criam mais chamados pendentes",
            },
          },
          patch2: {
            implementations: {
              i1: "- agora você pode criar chats para anotações, favoritar mensagens e expressar seus sentimentos reagindo às mensagens ❤️😮",
              i2: "- configuração para enviar listas e botões ou mensagens de texto aos contatos",
              i3: "- seleção de fuso horário",
              i4: "- novas animações nos botões de ações",
              i5: "- campo código do cliente nos contatos",
              i6: "- tempo máximo de espera na fila de pendentes",
              i7: "- novos filtros no dashboard de conversas",
              i8: "- quantidade de departamentos ilimitada",
            },
            adjustments: {
              a1: "- mensagem de ausência tornou-se opcional para o chip",
              a2: "- dashboards sendo carregados mais rápidos",
              a3: "- escolher se o chat será atribuído ao usuário ou ficará pendente na abertura de chats",
            },
            bugFixes: {
              b1: "- tempo de espera reiniciado ao reabrir ticket",
              b2: "- funcionalidade Drag e Drop não está mais travando a aplicação",
              b3: "- labels trazem informações em tempo real ao abrir conversas para usuários",
            },
          },
          patch1: {
            implementations: {
              i1: "- dashboard conversas por usuários",
              i2: "- dashboard interativo de conversas",
              i3: "- contagem do tempo de atendimento",
              i4: "- adicionada opção de fechar ticket pendente sem precisar aceitá-lo (apenas para administradores)",
              i5: "- menu liberado para mobiles",
              i6: "- visualização de foto de perfil dos contatos",
              i7: "- agora você pode enviar arquivos aos contatos apenas arrastando e soltando eles",
            },
            adjustments: {
              a1: "- não aparece mais 'NaN' no dashboard geral quando é a primeira vez de uso da aplicação",
              a2: "- navegadores voltaram a notificar novas mensagens",
              a3: "- escolha e envio de filas e botões está funcionando para WhatsApp Business também",
              a4: "- ao visualizar uma conversa já fechada, página continua na lista de fechadas",
              a5: "- mensagens de grupos e conversas particulares foram separadas",
            },
            bugFixes: {
              b1: "- inclusão de imagens na aplicação",
              b2: "- alteração das cores de títulos das páginas",
              b3: "- alteração das cores dos botões secundários",
              b4: "- todos os collapses do menu iniciam fechados",
              b5: "- liberadas opções de anexar arquivos e gravar áudio para administradores quando tickets estão pendentes",
              b6: "- departamentos, categorias e setores foram agrupados no menu",
              b7: "- adicionada lista de usuários na opção de transferência de conversa",
              b8: "- novo estilo da barra de rolagem",
            },
          },
        },
      },
      dragDropModal: {
        span: "Arraste e solte seu arquivo aqui!",
        obs: "(apenas um arquivo por tentativa)",
        await: "Aguarde enquanto enviamos o seu arquivo!",
        confirmationModal: {
          title: "Você gostaria de enviar o arquivo?",
          messages: {
            format: "Formato:",
            size: "Tamanho:",
            name: "Nome:",
          },
        },
      },
      openAi: {
        expander: "BestZap Bot",
        inputPlaceholder: "Digite aqui sua pergunta",
      },
      locationPreview: {
        viewButton: "Visualizar",
      },
      listPreview: {
        select: "Selecionar",
      },
      listPreviewModal: {
        title: "Selecionar",
        toasts: {
          notSelectedOption: "Selecione uma opção antes de enviar a mensagem!",
        },
      },

      eventPreview: {
        videoCall: "Chamada de vídeo no WhatsApp",
        voiceCall: "Chamada de voz no WhatsApp",
        seeDetails: "Ver Detalhes",
      },

      pollPreview: {
        multipleAnswers: "Selecione uma ou mais opções",
        singleAnswer: "Selecione uma opção",
        viewVotes: "Ver Votos",
      },
      groupInvitePreview: {
        description: "Convite de Grupo",
        joinButton: "Entrar",
        expiredButton: "Expirado",
        confirmationModal: {
          title: "Gostaria de entrar no grupo:",
        },
        toasts: {
          invalidInviteCode: "Código de convite inválido. Peça outro convite ao administrador do grupo!",
          inviteExpired: "Código de convite expirado. Peça outro convite ao administrador do grupo!",
        },
      },
      vCard: {
        confirmationModal: {
          title: "Gostaria de conversar com",
        },        
        button: 'Conversar',
        info: "Não é possível inicial uma conversa com este contato porque o número de telefone dele não foi compartilhado!",
        toasts: {
          noNumberProvided: 
            "Não é possível iniciar uma conversa com um contato compartilhado sem um número válido de WhatsApp. Peça para o contato que enviou a mensagem enviar novamente!",
        },
      },  
      multiVCard: {
        buttons: {
          showCards: "Ver todos",
          talk: "Conversar",
        },
        texts: {
          and: "e mais",
          otherContacts: "contatos",
        },
      },  
      image: {
        copyButton: "Copiar",
        doodleButton: "Rabiscar",
        editButton: "Editar",
        resetButton: "Limpar",
        undoButton: "Desfazer",
        cancelButton: "Cancelar",
        sendButton: "Enviar",
        tooltipCopyButton: "Copiado!",
        editingPanel: "Editando Imagem",
        success: "Imagem copiada! Agora você pode colar aqui se quiser.",
      },
      badges: {
        closedContent: "Finalizado",
      },
      signup: {
        title: "Cadastre-se",
        toasts: {
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      login: {
        title: "BestZap",
        subtitle: "Login",
        subtitleGroup: "Informe seu email",
        subtitlePassword: "Informe sua senha",
        form: {
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Entrar",
          back: "Voltar",
          register: "Não tem um conta? Cadastre-se!",
        },
      },
      whatsappButtonComponent: {
        tooltip: "Fale conosco!",
      },
      dashboard: {
        emptyBox: "Nenhum registro encontrado no período informado",

        settings: {
          allowGroupsDisabled: "Grupos não estão habilitados",
        },

        charts: {
          ticketsPerQueues: {
            title: "Atendimentos por Departamentos",
          },
          ticketsPerSubqueues: {
            title: "Atendimentos por Setores",
          },
          ticketsPerConnection: {
            title: "Atendimentos por Conexões",
          },
          openTicketsPerUsers: {
            title: "Chats Abertos por Usuários",
          },
          closedTicketsPerUsers: {
            title: "Chats Fechados por Usuários",
          },
          timePerUsers: {
            title: "Tempo Médio por Usuários (minutos)",
          },
          totalTimePerUsers: {
            title: "Tempo Total por Usuários (minutos)",
          },
        },
        buttons: {
          update: "Atualizar",
          filterMessageKeywords: "Filtrar por palavra-chave",
        },
        fields: {
          initialDate: "Data Inicial",
          finalDate: "Data Final",
          resaleSerialNumber: "Número da Revenda",
          serialNumber: "Número de Série",
          clientCode: "Código do Cliente",
          messageKeyword: "Palavra-chave",
        },
        messages: {
          inAttendance: {
            title: "Em Atendimento"
          },
          waiting: {
            title: "Aguardando"
          },
          closed: {
            title: "Finalizado"
          },
          newContacts: {
            title: "Novos Contatos"
          },
          messages: {
            title: "Mensagens"
          },
          attendant: {
            title: "Atendentes"
          },
          awaitingTime: {
            title: "Tempo de Espera (Média)"
          },
          attendanceTime: {
            title: "Tempo de Atendimento (Média)"
          },
          time: {
            minute: "Minuto",
            minutes: "Minutos",
            hour: "Hora",
            hours: "Horas",
            never: "Nunca",
          }
        }
      },
      generalDashboard: {
        title: "Dashboard Geral",
      },
      attendantsDashboard: {
        title: "Dashboard de Atendentes",
      },
      ratingsDashboard: {
        title: "Dashboard de Avaliações",
        cards: {
          rate: "Nota (Média)",
          tickets: "Conversas",
          contacts: "Contatos",
        },
        captions: {
          top5Users: "😀 Maiores Notas",
          bottom5Users: "😥 Menores Notas",
          allUsers: "✨ Todos os Usuários",
        },
        table: {
          id: "#",
          user: "Usuário",
          rate: "Nota",
          ratingsCount: "Avaliações",
        },
        tooltips: {
          tableView: "Visualização em Tabela",
          chartView: "Visualização em Gráfico",
        },
      },

      groupsDashboard: {
        title: "Dashboard de Grupos",

        cards: {
          chats: "Conversas",
          newGroups: "Novos Grupos",
        },
      },

      internalChatTicketsDashboard: {
        title: "Dashboard de Conversas Internas",
        amount: "Qtd de Conversas",
        faster: "Conversa Mais Rápida",
        longer: "Conversa Mais Longa",
        minute: "minuto",
        minutes: "minutos",
        hour: "hora",
        hours: "horas",

        table: {
          header: {
            id: "Conversa",
            user: "Usuário",
            chatName: "Nome da Conversa",
            protocol: "Protocolo",
            time: "Tempo",
            endedAt: "Encerrado Em",
          },
          rows: {
            minute: "minuto",
            minutes: "minutos",
            hour: "hora",
            hours: "horas",
          },
        },
      },

      ticketsDashboard: {
        title: "Dashboard de Conversas",
        amount: "Qtd de Conversas",
        faster: "Conversa Mais Rápida",
        longer: "Conversa Mais Longa",
        minute: "minuto",
        minutes: "minutos",
        hour: "hora",
        hours: "horas",

        table: {
          header: {
            id: "Conversa",
            connection: "Conexão",
            platform: "Plataforma",
            rate: "Nota",
            user: "Usuário",
            contactName: "Nome do Contato",
            contactNumber: "Número do Contato",
            resaleSerialNumber: "Número da Revenda",
            serialNumber: "Número de Série",
            clientCode: "Código do Cliente",
            queue: "Departamento",
            category: "Categoria",
            subqueue: "Setor",
            protocol: "Protocolo",
            time: "Tempo",
            endedAt: "Encerrado em",
          },
          rows: {
            minute: "minuto",
            minutes: "minutos",
            hour: "hora",
            hours: "horas",
          },
        },

        charts: {
          title: "Distribuição das Conversas: Usuário",
        },
      },
      connections: {
        title: "Conexões",
        usedChipsCount: {
          outOf: "de",
          chips: "chip(s)",
        },
        card: {
          defaultConnection: "Conexão Padrão",
          notDefaultConnection: "Conexão Não-Padrão",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "Tem certeza? Você precisará ler o QR Code novamente.",
        },
        buttons: {
          add: "Adicionar WhatsApp",
          disconnect: "desconectar",
          delete: "excluir",
          info: "Info",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
        },
        toolTips: {
          copyApiToken: "Copiar Token de API",
          copied: "Copiado!",
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            content:
              "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
          },

          importingMessages: {
            title: "Mensagens estão sendo importadas",
          },

          importedMessages: {
            title: "Todas as mensagens foram importadas",
          },
        },
        table: {
          name: "Nome",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          actions: "Ações",
          session: "Sessão",
        },
      },
      whatsappInfoModal: {
        title: "Conexão info",
        datas: {
          platform: "Plataforma:",
          whatsAppUserName: "Nome de usuário no WhatsApp:",
          phoneNumber: "Número do celular:",
        },
      },
      whatsappModal: {
        title: {
          add: "Adicionar WhatsApp",
          edit: "Editar WhatsApp",
        },
        form: {
          settings: "Configurações",
          name: "Nome",
          default: "Padrão",
          dispatch: "Disparo",
          sendRating: "Enquete de satisfação",
          shouldUseChipNameOnAutomaticMessages: "Usar nome da conexão no título das mensagens automáticas",
          closeNewTicketsWithMessageOutsideApplication: "Encerrar conversas iniciadas por mensagens fora da aplicação",
          waitForBotInteraction: "Aguardar pela interação com o robô",
          sendSignature: "Enviar assinatura nas mensagens",
          greetingMessage: "Mensagem de saudação",
          awaitingMessage: "Mensagem de espera",
          farewellMessage: "Mensagem de despedida",
          absenceMessage: "Mensagem de ausência",
          declinedCallMessage: "Mensagem de Ligação Rejeitada",
          alertContactPhoneNumber: "Número de Celular para Alertas",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        info: {
          message: "Para adicionar dados dinâmicos, coloque eles entre parênteses: (nomecontato)",
          variablesText: "Variáveis disponíveis: ",
          variablesList: "(nomecontato), (cumprimento)",

          alertContactPhoneNumberMessage: "O número de celular somente é notificado sobre conversas pendentes fora do horário de atendimento",
        },
        tooltips: {
          waitForBotInteraction: "Conversas pendentes apenas são exibidas aos usuários depois do contato interagir com o bot. Administradores conseguem ver todas as conversas pendentes.",
          invalidAlertContactPhoneNumber: "Número inválido de celular para alertas!",
          alertContactPhoneNumberNotOnWhatsapp: "Número de celular para alertas não está registrado no WhatsApp!",
        },
      },
      qrCode: {
        message: "Leia o QR Code para iniciar a sessão",
        steps: {
          message1: "- Abra WhatsApp em seu telefone",
          message2: "- Pressione 'Menu' ou 'Configurações' e selecione 'Dispositivos Vinculados'",
          message3: "- Pressione 'Vincular Dispositivo'",
          message4: "- Mire seu telefone na tela para capturar o QR Code",
          message5: "- Considere trocar para o Tema Claro para melhor captura do QR Code",
        },
        loadingScreenStatus: {
          loadingChats: "Carregando suas conversas",
        },
      },
      contacts: {
        title: "Contatos",
        tooltips: {
          listLayout: "Layout em lista",
          cardLayout: "Layout em cards",
          contactsLayout: "Contatos",
          groupsLayout: "Grupos",
          channelsLayout: "Canais",
          blockedLayout: "Bloqueados",
          inactivatedLayout: "Contatos desativados",
        },
        messages: {
          outOf: "de",
          contacts: "contatos",
        },
        toasts: {
          groupsNotEnabled: "Grupos não estão ativados! Você pode ativá-los na página de Configurações.",
          channelsNotEnabled: "Canais não estão ativados! Você pode ativá-los na página de Configurações.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          blockTitle: "Bloquear contato",
          unblockTitle: "Desbloquear contato",
          blockMessage: "Tem certeza que deseja bloquear o contato?",
          unblockMessage: "Tem certeza que deseja desbloquear o contato?",

          activeTitle: "Ativar",
          inactiveTitle: "Desativar",
          activeMessage: "Tem certeza que deseja ativar este contato?",
          inactiveMessage: "Tem certeza que deseja desativar este contato? Você não poderá mais conversar com este contato.",

          importContacts: "Importar Contatos",
          importGroups: "Importar Grupos",
          importChannels: "Importar Canais",

          deleteTitle: "Deletar ",
          importTitlte: "Importar contatos",
          importMessagesTitle: "Importar Mensagens",

          deleteMessage: "Tem certeza que deseja deletar este contato? Todas as conversas relacionadas serão perdidas.",
          deleteGroupMessage: "Tem certeza que deseja deletar este grupo? A conversa será perdida.",
          deleteChannelMessage: "Tem certeza que deseja deletar este canal? A conversa será perdida.",
          importMessage: "Deseja importar todos os contatos do telefone?",
          importMessagesMessage: "Tem certeza que deseja importar todas as mensagens do telefone? Serão importadas as últimas 1000 mensagens dos últimos três meses para cada conversa em seu telefone.",

          form: { chip: "Chip" },

          openPrivateChat: { title: "Gostaria de iniciar uma conversa com" },
          reopenPrivateChat: { title: "Gostaria de reabrir a conversa?" },

          toast: {
            importingMessages: "As mensagens estão sendo importadas, pode continuar usando a aplicação enquanto fazemos o processo!",
            noConnectedChip: "Não há chips conectados!",
          },
        },
        accordion: {
          title: "Tags",
          title2: "Conexão Padrão",
          title3: "Mídias",
          noLabels: "Não há tags vinculadas ao contato",
          showAllMedias: "Mostrar tudo",
        },
        buttons: {
          importMessages: "Importar Mensagens",
          import: "Importar Contatos",
          add: "Adicionar Contato",
          exportCSV: "Exportar contatos via arquivo CSV",
          importCSV: "Importar contatos via arquivo CSV",
        },
        table: {
          name: "Nome",
          label: "Tag",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Ações",
        },
      },
      viewTicketModal: {
        title: "Conversa",

        buttons: {
          return: "Voltar",
          exportToPDF: "Exportar para PDF",
          openPrivateChat: "Conversar",
        },
      },
      exportCSVModal: {
        title: "Exportar contatos para arquivo CSV",
        form: {
          contactType: {
            label: "Tipo de Contato",
            nonBlockedOption: "Não Bloqueado",
            blockedOption: "Bloqueado",
            inactivatedOption: "Inativados",
            bothOption: "Todos",
          },
          separateContacts: {
            label: "Separar contatos não bloqueados dos bloqueados e inativados",
          },
        },
        buttons: { cancel: "Cancelar", export: "Exportar", },
        toasts: {
          error: "Não foi possível exportar os contatos. Tente novamente mais tarde!",
          noContactsToExport: "Não há contatos para exportar!",
        }
      },
      importCSVModal: {
        title: "Importar contatos via arquivo CSV",
        importSpan: "Clique aqui para fazer o upload do arquivo",
        fileName: "Arquivo a ser importado:",
        info1: "O arquivo CSV deve ter quatro colunas nomeadas ContactName, ContactNumber, ContactEmail e ContactConnectionName.",
        info2: "Também deve conter cabeçalho e todos os valores devem ser strings!",

        warning: "Algumas linhas do arquivo não possuem as variáveis obrigatórias preenchidas (Nome e Número do Contato), então elas foram desconsideradas!",
        buttons: {
          cancel: "Cancelar",
          import: "Importar",
        },
        toasts: {
          notSupportedFormat: "Apenas arquivos CSV são suportados!",
          notUploadedFile: "Nenhum arquivo CSV foi selecionado!",
          moreVariablesThanLimit: "O arquivo tem mais variáveis do que o esperado! Tente com outro arquivo.",
          lessVariablesThanLimit: "O arquivo tem menos variáveis do que o esperado! Tente com outro arquivo.",
          headerNotExpected: "O arquivo não possui as colunas obrigatórias.",
          noDefaultWhatsappConnected: "Você não possui uma conexão padrão ou ela não está conectada. Verifique a página de Conexões!",
          contactsImported: "Todos os contatos foram importados! Recarregue a página para ver seus novos contatos!",
        },
        importationWords: {
          imported: "Importados",
          of: "de",
          contacts: "contatos",
        },
        tooltips: {
          stopImportation: "Parar importação",
        },
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
        },
        toasts: {
          invalidCPF: "CPF Inválido!",
          nonexistentCPF: "CPF Inexistente!",
          invalidCNPJ: "CNPJ Inválido!",
          nonexistentCNPJ: "CNPJ Inexistente!",
          invalidBirthday: "Data de Aniversário não pode ser depois de hoje!",
        },
        form: {
          mainInfo: "Dados do contato",
          extraInfo: "Informações adicionais",
          whatsapp: "Conexão Padrão",
          name: "Nome",
          label: "Tag",
          number: "Número do Whatsapp",
          cpfCnpj: "CPF/CNPJ",
          birthday: "Aniversário",
          surname: "Apelido",
          serialNumber: "Número de Série",
          resaleSerialNumber: "Número da Revenda",
          clientCode: "Código do Cliente",
          email: "Email",
          extraName: "Nome do campo",
          extraValue: "Valor",
          isBot: "Não enviar mensagens automáticas",
          sendButtonsLists: "Não enviar botões e listas",
          fieldLabel: "Priorizar usuário",
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
          importMessages: "Importar Mensagens",
        },
        importingMessagesBaileys: "As últimas mensagens estão sendo importadas. Pode voltar ao trabalho enquanto fazemos a importação para você!",
        confirmationModal: {
          titleBaileys: "Gostaria de importar as mensagens?",
          messageBaileys: "Esta ação pode ser executada apenas uma única vez por contato. Algumas imagens, vídeos, áudios e arquivos podem não ser importados!",
        },
      },
      quickAnswersModal: {
        title: {
          add: "Adicionar Resposta Rápida",
          edit: "Editar Resposta Rápida",
        },
        form: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      categoryModal: {
        title: {
          add: "Adicionar categoria",
          edit: "Editar categoria",
        },
        form: {
          name: "Nome",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      subqueueModal: {
        title: {
          add: "Adicionar setor",
          edit: "Editar setor",
        },
        form: {
          name: "Nome",
          description: "Descrição",
          category: "Categoria",
          queue: "Departamento",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      queueModal: {
        title: {
          add: "Adicionar departamento",
          edit: "Editar departamento",
        },
        form: {
          isDefault: "Padrão",
          name: "Nome",
          color: "Cor",
          greetingMessage: "Mensagem de saudação",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      viewOnceFileContextModal: {
        title: {
          up: "Enviar Arquivo com Visualização Única",
        },
      },
      fileContextModal: {
        title: {
          up: "Enviar Arquivo",
        },
        tooltips: {
          cancel: "Cancelar",
          confirm: "Confirmar",
        },
        textfield: "Legenda",
        noVisualizationMessage: "Visualização prévia não disponível!",
        addFile: "Adicionar arquivo",
      },
      editMessageModal: {
        title: {
          up: "Editar Mensagem",
        },
        textfield: "Nova mensagem",
        toasts: {
          blankInput: "Não é possível editar uma mensagem para ela ficar em branco!",
        },
      },
      editMessageHistoryModal: {
        title: {
          up: "Histórico da Mensagem Editada",
        },
      },

      messagePropertiesModal: {
        title: { up: "Propriedades da Mensagem", },

        spans: {
          internalMessage: "Mensagem Interna",
          date: "Data",
          hour: "Hora",
          sentBy: {
            sentBy: "Enviado por",
            me: "Mim",
            contact: "Contato",
          },
          user: "Usuário",
          connection: "Conexão",
          connectionNumber: "Número da Conexão",
        },

        buttons: { cancel: "Cancelar", },

        tooltips: { copy: "Copiar", copied: "Copiado", },
      },

      forwardMessageContactModal: {
        title: {
          up: "Encaminhar Mensagem para Contato",
        },
        tooltips: {
          send: "Contato",
        },
        contactType: {
          group: "Grupo",
        },
        confirmationModal: {
          title: "Deseja encaminhar mensagens para o contato?",
          name: "Nome:",
          number: "Número:",
        },
      },

      eventDetailsModal: {
        title: {
          up: "Detalhes do Evento",
        },
        
        buttons: {
          startDateTime: "Agendar no calendário",
          location: "Ver no mapa",
          call: "Copiar link",
        },

        tooltips: {
          copied: "Copiado",
        },
      },

      pollVotesModal: {
        title: {
          up: "Votos da Enquete",
        },
        votes: "Votos",
      },
      multiVCardModal: {
        title: {
          up: "Ver contatos",
        },
        confirmationModal: {
          title: "Gostaria de conversar com",
        },
        toasts: {
          noNumberProvided: 
            "Não é possível iniciar uma conversa com um contato compartilhado sem um número válido de WhatsApp. Peça para o contato que enviou a mensagem enviar novamente!",
        },
      },
      imageToStickerModal: {
        title: {
          up: "Criar Figurinha",
        },
        buttons: {
          cancel: "Cancelar",
          send: "Enviar",
        },
      },
      editImageModal: {
        title: {
          up: "Editar Imagem",
        },
        buttons: {
          cancel: "Cancelar",
          copy: "Copiar",
          send: "Enviar",
          confirm: "Confirmar",
        },
      },
      mediasGalleryModal: {
        title: {
          up: "Galeria de mídias",
        },
        tabBar: {
          images: "Imagens",
          videos: "Vídeos",
          audios: "Áudios",
          others: "Outros",
        },
      },
      stickersModal: {
        title: {
          up: "Figurinhas",
        },
        tabBar: {
          dracula: "Drácula",
          penguin: "Pinguim",
          pumpkin: "Abóbora",
          bunny: "Coelho",
          bot: "Robô",
          elf: "Elfo",
        },
        toasts: {
          sendSticker: "Não foi possível enviar a figurinha, tente novamente mais tarde!",
        },
      },    
      groupParticipantsModal: {
        title: {
          up: "Participantes",
        },
      },
      newContactsModal: {
        title: {
          up: "Novos Contatos",
        },
        contactType: {
          group: "Grupo",
        },
      },
      shareContactModal: {
        title: {
          up: "Compartilhar contato",
        },
        buttons: {
          send: "Compartilhar",
        },
        confirmationModal: {
          title: "Deseja compartilhar o contato?",
        },
        toast: {
          limit: "Apenas 5 contatos podem ser encaminhados por vez!",
        }
      },
      cameraModal: {
        title: "Tirar foto",
        buttons: {
          cancel: "Cancelar",
        },
        messages: {
          noVideoInputs: "Seu dispositivo não possui nenhuma entrada de vídeo.",
        },
        toasts: {
          exceptionListingDevices: "Não foi possível listar as câmeras disponíveis. Verifique se a permissão da câmera está concedida. Se estiver, tente novamente mais tarde!",
        },
        tooltips: {
          turnOnCamera: "Ligar camera",
          turnOffCamera: "Desligar camera",
          snapCamera: "Tirar foto",
          copyPhoto: "Copiar foto",
          sendPhoto: "Enviar foto",
        },
      },
      pollModal: {
        title: "Enquete",
        fields: {
          allowMultipleAnswers: "Permitir múltiplas respostas?",
          titleLabel: "Título",
          optionA: "Opção A",
          optionB: "Opção B",
          newOptionLabel: "Nova opção",
        },
        schemaValidations: {
          tooShort: "Muito curto!",
          tooLong: "Muito longo!",
          required: "Obrigatório",
        },
        buttons: {
          send: "Enviar",
          cancel: "Cancelar",
          newExtraOption: "Nova opção",
        },
      },
      locationModal: {
        title: "Enviar localização",
        buttons: {
          currentLocation: "Atual",
          companyLocation: "Empresa",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
          admin: "Administrador",
          user: "Usuário",
          configEnabled: "Acesso às Configurações",
          forceAcceptLongAwaitingTimeTickets: "Forçar aceitar conversas com tempo longo de espera",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        defaultConnectionMustBeSelected: "Conexão padrão deve ser selecionada também no campo de 'Conexões'",
        emailAlreadyRegistered: "Email já existente!",
      },
      chat: {
        noTicketMessage: "Selecione um chat para começar a conversar.",
      },
      ticketsManager: {
        fields: {
          name: "Nome",
        },

        buttons: {
          newTicket: "Nova Conversa",
          newNote: "Nova Anotação",
          newInternalChat: "Nova Conversa Interna",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Departamentos",
      },
      ticketInfo: {
        composing: "Digitando...",
        recording: "Gravando áudio...",
        lastSeenToday: "Última vez visto hoje às",
        lastSeenAt: "Última vez visto às",
      },
      tickets: {
        toasts: {
          add: "Conversa salva com sucesso!",
          deleted: "A conversa que você estava foi deletada.",
        },
        notification: {
          message: "Mensagem de",
          internalChat: "Conversa Interna",
          expirationWarning: "Encerramento automático em @ minuto(s)",
          hasMessageFromPhone: "Conversando pelo aplicativo do WhatsApp",
        },
        tabs: {
          open: { title: "Ativos" },
          closed: { title: "Resolvidos" },
        },
        buttons: {
          showAll: "Mostrar todas as conversas",
          showAllOff: "Ocultar todas as conversas",
        },
      },
      editTicketModal: {
        title: "Editar Conversa",

        fieldLabelLabel: "Tag",
        fieldLabelPlaceholder: "Tag",
        labelInfo: "Você não possui nenhuma tag. Por favor, cadastre algumas tags antes de fechar este chat!",

        fieldQueueLabel: "Departamento",
        fieldQueuePlaceholder: "Selecione uma departamento",
        fieldSubqueueLabel: "Setor",
        fieldSubqueuePlaceholder: "Selecione uma setor",

        buttons: {
          ok: "Editar",
          cancel: "Cancelar",
        },
        toast: {
          subqueuesNotLoaded: "Checando o departamento escolhido. Tente novamente em alguns segundos!",
        },
      },

      searchMessageModal: {
        title: "Pesquisar Mensagem",
        searchPlaceholder: "Palavras-Chave",

        toasts: {
          smallLengthSearchParam: "Texto curto para a pesquisa, adicione mais informações para melhorar a busca!",
        },

        confirmationModal: {
          title: "Você gostaria de ir para o histórico da mensagem selecionada?",
        },
      },

      transferTicketModal: {
        title: "Transferir Conversa",
        fieldLabel: "Selecione um usuário",
        fieldQueueLabel: "Transferir para departamento",
        fieldConnectionLabel: "Transferir para conexão",
        fieldQueuePlaceholder: "Selecione um departamento",
        fieldConnectionPlaceholder: "Selecione uma conexão",
        fieldText: "Observação da transferência",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
        toast: {
          userNotSelected: "Você precisa selecionar um usuário antes de transferir uma conversa!",
        },
        listSubheaders: {
          onlineUsers: "Usuários Online",
          offlineUsers: "Usuários Offline",
        },
      },
      ticketsList: {
        timeAwaitDay: "dia",
        timeAwaitHour: "hora",
        timeAwaitMinute: "minuto",
        timeAwaitSecond: "segundo",
        timeAwaitNow: "Agora",
        pendingHeader: "Aguardando",
        groupHeader: "Grupos",
        channelHeader: "Canais",
        assignedHeader: "Atendendo",
        noTicketsTitle: "Nada aqui!",
        noTicketsMessage:
          "Nenhuma conversa encontrada com esse status ou termo pesquisado",
        connectionTitle: "Conexão que está sendo utilizada atualmente.",
        isReturned: "Conversa Retornada",
        isTransfered: "Conversa Transferida",
        buttons: {
          accept: "Aceitar",
        },
      },
      newTicketModal: {
        title: "Criar Conversa",
        fieldLabel: "Digite para pesquisar o contato",
        connection: "Conexão",
        add: "Adicionar",
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
          toogle: "Atribuir conversa a mim?",
        },
        toasts: {
          whatsappNotSelected: "Uma conexão deve ser selecionada!",
        },
      },
      newNoteModal: {
        title: "Criar Anotação",
        form: {
          name: "Nome",
          extraInfo: "Informações Adicionais",
          extraName: "Nome do Campo",
          extraValue: "Valor",
        },
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
          toogle: "Atribuir anotação a mim?",
          addExtraInfo: "Adicionar Informação",
        },
      },
      newInternalChatModal: {
        title: "Criar Conversa Interna",

        form: {
          name: "Nome",
          user: "Usuário",
        },

        listSubheaders: {
          onlineUsers: "Usuários Online",
          offlineUsers: "Usuários Offline",
        },

        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },

        toasts: {
          chatNameInfo: "É necessário adicionar um nome com ao menos 3 caracteres à conversa!",
          selectedUserIdInfo: "É necessário selecionar um usuário para conversar!",
        },
      },
      newOpenAiModal: {
        title: "Criar Bot Chat",
        form: {
          name: "Nome",
        },
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
          toogle: "Atribuir conversa com bot a mim?",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          schedule: "Agenda",
          general: "Geral",
          attendants: "Atendentes",
          connections: "Conexões",
          tickets: "Conversas",
          groupsDashboard: "Grupos",
          ratings: "Avaliações",
          internalChats: "Conversas Internas",
          ticketsOpen: "Ativas",
          ticketsClosed: "Encerradas Hoje",
          ticketsSearch: "Buscar",
          contacts: "Contatos",
          labels: "Tags",
          messages: "Mensagens",
          quickAnswers: "Respostas Rápidas",
          starredMessages: "Favoritadas",
          businessHours: "Horário de Serviço",
          screening: "Triagem",
          queues: "Departamentos",
          categories: "Categorias",
          subqueues: "Setores",
          flow: "Fluxo",
          administration: "Administração",
          users: "Usuários",
          profile: "Organização",
          dispatch: "Disparo",
          managerDispatch: "Gerenciador",
          feedbackDispatch: "Feedback",
          settings: "Configurações",
          releases: "Novidades",
        },
        appBar: {
          sound: "Alertas",
          language: "Idioma",
          theme: "Tema",
          user: {
            profile: "Perfil",
            logout: "Sair",  
          },
        },
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
        notificationsNotSupported: "Notificações não são suportadas neste navegador!",
      },
      businessHours: {
        title: {
          title1: "Horário de Serviço",
        },
        buttons: {
          save: "Salvar",
        },
        assets: {
          to: "às",
          personalizedMessage: "Mensagem Personalizada",
          businessHour: "Expediente",
          intervalHour: "Intervalo",
        },
        checkbox: {
          open: "Aberto",
          closed: "Fechado",
        },
        weekdays: {
          sunday: "Domingo",
          monday: "Segunda",
          tuesday: "Terça",
          wednesday: "Quarta",
          thursday: "Quinta",
          friday: "Sexta",
          saturday: "Sábado",
        },
      },
      categories: {
        title: "Categorias",
        table: {
          name: "Nome",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar categoria",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! As conversas dessa categoria continuarão existindo, mas não terão mais nenhuma categoria atribuída.",
        },
      },
      subqueues: {
        title: "Setores",
        table: {
          name: "Nome",
          description: "Descrição",
          actions: "Ações",
          category: "Categoria",
          queue: "Departamento",
        },
        buttons: {
          add: "Adicionar setor",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! As conversas desse setor continuarão existindo, mas não terão mais nenhum setor atribuído.",
        },
      },
      schedule: {
        title: "Agenda",
        buttons: {
          add: "Adicionar Evento",
        },
        messages: {
          months: {
            january: 'Janeiro',
            february: 'Fevereiro',
            march: 'Março',
            april: 'Abril',
            may: 'Maio',
            june: 'Junho',
            july: 'Julho',
            august: 'Agosto',
            september: 'Setembro',
            october: 'Outubro',
            november: 'Novembro',
            december: 'Dezembro',
          },
          monthsShort: {
            jan: 'Jan',
            feb: 'Fev',
            mar: 'Mar',
            apr: 'Abr',
            may: 'Mai',
            jun: 'Jun',
            jul: 'Jul',
            aug: 'Ago',
            sep: 'Set',
            oct: 'Out',
            nov: 'Nov',
            dec: 'Dez'
          },
          weekdays: {
            sunday: 'Domingo',
            monday: 'Segunda',
            tuesday: 'Terça',
            wednesday: 'Quarta',
            thursday: 'Quinta',
            friday: 'Sexta',
            saturday: 'Sábado',
          },
          weekdaysShort: {
            sun: 'Dom',
            mon: 'Seg',
            tue: 'Ter',
            wed: 'Qua',
            thu: 'Qui',
            fri: 'Sex',
            sat: 'Sáb',
          },
          buttons: {
            month: 'Mês',
            week: 'Semana',
            day: 'Dia',
            agenda: 'Agenda',
            work_week: 'Dias Úteis',

            today: 'Hoje',
            next: 'Próximo',
            previous: 'Anterior',

            date: 'Date',
            time: 'Hora',
            event: 'Evento',
            events: 'Eventos',

            noEventsInRange: 'Não há eventos neste intervalo',
            allDay: 'O Dia Todo',
          },
        },
      },
      flow: {
        title: "Fluxo",
        counts: {
          queues: "Departamentos",
          categories: "Categorias",
          subqueues: "Setores",
        },
        tree: {
          chip: "Chip",
          queue: "Departamento",
          category: "Categoria",
          subqueue: "Setor",
        },
        messages: {
          noWhatsapp: "Você ainda não adicionou nenhuma conexão!",
          noFlow: "Você ainda não adicionou nenhum departamento, categoria e setor!",
        },
        speedDial: {
          newDepartment: "Novo departamento",
          newCategory: "Nova categoria",
          newSector: "Novo setor",
        },
      },
      queues: {
        title: "Departamentos",
        table: {
          isDefault: "Padrão",
          name: "Nome",
          color: "Cor",
          greeting: "Mensagem de saudação",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar departamento",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! As conversas desse departamento continuarão existindo, mas não terão mais nenhum departamento atribuído.",
        },
      },
      queueSelect: {
        inputLabel: "Departamentos",
      },
      whatsappSelect: {
        inputLabel: "Conexões",
      },
      userSelect: {
        inputLabel: "Usuários",
      },
      contactSelect: {
        inputLabel: "Contatos",
        searchTextPlaceholder: "Pesquisar...",
      },
      categorySelect: {
        inputLabel: "Categorias",
      },
      subqueueSelect: {
        inputLabel: "Setores",
      },
      connectionSelect: {
        inputLabel: "Conexão",
      },
      labelSelect: {
        inputLabel: "Tag",
      },
      ticketTypeSelect: {
        inputLabel: "Plataforma",
      },
      starredMessages: {
        title: "Favoritadas",
        loadMoreButton: "Buscar Mais",
        table: {
          actions: "Ações",
          message: "Mensagem",
          user: "Usuário",
        },
        confirmationModal: {
          title: "Gostaria de desfavoritar a mensagem?",
        },
      },
      quickAnswers: {
        title: "Respostas Rápidas",
        table: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Resposta Rápida",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Resposta Rápida: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      versionModal: {
        title: {
          up: "Versão Atual",
        },
        tooltips: {
          copy: "Copiar",
          copied: "Copiado",
        },
        spans: {
          year: "Ano",
          month: "Mês",
          day: "Dia",
          hour: "Hora",
          minute: "Minuto",
          group: "Grupo",
          tenant: "Tenant",
        },
      },
      labelModal: {
        title: {
          edit: "Editar tag",
          add: "Adicionar tag",
        },
        form: {
          name: "Nome",
          color: "Cor",
          description: "Descrição",
        },
        buttons: {
          cancel: "Cancelar",
          okEdit: "Salvar",
          okAdd: "Adicionar",
        },
      },
      labels: {
        title: "Tags",
        searchPlaceholder: "Pesquisar...",
        buttons: {
          add: "Adicionar Tag",
        },
        table: {
          name: "Nome",
          color: "Cor",
          description: "Descrição",
          actions: "Ações",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida! Os contatos dessa tag continuarão existindo, mas não terão mais nenhuma tag atribuída.",
        },
      },

      dispatchManager: {
        title: "Disparo",
        searchPlaceholder: "Pesquisar ...",

        buttons: {
          add: "Adicionar Disparo",
        },

        validation: {
          dispatchAlreadySent: "Não foi possível concluir a ação porque pelo menos um disparo já foi enviado!",
        },

        table: {
          name: "Nome",
          startDatetime: "Início",
          recurrence: "Recorrência",
          status: "Status",
          connection: "Conexão",
          actions: "Ações",

          recurrenceValues: {
            once: "Único",
            weekly: "Semanal",
            monthly: "Mensal",
          },

          statusValues: {
            cancelled: "Cancelado",
            concluded: "Concluído",
            inProgress: "Em progresso",
            pending: "Pendente",
          },
        },

        confirmationModals: {
          cancelTitle: "Cancelar Disparo?",
          deleteTitle: "Excluir Disparo?",
          uncancelTitle: "Descancelar Disparo?",
        },
      },

      buyDispatch: {
        message: "Disparo não está ativado em sua conta. Clique no botão para saber mais sobre a funcionalidade e contratá-la!",

        buttons: {
          buy: "Contratar",
        },
      },

      dispatchManagerModal: {
        title: {
          add: "Adicionar disparo",
          edit: "Editar disparo",
        },

        buttons: {
          cancel: "Cancelar",
          okAdd: "Adicionar",
          okEdit: "Salvar",
        },

        form: {
          name: "Nome",
          datetimeToDispatch: "Início",
          recurrence: "Recorrência",
          connection: "Conexão",

          recurrenceValues: {
            once: "Único",
            weekly:"Semanal",
            monthly: "Mensal",
          },

          buttons: {
            contacts: "Contatos",
            messages: "Mensagens",
          },
        },

        validations: {
          nameSize: "Nome do disparo deve conter entre 3 e 50 caracteres!",
          datetimeToDispatchEmpty: "Selecione uma data e horário para disparar mensagens!",
          datetimeToDispatchInvalid: "A data e horário de início do disparo das mensagens deve ser pelo menos uma hora a mais do que agora!",
          recurrenceInvalid: "Selecione uma recorrência para disparar mensagens!",
          connectionInvalid: "Selecione um chip para disparar mensagens!",

          message1Size: "Mensagem 1 deve ser preenchida e ter entre 3 e 500 caracteres!",
          otherMessagesSize: "Mensagens preenchidas devem conter entre 3 e 500 caracteres!",

          contactsSize: "Pelo menos 3 contatos devem ser selecionados!",
        },
      },

      dispatchSendingsModal: {
        title: "Envios do Disparo",
        dispatch: "Disparo:",

        confirmationModals: {
          cancelTitle: "Cancelar Envio do Disparo?",
          uncancelTitle: "Descancel Envio do Disparo?",
        },

        validation: {
          dispatchAlreadySent: "Não foi possível concluir a ação porque o disparo já foi enviado!",
        },

        filters: {
          nameNumber: "Nome ou Número",
        },

        table: {
          contactName: "Contato",
          contactNumber: "Número",
          message: "Mensagem",
          status: "Status",
          errorDescription: "Erro",
          lastUpdateAt: "Última Atualização",
          actions: "Ações",

          statusValues: {
            cancelled: "Cancelado",
            concluded: "Concluído",
            error: "Erro",
            inProgress: "Em progresso",
            pending: "Pendente",
          },
        },

        buttons: {
          close: "Fechar",
        },
      },

      dispatchSendingMessageModal: {
        title: "Mensagem",
      },

      dispatchSendingErrorDescriptionModal: {
        title: "Descrição do Erro",
      },

      ratingsSelect: {
        inputRating: "Avaliações",
      },

      dispatchStatusSelect: {
        inputStatus: "Status",

        statusValues: {
          cancelled: "Cancelado",
          concluded: "Concluído",
          error: "Erro",
          inProgress: "Em progresso",
          pending: "Pendente",
        },
      },

      dispatchManagerMessagesModal: {
        title: "Mensagens",

        buttons: {
          cancel: "Cancelar",
          confirm: "Confirmar",
        },

        form: {
          message1: "Mensagem 1",
          message2: "Mensagem 2",
          message3: "Mensagem 3",
        },
      },

      dispatchManagerContactsModal: {
        title: "Contatos",

        filters: {
          nameNumber: "Nome ou Número",
          birthday: "Aniversário",
          label: "Tag",
        },

        buttons: {
          additionalContacts: "Contatos Adicionais",
          cancel: "Cancelar",
          confirm: "Confirmar",
        },
      },

      dispatchManagerAdditionalContactsModal: {
        title: "Contatos Adicionais",

        invalidContacts: "Contatos Inválidos",

        form: {
          additionalContacts: "Contatos Adicionais",
        },

        validations: {
          invalidAdditionalContacts: "Alguns números inválidos foram digitados!",
        },

        buttons: {
          cancel: "Cancelar",
          validate: "Validar",
        },
      },

      transferList: {
        choices: "Escolhas",
        chosen: "Escolhidos",
        selected: "selecionados",
      },

      users: {
        title: "Usuários",
        searchPlaceholder: "Pesquisar ...",
        usedUsersCount: {
          outOf: "de",
          users: "usuário(s)",
        },
        tooltips: {
          listLayout: "Layout em listas",
          cardLayout: "Layout em cards",
          bothLayout: "Todos",
          adminsLayout: "Administradores",
          usersLayout: "Usuários",
          admin: "Administrador",
        },
        table: {
          name: "Nome",
          email: "Email",
          profile: "Perfil",
          whatsapp: "Conexão Padrão",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar usuário",
        },
        toasts: {
          notDeleted: "Você não pode excluir seu próprio usuário!",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. As conversas abertas deste usuário serão movidas para a fila.",
        },
      },
      settings: {
        success: "Configurações salvas com sucesso!",
        title: "Configurações",
        settings: {
          general: "Geral",
          privacy: "Privacidade",
          schedule: "Horários",
          sendings: "Envios",
          service: "Atendimento",
          options: {
            enabled: "Ativado",
            disabled: "Desativado",

            lessRecent: "Menos Recentes",
            moreRecent: "Mais Recentes",

            never: "Nunca",

            chats: "Conversas",
            groups: "Grupos",
            open: "Abertos",
            pending: "Pendentes",
            both: "Ambos",
          },
          ticketsListSortMethod: {
            name: "Ordenação das listas de conversas",
          },
          ticketsDynamicSorting: {
            name: "Ordenação dinâmica de conversas",
            tooltip: "As conversas são ordenadas pela mensagem mais recente",
          },
          enterIsSendMobile: {
            name: "Enviar mensagens com enter (somente em celulares)",
          },
          fetchUnreadMessagesAfterConnecting: {
            name: "Buscar mensagens não lidas após conexão do chip",
          },
          userPermissionsByChip: {
            name: "Permissões de usuários por chip",
          },
          allowDefaultQueue: {
            name: "Permitir departamento padrão",
          },
          allowGroups: {
            name: "Permitir grupos",
          },
          allowChannels: {
            name: "Permitir canais",
          },
          timezone: {
            name: "Fuso horário",
          },
          missedCalls: {
            name: "Criar chamados para ligações perdidas",
          },
          linkPreview: {
            name: "Enviar visualização de links",
          },
          notificationSoundForInternalChats: {
            name: "Som de notificação para conversas internas",
          },
          mergeMessagesFromDifferentConnectionsSetting: {
            name: "Ver mensagens de todas as conexões",
          },
          showOnlyMessagesRelatedToUsersChats: {
            name: "Exibir apenas mensagens vinculadas às conversas dos usuários",
            tooltip: "Válido apenas para usuários não administradores",
          },
          showMessagesRelatedToUserQueues: {
            name: "Exibir mensagens vinculadas aos departamentos dos usuários",
            tooltip: "Apenas funciona se a configuração 'Exibir apenas mensagens vinculadas às conversas dos usuários' estiver desabilitada",
          },
          viewPresenceUpdate: {
            name: "Exibir presença nas conversas",
            tooltip: "A opção deve estar ativada no WhatsApp de seu celular. Também, esta configuração não funciona para grupos e canais",
          },
          markMessagesAsRead: {
            name: "Marcar mensagens como lidas no aplicativo",
          },
          requireTagOnContacts: {
            name: "Tag obrigatória nos contatos",
          },
          sendSignatureOnMessages: {
            name: "Enviar assinatura nas mensagens",
          },
          adminCanViewOtherChats: {
            name: "Abrir a conversa se já estiver ativa com outro usuário",
          },
          automaticallyRejectCalls: {
            name: "Rejeitar ligações automaticamente",
            tooltip: "Quando ativada, uma mensagem é automaticamente enviada ao contato",
          },
          apiToken: {
            name: "Token da API",
            copyTooltip: "Copiar",
            copiedTooltip: "Copiado",
          },
          prioritizeUsers: {
            name: "Priorizar atendentes para conversar com contato",
          },
          prioritizeUsersTime: {
            name: "Tempo de prioridade dos atendentes (minutos)",
          },
          allowTransferTicketsToOfflineUsers: {
            name: "Permitir transferir conversas para usuários offline",
          },
          allowResolveTicketWithoutGoodBye: {
            name: "Permitir encerrar conversas sem enviar mensagem de despedida",
          },
          adminsPendingTicketsControl: {
            name: "Administradores controlam conversas pendentes",
          },
          chatExpiration: {
            name: "Tempo de expiração do chat - Em aguardo",
          },
          chatExpirationOpened: {
            name: "Tempo de expiração do chat - Em atendimento",
          },
          sendRatingPollOnChatExpiration: {
            name: "Enviar enquete de satisfação para conversas expiradas - Em aguardo",
          },
          sendRatingPollOnChatExpirationOpened: {
            name: "Enviar enquete de satisfação para conversas expiradas - Em atendimento",
          },
          sendListsButtons: {
            name: "Enviar listas e botões",
          },
          awaitingTime: {
            name: "Tempo de espera (minutos)",
          },
          attendanceTime: {
            name: "Tempo de atendimento (minutos)",
          },
          forceAcceptLongAwaitingTimeTickets: {
            name: "Forçar aceitar conversas com tempo longo de espera",
          },
          userCreation: {
            name: "Criação de usuário",
          },
          sendProtocolMessage: {
            name: "Enviar mensagem de protocolo",
          },
          sendTransferMessage:{
            name: "Enviar mensagem de transferência",
          },
          sendWaitingAttendant: {
            name: "Enviar mensagem de espera por resposta inválida",
          },
          sendNotificationsTo: {
            name: "Enviar notificações para",
          },
          sendNotifications: {
            name: "Enviar notificações de chats",
          },
        },
      },
      location: {
        errors: {
          permissionNotGranted: "A permissão de Geolocalização não está habilitada!",
          positionUnavaible: "Sua posição atual não está disponível no momento, tente novamente mais tarde!",
          timeout: "Não foi possível identificar sua posição atual, tente novamente mais tarde!",
        },
      },
      profile: {
        success: "Perfil atualizado com sucesso!",
        title: "Organização",
        tooltips: {
          turnOnGeolocation: "Ativar Geolocalização",
          turnOffGeolocation: "Desativar Geolocalização",
        },
        labels: {
          name: "Nome da Empresa",
          description: "Descrição da Empresa",
          logo: "Logotipo",
          logoField: "Clique abaixo para adicionar/substituir o logo",
          sendLogoAsSticker: "Enviar logo como figurinha",
          latitude: "Latitude",
          longitude: "Longitude",
          accuracy: "Precisão",
        },
        buttons: {
          save: "Salvar",
        },
      },

      messageNotAvailable: {
        deletedFile: "Este arquivo não está mais disponível, mas você pode vê-lo em seu celular via WhatsApp!",
      },

      messagesList: {
        header: {
          assignedTo: "Atribuído à:",
          buttons: {
            return: "Retornar",
            close: "Fechar",
            resolve: "Encerrar",
            resolveWithoutFarewell: "Encerrar sem despedida",
            reopen: "Reabrir",
            accept: "Aceitar",
          },
        },
        toast: {
          forwardMessage: {
            limit: "Apenas 20 mensagens podem ser encaminhadas por vez!",
            noSelectedMessages: "É necessário selecionar pelo menos 1 mensagem para encaminhar!",
          },
          closeTicket: {
            whatsappQueuesNotLoaded: "Carregando os dados da conversa antes de encerrar. Tente novamente em alguns segundos!",
          },
        },
        confirmationModal: {
          openPrivateChat: {
            title: "Gostaria de iniciar uma conversa com",
          },
          resendMessage: {
            title: "Gostaria de reenviar a mensagem?"
          },
        },
      },

      messageBodyWrapper: {
        readMore: "Ler Mais",
        readLess: "Ler Menos",
      },

      shareMessage: {
        notSupport: "Seu navegador não suporta compartilhar arquivos!",
        errorSharing: "Não foi possível compartilhar o arquivo! Tente novamente mais tarde.",
      },

      messagesListChatView: {
        noMessagesText: "Sem mensagens na conversa...",
      },

      messagesInput: {
        placeholderOpen: "Digite uma mensagem ou tecle ''/'' para utilizar as respostas rápidas cadastrada",
        placeholderOpenMobile: "Digite uma mensagem",
        placeholderClosed: "Reabra ou aceite essa conversa para enviar uma mensagem.",
        placeholderBlockedContact: "Você não pode enviar mensagens porque o contato está bloqueado!",
        placeholderInactiveContact: "Você não pode enviar mensagens porque o contato está inativo!",
        placeholderChannelContact: "Você não pode enviar mensagens para conversas de canais!",
        placeholderReadOnlyGroup: "Você não pode enviar mensagens porque não faz mais parte deste grupo.",
        signMessage: "Assinar",
        view: "Visualizar",
        download: "Baixar",
        forwardedMessage: "Mensagem encaminhada",
        editedMessage: "Editada",
        scrollToSeeMessages: "Role para ver novas mensagens",

        micAudioConditions: {
          permissionDeniedBySystem: "O acesso ao microfone foi negado pelo sistema de seu dispositivo!",
          lostConnection: "O navegador perdeu conexão com seu microfone. Tente novamente mais tarde!",
          micNotEnabled: "Microfone não habilitado!",
          micNotDetected: "Nenhum microfone foi detectado!",
        },

        toasts: {
          sendGif: "Não foi possível enviar o GIF, tente novamente mais tarde!",
          audioAccessNotAllowed: "O navegador não está habilitado para acessar seu microfone. Por favor, habilite a permissão antes de enviar áudios!",
          onlyImageAllowed: "Apenas imagens são permitidas para serem enviadas como figurinhas!",
          onlyImageAndVideoAllowed: "Apenas imagens e vídeos são permitidos para serem enviados como visualização única!",
        },

        tooltips: {
          emojis: "Selecionar emojis",
          record: "Gravar áudio",
          internalMessage: "Mensagem interna",
          moreVert: "Mais opções",
          attach: "Anexar arquivo",
          viewOnce: "Visualização única",
          poll: "Enquete",
          location: "Localização",
          takePhoto: "Tirar foto",
          vcard: "Compartilhar contato",
          send: "Enviar mensagem",
          cancel: "Cancelar",
          confirm: "Confirmar",
          stickers: "Selecionar figurinhas",
          createStickers: "Criar figurinhas",
          messageNotSent: "Mensagem não enviada",
        },
      },
      viewOnceRepliedMessage: {
        photo: "Foto",
        video: "Vídeo",
      },
      audioComponent: {
        accordionTitle: "Transcrição",
        tooltips: {
          copy: "Copiar",
          copied: "Copiado",
        },
      },
      contactDrawer: {
        header: "Dados do contato",
        buttons: {
          edit: "Editar contato",
          editGroup: "Editar grupo",
          participants: "Participantes",
        },
        extraInfo: "Outras informações",
        tooltips: {
          copy: "Copiar",
          copied: "Copiado!",
        },
      },
      ticketOptionsMenu: {
        edit: "Editar",
        block: "Bloquear",
        unblock: "Desbloquear",
        delete: "Deletar",
        note: "Anotação",
        transfer: "Transferir",
        searchMessage: "Pesquisar",
        confirmationModal: {
          title: "Deletar a conversa #",
          titleFrom: "do contato ",
          message:
            "Atenção! Todas as mensagens relacionadas a conversa serão perdidas.",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      myProfile: {
        confirmationModal: {
          deleteTitle: "Remover logotipo",
          deleteMessage: "Tem certeza que deseja remover o logotipo da empresa?"
        },
      },
      ticketOptionsContextMenu: {
        accept: "Aceitar",
        view: "Ver",
        markAsUnread: "Marcar como não lido",
        markAsRead: "Marcar como lido",
        transfer: "Transferir",
        edit: "Editar",
        return: "Retornar",
        reopen: "Reabrir",
        resolve: "Resolver",
        close: "Fechar Menu",
      },

      phoneNumberOptionsMenu: {
        chat: "Conversar com o Número",
        copy: "Copiar Número de Celular",
        close: "Fechar",
        confirmationModal: {
          title: "Gostaria de conversar com o número?",
          fields: {
            connection: "Conexão",
          },
        },
      },

      messageOptionsMenu: {
        transcript: "Transcrever",
        resend: "Reenviar",
        delete: "Deletar",
        reply: "Responder",
        forward: "Encaminhar",
        edit: "Editar",
        copy: "Copiar",
        star: "Favoritar",
        unstar: "Desfavoritar",
        speech: "Fala",
        properties: "Propriedades",
        close: "Fechar",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      backendErrors: {
        GENERAL_ERROR: "Não foi possível fazer isso no momento. Tente novamente mais tarde!",
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
        ERR_NO_DEF_WAPP_FOUND:
          "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONNECTION:
          "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_INVALID_CREDENTIALS:
          "Erro de autenticação. Por favor, tente novamente.",
        ERR_SENDING_WAPP_MSG:
          "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_WAPP_LIMIT_REACHED: "Limite de chips atingido! Entre em contato com o suporte para alterar o seu plano.",
        ERR_USER_LIMIT_REACHED: "Limite de usuários atingido! Entre em contato com o suporte para alterar o seu plano.",
        ERR_WAPP_NAME_ALREADY_USED: "Nome de WhatsApp já em uso.",
        ERR_WAPP_SINGLE_QUEUE: "É necessário ter no mínimo dois departamentos ou nenhum.",
        ERR_OTHER_OPEN_TICKET: "Já existe uma conversa aberta para este contato.",
        ERR_OTHER_OPEN_TICKET_USER: "Conversa está atribuída ao usuário:",
        ERR_OTHER_OPEN_TICKET_QUEUE:
          "Conversa está atribuída ao departamento:",
        ERR_OTHER_OPEN_TICKET_EXCEPTION:
          "Já existe uma conversa aberta com este contato. Verifique a aba Atendendo e Pendente nas Conversas Ativas!",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
        ERR_USER_ALREADY_LOGIN: "Usuário já conectado em outro computador",
        ERR_SERVER_MAINTANCE: "O servidor está em manutenção, por favor aguarde alguns minutos",
        ERR_INVALID_TOKEN: "Token inválido. Um novo será criado na próxima requisição",
        ERR_USER_CREATION_DISABLED: "A criação do usuário foi desabilitada pelo administrador.",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhuma conversa encontrada com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TRANSFERENCE_OBSERVATION_MESSAGE: "Erro ao criar mensagem de observação de transferência no banco de dados. Tente novamente mais tarde!",
        ERR_CREATING_INTERNAL_MESSAGE: "Erro ao criar mensagem interna no banco de dados. Tente novamente mais tarde!",
        ERR_CREATING_TICKET: "Erro ao criar conversa no banco de dados. Tente novamente mais tarde!",
        ERR_MYPROFILE_INVALID_NAME: "Nome do perfil inválido",
        ERR_UPDATE_MYPROFILE: "Não foi possível atualizar o perfil. Tente novamente mais tarde!",
        ERR_FETCH_WAPP_MSG:
          "Erro ao buscar a mensagem no WhatsApp, talvez ela seja muito antiga ou tenha sido excluída pelo contato.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED:
          "A mensagem de saudação é obrigatório quando há departamentos.",
        ERR_FILE_SIZE_UPLOAD:
          "Não é possível enviar arquivos com mais de 15MB.",
        ERR_FILE_NOT_SUPPORTED:
          "Este tipo de arquivo não é suportado!",
        ERR_REACHED_MAIN_QUEUES_LIMIT: 
          "Não é possível cadastrar mais do que 3 departamentos!",
        LABEL_NOT_SELECTED:
          "Tag não informada!",
        QUEUE_NOT_SELECTED:
          "Departamento não informado!",
        SUBQUEUE_NOT_SELECTED:
          "Setor não informado!",
        DASHBOARD_INITIAL_FINAL_DATE_CLEAN:
          "Você não pode limpar a data inicial e a data final!",
        DASHBOARD_INITIAL_DATE_AFTER_FINAL_DATE:
          "A data inicial não pode ser depois da data final!",
        BUSINESS_HOURS_INITIAL_HOUR_EQUAL_FINAL_HOUR:
          "A hora inicial não pode ser igual à hora final se a empresa está aberta! Verifique se todos os horários foram preenchidos corretamente.",
        BUSINESS_HOURS_INITIAL_HOUR_AFTER_FINAL_HOUR:
          "A hora inicial não pode ser depois da hora final! Verifique se todos os horários foram preenchidos corretamente.",
        BUSINESS_HOURS_PERSONALIZED_MESSAGES_EMPTY:
          "Mensagem Personalizada deve ter mais de 3 caracteres quando ativada! Verifique se todas as mensagens foram preenchidas corretamente.",
        INTERVAL_HOURS_EQUALS_AFTER_FINAL_HOUR:
          "Hora Inicial de Intervalo não pode ser igual ou depois da Hora Final do Intervalo.",
        INTERVAL_HOURS_NOT_BETWEEN_BUSINESS_HOURS:
          "Horário de Intervalo não está entre o Horário de Serviço.",
        SERIAL_NUMBER_NOT_INFORMED:
          "Número de Série não informado!",
        RESALE_SERIAL_NUMBER_NOT_INFORMED:
          "Número da Revenda não informado!",
        ERR_STAR_MSG:
          "Erro ao favoritar mensagem!",
        ERR_UNSTAR_MSG:
          "Erro ao desfavoritar mensagem! Tente novamenta mais tarde.",
        ERR_EMAIL_NOT_FOUND: 
          "Email não cadastrado!",
        ERR_EMAIL_ALREADY_EXISTS:
          "Já existe um usuário com este email!",
        ERR_LABEL_NAME_ALREADY_EXISTS:
          "Este nome já está em uso, escolha outro.",
        ERR_SUBQUEUE_INVALID_DESCRIPTION:
          "Informe uma descrição para a subfila!",
        ERR_INFO_NOT_FOUND:
          "Não foi possível buscar as informações. Provavelmente a mensagem não consta no celular!",
        ERR_PAYMENT_REQUIRED:
          "Há boletos vencidos para sua conta. Por favor, contate nosso suporte para mais informações!",
        ERR_SERVER_IN_MAINTENANCE:
          "O servidor está em manutenção no momento, por favor, tente novamente em alguns minutos!",
        ERR_SERVER_MIGRATION:
          "Sua conta está em processo de migração de servidor, por favor, tente novamente em alguns minutos!",
        EDIT_MESSAGE_LIMIT_MINUTES_REACHED:
          "Não é possível editar mensagens de texto após 15 minutos de seu envio!",
        EDIT_MESSAGE_INTERNAL_ERROR:
          "Houve um erro enquanto a mensagem estava sendo editada. Tente novamente mais tarde!",
        NO_MESSAGE_FOUND_BY_ID:
          "Nenhuma mensagem encontrada com o ID fornecido!",
        ERR_COMPANY_LOCATION_NOT_REGISTERED:
          "Localização não está informada no perfil do estabelecimento!",
        ERR_FORCE_ACCEPT_LONG_AWAITING_TIME_TICKETS: 
          "Há conversas com tempo de espera longo. É necessário aceitá-las primeiro!",
        ERR_OPENAI_TRANSCRIBE_AUDIO_NOT_FOUND:  "Áudio não encontrado para ser transcrevido! Tente novamente mais tarde.",
        ERR_TRANSCRIBE_AUDIO: "Não foi possível transcrever o áudio. Tente novamente mais tarde!",
        ERR_INVALID_POLL_DATA:
          "Dados inválidos para enquete! Verifique se todos os campos obrigatórios foram preenchidos!",
        ERR_INVALID_POLL_MESSAGE:
          "Enquete não encontrada! Tente novamente mais tarde.",
        ERR_INVALID_CPF:
          "CPF Inexistente!",
        ERR_INVALID_CNPJ:
          "CNPJ Inexistente!",
        ERR_INVALID_BIRTHDAY:
          "Data de Aniversário não pode ser depois de hoje!",
        ERR_CATEGORY_NAME_ALREADY_EXISTS:
          "Nome duplicado de categoria!",
        ERR_QUEUE_NAME_ALREADY_EXISTS:
          "Nome duplicado de departamento!",
        ERR_UNBLOCK_CONTACT:
          "Um erro ocorreu ao desbloquear o contato. Tente novamente mais tarde!",
        ERR_BLOCK_CONTACT:
          "Um erro ocorreu ao bloquear o contato. Tente novamente mais tarde!",
        ERR_SETTING_NOT_FOUND: "Configuração não encontrada!",
        ERR_GROUP_NOTIFICATION: "🤔 Não foi possível lidar com a notificação do grupo!",
        ERR_SENDING_WAPP_REACTION: "Erro ao enviar reação do WhatsApp. Verifique a página de conexões.",
        FAILED_TO_JOIN_GROUP_BY_INVITE_CODE: "Falha ao entrar no grupo. Peça outro convite ao administrador do grupo!",
        ERR_SEND_POLL_VOTE: "Não foi possível votar na enquete, tente novamente mais tarde!",
        ERR_MARK_CHAT_READ_UNREAD: "Não foi possível marcar a conversa como lida/não lida. Tente novamente mais tarde!",
        ERR_IMPORT_MESSAGES: "Não foi possível importar as mensagens. Tente novamente mais tarde!",
        ERR_SENDING_LIST_RESPONSE_MSG: "Erro ao enviar mensagem de Resposta de Lista do WhatsApp. Verifique a página de conexões!",
        ERR_SENDING_BUTTON_RESPONSE_MSG: "Erro ao enviar mensagem de Resposta de Botão do WhatsApp. Verifique a página de conexões!",
        ERR_USER_NOT_ALLOWED: "Usuário sem permissão para efetuar a operação!",
        NO_PERMISSION_UPDATE_GROUP_NAME: "Você não tem permissão para alterar o nome do grupo. Verifique com o administrador do grupo!",
        ERR_FORWARD_MESSAGE: "Não foi possível encaminhar a mensagem. Tente novamente mais tarde!",
        ERR_MARK_INTERNAL_CHAT_MESSAGES_AS_READ: "Não foi possível marcar as mensagens como lidas!",
        ERR_CREATING_PHONE_NUMBER_MESSAGE_TICKET: "Não foi possível criar conversa com o número fornecido na mensagem!",
        ERR_FETCHING_GROUP_PARTICIPANTS: "Não foi possível listar os participantes do grupo. Tente novamente mais tarde!",
        ERR_FETCHING_REMAINING_GROUP_TICKETS: "Não foi possível listar todas as conversas restantes do grupo. Tente novamente mais tarde!",
        ERR_FETCH_MESSAGES: "Não foi possível listar as mensagens da conversa. Tente novamente mais tarde!",
        ERR_WAPP_BECOMING_NOT_DEFAULT: "Não é possível transformar diretamente uma conexão padrão para não-padrão!",
        ERR_SEARCH_MESSAGE: "Não foi possível pesquisar por mensagens. Tente novamente mais tarde!",
        NOT_ALLOWED_TO_DELETE_MESSAGE: "Você não tem permissão de deletar mensagens que não foram enviadas por você!",
        NOT_ALLOWED_TO_EDIT_MESSAGE: "Você não tem permissão para editar mensagens que não foram enviadas por você!",
        ERR_MARK_BUTTON_LIST_AS_INTERACTED: "Não foi possível marcar botão/lista como interagido. Tente novamente mais tarde!",
        ERR_CREATE_EVENT_DETAILS: "Não foi possível criar a associação dos detalhes do evento com a mensagem!",
        ERR_BUTTON_OPTION: "Não foi possível criar a associação de opção do botão com a mensagem!",
        ERR_LIST_OPTION: "Não foi possível criar a associação de opção da lista com a mensagem!",
        ERR_POLL_OPTION: "Não foi possível criar a associação de opção da enquete com a mensagem!",
        ERR_DISPATCH_CREATE: "Não foi possível criar disparo. Tente novamente mais tarde!",
        ERR_DISPATCH_UPDATE: "Não foi possível atualizar disparo. Tente novamente mais tarde!",
      },
    },
  },
};

export { messages };
