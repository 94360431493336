import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	Checkbox,
	Chip,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
	chips: { display: "flex", flexWrap: "wrap", },

	chip: { margin: 2, color: theme.palette.text.primary, border: `2px solid ${theme.palette.primary.pastel}`, },

	chipCheckbox: {
		color: theme.palette.primary.main,
		'&.Mui-checked': { color: theme.palette.primary.main }
	},

	selectInputField: {
		width: "250px",
		"& .MuiOutlinedInput-notchedOutline": { borderColor: theme.palette.text.primary },
		"& .MuiSvgIcon-root": { color: theme.palette.text.primary },
	},
}));

const DispatchStatusSelect = ({ selectedOptions, onChange, styleSelectInputField=true }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();

  const statuses = ["cancelled", "concluded", "error", "inProgress", "pending"];
  const statusOptions = ["cancelled", "concluded", "error", "inProgress", "pending"];



  //  ***************
  //  ** Functions **
  //  ***************
  const handleChange = (event) => { onChange(event.target.value); };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div>
      <FormControl
        fullWidth
        size="small"
        margin="dense"
        variant="outlined"
        classes={styleSelectInputField ? { root: classes.selectInputField } : { }}
      >
        <InputLabel>{i18n.t("dispatchStatusSelect.inputStatus")}</InputLabel>

        <Select
          multiple
          label={i18n.t("dispatchStatusSelect.inputStatus")}
          value={selectedOptions}
          onChange={handleChange}
          MenuProps={{
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
            transformOrigin: { vertical: "top", horizontal: "left" },
            getContentAnchorEl: null,
          }}
          renderValue={selected => (
            <div className={classes.chips}>
              {selected?.length > 0 && selected.map(selectedStatus => {
                const status = statuses.find(s => s === selectedStatus);
                return status
                  ? (
                    <Chip
                      key={selectedStatus}
                      variant="outlined"
                      label={i18n.t(`dispatchStatusSelect.statusValues.${status}`)}
                      className={classes.chip}
                    />
                  )
                  : null;
              })}
            </div>
          )}
        >
          {statusOptions.map(status => (
            <MenuItem key={status} value={status}>
              <Checkbox className={classes.chipCheckbox} checked={selectedOptions.indexOf(status) > -1} />
              
              <ListItemText
                primary={i18n.t(`dispatchStatusSelect.statusValues.${status}`)}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default DispatchStatusSelect;
