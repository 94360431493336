import React, { useState, useEffect, useContext, useReducer } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import {
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@material-ui/core";
import CancelScheduleSendOutlinedIcon from "@material-ui/icons/CancelScheduleSendOutlined";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import SyncOutlinedIcon from "@material-ui/icons/SyncOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import BuyDispatch from "../../components/BuyDispatch";
import ConfirmationModal from "../../components/ConfirmationModal";
import DispatchManagerModal from "../../components/DispatchManagerModal";
import DispatchSendingsModal from "../../components/DispatchSendingsModal";
import extractDayMonthYearFromStringDateTime from "../../utils/extractDayMonthYearFromStringDateTime";
import extractHourMinuteFromStringDateTime from "../../utils/extractHourMinuteFromStringDateTime";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";

import SocketDispatchManager from "../../context/Socket/Listeners/SocketDispatchManager";

const reducer = (state, action) => {
  if (action.type === "LOAD_DISPATCHES") {
    const dispatches = action.payload;
    const newDispatches = [];

    dispatches.forEach((dispatch) => {
      const dispatchIndex = state.findIndex((d) => d.id === dispatch.id);
      if (dispatchIndex !== -1) { state[dispatchIndex] = dispatch; }
      else { newDispatches.push(dispatch); }
    });

    return [...state, ...newDispatches];
  }

  if (action.type === "UPDATE_DISPATCHES") {
    const dispatch = action.payload;
    const dispatchIndex = state.findIndex((d) => d.id === dispatch.id);

    if (dispatchIndex !== -1) {
      state[dispatchIndex] = dispatch;
      return [...state];
    }
    else { return [dispatch, ...state]; }
  }

  if (action.type === "DELETE_DISPATCH") {
    const dispatchId = action.payload;
    const dispatchIndex = state.findIndex((d) => d.id === dispatchId);
    if (dispatchIndex !== -1) { state.splice(dispatchIndex, 1); }
    return [...state];
  }

  if (action.type === "RESET") { return []; }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },

  dispatchesList: {
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    height: "90%",
    [theme.breakpoints.down("sm")]: { maxHeight: "80%", },
  },

  floatingButton: {
    transition: "transform 0.30s",
    "&:hover": { transform: "translateY(-5px)", },
  },

  icon: { color: theme.palette.text.primary, },

  actionButton: { "&:hover": { color: theme.palette.primary.main, }, },
}));

const DispatchManager = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const [dispatches, dispatchDispatch] = useReducer(reducer, []);

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [searchParam, setSearchParam] = useState("");

  const [dispatchEnabledOnBestzap, setDispatchEnabledOnBestzap] = useState(null);
  const [dispatchModalOpen, setDispatchModalOpen] = useState(false);
  const [dispatchSendingsModalOpen, setDispatchSendingsModalOpen] = useState(false);
  const [selectedDispatch, setSelectedDispatch] = useState(null);

  const [selectedDispatchId, setSelectedDispatchId] = useState(null);
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [confirmCancelModalOpen, setConfirmCancelModalOpen] = useState(false);
  const [confirmUncancelModalOpen, setConfirmUncancelModalOpen] = useState(false);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (user.profile === "user" || !user.configEnabled) { history.push(`/tickets`); }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const delayDebounceFN = setTimeout(() => {
      const fetchDispatchEnabledOnBestzap = async () => {
        const { data } = await api.get("/dispatchEnabledOnBestzap");
        setDispatchEnabledOnBestzap(data);
      };

      fetchDispatchEnabledOnBestzap();
    }, 500);

    return () => clearTimeout(delayDebounceFN);
  }, []);

  useEffect(() => {
    dispatchDispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  useEffect(() => {
    setLoading(true);

    const delayDebounceFN = setTimeout(() => {
      const fetchDispatches = async () => {
        try {
          const { data } = await api.get("/dispatches", { params: { searchParam, pageNumber } });

          dispatchDispatch({ type: "LOAD_DISPATCHES", payload: data.dispatches });
          setHasMore(data.hasMore);
          setLoading(false);
        } catch (exception) {
          console.log("Dispatch Manager Use Effect Exception:", exception);
        }
      };

      fetchDispatches();
    }, 500);

    return () => clearTimeout(delayDebounceFN);
  }, [searchParam, pageNumber]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenDispatchModal = () => {
    setSelectedDispatch(null);
    setDispatchModalOpen(true);
  };

  const handleCloseDispatchModal = () => {
    setSelectedDispatch(null);
    setDispatchModalOpen(false);
  };

  const handleOpenDispatchSendingsModal = () => {
    setDispatchSendingsModalOpen(true);
  };

  const handleCloseDispatchSendingsModal = () => {
    setSelectedDispatch(null);
    setDispatchSendingsModalOpen(false);
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const loadMore = () => {
    setPageNumber((previousState) => previousState + 1);
  };

  const handleScroll = (event) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) { loadMore(); }
  };

  const handleOpenConfirmDelete = (dispatchId) => {
    setSelectedDispatchId(dispatchId);
    setConfirmDeleteModalOpen(true);
  };

  const handleOpenConfirmCancel = (dispatchId) => {
    setSelectedDispatchId(dispatchId);
    setConfirmCancelModalOpen(true);
  };

  const handleOpenConfirmUncancel = (dispatchId) => {
    setSelectedDispatchId(dispatchId);
    setConfirmUncancelModalOpen(true);
  };

  const handleDeleteDispatch = async () => {
    try {
      const { data: scheduleDispatchSendingStatus } = await api.get(`/dispatch-validation/${selectedDispatchId}`);

      if (scheduleDispatchSendingStatus.some(status => status === 1)) {
        toast.info(i18n.t("dispatchManager.validation.dispatchAlreadySent"));

        const areAllDispatchSendingsSent = scheduleDispatchSendingStatus.every(status => status === 1);
        const isAtLeastOneDispatchSendingSent = scheduleDispatchSendingStatus.some(status => status === 1);
        await api.patch(`/dispatch-updateStatus/${selectedDispatchId}`, { areAllDispatchSendingsSent, isAtLeastOneDispatchSendingSent });
      }
      else {
        await api.delete(`/dispatch/${selectedDispatchId}`);
        setSelectedDispatchId(null);
      }
    } catch (exception) {
      console.log("Handle Delete Dispatch Exception:", exception);
      toastError(exception);
    }
  };

  const handleCancelDispatch = async () => {
    try {
      const { data: scheduleDispatchSendingStatus } = await api.get(`/dispatch-validation/${selectedDispatchId}`);

      if (scheduleDispatchSendingStatus.some(status => status === 1)) {
        toast.info(i18n.t("dispatchManager.validation.dispatchAlreadySent"));
      }
      else {
        await api.patch(`/dispatch-cancel/${selectedDispatchId}`);
        setSelectedDispatchId(null);
      }
    } catch (exception) {
      console.log("Handle Cancel Dispatch Exception:", exception);
      toastError(exception);
    }
  };

  const handleUncancelDispatch = async () => {
    try {
      await api.patch(`/dispatch-uncancel/${selectedDispatchId}`);
      setSelectedDispatchId(null);
    } catch (exception) {
      console.log("Handle Uncancel Dispatch Exception:", exception);
      toastError(exception);
    }
  };

  const handleEditDispatch = (dispatch) => {
    setSelectedDispatch(dispatch);
    setDispatchModalOpen(true);
  };

  const handleViewDispatchSendings = (dispatch) => {
    setSelectedDispatch(dispatch);
    handleOpenDispatchSendingsModal();
  };

  const handleSyncDispatchStatus = async (dispatchId) => {
    try {
      await api.patch(`/dispatch-syncStatus/${dispatchId}`);
    } catch (exception) {
      console.log(`Exception caught while Syncing Dispatch Status: ${JSON.stringify(exception)}`);
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <MainContainer>
      <SocketDispatchManager dispatch={dispatchDispatch} />

      <DispatchManagerModal
        open={dispatchModalOpen}
        onClose={handleCloseDispatchModal}
        dispatchId={selectedDispatch && selectedDispatch.id}
      />

      <DispatchSendingsModal
        open={dispatchSendingsModalOpen}
        onClose={handleCloseDispatchSendingsModal}
        dispatch={selectedDispatch && selectedDispatch}
      />

      <ConfirmationModal
        title={i18n.t("dispatchManager.confirmationModals.deleteTitle")}
        open={confirmDeleteModalOpen}
        onClose={setConfirmDeleteModalOpen}
        onConfirm={handleDeleteDispatch}
      />

      <ConfirmationModal
        title={i18n.t("dispatchManager.confirmationModals.cancelTitle")}
        open={confirmCancelModalOpen}
        onClose={setConfirmCancelModalOpen}
        onConfirm={handleCancelDispatch}
      />

      <ConfirmationModal
        title={i18n.t("dispatchManager.confirmationModals.uncancelTitle")}
        open={confirmUncancelModalOpen}
        onClose={setConfirmUncancelModalOpen}
        onConfirm={handleUncancelDispatch}
      />

      <MainHeader>
        <Title>
          {i18n.t("dispatchManager.title")}
        </Title>

        {dispatchEnabledOnBestzap && (
          <MainHeaderButtonsWrapper>
            <TextField
              placeholder={i18n.t("dispatchManager.searchPlaceholder")}
              type="search"
              value={searchParam}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ color: "gray" }} />
                  </InputAdornment>
                )
              }}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenDispatchModal}
              className={classes.floatingButton}
            >
              {i18n.t("dispatchManager.buttons.add")}
            </Button>
          </MainHeaderButtonsWrapper>
        )}
      </MainHeader>

      <Paper className={classes.mainPaper} variant="outlined">
        {dispatchEnabledOnBestzap === false && <BuyDispatch />}

        {dispatchEnabledOnBestzap === true && (
          <div className={classes.dispatchesList} onScroll={handleScroll}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{i18n.t("dispatchManager.table.name")}</TableCell>
                  <TableCell align="center">{i18n.t("dispatchManager.table.startDatetime")}</TableCell>
                  <TableCell align="center">{i18n.t("dispatchManager.table.recurrence")}</TableCell>
                  <TableCell align="center">{i18n.t("dispatchManager.table.status")}</TableCell>
                  <TableCell align="center">{i18n.t("dispatchManager.table.connection")}</TableCell>
                  <TableCell align="center">{i18n.t("dispatchManager.table.actions")}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {dispatches.map((dispatch) => (
                  <TableRow key={user.id}>
                    <TableCell align="center">{dispatch.name}</TableCell>
                    <TableCell align="center">{extractDayMonthYearFromStringDateTime(dispatch.datetimeToDispatch)} {extractHourMinuteFromStringDateTime(dispatch.datetimeToDispatch)}</TableCell>
                    <TableCell align="center">{i18n.t(`dispatchManager.table.recurrenceValues.${dispatch.recurrence}`)}</TableCell>
                    <TableCell align="center">{i18n.t(`dispatchManager.table.statusValues.${dispatch.status}`)}</TableCell>
                    <TableCell align="center">{dispatch.whatsapp.name}</TableCell>

                    <TableCell align="center">
                      {["cancelled"].includes(dispatch.status) && (
                        <IconButton className={classes.icon} size="small" onClick={() => handleOpenConfirmUncancel(dispatch.id)}>
                          <SendOutlinedIcon className={classes.actionButton} />
                        </IconButton>
                      )}

                      {["inProgress"].includes(dispatch.status) && (
                        <IconButton className={classes.icon} size="small" onClick={() => handleOpenConfirmCancel(dispatch.id)}>
                          <CancelScheduleSendOutlinedIcon className={classes.actionButton} />
                        </IconButton>
                      )}

                      {["cancelled", "pending"].includes(dispatch.status) && (
                        <IconButton className={classes.icon} size="small" onClick={() => handleEditDispatch(dispatch)}>
                          <EditIcon className={classes.actionButton} />
                        </IconButton>
                      )}

                      {["cancelled", "concluded", "inProgress", "pending"].includes(dispatch.status) && (
                        <IconButton className={classes.icon} size="small" onClick={() => handleViewDispatchSendings(dispatch)}>
                          <VisibilityOutlinedIcon className={classes.actionButton} />
                        </IconButton>
                      )}

                      {["cancelled", "inProgress", "pending"].includes(dispatch.status) && (
                        <IconButton className={classes.icon} size="small" onClick={() => handleSyncDispatchStatus(dispatch.id)}>
                          <SyncOutlinedIcon className={classes.actionButton} />
                        </IconButton>
                      )}

                      {["pending"].includes(dispatch.status) && (
                        <IconButton className={classes.icon} size="small" onClick={() => handleOpenConfirmDelete(dispatch.id)}>
                          <DeleteOutlineIcon className={classes.actionButton} />
                        </IconButton>
                      )}
                    </TableCell>

                  </TableRow>
                ))}

                {loading && <TableRowSkeleton columns={6} />}
              </TableBody>
            </Table>
          </div>
        )}
      </Paper>
    </MainContainer>
  );
};

export default DispatchManager;
