import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import { parseISO, format } from "date-fns";
import { 
  Done,
  DoneAll,
  AccessTime,
  FormatListBulletedOutlined,
  GetApp,
  CancelOutlined,
  CheckCircleOutlineOutlined
} from "@material-ui/icons";
import {
  Dialog,
  DialogTitle,
  Tooltip,
  Button,
  IconButton,
  TextField,
  Divider
} from '@material-ui/core';

import { Videoplayer } from "@openabos/react-circular-player";
import "@openabos/react-circular-player/dist/cjs/index.css";
import "../../styles/react-circular-player-new-styles.css";

import Audio from "../Audio";
import EventPreviewQuotedMessage from "../EventPreviewQuotedMessage";
import LocationPreview from "../LocationPreview";
import MarkdownWrapper from "../MarkdownWrapper";
import ModalImageCors from "../ModalImageCors";
import MultiVcardPreview from "../MultiVcardPreview";
import PollPreviewQuotedMessage from "../PollPreviewQuotedMessage";
import VcardPreview from "../VcardPreview";
import ViewOnceRepliedMessage from "../ViewOnceRepliedMessage";

import api from "../../services/api";
import toastError from "../../errors/toastError";

import removeSignatureFromBody from "../../utils/removeSignatureFromBody";
import removeTransferenceObservationSignatureFromBody from "../../utils/removeTransferenceObservationSignatureFromBody";

import { i18n } from "../../translate/i18n";
import saveFile from "../../utils/saveFile";

const useStyles = makeStyles(theme => ({
  // Main Containers
  dialogContent: {
    gap: "25px",
    padding: "1.5em",
    background: theme.backgroundImage,
    overflowX: "auto",
  },

  // Quoted Message
  buttonListFooter: { fontSize: "12px", color: theme.palette.text.primary, },

  stickerMessage: {
    maxHeight: 225,
    maxWidth: 225,
    borderRadius: 5,
    transition: "all 0.3s",
    [theme.breakpoints.down("sm")]: { maxHeight: 150, maxWidth: 150, },
  },

  deletedStickerMessage: { opacity: 0.7, filter: "grayscale(80%)", },

  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  messageMediaFullScreen: {
    transition: "all 0.30s",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },

  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: theme.palette.background.quotedPaper,
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedSideColorLeft: { flex: "none", width: "4px", backgroundColor: "#6bcbef", },

  quotedSideColorRight: { flex: "none", width: "4px", backgroundColor: "#35cd96", },

  messageContactName: { display: "flex", color: "#6bcbef", fontWeight: 500, },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },


  // Current Message View
  currentMessageViewContainer: { overflowX: "auto", },

  messageView: {
    overflowX: "auto",
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: "auto",
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": { display: "flex", position: "absolute", top: 0, right: 0, },
    whiteSpace: "pre-wrap",

    backgroundColor: theme.palette.primary.messageBox,
    marginRight: "1em",
    color: theme.palette.text.primary,
    alignSelf: "center",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  textContentItem: { overflowWrap: "break-word", padding: "3px 80px 6px 6px", },

  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: theme.palette.text.primary,
    userSelect: "none",
  },

  ackIcons: { fontSize: 18, verticalAlign: "middle", marginLeft: 4, },

  ackDoneAllIcon: { color: theme.palette.secondary.ackCheck, fontSize: 18, verticalAlign: "middle", marginLeft: 4, },

  reactionListSticker: {
    position: "absolute",
    bottom: "-20px",
    left: "5px",
    display: "flex",
    flexDirection: "row",
    zIndex: "9",
    gap: "2px",
  },

  reactionSpan: {
    borderRadius: "20px",
    padding: "3px 3px 3px 3px",
    backgroundColor: theme.palette.background.paper,
    fontSize: "15px",
    userSelect: "none",
    "&:hover": { filter: "brightness(0.92)", },
  },

  // Edit Message Field
  editMessageFieldContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "1em",
  },

  sendMessageIcons: { borderRadius:"20px", color: "grey", },

  // Floating Button
  floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)' },
  },

  //  ***********************
  //  ** Internal Messages **
  //  ***********************
  internalMessage: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: "auto",
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      visibility: "visible",
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },
  
    whiteSpace: "pre-wrap",
    backgroundColor: theme.palette.internalMessage,
    color: theme.palette.text.primary,
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },
}));

const MessageEditModal = ({ open, onClose, message }) => {
  //  ***********
  //  ** Datas **
  //  ***********
  const classes = useStyles();
  const [messageToEdit, setMessageToEdit] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(!!document.fullscreenElement);

  const [shouldResetCursor, setShouldResetCursor] = useState(true);
  const [shouldScrollTop, setShouldScrollTop] = useState(true);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (open && message.body !== undefined) {
      ["internal", "transference_observation"].includes(message.mediaType)
        ? setMessageToEdit(removeTransferenceObservationSignatureFromBody(message.body))
        : setMessageToEdit(removeSignatureFromBody(message.body))
    }
	}, [open, message]);

  useEffect(() => {
    if (open) {
      document.addEventListener("fullscreenchange", () => {
        setIsFullscreen(!!document.fullscreenElement);
      });
    
      return () => {
        document.removeEventListener("fullscreenchange", setIsFullscreen(!!document.fullscreenElement));
      };
    }
  }, [open]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();
    if (message.body !== undefined) {
      ["internal", "transference_observation"].includes(message.mediaType)
        ? setMessageToEdit(removeTransferenceObservationSignatureFromBody(message.body))
        : setMessageToEdit(removeSignatureFromBody(message.body));

      setShouldResetCursor(true);
      setShouldScrollTop(true);
    }
  };

  const handleMessageToEdit = (event) => {
    event.target.value.replaceAll("\n", "") === "" 
      ? setMessageToEdit("")
      : setMessageToEdit(event.target.value);
	}

  const checkMessageMedia = (message) => {
    if (message.mediaType === "location" && message.body.split('|').length >= 2) {
      let locationParts = message.body.split('|')
      let imageLocation = locationParts[0]
      let linkLocation = locationParts[1]

      let descriptionLocation = null

      if (locationParts.length > 2)
        descriptionLocation = message.body.split('|')[2]

      return <LocationPreview image={imageLocation} link={linkLocation} description={descriptionLocation} />
    }
    
    else if (message.mediaType === "button") { 
      const buttonMessage = message.body;
      const buttonMessageTitleFooter = buttonMessage.split("##");
      const buttonMessageArray = buttonMessageTitleFooter[0].split("||");
      const buttonTitle = buttonMessageTitleFooter[1];
      const buttonFooter = buttonMessageTitleFooter[2];

      const buttonBody = buttonMessageArray[0];
      const buttonOptionsArray = buttonMessageArray.slice(1);

      return(
        <>
          <b>{buttonTitle}</b>
          <p>{buttonBody}</p>
          <i className={classes.buttonListFooter}>{buttonFooter}</i>
          <hr />
          <center>
            {buttonOptionsArray.map((option, i) => (
              <Button
                color="inherit"
                variant="outlined"
                target="_blank"
                disabled
              >
                {option}
              </Button>
            ))}
          </center>
        </>
      );

    }
    
    else if (message.mediaType === "list") { 
      const listMessage = message.body;
      const listMessageArray = listMessage.split("||");

      const headerArray = listMessageArray[0].split("%%");
      const optionsArray = listMessageArray.slice(1);
      return (
        <>
          <b>{headerArray[0]}</b>
          <p>{headerArray[1]}</p>
          <i className={classes.buttonListFooter}>{headerArray[3]}</i>
          <center>
            <Button
              startIcon={<FormatListBulletedOutlined />}
              color="inherit"
              variant="outlined"
              target="_blank"
              href={message.mediaUrl}
              disabled
            >
              {headerArray[2]}
            </Button>
          </center>
          
          <hr />

          <center>
            {optionsArray.map((option, i) => (
              <Button
                color="inherit"
                variant="outlined"
                target="_blank"
                href={message.mediaUrl}
                disabled
              >
                {option.split("%%")[0]}
              </Button>
            ))}
          </center>
        </>
      );
    }
    
    else if (message.mediaType === "vcard") {
      let array = message.body.split("\n");
      let obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        let values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("waid=") !== -1) {
            obj.push({ number: values[ind].split("waid=")[1] });
          }

          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }
      return <VcardPreview contact={contact} numbers={obj[0]?.number} enableHandleNewChat={false} />
    }
    
    else if (message.mediaType === "multi_vcard") {
      const vcardsLimiter = "\n----vcardLimiter----\n";
      const vcards = message.body.split(vcardsLimiter).filter(vcard => vcard !== "");
      const vcardsElements = [];
      const contactsList = [];

      for (let vcardIndex = 0; vcardIndex < vcards.length; vcardIndex += 1) {
        vcardsElements.push(vcards[vcardIndex].split("\n"));
      }

      for (let vcardElementIndex = 0; vcardElementIndex < vcardsElements.length; vcardElementIndex += 1) {
        try {
          contactsList.push({
            number: vcardsElements[vcardElementIndex][4].split("waid=")[1].split(":")[0],
            name: vcardsElements[vcardElementIndex][3].split(":")[1],
          })
        } catch (err) {
          try {
            contactsList.push({
              number: vcardsElements[vcardElementIndex][6].split("waid=")[1].split(":")[0],
              name: vcardsElements[vcardElementIndex][3].split(":")[1],
            })
          } catch (err) {
            contactsList.push({
              number: undefined,
              name: vcardsElements[vcardElementIndex][3].split(":")[1],
            });
          }
        }
      }

      return <MultiVcardPreview contacts={contactsList} enableStartChat={false} />;
    }
    
    else if (message.mediaType === "image"&& !message.isViewOnce) {
      return <ModalImageCors imageUrl={message.mediaUrl} replyMessage={true} />;
    }
    
    else if (message.mediaType === "image" && message.isViewOnce) {
      return <ViewOnceRepliedMessage isPhoto={true} />
    }
    
    else if (message.mediaType === "sticker") {
      return (
        <img
          className={clsx(classes.stickerMessage, {[classes.deletedStickerMessage]: message.isDeleted,})}
          src={message.mediaUrl} alt={message.body} width={225} height={225}
        />
      );
    }
    
    else if (message.mediaType === "audio") {
      return <Audio url={message.mediaUrl} isVoice={message.isVoice} isFromMe={message.fromMe} />
    }
    
    else if (message.mediaType === "ptv") {
      return (
        <Videoplayer size={200} src={message.mediaUrl} boundaryColor={"#ff6961"} />
      );
    }
    
    else if (message.mediaType === "video" && !message.isViewOnce) {
      let mediaClass = isFullscreen ? `${classes.messageMediaFullScreen}` : `${classes.messageMedia}`;

      return (
        <>
        <video
          className={mediaClass}
          src={message.mediaUrl}
          controls={!message.isGif}
          autoPlay={message.isGif}
          muted={message.isGif}
          loop={message.isGif}
        />
        <br />
        </>
      );
    }
    
    else if (message.mediaType === "video" && message.isViewOnce) {
      return (
        <>
        <ViewOnceRepliedMessage isPhoto={false} />
        <br />
        </>
      );
    }
    
    else if (message.mediaType === "poll_creation") {
      return <PollPreviewQuotedMessage message={message} />;
    }

    else if (message.mediaType === "event") {
      return <EventPreviewQuotedMessage message={message} />;
    }
    
    else {
      const handleClick = (event) => {
        event.preventDefault();
        saveFile(message);
      }

      return (
        <>
          <div className={classes.downloadMedia}>
            <Button
              startIcon={<GetApp />}
              color="inherit"
              variant="outlined"
              target="_blank"
              href="#"
              onClick={handleClick}
              className={classes.floatingButton}
            >
              {i18n.t("messagesInput.download")}
            </Button>
            
          </div>
          <Divider />
        </>
      );
    }
  };

  const renderQuotedMessage = (message) => {
    return (
      <div
        className={clsx(classes.quotedContainerLeft, {
          [classes.quotedContainerRight]: message.fromMe,
        })}
      >
        <span
          className={clsx(classes.quotedSideColorLeft, {
            [classes.quotedSideColorRight]: message.quotedMsg?.fromMe,
          })}
        ></span>
        <div className={classes.quotedMsg}>
          {!message.quotedMsg?.fromMe && (
            <span className={classes.messageContactName}>
              {message.quotedMsg?.contact?.name}
            </span>
          )}

          {message.quotedMsg?.mediaType !== "list" 
           && message.quotedMsg?.mediaType !== "button" 
           && message.quotedMsg?.mediaType !== "vcard" 
           && message.quotedMsg?.mediaType !== "multi_vcard"
           && message.quotedMsg?.mediaType !== "image" 
           && message.quotedMsg?.mediaType !== "sticker"
           && message.quotedMsg?.mediaType !== "audio" 
           && message.quotedMsg?.mediaType !== "video" 
           && message.quotedMsg?.mediaType !== "location" 
           && message.quotedMsg?.mediaType !== "application"
           && message.quotedMsg?.mediaType !== "ptv" 
           && message.quotedMsg?.mediaType !== "poll_creation" 
           && message.quotedMsg?.mediaType !== "event"
           && (
            <MarkdownWrapper>{message.quotedMsg?.body}</MarkdownWrapper>
          )}

          {message.quotedMsg?.mediaType === "button" && (
            <>
            <b>{message.quotedMsg?.body.split("##")[1]}</b>
            <p>{message.quotedMsg?.body.split("||")[0]}</p>
            <i className={classes.buttonListFooter}>{message.quotedMsg?.body.split("##")[2]}</i>
            </>
          )}

          {message.quotedMsg?.mediaType === "list" && (
            <>
              <b>{message.quotedMsg?.body.split("||")[0].split("%%")[0]}</b>
              <p>{message.quotedMsg?.body.split("||")[0].split("%%")[1]}</p>
              <i className={classes.buttonListFooter}>{message.quotedMsg?.body.split("||")[0].split("%%")[3]}</i>
            </>
          )}

          {(message.quotedMsg?.mediaType === "vcard" 
          || message.quotedMsg?.mediaType === "multi_vcard"
          || message.quotedMsg?.mediaType === "image"
          || message.quotedMsg?.mediaType === "sticker"
          || message.quotedMsg?.mediaType === "audio"
          || message.quotedMsg?.mediaType === "video"
          || message.quotedMsg?.mediaType === "location"
          || message.quotedMsg?.mediaType === "application"
          || message.quotedMsg?.mediaType === "ptv"
          || message.quotedMsg?.mediaType === "poll_creation"
          || message.quotedMsg?.mediaType === "event"
          ) && checkMessageMedia(message.quotedMsg)}
        </div>
      </div>
    );
  };

  const renderMessageAck = (message) => {
    if (message.ack === 0) {
      return <AccessTime fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 1 || message.ack === 2) {
      return <Done fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 3) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    if (message.ack === 4 || message.ack === 5) {
      return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    }
  };

  const handleEditMessage = async () => {
    try {
      if (messageToEdit.replaceAll("\n", "").trim() === "") {
        toast.info(i18n.t("editMessageModal.toasts.blankInput"));
      } else {
        api.put(`/messagesEdit/${message.id}`, {
          newBody: ["internal", "transference_observation"].includes(message.mediaType) ? messageToEdit.trim() : removeSignatureFromBody(messageToEdit).trim(),
          mediaType: message.mediaType,
          ticketType: message.ticket.type
        });

        handleClose();
      }
    } catch(error) {
      console.log("Handle Edit Message Error:", error);
      toastError(error);
    }
  };
  
  const handleFocusMessageToEdit = (e) => {
    const textField = e.currentTarget;
  
    if (open) {
      setTimeout(() => {
        if (shouldScrollTop) {
          e.target.scrollTop = e.target.scrollHeight;
          setShouldScrollTop(false);
        }
  
        if (shouldResetCursor) {
          textField.setSelectionRange(textField.value.length, textField.value.length);
          setShouldResetCursor(false);
        }
      }, 100);
    }
  
    e.target.focus();
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth scroll="paper">
        <DialogTitle id="form-dialog-title">
          <span>{i18n.t("editMessageModal.title.up")}</span>
        </DialogTitle>

        <div className={classes.dialogContent}>
          {/* 
            **************************
            ** Current Message View **
            **************************
          */}
          <div className={classes.currentMessageViewContainer}>
            <div className={
              ["internal", "transference_observation"].includes(message.mediaType)
                ? `${classes.currentMessageViewContainer} ${classes.internalMessage}`
                : classes.messageView
            }>
              <div className={classes.textContentItem}>
                {message.quotedMsg && (
                  <div className={classes.textContentItem}>{renderQuotedMessage(message)}</div>
                )}

                {(message.mediaUrl) && checkMessageMedia(message)}

                {/* 
                  ***---- Message Body ----***
                */}
                <MarkdownWrapper>{message.body}</MarkdownWrapper>

                {/* 
                  ***---- Time Stamp and ACK ----***
                */}
                <span className={classes.timestamp}>
                  {JSON.stringify(message) !== '{}' && format(parseISO(message.createdAt), "HH:mm")}
                  {renderMessageAck(message)}
                </span>

                {/* 
                  ***---- Reactions ----***
                */}
                {(message.contactReaction || message.fromMeReaction) && (
                  <div className={classes.reactionListSticker}>
                    {message.contactReaction && (
                      <Tooltip title={i18n.t("reactions.fromContact")} placement="bottom-start" arrow>
                        <span className={classes.reactionSpan}>{message.contactReaction}</span>
                      </Tooltip>
                    )}

                    {message.fromMeReaction && (
                      <Tooltip title={i18n.t("reactions.fromMe")} placement="bottom-start" arrow>
                        <span className={classes.reactionSpan}>{message.fromMeReaction}</span>
                      </Tooltip>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          <Divider />

          {/* 
            ************************
            ** Edit Message Field **
            ************************
          */}
          <div className={classes.editMessageFieldContainer}>
            <Tooltip title={i18n.t("messagesInput.tooltips.cancel")} placement="top-start" arrow>
              <IconButton onClick={handleClose}>
                <CancelOutlined className={classes.sendMessageIcons} />
              </IconButton>
            </Tooltip>

            <TextField
              autoFocus
              focused
              fullWidth
              multiline

              label={i18n.t("editMessageModal.textfield")}
              margin="dense"
              maxRows={3}
              name="editedMessage"
              type="text"
              value={messageToEdit}
              variant="outlined"

              onChange={handleMessageToEdit}
              onFocus={(event) => handleFocusMessageToEdit(event)}
              onKeyDown={(e) => { e.key === "Enter" && !e.shiftKey && handleEditMessage(); }}
            />

            <Tooltip title={i18n.t("messagesInput.tooltips.confirm")} placement="top-start" arrow>
              <IconButton onClick={handleEditMessage}>
                <CheckCircleOutlineOutlined className={classes.sendMessageIcons} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default MessageEditModal;