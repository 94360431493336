import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { FormControl, InputLabel, Menu, MenuItem, Select } from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import toastError from "../../errors/toastError";
import useWhatsApps from "../../hooks/useWhatsApps";

const PhoneNumberMessageOptionsMenu = ({ anchorEl, handleClose, menuOpen, phoneNumber, setTabOpen = null }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { user } = useContext(AuthContext);
  const { whatsApps } = useWhatsApps();
  
  const history = useHistory();
  const defaultWhatsapp = whatsApps.length === 1 ? whatsApps[0] : null;

  const [chatWithNumberConfirmationOpen, setChatWithNumberConfirmationOpen] = useState(false);
  const [userQueues, setUserQueues] = useState([]);
  const [whatsappId, setWhatsappId] = useState("");



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (chatWithNumberConfirmationOpen && defaultWhatsapp) setDefaultWhatsapp();
  }, [chatWithNumberConfirmationOpen, defaultWhatsapp]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchUserQueuesIds = async () => {
        try {
          const { data } = await api.get(`/userQueuesIds/${user.id}`);
          setUserQueues(data);
        } catch (error) {
					console.log("Phone Number Message Options Menu Use Effect 1 Error:", error);
          toastError(error);
        }
      };
      fetchUserQueuesIds();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [user.id]);



  //  ***************
  //  ** Functions **
  //  ***************
  const onClose = () => {
    setChatWithNumberConfirmationOpen(false);
    setWhatsappId("");
  };

  const setDefaultWhatsapp = () => {
    setWhatsappId(whatsappId === "" && defaultWhatsapp ? defaultWhatsapp.id : whatsappId);
  };

  const handleChatWithNumberConfirmationModal = () => {
    setChatWithNumberConfirmationOpen(true);
    handleClose();
  };

  const handleCopyPhoneNumber = async () => {
    handleClose();
    await navigator.clipboard.writeText(phoneNumber);
  };

  const handleOpenChatWithPhoneNumber = async () => {
    try {
      if (!whatsappId || whatsappId === undefined || whatsappId === "") {
        toast.info(i18n.t("newTicketModal.toasts.whatsappNotSelected"));
      } else {
        const { data: ticket } = await api.post("/phoneNumberMessageTicket", { phoneNumber, whatsappId });
        const pendingTicketCondition = ticket.status === "pending" && (ticket.queueId === null || userQueues.includes(ticket.queueId));
        const openTicketAndAdminUserCondition = ticket.status === "open" && user.profile === "admin";
        const openTicketAndCommonUserCondition = ticket.status === "open" && ticket.userId === user.id;

        // ***---- User can see Ticket ----***
        if (pendingTicketCondition || openTicketAndAdminUserCondition || openTicketAndCommonUserCondition) {
          if (setTabOpen && setTabOpen !== undefined) setTabOpen(ticket.status);
          localStorage.setItem("tabOpenTickets", ticket.status);
          history.push(`/tickets/${ticket.id}`);
        }

        // ***---- User cannot see Ticket ----***
        else {
          
          // *** Pending Ticket in Another Queue ***
          if (ticket.status === "pending") {
            toast.info(`
              ${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET")}
              ${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET_QUEUE")}
              ${ticket.queue?.name}
            `);
          }

          // *** Ticket with Another User ***
          else {
            toast.info(`
              ${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET")}
              ${i18n.t("backendErrors.ERR_OTHER_OPEN_TICKET")}
              ${ticket.user?.name}
            `);
          }
        }

        onClose();
      }
    } catch (error) {
      console.log("- Phone Number Message Options Menu Error:", error);
      toastError(error);
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
    {/* ***---- Confirmation Modal for Opening Chat with Contact */}
    <ConfirmationModal
      title={i18n.t("phoneNumberOptionsMenu.confirmationModal.title")}
      open={chatWithNumberConfirmationOpen}
      onClose={onClose}
      onConfirm={handleOpenChatWithPhoneNumber}
    >
      <FormControl variant="outlined" margin="dense" fullWidth>
        <InputLabel>{i18n.t("phoneNumberOptionsMenu.confirmationModal.fields.connection")}</InputLabel>

        <Select
          fullWidth
          label={i18n.t("phoneNumberOptionsMenu.confirmationModal.fields.connection")}
          onChange={(event) => setWhatsappId(event.target.value)}
          value={whatsappId}
        >
          <MenuItem value={""}>&nbsp;</MenuItem>
          {whatsApps.map((whatsapp) => (
            <MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </ConfirmationModal>

    <Menu
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      transformOrigin={{vertical: "top", horizontal: "right"}}

      open={menuOpen}
      onClose={handleClose}
      onContextMenu={event => event.preventDefault()}
    >
      {/* ***---- Action: Chat with Phone Number ----*** */}
      <MenuItem onClick={handleChatWithNumberConfirmationModal}>{i18n.t("phoneNumberOptionsMenu.chat")}</MenuItem>

      {/* ***---- Action: Copy Phone Number */}
      <MenuItem onClick={handleCopyPhoneNumber}>{i18n.t("phoneNumberOptionsMenu.copy")}</MenuItem>

      {/* ***---- Action: Close ----*** */}
      <MenuItem onClick={handleClose}>{i18n.t("phoneNumberOptionsMenu.close")}</MenuItem>
    </Menu>
    </>
  );
};

export default PhoneNumberMessageOptionsMenu;