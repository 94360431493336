import { useEffect } from "react";

import { useSocketAuthenticated } from "../../SocketContext";
import api from "../../../../services/api";

const SocketCategories = ({ dispatch }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { getSocket } = useSocketAuthenticated();



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    const socket = getSocket();

    if (socket) {
      const handleCategory = (data) => {
        if ((data.action === "update" || data.action === "create") && (`${api.defaults.headers.tenantId.toString()}` === `${data.tenantId.toString()}`)) {
          dispatch({ type: "UPDATE_CATEGORIES", payload: data.category });
        }
  
        if ((data.action === "delete") && (`${api.defaults.headers.tenantId.toString()}` === `${data.tenantId.toString()}`)) {
          dispatch({ type: "DELETE_CATEGORY", payload: data.categoryId }); 
        }
      };
  
      socket.on("category", handleCategory);
  
      return () => {
        socket.off("category", handleCategory);
      };
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);


  
  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketCategories;