const truncateStringWithKeywordFocus = (string, keyword, numberOfCharacters) => {
  // ***---- String Length Smaller than or Equals to Number of Characters ----***
  if (string.length <= numberOfCharacters) return string;



  // ***---- Keyword not Present in the String ----***
  const keywordIndex = string.indexOf(keyword);
  const keywordLength = keyword.length;

  if (keywordIndex === -1) return string.slice(0, numberOfCharacters - 1) + "...";



  // ***---- Keyword Present in the String ----***
  const visibleCharacters = numberOfCharacters - 3;
  const halfVisible = Math.floor((visibleCharacters - keywordLength) / 2);

  const startVisibleKeywordIndex = Math.max(0, keywordIndex - halfVisible);
  const endVisibleKeywordIndex = Math.min(string.length, keywordIndex + keywordLength + halfVisible);

  const startEllipsis = startVisibleKeywordIndex > 0 ? "..." : "";
  const endEllipsis = endVisibleKeywordIndex < string.length ? "..." : "";

  return startEllipsis + string.substring(startVisibleKeywordIndex, endVisibleKeywordIndex) + endEllipsis;
};

export default truncateStringWithKeywordFocus;