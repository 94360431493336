import React, { useState, useEffect, useContext } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	IconButton,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Switch,
	TextField
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../Can";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import QueueSelectModals from "../QueueSelectModals";
import toastError from "../../errors/toastError";
import useAllWhatsApps from "../../hooks/useAllWhatsApps";
import WhatsappSelectModals from "../WhatsappSelectModals";

const useStyles = makeStyles(theme => ({
	root: { display: "flex", flexWrap: "wrap" },

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},

		floatingButton: {
			transition: 'transform 0.3s',
			'&:hover': { transform: 'translateY(-5px)' },
		}
	},

	passwordIcon: { color: theme.palette.text.primary },
	btnWrapper: { position: "relative" },

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	formControl: { margin: theme.spacing(1), minWidth: 120 },

	floatingButton: {
		transition: 'transform 0.3s',
		'&:hover': { transform: 'translateY(-5px)' },
	}
}));

const UserSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	password: Yup.string().min(5, "Too Short!").max(50, "Too Long!"),
	email: Yup.string().email("Invalid email").required("Required"),
});

const UserModal = ({ open, onClose, updateRegisteredUsersCount, userId }) => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();

	const initialState = {
		name: "",
		email: "",
		password: "",
		configEnabled: true,
		profile: "user",
		tenantId: "",
		forceAcceptLongAwaitingTimeTickets: true
	};

	const {  user: loggedInUser } = useContext(AuthContext);
	const {loading, whatsApps} = useAllWhatsApps();

	// ***---- Selected ----***
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [selectedWhatsappIds, setSelectedWhatsappIds] = useState([]);
	
	// ***---- Settings ----***
	const [userPermissionsByChip, setUserPermissionsByChip] = useState("disabled");
	const [forceAcceptLongAwaitingTimeTickets, setForceAcceptLongAwaitingTimeTickets] = useState("disabled");

	// ***---- Others ----***
	const [user, setUser] = useState(initialState);
	const [profile, setProfile] = useState("user");
	const [showPassword, setShowPassword] = useState(false);
	const [whatsappId, setWhatsappId] = useState(false);



	// 	*****************
	// 	** Use Effects **
	//  *****************
	useEffect(() => {
		const fetchUser = async () => {
			if (!userId) return;
			try {
				const { data } = await api.get(`/users/${userId}`);
				setUser(prevState => { return { ...prevState, ...data }; });

				const userQueueIds = data.queues?.map(queue => queue.id);
				const userWhatsappIds = data.whatsapps?.map(whatsapp => whatsapp.id);
				setSelectedQueueIds(userQueueIds);
				setSelectedWhatsappIds(userWhatsappIds);
				setWhatsappId(data.whatsappId ? data.whatsappId : '');
			} catch (error) {
				console.log("Users Modal Use Effect 1 Error:", error);
				toastError(error);
			}
		};

		fetchUser();
	}, [userId, open]);

	useEffect(() => {
		if (open) {
			const fetchSettings = async () => {
				try {
					const apiCalls = [
						api.get("/settings/forceAcceptLongAwaitingTimeTickets"),
						api.get("/settings/userPermissionsByChip")
					];

					const [
						ForceAcceptLongAwaitingTimeTicketsResponse,
						UserPermissionsByChipResponse
					] = await Promise.all(apiCalls);

					setForceAcceptLongAwaitingTimeTickets(ForceAcceptLongAwaitingTimeTicketsResponse.data.value); // getting "forceAcceptLongAwaitingTimeTickets" setting
					setUserPermissionsByChip(UserPermissionsByChipResponse.data.value); // getting "userPermissionsByChip" setting
				} catch (error) {
					console.log("User Modal Use Effect 2 Error:", error);
					toastError(error);
				}
			};

			fetchSettings();
		}
	}, [open]);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleClose = () => {
		onClose();
		setUser(initialState);
	};

	const handleSaveUser = async (values) => {
		const userData = { 
			...values,
			whatsappId,
			queueIds: selectedQueueIds,
			whatsappIds: selectedWhatsappIds
		};
		
		try {
			if (userPermissionsByChip === "enabled" &&
				whatsappId && whatsappId !== undefined && whatsappId !== "" &&
				selectedWhatsappIds && selectedWhatsappIds !== undefined && selectedWhatsappIds.length > 0 &&
				!selectedWhatsappIds.some(id => id === whatsappId)
			) {
				toast.info(i18n.t("userModal.defaultConnectionMustBeSelected"));
				return;
			}
			else if (userId) {
				await api.put(`/users/${userId}`, userData);
			}
			else {
				await api.post("/users", userData);
				updateRegisteredUsersCount((prevValue) => prevValue +1);
			}

			handleClose();
		} catch (error) {
			console.log("Handle Save User Error:", error);
			toastError(error);
		}
	};



	// 	************
	// 	** Return **
	// 	************
	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{userId
						? `${i18n.t("userModal.title.edit")}`
						: `${i18n.t("userModal.title.add")}`}
				</DialogTitle>

				<Formik
					initialValues={user}
					enableReinitialize={true}
					validationSchema={UserSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveUser(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								


								{/* 
									***********************
									** Name and Password **
									***********************
								*/}
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.name")}
										autoFocus
										autoComplete={"off"}
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										fullWidth
									/>
									<Field
										as={TextField}
										name="password"
										variant="outlined"
										margin="dense"
										label={i18n.t("userModal.form.password")}
										error={touched.password && Boolean(errors.password)}
										helperText={touched.password && errors.password}
										type={showPassword ? 'text' : 'password'}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														className={classes.passwordIcon}
														aria-label="toggle password visibility"
														onClick={() => setShowPassword((e) => !e)}
													>
														{showPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											)
										}}
										fullWidth
									/>
								</div>



								{/* 
									****************************
									** Email and User Profile **
									****************************
								*/}
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("userModal.form.email")}
										name="email"
										error={touched.email && Boolean(errors.email)}
										helperText={touched.email && errors.email}
										variant="outlined"
										margin="dense"
										fullWidth
									/>

									{!user.isOwner && <FormControl
										variant="outlined"
										className={classes.formControl}
										margin="dense"
									>
										<Can
											role={loggedInUser.profile}
											perform="user-modal:editProfile"
											yes={() => (
												<>
													<InputLabel id="profile-selection-input-label">
														{i18n.t("userModal.form.profile")}
													</InputLabel>

													<Field
														onSelect={setProfile(values.profile)}
														style={{ width: "calc(100% + 5%)" }}
														as={Select}
														label={i18n.t("userModal.form.profile")}
														name="profile"
														labelId="profile-selection-label"
														id="profile-selection"
														required																												
													>
														<MenuItem value="admin">{i18n.t("userModal.form.admin")}</MenuItem >
														<MenuItem value="user">{i18n.t("userModal.form.user")}</MenuItem>
														
													</Field>
												</>
											)}
										/>
									</FormControl>}
								</div>



								{/* 
									************
									** Queues **
									************
								*/}
								<Can
									role={loggedInUser.profile}
									perform="user-modal:editQueues"
									yes={() => (
										<QueueSelectModals
											selectedQueueIds={selectedQueueIds}
											onChange={values => setSelectedQueueIds(values)}
										/>
									)}
								/>



								{/* 
									***************
									** Whatsapps **
									***************
								*/}
								<Can
									role={loggedInUser.profile}
									perform="user-modal:editWhatsapps"
									yes={() => (
										<>
											{userPermissionsByChip === "enabled" && (
												<WhatsappSelectModals
													selectedWhatsappIds={selectedWhatsappIds}
													onChange={values => setSelectedWhatsappIds(values)}
												/>
											)}
										</>
									)}
								/>




								{/* 
									************************
									** Default Connection **
									************************
								*/}
								<Can
									role={loggedInUser.profile}
									perform="user-modal:editQueues"
									yes={() => (!loading &&
										<FormControl variant="outlined" margin="dense" className={classes.maxWidth} fullWidth>
											<InputLabel>{i18n.t("userModal.form.whatsapp")}</InputLabel>
											<Field
												as={Select}
												value={whatsappId}
												onChange={(e) => setWhatsappId(e.target.value)}
												label={i18n.t("userModal.form.whatsapp")}
											>
												<MenuItem value={''}>&nbsp;</MenuItem>
												{whatsApps.map((whatsapp) => (
													<MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
												))}
											</Field>
										</FormControl>
									)}
								/>

								{/*
									*******************
									** configEnabled **
									*******************
								*/}	
								<Can									
									role={loggedInUser.profile}
									perform="user-modal:configEnabled"
									yes={() => (
										<>
										{loggedInUser.isOwner && loggedInUser.id !== user.id && !user.isOwner && profile === "admin" && (
											<FormControlLabel
												control={<Field as={Switch} color="primary" name="configEnabled" checked={values.configEnabled} /> }
												label={i18n.t("userModal.form.configEnabled")}
											/>
										)}
										</>
									)}
								/>




								{/* 
									**************************
									** Force Accept Tickets **
									**************************
								*/}
								<Can
									role={loggedInUser.profile}
									perform="user-modal:forceAcceptLongAwaitingTimeTickets"
									yes={() => (
										<>
										{forceAcceptLongAwaitingTimeTickets === "enabled" && (
											<FormControlLabel
											label={i18n.t("userModal.form.forceAcceptLongAwaitingTimeTickets")}
											control={
												<Field 
													as={Switch}
													color="primary"
													name="forceAcceptLongAwaitingTimeTickets"
													checked={values.forceAcceptLongAwaitingTimeTickets}
												/>
											}
										/>
										)}
										</>
									)}
								/>					
							</DialogContent>

						
							{!(loggedInUser.id !== user.id && user.isOwner) && <DialogActions>
								<Button
									onClick={handleClose}
									color="inherit"
									disabled={isSubmitting}
									variant="outlined"
									className={classes.floatingButton}
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={`${classes.btnWrapper} ${classes.floatingButton}`}
								>
									{userId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>}
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default UserModal;
