import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import { toast } from "react-toastify";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
  Divider,
  TextField,
  useMediaQuery
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex", flexWrap: "wrap", },

  invalidAdditionalContactsContainer: {
    width: "100.00%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "1em",
  },

  invalidAdditionalContactsSubContainer: {
    width: "100.00%",
    display: "flex",
    flexDiretion: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "1em",
  },

  icon: { color: theme.palette.text.primary, },

  actionButton: {
    cursor: "pointer",
    "&:hover": { color: theme.palette.primary.main, },
  },

  btnWrapper: { position: "relative", },

  floatingButton: {
    transition: "transform 0.30s",
    "&:hover": { transform: "translateY(-5px)" },
  },
}));

const DispatchManagerAdditionalContactsModal = ({ open, onClose }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { setFieldValue, values } = useFormikContext();

  const CLEAN_ADDITIONAL_CONTACT_NUMBER = /[^0-9,]/g;
  const CLEAN_INVALID_ADDITIONAL_CONTACT_NUMBER = /[^0-9]/g;

  const [isAutoFocus, setIsAutoFocus] = useState(true);
  const [additionalContacts, setAdditionalContacts] = useState("");
  const [invalidAdditionalContacts, setInvalidAdditionalContacts] = useState([]);
  const [invalidAdditionalContactsCopy, setInvalidAdditionalContactsCopy] = useState([]);



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    if (open) setAdditionalContacts(values.additionalContacts.join(","));
  }, [open, values]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();

    setIsAutoFocus(true);
    setAdditionalContacts("");
    setInvalidAdditionalContacts([]);
    setInvalidAdditionalContactsCopy([]);
  };

  const handleAutoFocus = (event) => {
    const textField = event.currentTarget;
  
    if (isAutoFocus) {
      textField.setSelectionRange(textField.value.length, textField.value.length);
      setIsAutoFocus(false);
    }
  
    event.target.focus();
  };

  const handleChangeAdditionalContacts = (event) => {
    setAdditionalContacts(event.target.value.replace(CLEAN_ADDITIONAL_CONTACT_NUMBER, ""));
  };

  const handleChangeInvalidAdditionalContact = (event, index) => {
    const fixedInvalidAdditionalContact = event.target.value.replace(CLEAN_INVALID_ADDITIONAL_CONTACT_NUMBER, "");

    setInvalidAdditionalContacts(previousValue => {
      const newValue = previousValue.map((item, itemIndex) => {
        if (itemIndex === index) return fixedInvalidAdditionalContact;
        return item;
      });

      return newValue;
    });
  };

  const handleRemoveInvalidAdditionalContact = (index, shouldApplyFilterOnAdditionalContacts) => {
    const newValue = [...invalidAdditionalContacts];
    const droppedValue = newValue.splice(index, 1)[0];

    const newValueCopy = [...invalidAdditionalContactsCopy];
    newValueCopy.splice(index, 1);

    setAdditionalContacts(previousValue => {
      return shouldApplyFilterOnAdditionalContacts
        ? previousValue.split(",").filter(item => item !== droppedValue).join(",")
        : previousValue.split(",").join(",");
    });

    setInvalidAdditionalContacts(newValue);
    setInvalidAdditionalContactsCopy(newValueCopy);
  };

  const handleUpdateInvalidAdditionalContact = (index) => {
    const newValue = invalidAdditionalContacts[index];
    const currentValue = invalidAdditionalContactsCopy[index];

    setAdditionalContacts(previousValue => {
      return previousValue.split(",").map(value => value === currentValue ? newValue : value).join(",")
    });
  
    handleRemoveInvalidAdditionalContact(index, false);
  };

  const handleValidateAdditionalContacts = () => {
    // ***---- Preparing Additional Contacts ----***
    const invalidAdditionalContactsArray = [];
    const additionalContactsArray = [...new Set(additionalContacts.split(",").filter(additionalContact => additionalContact !== ""))];

    setAdditionalContacts(additionalContactsArray.join(","));

    // ***---- Filtering Invalid Additional Contacts ----***
    additionalContactsArray.forEach(additionalContact => {
      // ***---- Validation Additional Contacts ----***
      const ddd = parseInt(additionalContact.slice(2, 4), 10);
      let isAdditionalContactInvalid = false;

      if (!additionalContact.startsWith("55")) isAdditionalContactInvalid = true;
      else if (ddd <= 30 && additionalContact.length !== 13) isAdditionalContactInvalid = true;
      else if (ddd > 30 && additionalContact.length !== 12) isAdditionalContactInvalid = true;

      // ***---- Updating Invalid Additional Contacts Array ----***
      if (isAdditionalContactInvalid) invalidAdditionalContactsArray.push(additionalContact);
    });

    setInvalidAdditionalContacts(invalidAdditionalContactsArray);
    setInvalidAdditionalContactsCopy(invalidAdditionalContactsArray);

    // ***---- Return ----***
    if (invalidAdditionalContactsArray.length === 0) {
      setFieldValue("additionalContacts", additionalContactsArray);
      handleClose();
    } else {
      toast.info(i18n.t("dispatchManagerAdditionalContactsModal.validations.invalidAdditionalContacts"));
    }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" scroll="paper" fullWidth>
        <DialogTitle>
          {i18n.t("dispatchManagerAdditionalContactsModal.title")}
        </DialogTitle>

        <DialogContent dividers>
          <TextField
            autoFocus
            fullWidth
            multiline

            minRows={7}
            maxRows={7}
            
            margin="dense"
            variant="outlined"
            placeholder="5518999999999,5519999999999,5520999999999,553199999999,556799999999..."

            label={i18n.t("dispatchManagerAdditionalContactsModal.form.additionalContacts")}
            value={additionalContacts}

            onChange={handleChangeAdditionalContacts}
            onFocus={handleAutoFocus}
          />

          {invalidAdditionalContacts.length > 0 && (
            <>
              <br /><br />
              <Divider />
              <br />

              <span>
                <b>{i18n.t("dispatchManagerAdditionalContactsModal.invalidContacts")}</b>
              </span>

              <div className={classes.invalidAdditionalContactsContainer}>
                {invalidAdditionalContacts.map((invalidAdditionalContact, index) => (
                  <div className={classes.invalidAdditionalContactsSubContainer}>
                    <TextField
                      autoFocus={index === 0}
                      style={{ width: isSmallScreen ? "65.00%" : "85.00%", }}
                      margin="dense"
                      variant="outlined"
                      value={invalidAdditionalContact}
                      onChange={(event) => handleChangeInvalidAdditionalContact(event, index)}
                    />

                    <CheckOutlinedIcon
                      className={classes.actionButton}
                      onClick={() => handleUpdateInvalidAdditionalContact(index)}
                    />

                    <CloseOutlinedIcon
                      className={classes.actionButton}
                      onClick={() => handleRemoveInvalidAdditionalContact(index, true)}
                    />
                  </div>
                ))}
              </div>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleClose}
            color="inherit"
            variant="outlined"
            className={classes.floatingButton}
          >
            {i18n.t("dispatchManagerAdditionalContactsModal.buttons.cancel")}
          </Button>

          <Button
            onClick={handleValidateAdditionalContacts}
            color="primary"
            variant="contained"
            className={`${classes.btnWrapper} ${classes.floatingButton}`}
          >
            {i18n.t("dispatchManagerAdditionalContactsModal.buttons.validate")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DispatchManagerAdditionalContactsModal;
