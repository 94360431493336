import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { green } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
  textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},
  btnWrapper: {
		position: "relative",
	},
  buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
  fields: {
    display: "flex",
    flexDirection: "column",
  },
	floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)' },
  },
}));

const OpenAISchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
});

const NewOpenAIChatModal = ({ modalOpen, onClose, setTabOpen }) => {

	//
	// ***---- Datas ----***
	//
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { user } = useContext(AuthContext);



	//
	// ***---- Initial State ----***
	//
  const initialState = { name: "", assignToMe: true };
  const [openAI, setOpenAI] = useState(initialState);

	//
	// ***---- Functions ----***
	//
  const handleClose = () => {
    onClose();
    setOpenAI(initialState);
  };


	const handleOpenAIChat = async values => {
		// starting loading
    setLoading(true);



		// trying to create a new ticket
		try {
			let params = {
        name: values.name,
        assignToMe: values.assignToMe,
        extraInfo: [],
        userId: values.assignToMe ? user.id : null,
        status: values.assignToMe ? "open" : "pending",
				botChatCreatedBy: user.id,
				queueId: null,
				subqueueId: null,
				isCreatedByUser: true,
      };

			const { data: ticket } = await api.post("/botChats", params);
		  history.push(`/tickets/${ticket.id}`);

			setTabOpen(values.assignToMe ? "open" : "pending");
			localStorage.setItem("tabOpenTickets", values.assignToMe ? "open" : "pending");
		}

		// if an error happens, a message is thrown
    catch (err) {
			toast.error(err);
		}

		// finishing loading
    setLoading(false);
    handleClose();
	};


	//
	// ***---- Return ----***
	//
	return (
		<>
			<Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">


				{/*  
          ***********
          ** Title **
          ***********
        */}
				<DialogTitle id="form-dialog-title">
					{i18n.t("newOpenAiModal.title")}
				</DialogTitle>

				{/* 
          ************
          ** Formik **
          ************
        */}
        <Formik
					initialValues={openAI}
					enableReinitialize={true}
					validationSchema={OpenAISchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleOpenAIChat(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>

          {({ values, errors, touched, isSubmitting }) => (
						<Form>
							<DialogContent dividers>



								<div className={classes.fields}>



									{/* 
										****************
										** Name Field **
										****************
									*/}
									<Field
										as={TextField}
										label={i18n.t("newOpenAiModal.form.name")}
										name="name"
										autoFocus
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>


									{/* 
										*************************
										** Assign to Me? Field **
										*************************
									*/}
                
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												name="assignToMe"
												checked={values.assignToMe}
											/>
										}
										label={i18n.t("newOpenAiModal.buttons.toogle")}								
									/>
								</div>
							</DialogContent>

							{/* 
                ********************
                ** Action Buttons **
                ********************
              */}
              <DialogActions>



							{/* 
								*******************
								** Cancel Button **
								*******************
							*/}
							<Button
								onClick={handleClose}
								color="inherit"
								disabled={loading}
								variant="outlined"
								className={classes.floatingButton}
							>
								{i18n.t("newTicketModal.buttons.cancel")}
							</Button>



							{/* 
								****************
								** Add Button **
								****************
							*/}
							<Button
								type="submit"
								color="primary"
								disabled={isSubmitting}
								variant="contained"
								className={`${classes.btnWrapper} ${classes.floatingButton}`}
							>
								{i18n.t("newTicketModal.buttons.ok")}

								{isSubmitting && (
									<CircularProgress
										size={24}
										className={classes.buttonProgress}
									/>
								)}
							</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</>
	);
};

export default NewOpenAIChatModal;
