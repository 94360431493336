import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Menu, MenuItem, useMediaQuery } from "@material-ui/core";

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import EditTicketModal from "../EditTicketModal";
import NewNoteModal from "../NewNoteModal";
import SearchMessageModal from "../SearchMessageModal";
import toastError from "../../errors/toastError";
import TransferTicketModal from "../TransferTicketModal";

const TicketOptionsMenu = ({ ticket, menuOpen, handleClose, anchorEl, messagesListDispatch }) => {
	// 	***************
	// 	** Variables **
	// 	***************

	// ***---- React ----***
	const { user } = useContext(AuthContext);
	const history = useHistory();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
	const isMounted = useRef(true);

	// ***---- Confirmation Modals ----***
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [confirmationImportMessagesOpen, setConfirmationImportMessagesOpen] = useState(false);
	const [blockContactConfirmationOpen, setBlockContactConfirmationOpen] = useState(false);
	const [unblockContactConfirmationOpen, setUnblockContactConfirmationOpen] = useState(false);

	// ***---- Modals ----***
	const [searchMessageModalOpen, setSearchMessageModalOpen] = useState(false);
	const [noteModalOpen, setNoteModalOpen] = useState(false);
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const [editTicketModalOpen, setEditTicketModalOpen] = useState(false);

	// ***---- Settings ----***
	const [importMessages, setImportMessages] = useState("disabled");

	// ***---- Conditions ----***
	const showImportMessagesOption = importMessages === "enabled"
		&& isSmallScreen
		&& ticket.type === 0
		&& ticket.status === "open"
		&& !ticket.contact.isMessagesImported;

	const showEditTicketOption = ticket.type !== 3
		&& !["group", "channel"].includes(ticket.status);

	const showSearchMessageOption = ticket.status;

	const showBlockUnblockOption = ticket.type === 0
		&& ticket.contact
		&& user.profile === "admin"
		&& ["open", "pending"].includes(ticket.status);

	const showTransferAndDeleteOptions = ticket.status === "open";

	const showNoteOption = [0].includes(ticket.type) && ["open", "pending"].includes(ticket.status);

	const showTransferOption = ticket.type !== 3;

	const showDeleteOption = user.profile === "admin";

	const showDeleteGroupOption = ticket.status === "group" && user.profile === "admin";
	


	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		const fetchSettings = async () => {
			try {
				const apiCalls = [api.get("/importMessages")];
				const [importMessagesResponse] = await Promise.all(apiCalls);
				setImportMessages(importMessagesResponse.data.importMessages);
			} catch (error) {
				console.log("Ticket Options Menu Use Effect 1 Error:", error);
				toastError(error);
			}
		};

		fetchSettings();
	}, []);

	useEffect(() => {
		return () => { isMounted.current = false; };
	}, []);



	// 	***************
	// 	** Functions **
	// 	***************

	// ***---- Confirmation Modals ----***
	const handleConfirmationImportMessagesOpen = () => {
		handleClose();
		setConfirmationImportMessagesOpen(true);
	}

	const handleConfirmationImportMessagesClose = () => {
		setConfirmationImportMessagesOpen(false);
	}

	const handleOpenConfirmationModal = () => {
		setConfirmationOpen(true);
		handleClose();
	};

	const handleOpenBlockConfirmationModal = () => {
		setBlockContactConfirmationOpen(true);
		handleClose();
	};

	const handleOpenUnblockConfirmationModal = () => {
		setUnblockContactConfirmationOpen(true);
		handleClose();
	};



	// ***---- Modals ----***
	const handleOpenSearchMessageModal = () => {
		setSearchMessageModalOpen(true);
		handleClose();
	};

	const handleCloseSearchMessageModal = () => {
		if (isMounted.current) { setSearchMessageModalOpen(false); }
	};

	const handleOpenNoteModal = () => {
		setNoteModalOpen(true);
		handleClose();
	};

	const handleCloseNoteModal = () => {
		if (isMounted.current) { setNoteModalOpen(false); }
	};

	const handleOpenTransferModal = () => {
		setTransferTicketModalOpen(true);
		handleClose();
	};

	const handleCloseTransferTicketModal = () => {
		if (isMounted.current) { setTransferTicketModalOpen(false); }
	};

	const handleOpenEditTicketModal = () => {
		setEditTicketModalOpen(true);
		handleClose();
	}

	const handleCloseEditTicketModal = () => {
		if (isMounted.current) { setEditTicketModalOpen(false); }
	};




	// ***---- Functionalities ----***
	const handleImportMessages = async () => {
		try {
			const values = { isMessagesImported: true, whatsappId: ticket.whatsappId };
			await api.put(`/contactImportMessages/${ticket.contactId}`, values);
			toast.success(i18n.t("contactModal.importingMessagesBaileys"));
			setTimeout(() => { history.push(`/tickets`); }, 5000);
		} catch (err) { toast.error(err); }
	};

	const handleDeleteTicket = async () => {
		try {
			if (ticket.isGroup) {
				await api.delete(`/ticketsGroup/${ticket.id}`, {
					data: { contactId: ticket.contactId }
				});
			} else {
				await api.delete(`/ticketsChat/${ticket.id}`);
			}
		}
		catch (error) {
			console.log("Handle Delete Ticket Error:", error);
			toastError(error);
		}
	};

	const handleBlockContact = async (contactId) => {
		if (!contactId) return;
		try {
			await api.put(`/blockContact/${contactId}`);
			history.push(`/tickets`);
		} catch (error) {
			console.log("Handle Block Contact Error:", error);
			toastError(error);
		}
	};

	const handleUnblockContact = async (contactId) => {
		if (!contactId) return;
		try {
			await api.put(`/unblockContact/${contactId}`);
			history.push(`/tickets`);
		} catch (error) {
			console.log("Handle Unblock Contact Error:", error);
			toastError(error);
		}
	};



	// 	************
	// 	** Return **
	// 	************
	return (
		<>
			{/* 
				**********
				** Menu **
				**********
			*/}
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{ vertical: "bottom", horizontal: "right", }}
				keepMounted
				transformOrigin={{ vertical: "top", horizontal: "right", }}
				open={menuOpen}
				onClose={handleClose}
			>
				{/* ***---- Import Messages ----*** */}
				{showImportMessagesOption && (
					<MenuItem onClick={handleConfirmationImportMessagesOpen}>
						{i18n.t("contactModal.buttons.importMessages")}
					</MenuItem>
				)}

				{/* ***---- Edit Ticket ----*** */}
				{showEditTicketOption && (
					<MenuItem onClick={handleOpenEditTicketModal}>
						{i18n.t("ticketOptionsMenu.edit")}
					</MenuItem>
				)}

				{/* ***---- Block Contact ----*** */}
				{showBlockUnblockOption && !ticket.contact.isBlocked && (
					<MenuItem onClick={handleOpenBlockConfirmationModal}>
						{i18n.t("ticketOptionsMenu.block")}
					</MenuItem>
				)}

				{/* ***---- Unblock Contact ----*** */}
				{showBlockUnblockOption && ticket.contact.isBlocked && (
					<MenuItem onClick={handleOpenUnblockConfirmationModal}>
						{i18n.t("ticketOptionsMenu.unblock")}
					</MenuItem>
				)}

				{/* ***---- Search Message ----*** */}
				{showSearchMessageOption && (
					<MenuItem onClick={handleOpenSearchMessageModal}>
						{i18n.t("ticketOptionsMenu.searchMessage")}
					</MenuItem>
				)}

				{/* ***---- Create Note ----*** */}
				{showNoteOption && (
					<MenuItem onClick={handleOpenNoteModal}>
						{i18n.t("ticketOptionsMenu.note")}
					</MenuItem>
				)}

				{/* ***---- Transfer and Delete Open Tickets ----*** */}
				{showTransferAndDeleteOptions && (
					<>
						{/* ***---- Transfer Ticket ----*** */}
						{showTransferOption && (
							<MenuItem onClick={handleOpenTransferModal}>
								{i18n.t("ticketOptionsMenu.transfer")}
							</MenuItem>
						)}

						{/* ***---- Delete Ticket ----*** */}
						{showDeleteOption && (
							<MenuItem onClick={handleOpenConfirmationModal}>
								{i18n.t("ticketOptionsMenu.delete")}
							</MenuItem>
						)}
					</>
				)}

				{/* ***---- Delete Group Tickets ----*** */}
				{showDeleteGroupOption && (
					<MenuItem onClick={handleOpenConfirmationModal}>
						{i18n.t("ticketOptionsMenu.delete")}
					</MenuItem>
				)}
			</Menu>
			


			{/* 
				*************************
				** Confirmation Modals **
				*************************
			*/}

			{/* ***---- Block Contact ----*** */}
			<ConfirmationModal
				title={i18n.t("contacts.confirmationModal.blockTitle")}
				open={blockContactConfirmationOpen}
				onClose={() => setBlockContactConfirmationOpen(false)}
				onConfirm={() => handleBlockContact(ticket.contact.id)}
			>
				{i18n.t("contacts.confirmationModal.blockMessage")}
			</ConfirmationModal>

			{/* ***---- Unblock Contact ----*** */}
			<ConfirmationModal
				title={i18n.t("contacts.confirmationModal.unblockTitle")}
				open={unblockContactConfirmationOpen}
				onClose={() => setUnblockContactConfirmationOpen(false)}
				onConfirm={() => handleUnblockContact(ticket.contact.id)}
			>
				{i18n.t("contacts.confirmationModal.unblockMessage")}
			</ConfirmationModal>
			
			{/* ***---- Import Messages ----*** */}
			<ConfirmationModal
				title={i18n.t("contactModal.confirmationModal.titleBaileys")}
				open={confirmationImportMessagesOpen}
				onClose={handleConfirmationImportMessagesClose}
				onConfirm={async () => { await handleImportMessages() }}
			>
				{i18n.t("contactModal.confirmationModal.messageBaileys")}
			</ConfirmationModal>
			
			{/* ***---- Delete Ticket ----*** */}
			<ConfirmationModal
				title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}
							  ${ticket.id}
							  ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")}
								${ticket.type === 0 ? ticket.contact.name: ticket.noteName}?`}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteTicket}
			>
				{i18n.t("ticketOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>



			{/* 
				************
				** Modals **
				************
			*/}

			{/* ***---- Search Message ----*** */}
			{showSearchMessageOption && (
				<SearchMessageModal
					modalOpen={searchMessageModalOpen}
					onClose={handleCloseSearchMessageModal}
					ticketId={ticket.id}
					whatsappId={ticket.whatsappId}
					messagesListDispatch={messagesListDispatch}
				/>
			)}

			{/* ***---- Note ----*** */}
			{showNoteOption && (
				<NewNoteModal
					modalOpen={noteModalOpen}
					onClose={handleCloseNoteModal}
					contactId={ticket.contactId}
				/>
			)}
			
			{/* ***---- Transfer Ticket ----*** */}
			{showTransferAndDeleteOptions && showTransferOption && (
				<TransferTicketModal
					modalOpen={transferTicketModalOpen}
					onClose={handleCloseTransferTicketModal}
					ticketid={ticket.id}
					ticketType={ticket.type}
					ticketWhatsappId={ticket.whatsappId}
				/>
			)}

			{/* ***---- Edit Ticket ----*** */}
			{showEditTicketOption && (
				<EditTicketModal
					modalOpen={editTicketModalOpen}
					onClose={handleCloseEditTicketModal}
					ticket={ticket}
					ticketStatus={ticket.status}
				/>
			)}
		</>
	);
};

export default TicketOptionsMenu;
