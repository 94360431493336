import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogContent, DialogTitle, Tooltip } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import truncateString from "../../utils/truncateString";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "1em",
  },

  eventNameDescriptionContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "3px",
  },

  otherDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    gap: "3px",
  },

  otherDetailsIconContainer: {
    fontSize: "1.25em",
  },

  otherDetailsContentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "3px",
  },

  interactiveDetail: {
    cursor: "pointer",
    color: theme.palette.secondary.ackCheck,
    "&:hover": { textDecoration: "underline", },
  },
}));

const EventDetailsModal = ({ open, onClose, eventDetails }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();

  const [isJoinLinkCopied, setIsJoinLinkCopied] = useState(false);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => { onClose(); };

  // ***---- Formatters ----***
  const formatGoogleCalendarDate = () => {
    const date = new Date(parseInt(eventDetails.startTimestamp, 10));
    const isoString = date.toISOString();
    const initialDateTime = isoString.replace(/[-:]/g, "").split(".")[0] + "Z";

    const datePlusOneHour = new Date(date.getTime() + 60 * 60 * 1000);
    const isoStringPlusOneHour = datePlusOneHour.toISOString();
    const finalDateTime = isoStringPlusOneHour.replace(/[-:]/g, "").split(".")[0] + "Z";

    return `${initialDateTime}/${finalDateTime}`;
  };

  const formatGoogleCalendarUrl = () => {
    const baseUrl = "https://calendar.google.com/calendar/u/0/r/eventedit?";
    const eventName = `text=${encodeURIComponent(eventDetails.name)}`;
    const eventDescription = `&details=${encodeURIComponent(eventDetails.description)}`;
    const eventLocationName = `&location=${encodeURIComponent(eventDetails.locationName)}`;
    const eventDates = `&dates=${formatGoogleCalendarDate()}`;
    return `${baseUrl}${eventName}${eventDescription}${eventLocationName}${eventDates}`;
  };

  const formatGoogleMapUrl = () => {
    const baseUrl = "https://www.google.com/maps/search/?api=1&query=";
    return `${baseUrl}${encodeURIComponent(eventDetails.locationName)}`
  };

  // ***---- Redirecters ----***
  const redirectToCalendar = () => {
    const redirectUrl = formatGoogleCalendarUrl();
    window.open(redirectUrl, "_blank");
  };

  const redirectToLocation = () => {
    const redirectUrl = formatGoogleMapUrl();
    window.open(redirectUrl, "_blank");
  };
  
  // ***---- Copiers ----***
  const copyCallLink = async () => {
    setIsJoinLinkCopied(true);
    await navigator.clipboard.writeText(eventDetails.joinLink);
    setTimeout(() => { setIsJoinLinkCopied(false); }, 500);
  };



  //  ************
  //  ** Return **
  //  ************
  if (open) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        scroll="paper"
        fullWidth
      >
        <DialogTitle>
          {i18n.t("eventDetailsModal.title.up")}
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {/* 
            **************************
            ** Name and Description **
            **************************
          */}
          <div className={classes.eventNameDescriptionContainer}>
            <span><b>{eventDetails.name}</b></span>
            <span>{eventDetails.description}</span>
          </div>



          {/* 
            *********************
            ** Start Date Time **
            *********************
          */}
          <div className={classes.otherDetailsContainer}>
            <div className={classes.otherDetailsIconContainer}>
              <span>📆</span>
            </div>

            <div className={classes.otherDetailsContentContainer}>
              <span>{eventDetails.startDateTime}</span>
              <span className={classes.interactiveDetail} onClick={redirectToCalendar}>{i18n.t("eventDetailsModal.buttons.startDateTime")}</span>
            </div>
          </div>



          {/*
            **************
            ** Location **
            **************
          */}
          {eventDetails.locationName !== null && eventDetails.locationName !== undefined && (
            <div className={classes.otherDetailsContainer}>
              <div className={classes.otherDetailsIconContainer}>
                <span>📍</span>
              </div>

              <div className={classes.otherDetailsContentContainer}>
                <span>{truncateString(eventDetails.locationName, 27)}</span>
                <span className={classes.interactiveDetail} onClick={redirectToLocation}>{i18n.t("eventDetailsModal.buttons.location")}</span>
              </div>
            </div>
          )}
          


          {/* 
            **********
            ** Call **
            **********
          */}
          {eventDetails.joinLink !== null && eventDetails.joinLink !== undefined && (
            <div className={classes.otherDetailsContainer}>
              <div className={classes.otherDetailsIconContainer}>
                <span>{eventDetails.isVideoCall ? "📹" : "📞"}</span>
              </div>

              <div className={classes.otherDetailsContentContainer}>
                <span>{eventDetails.isVideoCall ? (i18n.t("eventPreview.videoCall")) : (i18n.t("eventPreview.voiceCall"))}</span>
                
                <Tooltip
                  title={isJoinLinkCopied ? i18n.t("eventDetailsModal.tooltips.copied") : ""}
                  placement="top-start"
                  arrow
                >
                  <span className={classes.interactiveDetail} onClick={copyCallLink}>{i18n.t("eventDetailsModal.buttons.call")}</span>
                </Tooltip>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    );
  }

  else {
    return <></>;
  }
};

export default EventDetailsModal;
