import { useEffect } from "react";

import { useSocketAuthenticated } from "../../SocketContext";
import api from "../../../../services/api";

const SocketDispatchSendingsManager = ({ dispatch }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { getSocket } = useSocketAuthenticated();



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    const socket = getSocket();

    if (socket) {
      const handleDispatchSendings = (data) => {
        if ((data.action === "update" || data.action === "create") && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          const dispatchSending = data.dispatchSending;

          const transformedDispatchSending = {
            id: dispatchSending.id,
            contactName: dispatchSending.dispatchContact.contact
              ? dispatchSending.dispatchContact.contact.name
              : dispatchSending.dispatchContact.additionalContact,
            contactNumber: dispatchSending.dispatchContact.contact
              ? dispatchSending.dispatchContact.contact.number
              : dispatchSending.dispatchContact.additionalContact,
            contactLabels: dispatchSending.dispatchContact.contact?.labels
              ? dispatchSending.dispatchContact.contact.labels.map(label => { return { id: label.id, name: label.name }; })
              : [],
            message: dispatchSending.dispatchMessage.body,
            status: dispatchSending.status,
            errorDescription: dispatchSending.errorDescription,
            lastUpdate: dispatchSending.updatedAt
          };

          dispatch({ type: "UPDATE_DISPATCH_SENDINGS", payload: transformedDispatchSending });
        }

        if ((data.action === "delete") && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          dispatch({ type: "DELETE_DISPATCH_SENDING", payload: +data.dispatchSendingId });
        }
      };

      socket.on("dispatchManagerSendings", handleDispatchSendings);

      return () => {
        socket.off("dispatchManagerSendings", handleDispatchSendings);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);



  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketDispatchSendingsManager;
