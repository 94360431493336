import React, { useState, useEffect, useContext } from "react"
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles"
import {
	Container,
	Grid,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ChartTicketsPerQueue from "./Charts/TicketsPerQueue/Chart";
import ChartTicketsPerQueueTitle from "./Charts/TicketsPerQueue/Title";
import ChartTicketsPerSubqueue from "./Charts/TicketsPerSubqueue/Chart";
import ChartTicketsPerSubqueueTitle from "./Charts/TicketsPerSubqueue/Title";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import NewContactsModal from "../../components/NewContactsModal";
import Title from "../../components/Title";
import useAttendanceTime from "../../hooks/useAttendanceTime";
import useAwaitingTime from "../../hooks/useAwaitingTime";
import useClosedTickets from "../../hooks/useClosedTickets";
import useCountTickets from "../../hooks/useCountTickets";
import useNewContactsCount from "../../hooks/useNewContactsCount";

const useStyles = makeStyles(theme => ({
	mainContainerScroll: { overflowY: "scroll", ...theme.scrollbarStyles, paddingBottom: "20px", },

	container: { paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), },

	flexContainer: {
		display: "flex",
		justifyContent: "right",
		alignItems: "baseline",
		gap: "15px",
		paddingRight: "10px",
	},

	fixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
		height: "240px",
	},

	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "hidden",
		flexDirection: "column",
		height: 120,
		transition: 'transform 0.3s',
		position: "relative",
		'&:hover': { filter: "brightness(0.85)", transform: 'translateY(-5px)', },
	},

	mainContainer: { height: "100%", },

	dashboardContainer: {
		width: "100%",
		height: "400px",
		backgroundColor: theme.palette.background.paper,
		borderRadius: "5px",
		padding: "20px 0px 20px 0px",
		textIndent: "15px",
	},

	sectionContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		flexWrap: "wrap",
		gap: "10px",
		[theme.breakpoints.down('sm')]: { justifyContent: "center", gap: "10px", },
	},

	cardItem: { width: "400px", },

	nonFloatingButton: { color: theme.palette.text.primary, },

	floatingButton: {
    transition: 'transform 0.3s',
		color: theme.palette.text.primary,
    '&:hover': { transform: 'translateY(-5px)', },
  },

	actionButton: { "&:hover": { color: theme.palette.primary.main, }, },
}))

const Dashboard = () => {
	// ***************
	// ** Variables **
	// ***************
	const contactType = 0;
	const classes = useStyles();
	const { user } = useContext(AuthContext);

	const [initialDate, setInitialDate] = useState();
	const [finalDate, setFinalDate] = useState();
	const [newContactsModalOpen, setNewContactsModalOpen] = useState(false);
	const [queuesCount, setQueuesCount] = useState(0);
	const [subqueuesCount, setSubqueuesCount] = useState(0);

	const userQueueIds = user.queues && user.queues.length > 0
		? []
		: user.queues.map(q => q.id);
	



	// *****************
	// ** Use Effects **
	// *****************
	useEffect(() => {
		const today = new Date();		
		let day = today.getDate();
		let month = today.getMonth() + 1;
		let year = today.getFullYear();

		if (month < 10) { month = '0' + month.toString(); }
		if (day < 10){ day = '0' + day.toString(); }

	 	setInitialDate(`${year}-${month}-${day}`);
		setFinalDate(`${year}-${month}-${day}`);		
  }, []);

	useEffect(() => {
		const delayDebounceFN = setTimeout(() => {
			const countQueues = async() => {
				const { data: count1 } = await api.get("/queueCount");
				const { data: count2 } = await api.get("/subqueuesCount");
				setQueuesCount(count1);
				setSubqueuesCount(count2);
			};
			countQueues();
		});
		return () => clearTimeout(delayDebounceFN);
	}, []);


	// ***************
	// ** Functions **
	// ***************
	const GetTickets = (status, showAll, considerInternalChats) => {
		const { count } = useCountTickets({
			status: status,
			showAll: showAll,
			queueIds: JSON.stringify(userQueueIds),
			considerInternalChats,
			userId: user.id,
			userProfile: user.profile
		});
		return count;
	}

	const GetClosedTicketsBetweenDates = (initialDate, finalDate, considerInternalChats) => {
		const { count } = useClosedTickets({ initialDate, finalDate, considerInternalChats });
		return count;
	}

	const handleOpenNewContactsModal = () => {
		setNewContactsModalOpen(true);
	};

	const handleCloseNewContactsModal = () => {
		setNewContactsModalOpen(false);
	};

	const GetNewContacts = (initialDate, finalDate) => {
		const { count } = useNewContactsCount({initialDate, finalDate, contactType});
		return count;
	}

	const GetAttendanceTime = (initialDate, finalDate, considerInternalChats) => {
		const { avg } = useAttendanceTime({ initialDate, finalDate, considerInternalChats });

		let time;

		// Caso não há nenhum atendimento com a propriedade `withUserAt` diferente
		// de NULL OU a média de espera é menor do que 2, "1 minuto" é retornado.
		//
		// Também é retornada a mesma coisa quando o tempo ainda não foi buscado no
		// banco de dados.
		if (isNaN(parseInt(avg.data)) || avg.data < 2) {
			time = `1 ${i18n.t("dashboard.messages.time.minute")}`;
		}

		// Caso contrário, é retornado a média de espera.
		else {
			
			// Convertendo tempo em minutos
			if (avg.data < 60) {
				time = parseInt(avg.data) + ` ${i18n.t("dashboard.messages.time.minutes")}`;
			}

			// Convertendo tempo em horas
			else {
				const timeHours = ~~(parseInt(avg.data) / 60);

				if (timeHours > 1) {
					time = timeHours + ` ${i18n.t("dashboard.messages.time.hours")}`;
				}
				else {
					time = timeHours + ` ${i18n.t("dashboard.messages.time.hour")}`;
				}
			}
		}

		return time;
	}

	const GetAwaitingTime = (initialDate, finalDate, considerInternalChats) => {
		const { avg } = useAwaitingTime({ initialDate, finalDate, considerInternalChats });

		let time;

		// Caso não há nenhum atendimento com a propriedade `withUserAt` diferente
		// de NULL OU a média de espera é menor do que 2, "1 minuto" é retornado.
		if (isNaN(parseInt(avg.data)) || avg.data < 2) {
			time = `1 ${i18n.t("dashboard.messages.time.minute")}`;
		}

		// Caso contrário, é retornado a média de espera.
		else {


			// Convertendo tempo em minutos
			if (avg.data < 60) {
				time = parseInt(avg.data) + ` ${i18n.t("dashboard.messages.time.minutes")}`;
			}

			// Convertendo tempo em horas
			else {
				const timeHours = ~~(parseInt(avg.data) / 60);

				if (timeHours > 1) {
					time = timeHours + ` ${i18n.t("dashboard.messages.time.hours")}`;
				}
				else {
					time = timeHours + ` ${i18n.t("dashboard.messages.time.hour")}`;
				}
			}
		}

		return time;
	}

	const handleFilterDate = (newDate, flagInitialDate) => {
		const dashboardInitialDate = document.getElementById("dashboardInitialDate");
		const dashboardFinalDate = document.getElementById("dashboardFinalDate");
		
		if (dashboardInitialDate.value === "" || dashboardFinalDate.value === "") { 
			dashboardInitialDate.value = initialDate;
			dashboardFinalDate.value = finalDate;
		}
		else if (dashboardInitialDate.value > dashboardFinalDate.value) { toast.info(i18n.t("backendErrors.DASHBOARD_INITIAL_DATE_AFTER_FINAL_DATE")); }
		else if (flagInitialDate) { setInitialDate(newDate.target.value); }
		else { setFinalDate(newDate.target.value); }
	}

	const handleChangeInitialDate = (newDate) => { handleFilterDate(newDate, true); }
	const handleChangeFinalDate = (newDate) => { handleFilterDate(newDate, false); }



	// ************
	// ** Return **
	// ************
	return (
		<MainContainer>

			<NewContactsModal
        open={newContactsModalOpen}
        onClose={handleCloseNewContactsModal}
        initialDate={initialDate}
				finalDate={finalDate}
				contactType={contactType}
      />
			
			{/* 
					***********
					** Title **
					***********
			*/}
			<MainHeader>
				<Title>{i18n.t("generalDashboard.title")}</Title>
			</MainHeader>



			{/* 
				**********
				** Info **
				**********
			*/}
			<div className={classes.mainContainerScroll}>
				<Container maxWidth="lg" className={classes.container}>
					<Grid container spacing={3} className={classes.mainContainer}>

						{/* 
								*****************
								** Date Inputs **
								***************** 
						*/}
						<Grid item xs={12}>
							<div className={classes.flexContainer}>
								<TextField
									variant="outlined"
									margin="normal"
									type="date"
									id="dashboardInitialDate"
									name="dashboardInitialDate"
									label={i18n.t("dashboard.fields.initialDate")}
									value={initialDate}
									onChange={handleChangeInitialDate}
								/>
								<TextField
									variant="outlined"
									margin="normal"
									type="date"
									id="dashboardFinalDate"
									name="dashboardFinalDate"
									label={i18n.t("dashboard.fields.finalDate")}
									value={finalDate}
									onChange={handleChangeFinalDate}
								/>
							</div>
						</Grid>

						{/* 
								***********
								** Cards **
								***********
						*/}
						<Grid item xs={12} className={classes.sectionContainer}>

							{/* Open Tickets */}
							<Grid className={classes.cardItem}>
								<Paper className={classes.customFixedHeightPaper}>
									<Typography component="h3" variant="h6" paragraph>
										{i18n.t("dashboard.messages.inAttendance.title")}
									</Typography>

									<Grid item>
										<Typography component="h1" variant="h4">
											{GetTickets("open", "true", false)}
										</Typography>
									</Grid>
								</Paper>
							</Grid>

							{/* Pending Tickets */}
							<Grid className={classes.cardItem}>
								<Paper className={classes.customFixedHeightPaper}>
									<Typography component="h3" variant="h6" paragraph>
										{i18n.t("dashboard.messages.waiting.title")}
									</Typography>

									<Grid item>
										<Typography component="h1" variant="h4">
											{GetTickets("pending", "true", false)}
										</Typography>
									</Grid>
								</Paper>
							</Grid>

							{/* Closed Tickets */}
							<Grid className={classes.cardItem}>
								<Paper className={classes.customFixedHeightPaper}>
									<Typography component="h3" variant="h6" paragraph>
										{i18n.t("dashboard.messages.closed.title")}
									</Typography>

									<Grid item>
										<Typography component="h1" variant="h4">
											{GetClosedTicketsBetweenDates(`${initialDate}T00:00:00`, `${finalDate}T23:59:59`, false)}
										</Typography>
									</Grid>
								</Paper>
							</Grid>

							{/* New Contacts */}
							<Grid className={classes.cardItem}>
								<Paper className={classes.customFixedHeightPaper}>
									<Typography component="h3" variant="h6" paragraph>
										{i18n.t("dashboard.messages.newContacts.title")}
									</Typography>

									<Grid item>
										<Typography component="h1" variant="h4">
											{GetNewContacts(`${initialDate}T00:00:01`, `${finalDate}T23:59:59`)}
											&nbsp;
											{GetNewContacts(`${initialDate}T00:00:01`, `${finalDate}T23:59:59`) > 0 && (
												<IconButton size="small" onClick={handleOpenNewContactsModal}>
													<Visibility className={`${classes.floatingButton} ${classes.actionButton}`} />
												</IconButton>
											)}

											{GetNewContacts(`${initialDate}T00:00:01`, `${finalDate}T23:59:59`) === 0 && (
												<IconButton size="small">
													<VisibilityOff className={classes.nonFloatingButton} />
												</IconButton>
											)}
										</Typography>
									</Grid>
								</Paper>
							</Grid>

							{/* Avg Awaiting Time */}
							<Grid className={classes.cardItem}>
								<Paper className={classes.customFixedHeightPaper}>
									<Typography component="h3" variant="h6" paragraph>
										{i18n.t("dashboard.messages.awaitingTime.title")}
									</Typography>

									<Grid item>
										<Typography component="h1" variant="h4">
										{GetAwaitingTime(`${initialDate}T00:00:01`, `${finalDate}T23:59:59`, false)}
										</Typography>
									</Grid>
								</Paper>
							</Grid>

							{/* Avg Attendance Time */}
							<Grid className={classes.cardItem}>
								<Paper className={classes.customFixedHeightPaper}>
									<Typography component="h3" variant="h6" paragraph>
										{i18n.t("dashboard.messages.attendanceTime.title")}
									</Typography>
									
									<Grid item>
										<Typography component="h1" variant="h4">
											{GetAttendanceTime(`${initialDate}T00:00:01`, `${finalDate}T23:59:59`, false)}
										</Typography>
									</Grid>
								</Paper>
							</Grid>
						</Grid>

		

						{/* 
								***********
								** Plots **
								***********
						*/}
						{queuesCount !== 0 && (
							<>
								{/* Tickets Per Queue */}
								<Grid item xs={12}>
									<Paper className={classes.dashboardContainer}>
										<ChartTicketsPerQueueTitle />
										<ChartTicketsPerQueue initialDate={initialDate} finalDate={finalDate} />
									</Paper>
								</Grid>
								
								{subqueuesCount !== 0 && (
									// Tickets Per Subqueue
									<Grid item xs={12} className={classes.mainContainer}>
										<Paper className={classes.dashboardContainer}>
											<ChartTicketsPerSubqueueTitle />
											<ChartTicketsPerSubqueue initialDate={initialDate} finalDate={finalDate} />
										</Paper>
									</Grid>
								)}
							</>
						)}
					</Grid>   
				</Container>
			</div>
		</MainContainer>
	)
}

export default Dashboard;