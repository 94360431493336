const formatDefaultValueForDatetimeInput = (datetime) => {
  const datetimeToFormat = datetime ? datetime : new Date();

  const year = datetimeToFormat.getFullYear();
  const month = (datetimeToFormat.getMonth() + 1).toString().padStart(2, "0");
  const day = datetimeToFormat.getDate().toString().padStart(2, "0");

  const hours = datetimeToFormat.getHours().toString().padStart(2, "0");
  const minutes = datetimeToFormat.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export default formatDefaultValueForDatetimeInput;
