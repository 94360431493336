import React, { useState, useEffect } from "react"
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles"
import {
	Container,
	Grid,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { i18n } from "../../translate/i18n";
import ChartTicketsPerConnection from "./Charts/TicketsPerConnection/Chart";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import NewContactsModal from "../../components/NewContactsModal";
import TicketsPerConnectionTitle from "./Charts/TicketsPerConnection/Title";
import Title from "../../components/Title";
import useCountGroupsTickets from "../../hooks/useCountGroupsTickets";
import useNewContactsCount from "../../hooks/useNewContactsCount";

const useStyles = makeStyles(theme => ({
	mainContainerScroll: { overflowY: "scroll", ...theme.scrollbarStyles, paddingBottom: "20px", },

	container: { paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), },

	flexContainer: {
		display: "flex",
		justifyContent: "right",
		alignItems: "baseline",
		gap: "15px",
		paddingRight: "10px",
	},

	customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "hidden",
		flexDirection: "column",
		height: 120,
		transition: 'transform 0.3s',
		position: "relative",
		'&:hover': { filter: "brightness(0.85)", transform: 'translateY(-5px)', },
	},

	mainContainer: { height: "100%", },

	dashboardContainer: {
		width: "100%",
		height: "400px",
		backgroundColor: theme.palette.background.paper,
		borderRadius: "5px",
		padding: "20px 0px 20px 0px",
		textIndent: "15px",
	},

	sectionContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		flexWrap: "wrap",
		gap: "10px",
		[theme.breakpoints.down('sm')]: { justifyContent: "center", gap: "10px", },
	},

	cardItem: { width: "400px", },

	nonFloatingButton: { color: theme.palette.text.primary, },

	floatingButton: {
    transition: 'transform 0.3s',
		color: theme.palette.text.primary,
    '&:hover': { transform: 'translateY(-5px)', },
  },

	actionButton: { "&:hover": { color: theme.palette.primary.main, }, },
}))

const GroupsDashboard = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const contactType = 1;

  const classes = useStyles();

  const [initialDate, setInitialDate] = useState();
  const [finalDate, setFinalDate] = useState();
  
  const [newContactsModalOpen, setNewContactsModalOpen] = useState(false);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();

    if (month < 10) { month = "0" + month.toString(); }
    if (day < 10) { day = "0" + day.toString(); }

    setInitialDate(`${year}-${month}-${day}`);
    setFinalDate(`${year}-${month}-${day}`);
  }, []);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenNewContactsModal = () => {
    setNewContactsModalOpen(true);
  };

  const handleCloseNewContactsModal = () => {
    setNewContactsModalOpen(false);
  };

  const GetTickets = () => {
    const { count } = useCountGroupsTickets();
    return count;
  };

  const GetNewContacts = (initialDate, finalDate) => {
    const { count } = useNewContactsCount({ initialDate, finalDate, contactType });
    return count;
  };

  const handleFilterDate = (newDate, flagInitialDate) => {
    const dashboardInitialDate = document.getElementById("dashboardInitialDate");
    const dashboardFinalDate = document.getElementById("dashboardFinalDate");

    if (dashboardInitialDate.value === "" || dashboardFinalDate.value === "") {
      dashboardInitialDate.value = initialDate;
      dashboardFinalDate.value = finalDate;
    }
    else if (dashboardInitialDate.value > dashboardFinalDate.value) toast.info(i18n.t("backendErrors.DASHBOARD_INITIAL_DATE_AFTER_FINAL_DATE"));
    else if (flagInitialDate) setInitialDate(newDate.target.value);
    else setFinalDate(newDate.target.value);
  };

  const handleChangeInitialDate = (newDate) => {
    handleFilterDate(newDate, true);
  }

  const handleChangeFinalDate = (newDate) => {
    handleFilterDate(newDate, false);
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <MainContainer>
      <NewContactsModal
        open={newContactsModalOpen}
        onClose={handleCloseNewContactsModal}
        initialDate={initialDate}
        finalDate={finalDate}
        contactType={contactType}
      />

      {/* 
        ***********
        ** Title **
        ***********
      */}
      <MainHeader>
        <Title>{i18n.t("groupsDashboard.title")}</Title>
      </MainHeader>



      {/* 
        **********
        ** Info **
        **********
      */}
      <div className={classes.mainContainerScroll}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3} className={classes.mainContainer}>
            {/* 
              *****************
              ** Date Inputs **
              *****************
            */}
            <Grid item xs={12}>
              <div className={classes.flexContainer}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  type="date"
                  id="dashboardInitialDate"
                  name="dashboardInitialDate"
                  label={i18n.t("dashboard.fields.initialDate")}
                  value={initialDate}
                  onChange={handleChangeInitialDate}
                />

                <TextField
                  variant="outlined"
                  margin="normal"
                  type="date"
                  id="dashboardFinalDate"
                  name="dashboardFinalDate"
                  label={i18n.t("dashboard.fields.finalDate")}
                  value={finalDate}
                  onChange={handleChangeFinalDate}
                />
              </div>
            </Grid>



            {/* 
              ***********
              ** Cards **
              ***********
            */}
            <Grid item xs={12} className={classes.sectionContainer}>
              
              {/* Tickets Count */}
              <Grid className={classes.cardItem}>
                <Paper className={classes.customFixedHeightPaper}>
                  <Typography component="h3" variant="h6" paragraph>
                    {i18n.t("groupsDashboard.cards.chats")}
                  </Typography>

                  <Grid item>
                    <Typography component="h1" variant="h4">
                      {GetTickets("group", "true", false)}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>

              {/* New Contacts */}
              <Grid className={classes.cardItem}>
                <Paper className={classes.customFixedHeightPaper}>
                  <Typography component="h3" variant="h6" paragraph>
                    {i18n.t("groupsDashboard.cards.newGroups")}
                  </Typography>

                  <Grid item>
                    <Typography component="h1" variant="h4">
                      {GetNewContacts(`${initialDate}T00:00:01`, `${finalDate}T23:59:59`)}
                      &nbsp;
                      {GetNewContacts(`${initialDate}T00:00:01`, `${finalDate}T23:59:59`) > 0 && (
                        <IconButton size="small" onClick={handleOpenNewContactsModal}>
                          <Visibility className={`${classes.floatingButton} ${classes.actionButton}`} />
                        </IconButton>
                      )}

                      {GetNewContacts(`${initialDate}T00:00:01`, `${finalDate}T23:59:59`) === 0 && (
                        <IconButton size="small">
                          <VisibilityOff className={classes.nonFloatingButton} />
                        </IconButton>
                      )}
                    </Typography>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>



            {/* 
              ***********
              ** Plots **
              ***********
            */}
            <Grid item xs={12}>
              <Paper className={classes.dashboardContainer}>
                <TicketsPerConnectionTitle />
                <ChartTicketsPerConnection initialDate={initialDate} finalDate={finalDate} />
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </MainContainer>
  );
};

export default GroupsDashboard;