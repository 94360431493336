import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Alert from '@material-ui/lab/Alert';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Typography,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import UploadCSVImg from '../../assets/uploadCSV.svg';

const useStyles = makeStyles(theme => ({
	root: { display: "flex", flexWrap: "wrap", },
  dialogContent: { padding: "25px", ...theme.scrollbarStyles, },
  uploadInput: { display: "none", },
  badge: { backgroundColor: "red", color: "white", padding: "5px", borderRadius: "10px", },

  uploadArea: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "15px",
    cursor: "pointer",
    userSelect: "none",

    borderRadius: "20px",
    padding: "10px",
    border: "10px dashed",

    "&:hover": { filter: "brightness(0.75)", },
  },

  uploadAreaImg: { width: "220px", },
  uploadAreaSpan: { fontSize: "16px", },
  btnWrapper: { position: "relative", },

  floatingButton: {
		transition: 'transform 0.3s',
		'&:hover': { transform: 'translateY(-5px)', },
	},

  textField: { marginRight: theme.spacing(1), flex: 1, width: "100%", },
  importationProgress: { display: "flex", flexDirection: "column", gap: "5px", },

  importationOptions: {
    display: "flex",
    flexDirection: "row",
    gap: "5px",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

const ImportCSVModal = ({ open, onClose }) => {
	//
  //  ***---- Datas ----***
  //
  const classes = useStyles();
  const history = useHistory();
  const [media, setMedia] = useState([]);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState("---");
  const [invalidLines, setInvalidLines] = useState(false);

  const [contactsToImport, setContactsToImport] = useState(0);
  const [importedContacts, setImportedContacts] = useState(0);
  const [importingCSV, setImportingCSV] = useState(false);
  const expectedHeaders = ["ContactName", "ContactNumber", "ContactEmail", "ContactConnectionName"];  

  //
  // ***---- Functions ----***
  //
  const handleClose = () => {
    if (!importingCSV) {
      setContactsToImport(0);
      setImportedContacts(0);
      setInvalidLines(false);
      setFileUploaded(false);
      setFileName("---");
      setMedia([]);
      onClose();
    }
	};

  const handleChangeMedia = (e) => {
    if (!e.target.files) return;
    const acceptedFileFormat = ["csv"];
    const uploadedFileFormat = e.target.files[0].type.split("/").pop();

    if (acceptedFileFormat.includes(uploadedFileFormat)) {
      setMedia(e.target.files[0]);
      setFileName(e.target.files[0].name);
      setFileUploaded(true);
      setInvalidLines(false);
    }
    else {
      toast.info(i18n.t("importCSVModal.toasts.notSupportedFormat"));
      setMedia(JSON.stringify(media) !== JSON.stringify([]) ? media : []);
      setFileName(JSON.stringify(media) !== JSON.stringify([]) ? fileName : "---");
      setFileUploaded(JSON.stringify(media) !== JSON.stringify([]) ? fileUploaded : true);
    }
  };

  const handleExpectedHeaders = (headers) => {
    const sortedHeaders = headers.slice().sort();
    const sortedExpectedHeaders = expectedHeaders.slice().sort();
    return JSON.stringify(sortedHeaders) === JSON.stringify(sortedExpectedHeaders);
  };

  const handleImportCSV = async () => {

    //
    //  ***---- File not Uploaded ----***
    //
    if (!fileUploaded) {
      toast.info(i18n.t("importCSVModal.toasts.notUploadedFile"));
    }

    //
    //  ***---- No Available Connections ----***
    //
    else if ((await api.get("/connectedDefaultWhatsapp")).data.count === 0) {
      toast.info(i18n.t("importCSVModal.toasts.noDefaultWhatsappConnected"));
    }

    //
    //  ***---- File Uploaded ----***
    //
    else {
      const reader = new FileReader();

      // Function to be called when the reader finishes reading the file
      reader.onload = async (event) => {
        const numberVariables = 4; // [ContactName, ContactNumber, ContactEmail, ContactConnectionName]
        const text = event.target.result;
        const rows = text.split('\n');
        const headers = rows[0].split(',').length !== 1
          ? rows[0].split(',').map(header => header.trim())
          : rows[0].split(';').map(header => header.trim());
      
        // File has more than four variables
        if (headers.length > numberVariables) {
          toast.info(i18n.t("importCSVModal.toasts.moreVariablesThanLimit"));
        }

        // File has less than four variables
        else if (headers.length < numberVariables) {
          toast.info(i18n.t("importCSVModal.toasts.lessVariablesThanLimit"));
        }

        // File does not have expected headers
        else if (!handleExpectedHeaders(headers)) {
          toast.info(i18n.t("importCSVModal.toasts.headerNotExpected"));
        }

        // File has the right amount of variables
        else {
          setImportingCSV(true);
          const contacts = [];
          const { data: { contactsNumbers: existedContactsNumbers }} = await api.get("/contactsNumbers");

          // Loop through the remaining rows to create an array of objects
          for (let index = 1; index < rows.length; index++) {
            const row = rows[index].split(',').length !== 1
              ? rows[index].split(',')
              : rows[index].split(';');

            const rowData = {};
            try {
              for (let variableIndex = 0; variableIndex < headers.length; variableIndex++) {
                rowData[headers[variableIndex]] = row[variableIndex].replaceAll("\r", "").trim();
              }

              // If object has the required fields (ContactName and ContactNumber),
              // it's pushed into data array. Else, it's descarted.
              if (rowData["ContactName"] !== "" && rowData["ContactNumber"] !== "") { contacts.push(rowData); }
              else if (!invalidLines) { setInvalidLines(true); }
            } catch (e) { console.log(e); }
          }
        
          // ***---- Importation ----***
          setContactsToImport(contacts.length);

          const contactsPromise = contacts.map(async contact => {
            return new Promise(async (resolve, reject) => {
              if (existedContactsNumbers.some(item => item.number === contact["ContactNumber"])) {
                try {
                  const { data: { id: contactId } } = await api.get(`/contact/${contact["ContactNumber"]}`);
                  await api.put(`/contacts/CSVImport/${contactId}`, {
                    name: contact["ContactName"],
                    number: contact["ContactNumber"],
                    email: contact["ContactEmail"],
                    whatsappName: contact["ContactConnectionName"],
                  });
                  resolve();
                } catch (error) { console.log(error); }
              }
              else {
                try {
                  await api.post("/contacts/CSVImport", {
                    name: contact["ContactName"],
                    number: contact["ContactNumber"],
                    email: contact["ContactEmail"],
                    whatsappName: contact["ContactConnectionName"],
                  });
                  resolve();
                } catch (error) { console.log(error); }
              }
  
              setImportedContacts(prevCount => prevCount + 1);
              resolve();
            });
          });

          Promise.all(contactsPromise).then((result) => { history.go(0); });
        }
      };      

      reader.readAsText(media);
    }
  };


  //
  //  ***---- Return ----***
  //
	return (
		<div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">

        {/* 
          ***********
          ** Title **
          ***********
        */}
        <DialogTitle id="form-dialog-title">{i18n.t("importCSVModal.title")}</DialogTitle>



        {/* 
          *************
          ** Content **
          *************
        */}
        <DialogContent dividers className={classes.dialogContent}>
          
          {/* Not Importing CSV */}
          {!importingCSV && (
            <>
            {/* Upload Input */}
            <input
              type="file"
              id="uploadCSV-button"
              className={classes.uploadInput}
              onChange={handleChangeMedia}
              accept=".csv"
            />

            {/* Upload Area */}
            <label className={classes.uploadArea} htmlFor="uploadCSV-button" disabled>
              <img id="uploadImg" className={classes.uploadAreaImg} src={UploadCSVImg} alt="Import CSV File" />
              <span className={classes.uploadAreaSpan}>{i18n.t("importCSVModal.importSpan")}</span>
            </label>

            <br />

            <Alert severity="info">
              {i18n.t("importCSVModal.info1")}
              <br />
              {i18n.t("importCSVModal.info2")}
            </Alert>

            <br />

            {invalidLines && (<Alert severity="warning">{i18n.t("importCSVModal.warning")}</Alert>)}
            </>
          )}

          {/* Importing CSV */}
          {importingCSV && (
            <div className={classes.importationProgress}>
              <LinearProgress 
                variant="determinate" 
                value={importedContacts * 100 / contactsToImport}
              />

              <div className={classes.importationOptions}>
                <span>
                  {
                    `${i18n.t("importCSVModal.importationWords.imported")}
                    ${importedContacts}
                    ${i18n.t("importCSVModal.importationWords.of")}
                    ${contactsToImport}
                    ${i18n.t("importCSVModal.importationWords.contacts")}`
                  }
                </span>
              </div>
            </div>
          )}

          <br />
          <br />

          {/* Fields Area */}
          
          {/* Uploaded File Name */}
          <Typography>{i18n.t("importCSVModal.fileName")} <span className={classes.badge}>{fileName}</span></Typography>

          <br />
        </DialogContent>



        {/* 
          *************
          ** Actions **
          *************
        */}
        <DialogActions>
          <Button
						onClick={handleClose}
						color="inherit"
						variant="outlined"
						className={classes.floatingButton}
            disabled={importingCSV}
					>
						{i18n.t("importCSVModal.buttons.cancel")}
					</Button>

          <Button
            onClick={async () => await handleImportCSV()}
            type="submit"
            color="primary"
            variant="contained"
            className={`${classes.btnWrapper} ${classes.floatingButton}`}
            disabled={importingCSV}
          >
            {i18n.t("importCSVModal.buttons.import")}
          </Button>
        </DialogActions>
      </Dialog>
		</div>
	);
};

export default ImportCSVModal;
