import { useEffect } from "react";

import { useSocketAuthenticated } from "../../SocketContext";
import api from "../../../../services/api";

const SocketSettings = ({ setSettings }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { getSocket } = useSocketAuthenticated();



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    const socket = getSocket();

    if (socket) {
      const handleSetting = (data) => {
        if (data.action === "update" && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)) {
          setSettings(prevState => {
            const aux = [...prevState];
            const settingIndex = aux.findIndex(s => s.key === data.setting.key);
            aux[settingIndex].value = data.setting.value;
            return aux;
          });
        }
      };
      
      socket.on("settings", handleSetting);
      
      return () => {
        socket.off("settings", handleSetting);
      };
    }
		
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setSettings]);


  
  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketSettings;