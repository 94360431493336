import { useEffect } from "react";

import { useSocketAuthenticated } from "../../SocketContext";
import api from "../../../../services/api";

const SocketMessagesList = ({
  dispatch,
  setAreThereNewMessagesScroll,
  shouldDisplayScrollButton,
  scrollToBottom,
  setFilesExistenceDictionary
}) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { getSocket } = useSocketAuthenticated();



  //  ****************
  //  ** Use Effect **
  //  ****************
  useEffect(() => {
    const socket = getSocket();

    if (socket) {
      const handleAppMessage = (data) => {
        if (
          (data.action === "createAppMessageTicket")
          && (data.message.ticketId.toString() === window.selectedTicketId)
          && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)
        ) {
          dispatch({ type: "ADD_MESSAGE", payload: data.message });

          if (data.message.mediaUrl) {
            setFilesExistenceDictionary(previousValue => {
              return { ...previousValue, [data.message.mediaUrl]: true };
            });
          }
          
          if (shouldDisplayScrollButton) setAreThereNewMessagesScroll(true);

          // ***---- scrollToBottom ----***
          //
          // - (scrollButton is not visible)
          // - OR (message has been sent by the user
          // - AND message has been sent from BestZap [userId is not null nor undefined])
          if (!shouldDisplayScrollButton || (data.message.fromMe && data.message.userId)) {
            setAreThereNewMessagesScroll(false);
            scrollToBottom(true);
          }
        }
  
        if (
          (data.action === "updateAppMessage")
          && (data.ticketId.toString() === window.selectedTicketId)
          && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)
        ) {
          dispatch({ type: "UPDATE_MESSAGE", payload: data.message });

          if (data.message.mediaUrl) {
            setFilesExistenceDictionary(previousValue => {
              return { ...previousValue, [data.message.mediaUrl]: true };
            });
          }
        }
  
        if (
          (data.action === "transcribeAppMessage")
          && (data.ticketId.toString() === window.selectedTicketId)
          && (`${api.defaults.headers.tenantId}` === `${data.tenantId}`)
        ) {
          dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
        }
      };
  
      socket.on("appMessage", handleAppMessage);
  
      return () => {
        socket.off("appMessage", handleAppMessage);
      };
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setAreThereNewMessagesScroll, shouldDisplayScrollButton, scrollToBottom, setFilesExistenceDictionary]);

  //  ************
  //  ** Return **
  //  ************
  return (<></>);
};

export default SocketMessagesList;