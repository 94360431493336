import React, { useState } from "react";
import { parseISO, format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { FileCopyOutlined } from "@material-ui/icons";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Tooltip,
} from "@material-ui/core";

import { i18n } from "../../translate/i18n";

const useStyles = makeStyles(theme => ({
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    gap: "1em",
  },

  infoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    width: "100%",
  },

  floatingButton: {
		transition: 'transform 0.3s',
		'&:hover': { transform: 'translateY(-5px)', },
	},
}));

const MessagePropertiesModal = ({ open, onClose, message }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const [copyTooltip, setCopyTooltip] = useState(i18n.t("messagePropertiesModal.tooltips.copy"));



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => { onClose(); };

  const handleCopyProperties = async () => {
    const isInternalMessage = ["internal", "transference_observation"].includes(message.mediaType);
    const date = format(parseISO(message.createdAt), "dd/MM/yyyy");
    const hour = format(parseISO(message.createdAt), "HH:mm");
    const fromMe = message.fromMe ? i18n.t("messagePropertiesModal.spans.sentBy.me") : i18n.t("messagePropertiesModal.spans.sentBy.contact");
    const userName = message.user?.name ? message.user.name : "-";
    const whatsappName = message.ticket?.whatsapp?.name ? message.ticket.whatsapp.name : "-";
    const phoneNumber = message.ticket?.whatsapp?.phoneNumber ? message.ticket.whatsapp.phoneNumber : "-";

    const clipboardText = isInternalMessage
      ? `${i18n.t("messagePropertiesModal.spans.internalMessage")}\n${i18n.t("messagePropertiesModal.spans.date")}: ${date}\n${i18n.t("messagePropertiesModal.spans.hour")}: ${hour}\n${i18n.t("messagePropertiesModal.spans.sentBy.sentBy")}: ${fromMe}\n${i18n.t("messagePropertiesModal.spans.user")}: ${userName}`
      : `${i18n.t("messagePropertiesModal.spans.date")}: ${date}\n${i18n.t("messagePropertiesModal.spans.hour")}: ${hour}\n${i18n.t("messagePropertiesModal.spans.sentBy.sentBy")}: ${fromMe}\n${i18n.t("messagePropertiesModal.spans.user")}: ${userName}\n${i18n.t("messagePropertiesModal.spans.connection")}: ${whatsappName}\n${i18n.t("messagePropertiesModal.spans.connectionNumber")}: ${phoneNumber}`;

    await navigator.clipboard.writeText(clipboardText);
    setCopyTooltip(i18n.t("messagePropertiesModal.tooltips.copied"));
    setTimeout(() => { setCopyTooltip(i18n.t("messagePropertiesModal.tooltips.copy")); }, 700);
  };

  const renderProperties = () => {
    if (message) {
      const isInternalMessage = ["internal", "transference_observation"].includes(message.mediaType);
      const date = format(parseISO(message.createdAt), "dd/MM/yyyy");
      const hour = format(parseISO(message.createdAt), "HH:mm");
      const fromMe = message.fromMe ? i18n.t("messagePropertiesModal.spans.sentBy.me") : i18n.t("messagePropertiesModal.spans.sentBy.contact");
      const userName = message.user?.name ? message.user.name : "-";
      const whatsappName = message.ticket?.whatsapp?.name ? message.ticket.whatsapp.name : "-";
      const phoneNumber = message.ticket?.whatsapp?.phoneNumber ? message.ticket.whatsapp.phoneNumber : "-";
      
      return (
        <>
          {isInternalMessage && (
            <>
              <span><b>{i18n.t("messagePropertiesModal.spans.internalMessage")}</b></span>
              <br />
            </>
          )}

          <span>{i18n.t("messagePropertiesModal.spans.date")}: <b>{date}</b></span>
          <br/>
          <span>{i18n.t("messagePropertiesModal.spans.hour")}: <b>{hour}</b></span>
          <br />
          <span>{i18n.t("messagePropertiesModal.spans.sentBy.sentBy")}: <b>{fromMe}</b></span>
          <br />
          <span>{i18n.t("messagePropertiesModal.spans.user")}: <b>{userName}</b></span>
          <br/>

          {!isInternalMessage && (
            <>
              <span>{i18n.t("messagePropertiesModal.spans.connection")}: <b>{whatsappName}</b></span>
              <br/>
              <span>{i18n.t("messagePropertiesModal.spans.connectionNumber")}: <b>{phoneNumber}</b></span>
            </>
          )}
        </>
      );
    }
    else { return (<></>); }
  };



  //  ************
  //  ** Return **
  //  ************
  return (
    <div className={classes.root}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        scroll="paper"
        fullWidth
      >
        <DialogTitle>
          <span>{i18n.t("messagePropertiesModal.title.up")}</span>
        </DialogTitle>

        <DialogContent className={classes.dialogContent} dividers>
          <div className={classes.infoContainer}>{renderProperties()}</div>
        </DialogContent>

        <Divider />

        <DialogActions>
          <Button
						onClick={handleClose}
						color="inherit"
						variant="outlined"
						className={classes.floatingButton}
					>
						{i18n.t("messagePropertiesModal.buttons.cancel")}
					</Button>

          <Tooltip title={copyTooltip} placement="top-start" arrow>
            <Button
              color="inherit"
              variant="outlined"
              className={classes.floatingButton}
              onClick={handleCopyProperties}
            >
              <FileCopyOutlined />
            </Button>
            </Tooltip>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MessagePropertiesModal;