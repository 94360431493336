import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	ClickAwayListener,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	IconButton,
	Switch,	
	TextField,
	Tooltip,
	Typography,
	useMediaQuery,
} from "@material-ui/core";
import { ExpandMore, InfoOutlined, HelpOutlineOutlined } from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import QueueSelectModals from "../QueueSelectModals";
import toastError from "../../errors/toastError";
import { toast } from "react-toastify";

const useStyles = makeStyles(theme => ({
	infoSection: { display: "flex", alignItems: "center", flexWrap: "wrap", gap: "5px", },
	
	root: { display: "flex", flexWrap: "wrap", },
	
	btnWrapper: { position: "relative", },

	accordionSummary: { "& .MuiAccordionSummary-expandIcon": { color: theme.palette.text.primary }, },

	accordionDetails: {
		display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    flexWrap: "wrap",
    gap: "5px",
	},

	hr: { width: "100%" },

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

	floatingButton: {
		transition: 'transform 0.3s',
		'&:hover': { transform: 'translateY(-5px)', },
	}
}));

const SessionSchema = Yup.object().shape({
	name: Yup.string().min(2, "Too Short!").max(50, "Too Long!").required("Required"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
	const initialState = {
		name: "",
		greetingMessage: "",
		awaitMessage: "",
		farewellMessage: "",
		absenceMessage: "",
		declinedCallMessage: "",
		alertContactPhoneNumber: "",
		isDefault: false,
		isDispatch: false,
		sendRating: false,
		shouldUseChipNameOnAutomaticMessages: false,
		closeNewTicketsWithMessageOutsideApplication: false,
		waitForBotInteraction: false,
		sendSignature: true
	};

	const [whatsappModalAccordionOpen, setWhatsappModalAccordionOpen] = useState(false);
	const [waitForBotInteractionTooltip, setWaitForBotInteractionTooltip] = useState(false);
	const [waitForBotInteractionDisabled, setWaitForBotInteractionDisabled] = useState(true);
	const [whatsApp, setWhatsApp] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);

	const [sendSignatureGeneralSetting, setSendSignatureGeneralSetting] = useState("enabled");
	
	


	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		const fetchSettings = async () => {
			if (open) {
				try {
					const apiCalls = [api.get("/settings/sendSignatureOnMessages")];

					const [SendSignatureOnMessagesResponse] = await Promise.all(apiCalls);

					setSendSignatureGeneralSetting(SendSignatureOnMessagesResponse.data.value); // getting "sendSignatureOnMessages" setting
				} catch (exception) {
					console.log("Whatsapp Modal Use Effect 1 Exception:", exception);
					toastError(exception);
				}
			}
		};

		fetchSettings();
	}, [open]);

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`whatsapp/${whatsAppId}`);
				setWhatsApp(data);

				const whatsQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(whatsQueueIds);

				setWaitForBotInteractionDisabled(whatsQueueIds.length === 0);
			} catch (exception) {
				console.log("Whatsapp Modal Use Effect 2 Exception:", exception);
				toastError(exception);
			}
		};
		fetchSession();
	}, [whatsAppId]);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleSaveWhatsApp = async values => {
		// 	********************
		// 	** Preparing Data **
		// 	********************

		// ***---- waitForBotInteraction ----***
		values.waitForBotInteraction = selectedQueueIds.length === 0
			? false
			: values.waitForBotInteraction;

		// ***---- queueIds ----***
		const whatsappData = { ...values, queueIds: selectedQueueIds };



		// 	*****************
		// 	** Validations **
		// 	*****************

		// ***---- AlertContactPhoneNumber ----***
		const isAlertContactPhoneNumberValid = ![null, undefined, ""].includes(values.alertContactPhoneNumber);
		const isAlertContactPhoneNumberLengthValid = values.alertContactPhoneNumber?.length >= 10 && values.alertContactPhoneNumber?.length <= 13;

		if (isAlertContactPhoneNumberValid) {
			if (!isAlertContactPhoneNumberLengthValid) {
				toast.info(i18n.t("whatsappModal.tooltips.invalidAlertContactPhoneNumber"));
				return;
			}
	
			const { data: isAlertContactPhoneNumberOnWhatsapp } = await api.get(`/validation/phoneNumberOnWhatsapp/${values.alertContactPhoneNumber}`);
			
			if (!isAlertContactPhoneNumberOnWhatsapp) {
				toast.info(i18n.t("whatsappModal.tooltips.alertContactPhoneNumberNotOnWhatsapp"));
				return;
			}
		}

		

		// 	********************
		// 	** Storage/Update **
		// 	********************
		try {
			if (whatsAppId) {
				await api.put(`/whatsapp/${whatsAppId}`, whatsappData);
			} else {
				await api.post("/whatsapp", whatsappData);
			}

			handleClose();
		} catch (error) {
			console.log("Handle Save WhatsApp Error:", error);
			toastError(error);
		}
	};

	const handleClose = () => {
		onClose();
		setWhatsApp(initialState);
		setSelectedQueueIds([]);
		setWaitForBotInteractionTooltip(false);
		setWaitForBotInteractionDisabled(true);
		setWhatsappModalAccordionOpen(false);
		setSendSignatureGeneralSetting(false);
	};

	const handleOpenWaitForBotInteractionTooltip = () => { setWaitForBotInteractionTooltip(true); };
	const handleCloseWaitForBotInteractionTooltip = () => { setWaitForBotInteractionTooltip(false); };



	// 	************
	// 	** Return **
	// 	************
	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth scroll="paper">
				<DialogTitle>
					{whatsAppId ? i18n.t("whatsappModal.title.edit") : i18n.t("whatsappModal.title.add")}
				</DialogTitle>
				
				<Formik
					initialValues={whatsApp}
					enableReinitialize={true}
					validationSchema={SessionSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveWhatsApp(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div>
									<span className={classes.infoSection}>
										<InfoOutlined /> {i18n.t("whatsappModal.info.message")}
									</span>

									<span className={classes.infoSection}>
										{i18n.t("whatsappModal.info.variablesText")}
										{i18n.t("whatsappModal.info.variablesList")}
									</span>
								</div>

								<div>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										fullWidth
										className={classes.textField}
									/>
								</div>

								<div>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.greetingMessage")}
										type="greetingMessage"
										multiline
										rows={5}
										fullWidth
										name="greetingMessage"
										error={touched.greetingMessage && Boolean(errors.greetingMessage)}
										helperText={touched.greetingMessage && errors.greetingMessage}
										variant="outlined"
										margin="dense"
									/>
								</div>

								<div>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.awaitingMessage")}
										type="awaitMessage"
										multiline
										rows={5}
										fullWidth
										name="awaitMessage"
										error={touched.awaitMessage && Boolean(errors.awaitMessage)}
										helperText={touched.awaitMessage && errors.awaitMessage}
										variant="outlined"
										margin="dense"
									/>
								</div>

								<div>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.farewellMessage")}
										type="farewellMessage"
										multiline
										rows={5}
										fullWidth
										name="farewellMessage"
										error={touched.farewellMessage && Boolean(errors.farewellMessage)}
										helperText={touched.farewellMessage && errors.farewellMessage}
										variant="outlined"
										margin="dense"
									/>
								</div>

								<div>
									<Field
										id="absenceMessageField"
										as={TextField}
										label={i18n.t("whatsappModal.form.absenceMessage")}
										type="absenceMessage"
										multiline
										rows={5}
										fullWidth
										name="absenceMessage"
										error={touched.absenceMessage && Boolean(errors.absenceMessage)}
										helperText={touched.absenceMessage && errors.absenceMessage}
										variant="outlined"
										margin="dense"
									/>
								</div>

								<div>
									<Field
										id="declinedCallMessageField"
										as={TextField}
										label={i18n.t("whatsappModal.form.declinedCallMessage")}
										type="declinedCallMessage"
										multiline
										rows={5}
										fullWidth
										name="declinedCallMessage"
										error={touched.declinedCallMessage && Boolean(errors.declinedCallMessage)}
										helperText={touched.declinedCallMessage && errors.declinedCallMessage}
										variant="outlined"
										margin="dense"
									/>
								</div>

								<div>
									<Field
										id="alertContactPhoneNumberField"
										as={TextField}
										label={i18n.t("whatsappModal.form.alertContactPhoneNumber")}
										type="alertContactPhoneNumber"
										fullWidth
										name="alertContactPhoneNumber"
										error={touched.alertContactPhoneNumber && Boolean(errors.alertContactPhoneNumber)}
										helperText={touched.alertContactPhoneNumber && errors.alertContactPhoneNumber}
										variant="outlined"
										margin="dense"
										placeholder={"5518999999999"}

										onInput={(event) => { event.target.value = event.target.value.replace(/\D/g, "") }}
										InputProps={{
											inputProps: { 
												inputMode: "numeric",
												pattern: "[0-9]*",
												maxLength: 13,
												minLength: 10
											}
										}}
									/>
									
									<div>
										<span className={classes.infoSection}>
											<InfoOutlined /> {i18n.t("whatsappModal.info.alertContactPhoneNumberMessage")}
										</span>

										<br />
									</div>
								</div>

								<QueueSelectModals
									selectedQueueIds={selectedQueueIds}
									onChange={selectedIds => {
										setSelectedQueueIds(selectedIds);
										setWaitForBotInteractionDisabled(selectedIds.length === 0);
									}}
								/>
								
								<br />
								<hr />

								<Accordion expanded={whatsappModalAccordionOpen}>
									<AccordionSummary
										expandIcon={<ExpandMore />}
										TransitionProps={{ unmountOnExit: true }}
										className={classes.accordionSummary}
										onClick={() => setWhatsappModalAccordionOpen((previousValue) => !previousValue)}
									>
										<Typography>
											{i18n.t("whatsappModal.form.settings")}
										</Typography>
									</AccordionSummary>

									<AccordionDetails className={classes.accordionDetails}>
										<FormControlLabel
											control={<Field as={Switch} color="primary" name="isDefault" checked={values.isDefault} disabled={values.isDefault} />}
											label={i18n.t("whatsappModal.form.default")}
										/>

										<FormControlLabel
											control={<Field as={Switch} color="primary" name="isDispatch" checked={values.isDispatch} />}
											label={i18n.t("whatsappModal.form.dispatch")}
										/>

										<FormControlLabel
											control={<Field as={Switch} color="primary" name="sendRating" checked={values.sendRating} />}
											label={i18n.t("whatsappModal.form.sendRating")}
										/>

										<hr className={classes.hr} />

										<FormControlLabel
											control={<Field as={Switch} color="primary" name="shouldUseChipNameOnAutomaticMessages" checked={values.shouldUseChipNameOnAutomaticMessages} />}
											label={i18n.t("whatsappModal.form.shouldUseChipNameOnAutomaticMessages")}
										/>

										<FormControlLabel
											control={<Field as={Switch} color="primary" name="closeNewTicketsWithMessageOutsideApplication" checked={values.closeNewTicketsWithMessageOutsideApplication} />}
											label={i18n.t("whatsappModal.form.closeNewTicketsWithMessageOutsideApplication")}
										/>

										<hr className={classes.hr} />

										<FormControlLabel
											control={<Field as={Switch} color="primary" name="waitForBotInteraction" checked={values.waitForBotInteraction} />}
											disabled={waitForBotInteractionDisabled}
											label={
												<>
												{i18n.t("whatsappModal.form.waitForBotInteraction")}

												{!isSmallScreen && (
													<ClickAwayListener onClickAway={handleCloseWaitForBotInteractionTooltip}>
														<Tooltip
															arrow
															disableFocusListener
															disableTouchListener

															placement="top-start"
															PopperProps={{ disablePortal: true }}
															onClose={handleCloseWaitForBotInteractionTooltip}
															open={waitForBotInteractionTooltip}
															title={i18n.t("whatsappModal.tooltips.waitForBotInteraction")}
														>
															<sup onClick={handleOpenWaitForBotInteractionTooltip}>
																<IconButton color="inherit" className={classes.floatingButton}>
																	<HelpOutlineOutlined style={{ fontSize: "0.75em" }} />
																</IconButton>
															</sup>
														</Tooltip>
													</ClickAwayListener>
												)}
												</>
											}
										/>

										{sendSignatureGeneralSetting === "enabled" && (
											<FormControlLabel
												control={<Field as={Switch} color="primary" name="sendSignature" checked={values.sendSignature} />}
												label={i18n.t("whatsappModal.form.sendSignature")}
											/>
										)}
									</AccordionDetails>
								</Accordion>
							</DialogContent>

							<DialogActions>
								<Button
									onClick={handleClose}
									color="inherit"
									disabled={isSubmitting}
									variant="outlined"
									className={classes.floatingButton}
								>
									{i18n.t("whatsappModal.buttons.cancel")}
								</Button>

								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={`${classes.btnWrapper} ${classes.floatingButton}`}
								>
									{whatsAppId ? i18n.t("whatsappModal.buttons.okEdit") : i18n.t("whatsappModal.buttons.okAdd")}
									{isSubmitting && (<CircularProgress size={24} className={classes.buttonProgress} />)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default React.memo(WhatsAppModal);
