import React, { useState, useEffect } from "react";
import { Paper, makeStyles } from "@material-ui/core";

import api from "../../services/api";
import MessagesListTicketView from "../MessagesListTicketView";
import TicketInfo from "../TicketInfo";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    backgroundColor: "rgba(255, 255, 255, 0)",
    padding: "3px",
    flex: 1,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeft: "0",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  ticketInfo: {
    borderRadius: "10px",
    backgroundColor: theme.palette.background.default,
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: { maxWidth: "80%", flexBasis: "80%", },
  },
}));

const TicketView = ({ viewTicketId }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const ticketId = viewTicketId;
  const classes = useStyles();

  const [contact, setContact] = useState({});
  const [ticket, setTicket] = useState({});

  

  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const fetchTicket = async () => {
        try {
          const { data } = await api.get("/tickets/" + ticketId);
          setContact(data.contact);
          setTicket(data);
        } catch (exception) {
          console.log("Ticket View Use Effect Exception:", exception);
          toastError(exception);
        }
      };
      fetchTicket();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [ticketId]);



  //  ************
  //  ** Return **
  //  ************
  return (
    <Paper variant="outlined" elevation={0} className={classes.mainWrapper}>
      <div className={classes.ticketInfo}>
        <TicketInfo contact={contact} ticket={ticket} />
      </div>
      
      <MessagesListTicketView ticketId={ticketId} ticketType={ticket.type} />
    </Paper>
  );
};

export default TicketView;
