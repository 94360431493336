import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
	ClickAwayListener,
	Container,
	IconButton,
	Paper,
	Select,
	Switch,
	Tab,
	Tabs,
	Tooltip,
	Typography
} from "@material-ui/core";
import FilterNoneOutlinedIcon from '@material-ui/icons/FilterNoneOutlined';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';

import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n.js";
import api from "../../services/api";
import SocketSettings from "../../context/Socket/Listeners/SocketSettings/index.js";
import toastError from "../../errors/toastError";
import TabPanel from "../../components/TabPanel";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(8, 8, 3),
		backgroundColor: theme.palette.background.default,
		color: theme.palette.text.primary,
	},

	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 12,
	},

	settingOption: { marginLeft: "auto", },

	floatingButton: {
    transition: 'transform 0.3s',
    '&:hover': { transform: 'translateY(-5px)', },
  },
}));

const Settings = () => {
	// 	***************
	// 	** Variables **
	// 	***************
	const classes = useStyles();
	const history = useHistory();
	const { user } = useContext(AuthContext);
	const [tab, setTab] = useState(localStorage.getItem("tabOpenSettings"));
	const [tabOpen, setTabOpen] = useState(localStorage.getItem("tabOpenSettings"));
	const [settings, setSettings] = useState([]);
	const [isApiTokenCopied, setIsApiTokenCopied] = useState(false);

	const [automaticallyRejectCallsTooltip, setAutomaticallyRejectCallsTooltip] = useState(false);
	const [showMessagesRelatedToUserQueuesTooltip, setShowMessagesRelatedToUserQueuesTooltip] = useState(false);
	const [showOnlyMessagesRelatedToUserChatsTooltip, setShowOnlyMessagesRelatedToUserChatsTooltip] = useState(false);
	const [ticketsDynamicSortingTooltip, setTicketsDynamicSortingTooltip] = useState(false);
	const [viewPresenceUpdateTooltip, setViewPresenceUpdateTooltip] = useState(false);



	// 	*****************
	// 	** Use Effects **
	// 	*****************
	useEffect(() => {
		if (user.profile === "user" || !user.configEnabled) { history.push(`/tickets`); }
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/settings");
				setSettings(data);
			} catch (error) {
				console.log("Settings Use Effect 1 Error:", error);
				toastError(error);
			}
		};
		fetchSession();
	}, []);



	// 	***************
	// 	** Functions **
	// 	***************
	const handleOpenTicketDynamicSortingTooltip = () => { setTicketsDynamicSortingTooltip(true); };
	const handleCloseTicketDynamicSortingTooltip = () => { setTicketsDynamicSortingTooltip(false); };

	const handleOpenShowMessagesRelatedToUserQueuesTooltip = () => { setShowMessagesRelatedToUserQueuesTooltip(true); };
	const handleCloseShowMessagesRelatedToUserQueuesTooltip = () => { setShowMessagesRelatedToUserQueuesTooltip(false); };

	const handleOpenShowOnlyMessagesRelatedToUserChatsTooltip = () => { setShowOnlyMessagesRelatedToUserChatsTooltip(true); };
	const handleCloseShowOnlyMessagesRelatedToUserChatsTooltip = () => { setShowOnlyMessagesRelatedToUserChatsTooltip(false); };

	const handleOpenAutomaticallyRejectCallsTooltip = () => { setAutomaticallyRejectCallsTooltip(true); };
	const handleCloseAutomaticallyRejectCallsTooltip = () => { setAutomaticallyRejectCallsTooltip(false); };

	const handleOpenViewPresenceUpdateTooltip = () => { setViewPresenceUpdateTooltip(true); };
	const handleCloseViewPresenceUpdateTooltip = () => { setViewPresenceUpdateTooltip(false); };

	const handleChangeTabOpen = (e, newValue) => {
		setTabOpen(newValue);
		setTab(newValue);
		localStorage.setItem("tabOpenSettings", newValue);
	};

	const handleChangeSetting = async e => {
		const selectedValue = e.target.value;
		const settingKey = e.target.name;

		if ((settingKey === "allowGroups" || settingKey === "allowChannels")
		  && selectedValue === "disabled"
			&& (localStorage.getItem("tabOpenTickets") === "group" || localStorage.getItem("tabOpenTickets") === "channel")
		) {
			localStorage.setItem("tabOpenTickets", "open");
		}

		try {
			await api.put(`/settings/${settingKey}`, { value: selectedValue });
			handleChangeTabOpen(e, tab);
		} catch (error) {
			console.log("Handle Change Setting Error:", error);
			toastError(error);
		}
	};

	const handleChangeSettingToggle = async e => {
		const selectedValue = e.target.checked ? "enabled" : "disabled";
		const settingKey = e.target.name;

		if ((settingKey === "allowGroups" || settingKey === "allowChannels")
		  && selectedValue === "disabled"
			&& (localStorage.getItem("tabOpenTickets") === "group" || localStorage.getItem("tabOpenTickets") === "channel")
		) {
			localStorage.setItem("tabOpenTickets", "open");
		}

		try {
			await api.put(`/settings/${settingKey}`, { value: selectedValue });
			handleChangeTabOpen(e, tab);
		} catch (error) {
			console.log("Handle Change Setting Toggle Error:", error);
			toastError(error);
		}
	};

	const getSettingValue = key => {
		const { value } = settings.find(s => s.key === key);
		return value;
	};

	const handleCopyApiToken = async () => {
		setIsApiTokenCopied(true);
		await navigator.clipboard.writeText(getSettingValue("userApiToken"));
		setTimeout(() => { setIsApiTokenCopied(false); }, 500)
	};


	
	// 	************
	// 	** Return **
	// 	************
	return (
		<div className={classes.root}>
			<SocketSettings setSettings={setSettings} />

			<Container className={classes.container} maxWidth="sm">

				<Tabs 
					onChange={handleChangeTabOpen} 
					value={tabOpen}
					indicatorColor="primary"
					textColor="primary"
					variant="scrollable"
					scrollButtons="on"
					centered
				>
					<Tab label={i18n.t("settings.settings.general")} value={"general"}/>
					<Tab label={i18n.t("settings.settings.privacy")} value={"privacy"}/>
					<Tab label={i18n.t("settings.settings.schedule")} value={"schedule"}/>
					<Tab label={i18n.t("settings.settings.sendings")} value={"sendings"}/>
					<Tab label={i18n.t("settings.settings.service")} value={"service"}/>
				</Tabs>



				{/* 
					*****************
					** General Tab **
					*****************
				*/}
				<TabPanel value={tabOpen} name="general">
					<br /><hr /><br />

					{/* 
						*************************
						** Allow Default Queue **
						*************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.allowDefaultQueue.name")}
						</Typography>
						<Switch
        			checked={settings && settings.length > 0 && getSettingValue("allowDefaultQueue") === 'enabled'}
        			onChange={handleChangeSettingToggle}
        			name="allowDefaultQueue"
    				/>
					</Paper>



					{/* 
						******************
						** Allow Groups **
						******************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.allowGroups.name")}
						</Typography>
						<Switch
        			checked={settings && settings.length > 0 && getSettingValue("allowGroups") === 'enabled'}
        			onChange={handleChangeSettingToggle}
        			name="allowGroups"
    				/>
					</Paper>



					{/* 
						********************
						** Allow Channels **
						********************
					*/}
					{/* <Paper className={classes.paper}>
						<Typography variant="body1">
						{i18n.t("settings.settings.allowChannels.name")}
						</Typography>
						<Switch
        			checked={settings && settings.length > 0 && getSettingValue("allowChannels") === 'enabled'}
        			onChange={handleChangeSettingToggle}
        			name="allowChannels"
    				/>
					</Paper> */}



					{/* 
						********************************
						** Automatically Reject Calls **
						********************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.automaticallyRejectCalls.name")}

							<ClickAwayListener onClickAway={handleCloseAutomaticallyRejectCallsTooltip}>
								<Tooltip
									arrow
									disableFocusListener
									disableTouchListener

									placement="top-start"
									PopperProps={{ disablePortal: true }}
									onClose={handleCloseAutomaticallyRejectCallsTooltip}
									open={automaticallyRejectCallsTooltip}
									title={i18n.t("settings.settings.automaticallyRejectCalls.tooltip")}
								>
									<sup onClick={handleOpenAutomaticallyRejectCallsTooltip}>
										<IconButton color="inherit" className={classes.floatingButton}>
											<HelpOutlineOutlinedIcon style={{ fontSize: "0.75em" }} />
										</IconButton>
									</sup>
								</Tooltip>
							</ClickAwayListener>
						</Typography>

						<Switch
							checked={settings && settings.length > 0 && getSettingValue("automaticallyRejectCalls") === "enabled"}
							onChange={handleChangeSettingToggle}
							name={"automaticallyRejectCalls"}
						/>
					</Paper>



					{/* 
						*****************
						** Missed Call **
						*****************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.missedCalls.name")}
						</Typography>
						<Switch
        			checked={settings && settings.length > 0 && getSettingValue("missedCalls") === 'enabled'}
        			onChange={handleChangeSettingToggle}
        			name="missedCalls"
    				/>
					</Paper>



					{/* 
						******************************
						** Tickets List Sort Method **
						******************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.ticketsListSortMethod.name")}
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="ticketsListSortMethod-setting"
							name="ticketsListSortMethod"
							value={
								settings && settings.length > 0 && getSettingValue("ticketsListSortMethod")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="asc">
								{i18n.t("settings.settings.options.lessRecent")}
							</option>
							<option value="desc">
								{i18n.t("settings.settings.options.moreRecent")}
							</option>
						</Select>
					</Paper>



					{/*
						****************************
						** Ticket Dynamic Sorting **
						****************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.ticketsDynamicSorting.name")}

							<ClickAwayListener onClickAway={handleCloseTicketDynamicSortingTooltip}>
								<Tooltip
									arrow
									disableFocusListener
									disableTouchListener

									placement="top-start" 
									PopperProps={{ disablePortal: true }}
									onClose={handleCloseTicketDynamicSortingTooltip}
									open={ticketsDynamicSortingTooltip}
									title={i18n.t("settings.settings.ticketsDynamicSorting.tooltip")}
								>
									<sup onClick={handleOpenTicketDynamicSortingTooltip}>
										<IconButton color="inherit" className={classes.floatingButton}>
											<HelpOutlineOutlinedIcon style={{ fontSize: "0.75em" }} />
										</IconButton>
									</sup>
								</Tooltip>
							</ClickAwayListener>
							
						</Typography>

						

						<Switch
							checked={settings && settings.length > 0 && getSettingValue("ticketsDynamicSorting") === "enabled"}
							onChange={handleChangeSettingToggle}
							name="ticketsDynamicSorting"
						/>
					</Paper>



					{/* 
						*******************
						** Enter is Send **
						*******************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.enterIsSendMobile.name")}
						</Typography>
						<Switch
        			checked={settings && settings.length > 0 && getSettingValue("enterIsSendMobile") === 'enabled'}
        			onChange={handleChangeSettingToggle}
        			name="enterIsSendMobile"
    				/>
					</Paper>

					{/* 
						*****************************
						** Require Tag on Contacts **
						*****************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.requireTagOnContacts.name")}
						</Typography>
						<Switch
        			checked={settings && settings.length > 0 && getSettingValue("requireTagOnContactWhenClosingTicket") === 'enabled'}
        			onChange={handleChangeSettingToggle}
        			name="requireTagOnContactWhenClosingTicket"
    				/>
					</Paper>

					<br /><hr /><br />

					{/* 
						********************
						** User API Token **
						********************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.apiToken.name")}
						</Typography>

						<Tooltip 
							title={
								isApiTokenCopied 
								? i18n.t("settings.settings.apiToken.copiedTooltip")
								: i18n.t("settings.settings.apiToken.copyTooltip")
							}
							placement="top-start" arrow
						>
							<IconButton
								color="inherit"
								className={classes.floatingButton}
								onClick={handleCopyApiToken}
								disabled={settings && settings.length > 0 ? false : true}
							>
								<FilterNoneOutlinedIcon fontSize="small" />
							</IconButton>
						</Tooltip>
					</Paper>
				</TabPanel>



				{/* 
					*****************
					** Privacy Tab **
					*****************
				*/}
				<TabPanel value={tabOpen} name="privacy">
					<br /><hr /><br />

			

					{/* 
						*************************************************
						** Fetch Unread Messages after Chip Connection **
						*************************************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.fetchUnreadMessagesAfterConnecting.name")}
						</Typography>
						<Switch
        			checked={settings && settings.length > 0 && getSettingValue("fetchUnreadMessagesAfterConnecting") === 'enabled'}
        			onChange={handleChangeSettingToggle}
        			name="fetchUnreadMessagesAfterConnecting"
    				/>
					</Paper>



					{/* 
						***************************
						** Mark Messages as Read **
						***************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.markMessagesAsRead.name")}
						</Typography>
						<Switch
        			checked={settings && settings.length > 0 && getSettingValue("markMessagesAsRead") === 'enabled'}
        			onChange={handleChangeSettingToggle}
        			name="markMessagesAsRead"
    				/>
					</Paper>



					{/* 
						********************************
						** Admin Can View Other Chats **
						********************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.adminCanViewOtherChats.name")}
						</Typography>
						<Switch
        			checked={settings && settings.length > 0 && getSettingValue("adminCanViewOtherChats") === 'enabled'}
        			onChange={handleChangeSettingToggle}
        			name="adminCanViewOtherChats"
    				/>
					</Paper>



					{/* 
						***********************************
						** Show Presence Update on Chats **
						***********************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.viewPresenceUpdate.name")}

							<ClickAwayListener onClickAway={handleCloseViewPresenceUpdateTooltip}>
								<Tooltip
									arrow
									disableFocusListener
									disableTouchListener

									placement="top-start"
									PopperProps={{ disablePortal: true }}
									onClose={handleCloseViewPresenceUpdateTooltip}
									open={viewPresenceUpdateTooltip}
									title={i18n.t("settings.settings.viewPresenceUpdate.tooltip")}
								>
									<sup onClick={handleOpenViewPresenceUpdateTooltip}>
										<IconButton color="inherit" className={classes.floatingButton}>
											<HelpOutlineOutlinedIcon style={{ fontSize: "0.75em" }} />
										</IconButton>
									</sup>
								</Tooltip>
							</ClickAwayListener>
						</Typography>

						<Switch
							checked={settings && settings.length > 0 && getSettingValue("viewPresenceUpdate") === "enabled"}
							onChange={handleChangeSettingToggle}
							name="viewPresenceUpdate"
						/>
					</Paper>



					<br /><hr /><br />

					{/* 
						********************************
						** Merge Connections Messages **
						********************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.mergeMessagesFromDifferentConnectionsSetting.name")}
						</Typography>
						<Switch
							checked={settings && settings.length > 0 && getSettingValue("mergeMessagesFromDifferentConnectionsSetting") === 'enabled'}
							onChange={handleChangeSettingToggle}
							name="mergeMessagesFromDifferentConnectionsSetting"
						/>
					</Paper>


					{/* 
						************************************************
						** Show Only Messages Related to Users' Chats **
						************************************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.showOnlyMessagesRelatedToUsersChats.name")}

							<ClickAwayListener onClickAway={handleCloseShowOnlyMessagesRelatedToUserChatsTooltip}>
								<Tooltip
									arrow
									disableFocusListener
									disableTouchListener

									placement="top-start"
									PopperProps={{ disablePortal: true }}
									onClose={handleCloseShowOnlyMessagesRelatedToUserChatsTooltip}
									open={showOnlyMessagesRelatedToUserChatsTooltip}
									title={i18n.t("settings.settings.showOnlyMessagesRelatedToUsersChats.tooltip")}
								>
									<sup onClick={handleOpenShowOnlyMessagesRelatedToUserChatsTooltip}>
										<IconButton color="inherit" className={classes.floatingButton}>
											<HelpOutlineOutlinedIcon style={{ fontSize: "0.75em" }} />
										</IconButton>
									</sup>
								</Tooltip>
							</ClickAwayListener>
						</Typography>
						<Switch
							checked={settings && settings.length > 0 && getSettingValue("showOnlyMessagesRelatedToUsersChats") === 'enabled'}
							onChange={handleChangeSettingToggle}
							name="showOnlyMessagesRelatedToUsersChats"
						/>
					</Paper>



					{/* 
						******************************************
						** Show Messages Related To User Queues **
						******************************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.showMessagesRelatedToUserQueues.name")}

							<ClickAwayListener onClickAway={handleCloseShowMessagesRelatedToUserQueuesTooltip}>
								<Tooltip
									arrow
									disableFocusListener
									disableTouchListener

									placement="top-start"
									PopperProps={{ disablePortal: true }}
									onClose={handleCloseShowMessagesRelatedToUserQueuesTooltip}
									open={showMessagesRelatedToUserQueuesTooltip}
									title={i18n.t("settings.settings.showMessagesRelatedToUserQueues.tooltip")}
								>
									<sup onClick={handleOpenShowMessagesRelatedToUserQueuesTooltip}>
										<IconButton color="inherit" className={classes.floatingButton}>
											<HelpOutlineOutlinedIcon style={{ fontSize: "0.75em" }} />
										</IconButton>
									</sup>
								</Tooltip>
							</ClickAwayListener>
						</Typography>

						<Switch
							checked={settings && settings.length > 0 && getSettingValue("showMessagesRelatedToUserQueues") === "enabled"}
							onChange={handleChangeSettingToggle}
							name="showMessagesRelatedToUserQueues"
						/>
					</Paper>



					{/* 
						**************************************
						** Users Permissions by Connections **
						**************************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.userPermissionsByChip.name")}
						</Typography>
						<Switch
							checked={settings && settings.length > 0 && getSettingValue("userPermissionsByChip") === 'enabled'}
							onChange={handleChangeSettingToggle}
							name="userPermissionsByChip"
						/>
					</Paper>
				</TabPanel>



				{/* 
					******************
					** Schedule Tab **
					******************
				*/}
				<TabPanel value={tabOpen} name="schedule">
					<br /><hr /><br />

					{/* 
						**************
						** Timezone **
						**************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.timezone.name")}
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="timezone-setting"
							name="timezone"
							value={
								settings && settings.length > 0 && getSettingValue("timezone")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="America/Noronha">GMT-2</option>
							<option value="America/Sao_Paulo">GMT-3</option>
							<option value="America/Cuiaba">GMT-4</option>
							<option value="America/Rio_Branco">GMT-5</option>
						</Select>
					</Paper>



					{/* 
						*******************
						** Awaiting Time **
						*******************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.awaitingTime.name")}
						</Typography>

						<Select
							margin="dense"
							variant="outlined"
							native
							id="awaitingTime-setting"
							name="awaitingTime"
							value={
								settings && settings.length > 0 && getSettingValue("awaitingTime")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="-1">{i18n.t("settings.settings.options.never")}</option>
							<option value="10">10</option>
							<option value="15">15</option>
							<option value="20">20</option>
							<option value="25">25</option>
							<option value="30">30</option>
							<option value="45">45</option>
							<option value="60">60</option>
							<option value="90">90</option>
							<option value="120">120+</option>
						</Select>
					</Paper>



					{/* 
						*********************
						** Attendance Time **
						*********************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.attendanceTime.name")}
						</Typography>

						<Select
							margin="dense"
							variant="outlined"
							native
							id="attendanceTime-setting"
							name="attendanceTime"
							value={
								settings && settings.length > 0 && getSettingValue("attendanceTime")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="-1">{i18n.t("settings.settings.options.never")}</option>
							<option value="10">10</option>
							<option value="15">15</option>
							<option value="20">20</option>
							<option value="25">25</option>
							<option value="30">30</option>
							<option value="45">45</option>
							<option value="60">60</option>
							<option value="90">90</option>
							<option value="120">120+</option>
						</Select>
					</Paper>

					<br /><hr /><br />

					{/* 
						*********************************************
						** Force Accept Long Awaiting Time Tickets **
						*********************************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.forceAcceptLongAwaitingTimeTickets.name")}
						</Typography>
						<Switch
        			checked={settings && settings.length > 0 && getSettingValue("forceAcceptLongAwaitingTimeTickets") === 'enabled'}
        			onChange={handleChangeSettingToggle}
        			name="forceAcceptLongAwaitingTimeTickets"
    				/>
					</Paper>
				</TabPanel>



				{/* 
					******************
					** Sendings Tab **
					******************
				*/}
				<TabPanel value={tabOpen} name="sendings">
					<br /><hr /><br />

					{/* 
						************************
						** Send Notifications **
						************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.sendNotifications.name")}
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="sendNotifications-setting"
							name="sendNotifications"
							value={
								settings && settings.length > 0 && getSettingValue("sendNotifications")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="open">
								{i18n.t("settings.settings.options.open")}
							</option>
							<option value="pending">
								{i18n.t("settings.settings.options.pending")}
							</option>
							<option value="both">
								{i18n.t("settings.settings.options.both")}
							</option>
						</Select>
					</Paper>



					{/* 
						*****************************************
						** Send Chats and Groups Notifications **
						*****************************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.sendNotificationsTo.name")}
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="sendNotificationsChatsGroups-setting"
							name="sendNotificationsChatsGroups"
							value={
								settings && settings.length > 0 && getSettingValue("sendNotificationsChatsGroups")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="both">
								{i18n.t("settings.settings.options.both")}
							</option>
							<option value="chats">
								{i18n.t("settings.settings.options.chats")}
							</option>
							<option value="groups">
								{i18n.t("settings.settings.options.groups")}
							</option>
						</Select>
					</Paper>



					{/* 
						*******************************************
						** Sound Notification for Internal Chats **
						*******************************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.notificationSoundForInternalChats.name")}
						</Typography>
						<Switch
							checked={settings && settings.length > 0 && getSettingValue("notificationSoundForInternalChats") === "enabled"}
							onChange={handleChangeSettingToggle}
							name="notificationSoundForInternalChats"
						/>
					</Paper>



					<br /><hr /><br />



					{/* 
						******************
						** Link Preview **
						******************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.linkPreview.name")}
						</Typography>
						<Switch
        			checked={settings && settings.length > 0 && getSettingValue("linkPreview") === 'enabled'}
        			onChange={handleChangeSettingToggle}
        			name="linkPreview"
    				/>
					</Paper>



					{/* 
						***************************
						** Send Protocol Message **
						***************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.sendProtocolMessage.name")}
						</Typography>
						<Switch
        			checked={settings && settings.length > 0 && getSettingValue("sendProtocolMessage") === 'enabled'}
        			onChange={handleChangeSettingToggle}
        			name="sendProtocolMessage"
    				/>
					</Paper>



					{/*
						********************************
						** Send Signature on Messages **
						********************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.sendSignatureOnMessages.name")}
						</Typography>
						<Switch
        			checked={settings && settings.length > 0 && getSettingValue("sendSignatureOnMessages") === 'enabled'}
        			onChange={handleChangeSettingToggle}
        			name="sendSignatureOnMessages"
    				/>
					</Paper>



					{/* 
						***************************
						** Send Transfer Message **
						***************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.sendTransferMessage.name")}
						</Typography>
						<Switch
        			checked={settings && settings.length > 0 && getSettingValue("sendTransferMessage") === 'enabled'}
        			onChange={handleChangeSettingToggle}
        			name="sendTransferMessage"
    				/>
					</Paper>



					{/* 
						****************************
						** Send Waiting Attendant **
						****************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.sendWaitingAttendant.name")}
						</Typography>
						<Switch
        			checked={settings && settings.length > 0 && getSettingValue("sendWaitingAttendant") === 'enabled'}
        			onChange={handleChangeSettingToggle}
        			name="sendWaitingAttendant"
    				/>
					</Paper>
				</TabPanel>



				{/* 
					*****************
					** Service Tab **
					*****************
				*/}
				<TabPanel value={tabOpen} name="service">
					<br /><hr /><br />

					{/* 
						*********************
						** Prioritize User **
						*********************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.prioritizeUsers.name")}
						</Typography>
						<Switch
        			checked={settings && settings.length > 0 && getSettingValue("prioritizeUser") === 'enabled'}
        			onChange={handleChangeSettingToggle}
        			name="prioritizeUser"
    				/>
					</Paper>

					{/* 
						**************************
						** Prioritize User Time **
						**************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.prioritizeUsersTime.name")}
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="prioritizeUserTime-setting"
							name="prioritizeUserTime"
							value={
								settings && settings.length > 0 && getSettingValue("prioritizeUserTime")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="1">1</option>
							<option value="2">2</option>
							<option value="3">3</option>
							<option value="4">4</option>
							<option value="5">5</option>
							<option value="10">10</option>
							{/* <option value="infinity">∞</option> */}
						</Select>
					</Paper>

					<br /><hr /><br />

					{/* 
						************************
						** Expiration Pending **
						************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.chatExpiration.name")}
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="chatExpiration-setting"
							name="chatExpiration"
							value={
								settings && settings.length > 0 && getSettingValue("chatExpiration")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="0">{`${i18n.t("dashboard.messages.time.never")}`}</option>
							<option value="1440">{`24 ${i18n.t("dashboard.messages.time.hours")}`}</option>
							<option value="2880">{`48 ${i18n.t("dashboard.messages.time.hours")}`}</option>
						</Select>
					</Paper>

					{/* 
						********************************************
						** Send Rating Poll to Expiration Pending **
						********************************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.sendRatingPollOnChatExpiration.name")}
						</Typography>
						<Switch
							checked={settings && settings.length > 0 && getSettingValue("sendRatingPollOnChatExpiration") === "enabled"}
							onChange={handleChangeSettingToggle}
							name="sendRatingPollOnChatExpiration"
						/>
					</Paper>

					{/* 
						***********************
						** Expiration Opened **
						***********************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.chatExpirationOpened.name")}
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="chatExpiration-setting"
							name="chatExpirationOpened"
							value={
								settings && settings.length > 0 && getSettingValue("chatExpirationOpened")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="0">{`${i18n.t("dashboard.messages.time.never")}`}</option>
							<option value="1440">{`24 ${i18n.t("dashboard.messages.time.hours")}`}</option>
							<option value="2880">{`48 ${i18n.t("dashboard.messages.time.hours")}`}</option>
						</Select>
					</Paper>

					{/* 
						*******************************************
						** Send Rating Poll to Expiration Opened **
						*******************************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
						{i18n.t("settings.settings.sendRatingPollOnChatExpirationOpened.name")}
						</Typography>
						<Switch
							checked={settings && settings.length > 0 && getSettingValue("sendRatingPollOnChatExpirationOpened") === 'enabled'}
							onChange={handleChangeSettingToggle}
							name="sendRatingPollOnChatExpirationOpened"
						/>
					</Paper>

					<br /><hr /><br />

					{/* 
						*********************************************
						** Allow Transfer Tickets to Offline Users **
						*********************************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.allowTransferTicketsToOfflineUsers.name")}
						</Typography>
						<Switch
        			checked={settings && settings.length > 0 && getSettingValue("allowTransferTicketsToOfflineUsers") === 'enabled'}
        			onChange={handleChangeSettingToggle}
        			name="allowTransferTicketsToOfflineUsers"
    				/>
					</Paper>

					{/* 
						***************************************************
						** Allow Resolve Ticket Without Good Bye Message **
						***************************************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.allowResolveTicketWithoutGoodBye.name")}
						</Typography>
						<Switch
							checked={settings && settings.length > 0 && getSettingValue("allowResolveTicketWithoutGoodBye") === "enabled"}
							onChange={handleChangeSettingToggle}
							name="allowResolveTicketWithoutGoodBye"
						/>
					</Paper>

					{/* 
						***************************************
						** Admins Control to Pending Tickets **
						***************************************
					*/}
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.adminsPendingTicketsControl.name")}
						</Typography>
						<Switch
							checked={settings && settings.length > 0 && getSettingValue("adminsPendingTicketsControl") === "enabled"}
							onChange={handleChangeSettingToggle}
							name="adminsPendingTicketsControl"
						/>
					</Paper>
				</TabPanel>
			</Container>
		</div>
	);
};

export default Settings;
