import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from "@material-ui/core/styles";
import {
	Container,
	Grid,
	MenuItem,
	Paper,
	TextField,
	Typography,
} from "@material-ui/core";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";

import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import { i18n } from "../../translate/i18n";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import NoDataImg from '../../assets/noData.svg';
import Title from "../../components/Title";
import UserSelect from "../../components/UserSelect";
import useInternalChatTicketsDashboardTable from "../../hooks/useInternalChatTicketsDashboardTable";
import ViewTicketModal from "../../components/ViewTicketModal";

const useStyles = makeStyles(theme => ({
	viewTicket: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		width: "100px",
		maxWidth: "100px",
		minWidth: "100px",
		alignItems: "center",
		gap: "2px",
	},

	viewPlatform: { width: "100%", display: "flex", alignItems: "center", justifyContent: "center", },
	
	viewButton: { cursor: "pointer", borderRadius: "50%", },
  
	mainContainerScroll: { overflowY: "scroll", ...theme.scrollbarStyles, paddingBottom: "20px", },

  container: { paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), },

  flexContainer: {
		display: "flex",
		justifyContent: "right",
		alignItems: "baseline",
		gap: "15px",
		paddingRight: "10px",
	},

  customFixedHeightPaper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "hidden",
		flexDirection: "column",
		height: 120,
		transition: 'transform 0.3s',
		position: "relative",
		'&:hover': { filter: "brightness(0.85)", transform: 'translateY(-5px)', },
	},

	sectionContainer: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		flexWrap: "wrap",
		[theme.breakpoints.down('sm')]: { justifyContent: "center", gap: "10px", },
	},

	filterField: { width: "250px", },

	cardItem: { width: "300px", },

	ticketsTable: {
		// scroll
		"& .MuiDataGrid-virtualScroller": { ...theme.scrollbarStyles, },

		// header
		"& .MuiDataGrid-columnHeaders": {
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.text.primary,
			fontSize: 16,
		},

		// rows
		"& .MuiDataGrid-cell": { color: theme.palette.text.primary, },

		// pair rows
		"& .MuiDataGrid-virtualScrollerRenderZone": {
			"& .MuiDataGrid-row": {
				"&:nth-child(2n)": { backgroundColor: theme.palette.background.paper, }
			}
		},

		// buttons
		"& .MuiButtonBase-root": { color: theme.palette.text.primary, },

		// checkboxes - not checked
		"& [data-testId='CheckBoxOutlineBlankIcon']": { color: theme.palette.text.primary, },

		// checkboxes - checked
		"& [data-testId='CheckBoxIcon']": { color: theme.palette.primary.main, },

		// checkbox - header (unselect all)
		"& [data-testId='IndeterminateCheckBoxIcon']": { color: theme.palette.primary.main, },

		// footer - selected rows
		"& .MuiDataGrid-footerContainer": { color: theme.palette.text.primary, },

		// footer - pagination
		"& .MuiTablePagination-root": { color: theme.palette.text.primary, },
	},

	emptyBoxContainer: {
    display: "flex",
		width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "50px",
  },

  emptyBoxImage: { width: "250px", height: "250px", },

  emptyBoxSpan: { fontSize: "20px", },

	dashboardContainer: {
		width: "100%",
		height: "400px",
		backgroundColor: theme.palette.background.paper,
		borderRadius: "5px",
		padding: "20px 0px 20px 0px",
		textIndent: "15px",
	},

	actionButton: { "&:hover": { color: theme.palette.primary.main, }, },
}));

const InternalChatsDashboard = () => {
  //  ***************
  //  ** Variables **
  //  ***************

  //
  // ***---- Styles and Contexts -----***
  //
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  //
  // ***---- States ----***
  //
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);
  const [ticketId, setTicketId] = useState(null);
  const [viewTicketModalOpen, setViewTicketModalOpen] = useState(false);
  
  const [selectedUserIds, setSelectedUserIds] = useState([]);

  //
  // ***---- Variables to Structure ----***
  //
  let timesTicket = 0;

  let closedTickets = 0;
  let structuredClosedTickets = 0;
  let viewClosedTickets = 0;
  let filteredClosedTickets = 0;



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    const today = new Date();
    let day = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();

    if (month < 10) { month = `0${month.toString()}`; }
    if (day < 10) { day = `0${day.toString()}`; }

    setInitialDate(`${year}-${month}-${day}`);
    setFinalDate(`${year}-${month}-${day}`);
  }, []);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleOpenViewTicketModal = (ticketId) => {
    setViewTicketModalOpen(true);
    setTicketId(ticketId);
  };

  const handleCloseViewTicketModal = () => {
    setViewTicketModalOpen(false);
    setTicketId(null);
  };



  const handleDateFilter = (newDate, flagInitialDate) => {
    const dashboardInitialDate = document.getElementById("dashboardInitialDate");
    const dashboardFinalDate = document.getElementById("dashboardFinalDate");

    if (dashboardInitialDate.value === "" || dashboardFinalDate.value === "") {
      dashboardInitialDate.value = initialDate;
      dashboardFinalDate.value = finalDate;
    }
    else if (dashboardInitialDate.value > dashboardFinalDate.value) {
      toast.info(i18n.t("backendErrors.DASHBOARD_INITIAL_DATE_AFTER_FINAL_DATE"));
    }
    else if (flagInitialDate) {
      setInitialDate(newDate.target.value);
      setSelectedUserIds([]);
    }
    else {
      setFinalDate(newDate.target.value);
      setSelectedUserIds([]);
    }
  };

  const handleChangeInitialDate = (newDate) => { handleDateFilter(newDate, true); };
  
  const handleChangeFinalDate = (newDate) => { handleDateFilter(newDate, false); };



  const GetFasterTicket = () => {
    const fasterTicket = timesTicket === 0 ? 0 : timesTicket.reduce((min, current) => current < min ? current : min, Infinity);
    let message;

    // Caso não há nenhum atendimento com a propriedade `withUserAt` diferente
    // de NULL OU a média de espera é menor do que 2, "1 minuto" é retornado
    if (isNaN(parseInt(fasterTicket, 10)) || fasterTicket < 2) {
      message = `1 ${i18n.t("internalChatTicketsDashboard.minutes")}`;
    }

    // Caso contrário, é retornado a média de espera
    else {
      // Convertendo tempo em minutos
      if (fasterTicket < 60) {
        message = `${fasterTicket} ${i18n.t("internalChatTicketsDashboard.minutes")}`;
      }

      // Convertendo tempo em horas
      else {
        const fasterTicketHours = ~~(fasterTicket / 60);
        message = fasterTicketHours > 1
          ? `${fasterTicketHours} ${i18n.t("internalChatTicketsDashboard.hours")}`
          : `${fasterTicketHours} ${i18n.t("internalChatTicketsDashboard.hour")}`;
      }
    }

    return message;
  };

  const GetLongerTicket = () => {
    const longerTicket = timesTicket === 0 ? 0 : timesTicket.reduce((max, current) => current > max ? current : max, -Infinity);
    let message;

    // Caso não há nenhum atendimento com a propriedade `withUserAt` deferente
    // de NULL OU a média de espera é menor do que 2, "1 minuto" é retornado
    if (isNaN(parseInt(longerTicket, 10)) || longerTicket < 2) {
      message = `1 ${i18n.t("internalChatTicketsDashboard.minutes")}`;
    }

    // Caso contrário, é retornado a média de espera
    else {
      // Convertendo tempo em minutos
      if (longerTicket < 60) {
        message = `${longerTicket} ${i18n.t("internalChatTicketsDashboard.minutes")}`;
      }

      // Convertendo tempo em horas
      else {
        const longerTicketHours = ~~(longerTicket / 60);
        message = longerTicketHours > 1
          ? `${longerTicketHours} ${i18n.t("internalChatTicketsDashboard.hours")}`
          : `${longerTicketHours} ${i18n.t("internalChatTicketsDashboard.hour")}`;
      }
    }

    return message;
  };

  const GetClosedTicketsTable = (initialDate, finalDate) => {
    const structuredInitialDate = initialDate ? `${initialDate}T00:00:00` : "2022-12-29T00:00:00";
    const structuredFinalDate = finalDate ? `${finalDate}T23:59:59` : "2022-12-29T23:59:59";

    const queryResult = useInternalChatTicketsDashboardTable({
      initialDate: structuredInitialDate,
      finalDate: structuredFinalDate
    });

    return queryResult;
  };



  //  **********************
  //  ** Table Data Query **
  //  **********************
  closedTickets = GetClosedTicketsTable(initialDate, finalDate);

  structuredClosedTickets = closedTickets === 0 ? 0 : closedTickets.map(ticket => {
    const { id, protocol, noteName, isClosedAt, withUserAt, user, userInternalChat } = ticket;

    const userId = user ? user.id : null;
    const userName = user ? user.name : null;

    const userInternalChatId = userInternalChat ? userInternalChat.id : null;
    const userInternalChatName = userInternalChat ? userInternalChat.name : null;

    const formattedDate = isClosedAt ? new Date(isClosedAt).toLocaleDateString("pt-BR") : null;
    const isClosedAtCalc = new Date(isClosedAt);
    const withUserAtCalc = new Date(withUserAt);
    const diffInMinutes = Math.floor((isClosedAtCalc - withUserAtCalc) / (1000 * 60));

    return {
      id,
      protocol,
      userId,
      userName,
      userInternalChatId,
      userInternalChatName,
      noteName,
      isClosedAt: formattedDate,
      time: diffInMinutes
    };
  });

  // ***---- Filtered DataTable ----***

  // - Se as informações já foram estruturadas e o usuário é administrador,
  // este conseguirá ver todas as informações
  if (structuredClosedTickets !== 0 && user.profile === "admin") {
    viewClosedTickets = structuredClosedTickets;
    filteredClosedTickets = viewClosedTickets;
  }

  // - Se as informações já foram estruturadas e o usuário não é administrador,
  // este conseguirá ver somente as informações relacionadas a ele
  else if (structuredClosedTickets !== 0 && user.profile !== "admin") {
    viewClosedTickets = structuredClosedTickets.filter(ticket => ticket.userId === user.id || ticket.userInternalChatId === user.id);
    filteredClosedTickets = viewClosedTickets;
  }

  // - Filtros aplicados pelo usuário
  if (selectedUserIds.length > 0) {
    filteredClosedTickets = viewClosedTickets;

    // ***---- Filtro de Usuário ----***
    if (selectedUserIds.length > 0) {
      filteredClosedTickets = filteredClosedTickets.filter(ticket => 
        selectedUserIds.includes(ticket.userId) || selectedUserIds.includes(ticket.userInternalChatId)
      );
    }
  }


  // ***---- Times ----***
  timesTicket = filteredClosedTickets === 0 ? 0 : filteredClosedTickets.map(ticket => ticket.time);

  
  // ***---- Filter Options Query ----***

  // - Users
  const uniqueUsers = [];

  if (viewClosedTickets !== 0 && viewClosedTickets.length !== 0) {
    viewClosedTickets.forEach(ticket => {
      const existingUser = uniqueUsers.length === 0
        ? false
        : uniqueUsers.find(user => user.id === ticket.userId);
      const existingUser2 = uniqueUsers.length === 0
        ? false
        : uniqueUsers.find(user => user.id === ticket.userInternalChatId);
  
      if (!existingUser && ticket.userId) uniqueUsers.push({ id: ticket.userId, name: ticket.userName });
      if (!existingUser2 && ticket.userInternalChatId) uniqueUsers.push({ id: ticket.userInternalChatId, name: ticket.userInternalChatName });
    });
  }



  // ***---- Table Structure ----***
  // chat name
  const columns = [
    {
      field: "id", type: "number", headerName: i18n.t("internalChatTicketsDashboard.table.header.id"), width: 100, align: "center",
      renderCell: (params) => (
        <div className={classes.viewTicket}>
          <MenuItem
            className={`${classes.actionButton} ${classes.viewButton}`}
            onClick={() => {handleOpenViewTicketModal(params.value)}}
          >
            <VisibilityOutlinedIcon />
          </MenuItem>
          {params.value}
        </div>
      ),
    },

    { field: "userName", type: "string", headerName: i18n.t("internalChatTicketsDashboard.table.header.user"), width: 130, },

    { field: "userInternalChatName", type: "string", headerName: i18n.t("internalChatTicketsDashboard.table.header.user"), width: 130, },

    { field: "noteName", type: "string", headerName: i18n.t("internalChatTicketsDashboard.table.header.chatName"), width: 130, },

    {
      field: "protocol", type: "string", headerName: i18n.t("internalChatTicketsDashboard.table.header.protocol"), width: 130, align: "center",
      valueGetter: (params) => { return params.value ? params.value : "-" },
    },

    {
      field: "time", type: "number", headerName: i18n.t("internalChatTicketsDashboard.table.header.time"), width: 130, align: "center",
      renderCell: (params) => (
        <div className={classes.viewPlatform}>
          {params.value <= 1 && (
            `${params.value} ${i18n.t("internalChatTicketsDashboard.table.rows.minute")}`
          )}

          {params.value > 1 && params.value < 60 && (
            `${params.value} ${i18n.t("internalChatTicketsDashboard.table.rows.minutes")}`
          )}

          {params.value > 60 && ~~(params.value / 60) === 1 && (
            `${~~(params.value / 60)} ${i18n.t("internalChatTicketsDashboard.table.rows.hour")}`
          )}

          {params.value > 60 && ~~(params.value / 60) > 1 && (
            `${~~(params.value / 60)} ${i18n.t("internalChatTicketsDashboard.table.rows.hours")}`
          )}
        </div>
      ),
    },

    { field: "isClosedAt", type: "date", headerName: i18n.t("internalChatTicketsDashboard.table.header.endedAt"), width: 130, align: "center", },
  ];

  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      <ViewTicketModal open={viewTicketModalOpen} onClose={handleCloseViewTicketModal} ticketId={ticketId} />

      <MainContainer>
        {/* 
          ***********
          ** Title **
          ***********
        */}
        <MainHeader>
          <Title>{i18n.t("internalChatTicketsDashboard.title")}</Title>
        </MainHeader>



        {/* 
          ***************
          ** Container **
          ***************
        */}
        <div className={classes.mainContainerScroll}>
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              {/* 
                *****************
                ** Date Inputs **
                *****************
              */}
              <Grid item xs={12}>
                <div className={classes.flexContainer}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    type="date"
                    id="dashboardInitialDate"
                    name="dashboardInitialDate"
                    label={i18n.t("dashboard.fields.initialDate")}
                    value={initialDate}
                    onChange={handleChangeInitialDate}
                  />

                  <TextField
                    variant="outlined"
                    margin="normal"
                    type="date"
                    id="dashboardFinalDate"
                    name="dashboardFinalDate"
                    label={i18n.t("dashboard.fields.finalDate")}
                    value={finalDate}
                    onChange={handleChangeFinalDate}
                  />
                </div>
              </Grid>

              {/* when there are datas over the selected period, the table is shown */}
              {(viewClosedTickets !== 0 && viewClosedTickets.length > 0) && (
                <>
                  {/* 
                    *************
                    ** Filters **
                    *************
                  */}
                  <Grid item xs={12} className={classes.sectionContainer}>
                    {/* 

                      ***---- Users List ----***

                    */}
                    <Can
                      role={user.profile}
                      perform="ticket-options:deleteTicket"
                      yes={() => (
                        <Grid className={classes.filterField}>
                          <UserSelect
                            selectedUserIds={selectedUserIds}
                            onChange={values => setSelectedUserIds(values)}
                            usersOptions={uniqueUsers}
                            styleSelectInputField={true}
                          />
                        </Grid>
                      )}
                    />
                  </Grid>



                  {/* 
                    ***********
                    ** Cards **
                    ***********
                  */}
                  <Grid item xs={12} className={classes.sectionContainer}>
                    {/* 
                    
                      ***---- Number of Chats ----***

                    */}
                    <Grid className={classes.cardItem}>
                      <Paper className={classes.customFixedHeightPaper}>
                        <Typography component="h3" variant="h6" paragraph>
                          {i18n.t("internalChatTicketsDashboard.amount")}
                        </Typography>

                        <Grid item>
                          <Typography component="h1" variant="h4">
                            {filteredClosedTickets.length}
                          </Typography>
                        </Grid>
                      </Paper>
                    </Grid>



                    {/* 

                      ***---- Chat with Less Attendance Time (minutes) ----***

                    */}
                    <Grid className={classes.cardItem}>
                      <Paper className={classes.customFixedHeightPaper}>
                        <Typography component="h3" variant="h6" paragraph>
                          {i18n.t("internalChatTicketsDashboard.faster")}
                        </Typography>

                        <Grid item>
                          <Typography component="h1" variant="h4">
                            {GetFasterTicket()}
                          </Typography>
                        </Grid>
                      </Paper>
                    </Grid>



                    {/* 
                      
                      ***---- Chat with More Attendance Time (minutes) ----***

                    */}
                    <Grid className={classes.cardItem}>
                      <Paper className={classes.customFixedHeightPaper}>
                        <Typography component="h3" variant="h6" paragraph>
                          {i18n.t("internalChatTicketsDashboard.longer")}
                        </Typography>

                        <Grid item>
                          <Typography component="h1" variant="h4">
                            {GetLongerTicket()}
                          </Typography>
                        </Grid>
                      </Paper>
                    </Grid>
                  </Grid>



                  {/* 
                    ***********
                    ** Table **
                    ***********
                  */}
                  <Grid item xs={12}>
                    <div style={{ height: 500, width: "100%" }}>
                      <DataGrid
                        className={classes.ticketsTable}
                        rows={filteredClosedTickets}
                        columns={columns}
                        rowsPerPageOptions={[7, 25, 50, 100]}
                        checkboxSelection={true}
                        disableColumnMenu={false}
                      />
                    </div>
                  </Grid>
                </>
              )}

              {/* when there are no datas over the selected period, a message is shown */}
              {(viewClosedTickets === 0 || viewClosedTickets.length === 0) && (
                <Paper className={classes.dashboardContainer}>
                  <div className={classes.emptyBoxContainer}>
                    <img className={classes.emptyBoxImage} src={NoDataImg} alt="No Data" />
                    <span className={classes.emptyBoxSpan}>{i18n.t("dashboard.emptyBox")}</span>
                  </div>
                </Paper>
              )}
            </Grid>
          </Container>
        </div>
      </MainContainer>
    </>
  );
};

export default InternalChatsDashboard;