import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  highlightedText: { padding: "2px", },
}));

const HighlightedText = ({ keywords, text }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();



  //  ***************
  //  ** Functions **
  //  ***************
  const highlightKeywords = (keywords, messageBody) => {
    const regex = new RegExp(`(${keywords})`, "gi");
    const parts = messageBody.split(regex);
    return parts;
  };



  //  ****************
  //  ** Text Parts **
  //  ****************
  const parts = highlightKeywords(keywords, text);



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      {parts.map((part, index) =>
        part.toLowerCase() === keywords.toLowerCase()
          ? (<mark className={classes.highlightedText} key={index}>{part}</mark>)
          : (part)
      )}
    </>
  );
};

export default HighlightedText;