import { useContext, useEffect, useReducer, useState } from "react";

import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const reducer = (state, action) => {
  if (action.type === "LOAD_WHATSAPPS") {
    const whatsapps = action.payload;

    return [...whatsapps];
  }
};

const useAllWhatsAppsDispatch = () => {
  //  ***************
  //  ** Variables **
  //  ***************
  const { isAuth } = useContext(AuthContext);
  const [whatsapps, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(true);



  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (isAuth) {
      setLoading(true);

      const fetchWhatsappsDispatch = async () => {
        try {
          const { data } = await api.get("/AllWhatsappsDispatch");
          dispatch({ type: "LOAD_WHATSAPPS", payload: data });
          setLoading(false);
        } catch (exception) {
          setLoading(false);
          console.log("Use All WhatsApps Dispatch Exception:", exception);
          toastError(exception);
        }
      };

      fetchWhatsappsDispatch();
    }
  }, [isAuth]);



  //  ************
  //  ** Return **
  //  ************
  return { whatsapps, loading }
};

export default useAllWhatsAppsDispatch;
