import React from "react";

const EventPreviewQuotedMessage = ({ message }) => {
  //  ************
  //  ** Return **
  //  ************
  return (
    <div>
      <b>📆 {message.body}</b>
    </div>
  );
};

export default EventPreviewQuotedMessage;