import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  TextField,
  Tooltip,
  useMediaQuery
} from "@material-ui/core";
import {
  BrushOutlined,
  CancelOutlined,
  CheckCircleOutlineOutlined,
  ClearOutlined
} from "@material-ui/icons";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import FileContextEditImageModal from "../FileContextEditImageModal";
import iconFieldAndFolder from '../../assets/file_and_folder.png';
import iconPlus from "../../assets/plus.png";
import toastError from "../../errors/toastError";
import truncateString from "../../utils/truncateString";

const useStyles = makeStyles((theme) => ({
  //  ********************
  //  ** Main Container **
  //  ********************
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "25px",
    padding: "1.5em",
    background: theme.backgroundImage,
  },



  //  ********************************
  //  ** Single File View Container **
  //  ********************************
  fileViewContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  fileView: {
    width: "100%",
    borderRadius: "5px",
    userSelect: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
  },

  fileIcon: {
    width: "100px",
    filter: "grayscale(.9)",
    opacity: "0.9",
    userDrag: "none",
  },

  multipleFilesIcon: {
    width: "50px",
    filter: "grayscale(.9)",
    opacity: "0.9",
    userDrag: "none",
  },

  fileSpan: {
    color: theme.palette.text.primary,
    textAlign: "center",
    opacity: "0.9",
  },

  fileName: {
    opacity: "0.9",
    textAlign: "center",
    marginTop: "1em",
  },



  //  ***********************************
  //  ** Multiple Files View Container **
  //  ***********************************
  multipleFilesViewContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "1em",
  },

  multipleFilesView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    width: "100px",
    height: "125px",
    borderRadius: "20px",
    border: "2px solid red",

    cursor: "pointer",
    transition: "all 0.3s ease",

    "&:hover": {
      transform: "translateY(-5px)",
      filter: "brightness(0.75)",
    },
  },

  removeFileContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
  },

  removeFileIconButton: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
    width: "17px",
    height: "17px",
    top: "-8px",
    left: "-3px",
    "&:hover": { backgroundColor: theme.palette.primary.main },
  },

  removeFileButton: {
    width: "17px",
  },

  actionButton: {
    color: theme.palette.text.primary,
  },

  secondaryMultipleFilesView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "3px",
  },




  //  *****************
  //  ** Edit Button **
  //  *****************
  floatingButton: {
    transition: "transform 0.3s",
    "&:hover": { transform: "translateY(-5px)" },
  },




  //  ******************
  //  ** Legend Field **
  //  ******************
  legendFieldContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
    gap: "1em",
  },

  legendIcons: { borderRadius: "20px", color: "grey" },
}));

const FileContextModal = ({ open, onClose, medias, setMedias }) => {
  //  ***************
  //  ** Variables **
  //  ***************
  const classes = useStyles();
  const { ticketId } = useParams();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [fileLegend, setFileLegend] = useState("");

  const [mediasTreatment, setMediasTreatment] = useState([]);
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaUrlIndex, setMediaUrlIndex] = useState(0);

  const [editImageModalOpen, setEditImageModalOpen] = useState(false);

  

  //  *****************
  //  ** Use Effects **
  //  *****************
  useEffect(() => {
    if (medias && medias.length > 0) { setMediaUrl(URL.createObjectURL(medias[0])); }
  }, [medias]);

  useEffect(() => {
    return () => { URL.revokeObjectURL(mediaUrl); };
  }, [mediaUrl]);

  useEffect(() => {
    if (medias && medias.length > 0) {
      const checkMediasTreatment = () => {
        const treatments = [];

        for (let index = 0; index < medias.length; index++) {
          // 
          // Codes:
          // 
          // - 1 > pdf (embed tag)
          // - 2 > txt (embed tag)
          // - 3 > md (embed tag)
          // - 4 > image (img tag)
          // - 5 > video (video tag)
          // - 6 > audio (audio tag)
          // - 7 > other file type (general visualization)
          try {
            const mediaType = medias[index].type.split("/")[0];

            if (isSmallScreen) { treatments.push(7); }
            else if (medias[index].type === "application/pdf") { treatments.push(1); }
            else if (medias[index].type === "text/plain") { treatments.push(2); }
            else if (medias[index].name.split(".")[medias[index].name.split(".").length - 1] === "md") { treatments.push(3); }
            else if (mediaType === "image") { treatments.push(4); }
            else if (mediaType === "video") { treatments.push(5); }
            else if (mediaType === "audio") { treatments.push(6); }
            else { treatments.push(7); }
          } catch (err) { treatments.push(7); }
        }

        setMediasTreatment(treatments);
      };
      checkMediasTreatment();
    }
  }, [medias, isSmallScreen]);



  //  ***************
  //  ** Functions **
  //  ***************
  const handleClose = () => {
    onClose();
    setFileLegend("");
    setMedias([]);

    setMediasTreatment([]);
    setMediaUrl("");
    setMediaUrlIndex(0);

    document.getElementById("upload-button").value = "";
  };

  const handleUploadMedia = async (files) => {
    const notAllowedFormats = ["ts", "js", "sh"];
    handleClose();

    for (let index = 0; index < files.length; index++) {
      try {
        if (notAllowedFormats.includes(files[index].name.split(".")[1])) {
          toast.info(i18n.t("backendErrors.ERR_FILE_NOT_SUPPORTED"));
        } else if (files[index].size > 8000000 * 15) {
          toast.info(i18n.t("backendErrors.ERR_FILE_SIZE_UPLOAD"));
        } else {
          const legend = index === 0 ? fileLegend : "";

          const formData = new FormData();
          formData.append("fromMe", true);
          formData.append("body",  legend);
          formData.append("isNotVoice", true);
          formData.append("medias", files[index]);

          await api.post(`/messages/${ticketId}`, formData);
        }
      } catch (error) {
        console.log("Handle Upload Media Error:", error);
        toastError(error);
      }
    }
  };

  const handleFileLegend = (event) => {
    event.target.value.replaceAll("\n", "") === ""
      ? setFileLegend("")
      : setFileLegend(event.target.value);
  };

  const handleChangeFileView = (index) => {
    setMediaUrl(URL.createObjectURL(medias[index]));
    setMediaUrlIndex(index);
  };

  const handleRemoveFile = (index) => {
    medias.splice(index, 1);
    
    const tempMediasTreatment = mediasTreatment.slice();
    tempMediasTreatment.splice(index, 1);
    setMediasTreatment(tempMediasTreatment);

    if (medias && medias.length > 0) {
      setMediaUrlIndex(0);
      setMediaUrl(URL.createObjectURL(medias[0]));
    } else { handleClose(); }
  };

  const renderMediasTreatment = (media, index) => {
    return (
      <Tooltip title={media.name} placement="top-start" arrow>
        <div className={classes.multipleFilesView}>
          <div className={classes.removeFileContainer}>
            <IconButton
              className={classes.removeFileIconButton}
              size="small"
              onClick={() => handleRemoveFile(index)}
            >
              <ClearOutlined className={`${classes.actionButton} ${classes.removeFileButton}`} />
            </IconButton>
          </div>

          <div className={classes.secondaryMultipleFilesView} onClick={() => handleChangeFileView(index)}>
            <div className={classes.fileView}>
              <img className={classes.multipleFilesIcon} src={iconFieldAndFolder} alt="Icon" />
            </div>
          
            {mediasTreatment[index] !== 0 && (
              <span className={classes.fileName}>
                {truncateString(media.name, 10)}
              </span>
            )}
          </div>
        </div>
      </Tooltip>
    );
  };

  const handleAddMoreFiles = (e) => {
    if (!e.target.files) { return; }

    const file = e.target.files[0];
    setMedias([...medias, file]);
  };

  const handleOpenEditImageModal = () => { setEditImageModalOpen(true); };
  const handleCloseEditImageModal = () => { setEditImageModalOpen(false); };



  //  ************
  //  ** Return **
  //  ************
  return (
    <>
      {mediasTreatment[mediaUrlIndex] === 4 && (
        <FileContextEditImageModal
          open={editImageModalOpen}
          onClose={handleCloseEditImageModal}
          imageUrl={mediaUrl}
          medias={medias}
          setMedias={setMedias}
          mediaUrlIndex={mediaUrlIndex}
        />
      )}

      <Dialog open={open} onClose={handleClose} maxWidth="xs" scroll="paper" fullWidth>
        <DialogTitle>
          <span>{i18n.t("fileContextModal.title.up")}</span>
        </DialogTitle>

        <div className={classes.dialogContent}>

          {/* 
            ***********************
            ** Single File View **
            ***********************
          */}
          <div className={classes.fileViewContainer}>
            {/* ***---- Visualizations ----*** */}
            {mediasTreatment[mediaUrlIndex] === 1 && (<embed className={classes.fileView} src={mediaUrl} type="application/pdf" />)}
            {mediasTreatment[mediaUrlIndex] === 2 && (<embed className={classes.fileView} src={mediaUrl} type="text/plain" />)}
            {mediasTreatment[mediaUrlIndex] === 3 && (<embed className={classes.fileView} src={mediaUrl} type="text/markdown" />)}

            {mediasTreatment[mediaUrlIndex] === 4 && (<img className={classes.fileView} alt="📁" src={mediaUrl} />)}
            {mediasTreatment[mediaUrlIndex] === 5 && (<video className={classes.fileView} controls><source src={mediaUrl} type={medias[0].type} /></video>)}
            {mediasTreatment[mediaUrlIndex] === 6 && (<audio  controls><source src={mediaUrl} type={medias[0].type} /></audio>)}

            {mediasTreatment[mediaUrlIndex] === 7 && (
              <div className={classes.fileView}>
                <img className={classes.fileIcon} src={iconFieldAndFolder} alt="Icon" />
                <span className={classes.fileSpan}>{i18n.t("fileContextModal.noVisualizationMessage")}</span>
              </div>)
            }
          
            {/* ***---- Image Edit Button ----*** */}
            {mediasTreatment[mediaUrlIndex] === 4 && (
              <>
                <br />
                <Button
                  className={classes.floatingButton}
                  startIcon={<BrushOutlined />}
                  color="inherit"
                  variant="outlined"
                  onClick={handleOpenEditImageModal}
                >{i18n.t("image.editButton")}</Button>
              </>
            )}
          </div>
          
          

          {/* 
            *************************
            ** Multiple Files View **
            *************************
          */}
          <div className={classes.multipleFilesViewContainer}>
            {medias && medias.length > 0 && medias.map((media, index) => (
              renderMediasTreatment(media, index)
            ))}

            {medias && medias.length < 5 && (
              <>
              <input
                type="file"
                id="upload-more-file"
                style={{ display: "none" }}
                onChange={handleAddMoreFiles}
              />

              <Tooltip title={i18n.t("fileContextModal.addFile")} placement="top-start" arrow>
                <label className={classes.multipleFilesView} htmlFor="upload-more-file">
                  <img className={classes.multipleFilesIcon} src={iconPlus} alt="Icon" />
                </label>
              </Tooltip>
              </>
            )}
          </div>



          <Divider />



          {/* 
            ******************
            ** Legend Field **
            ******************
          */}
          <div className={classes.legendFieldContainer}>
            <Tooltip title={i18n.t("fileContextModal.tooltips.cancel")} placement="top-start" arrow>
              <IconButton onClick={handleClose}>
                <CancelOutlined className={classes.legendIcons} />
              </IconButton>
            </Tooltip>

            <TextField
              autoFocus
              focused
              fullWidth
              multiline

              label={i18n.t("fileContextModal.textfield")}
              margin="dense"
              maxRows={3}
              name="fileLegend"
              type="text"
              value={fileLegend}
              variant="outlined"

              onChange={handleFileLegend}
              onKeyDown={(e) => { e.key === "Enter" && !e.shiftKey && handleUploadMedia(medias); }}
            />

            <Tooltip title={i18n.t("fileContextModal.tooltips.confirm")} placement="top-start" arrow>
              <IconButton onClick={() => handleUploadMedia(medias)}>
                <CheckCircleOutlineOutlined className={classes.legendIcons} />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default FileContextModal;